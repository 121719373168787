import React, {useEffect,useState} from "react";
import { BrowserRouter as Router, Link } from 'react-router-dom'

import DatosCliente from "./DatosCliente";
import ResumenProductos from "./ResumenProductos";
import Template from "../Template";
import axios from "axios";
import {Bars} from 'react-loader-spinner';
import NumberFormat from 'react-number-format';



const Pagos = (props) => {

  const [currentUser, setCurrentUser] = useState({});
  const [walletData,setWalletData] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    setLoading(true);
    loadConsulta()
  }, [])

  const loadConsulta = async () => {
    // await axios.get(`https://api.sumadata.net/pagos?usuario=14`).then((res) => {
    await axios.get(`/api/v1/safi/pagos`).then((res) => {
        setWalletData(res.data.pagos);
        setCurrentUser(res.data.currentUser);
        console.log(`PAGOS DISPLAY`, res.data.pagos)
        setLoading(false);
    });
  };

    return (
      <>
        {loading ?
          <div className="flex h-screen">
            <div className="m-auto">
              <Bars
                color="#00BFFF"
                height={100}
                width={100}
                timeout={20000} //3 secs
              />
            </div>
          </div>
         :

          <div className="overflow-y-auto">

              {/*
                <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">

                    <div class="bg-white rounded-lg w-full h-auto py-4 flex flex-row justify-between divide-x divide-solid divide-gray-400">
                        <div class="relative flex-1 flex flex-col gap-2 px-4">
                            <label class="text-gray-800 text-base font-semibold tracking-wider">Total Cartera</label>
                            <label class="text-green-800 text-4xl font-bold">$89.000 USD</label>
                            <div class="absolute bg-red-400 rounded-md font-semibold text-xs text-gray-100 p-2 right-4 bottom-0">
                                - 5%
                            </div>
                        </div>
                        <div class="relative flex-1 flex flex-col gap-2 px-4">
                            <label class="text-gray-800 text-base font-semibold tracking-wider">Meta recaudo / mes</label>
                            <label class="text-green-800 text-4xl font-bold">$7.000 USD</label>
                            <div class="absolute bg-green-400 rounded-md font-semibold text-xs text-gray-100 p-2 right-4 bottom-0">
                                + 10%
                            </div>
                        </div>
                        <div class="relative flex-1 flex flex-col gap-2 px-4">
                            <label class="text-gray-800 text-base font-semibold tracking-wider">Recaudado en el mes</label>
                            <label class="text-green-800 text-4xl font-bold">$1.200 USD</label>
                            <div class="absolute bg-green-400 rounded-md font-semibold text-xs text-gray-100 p-2 right-4 bottom-0">
                                + 5%
                            </div>
                        </div>
                    </div>
                </div> */}



            <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                  Mis pagos
                </h3>
              </div>

              <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
                <div className="ml-2 mt-2 w-full mr-2">
                  <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            N. Crédito
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Fecha de Pago
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Total Pagado
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Nombre Cliente
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Forma de Pago
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Producto
                          </th>


                        </tr>
                      </thead>
                      <tbody>

                          {walletData.rows.map(walletCredits => {

                            return(

                              <tr
                              key={walletCredits.CreditoID}
                              className="bg-white border-b border-gray-200"
                              >
                              
                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                                <a className="bg-orange-300 hover:bg-orange-400 text-orange-800 font-bold w-30 py-2 px-4 rounded inline-flex text-center">

                                <span>{walletCredits.CreditoID}</span>
                                </a>

                              </td>

                              <td className={"px-6 py-3  text-sm leading-5  text-center "}>


                                  <span className="text-sm font-medium bg-green-100 py-1 px-2 rounded text-green-500 align-middle">{walletCredits.FechaPago}</span>


                              </td>

                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-left">
                              <NumberFormat value={walletCredits.TotalPagado} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">
                              {walletCredits.NombreCliente}
                              </td>

                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {walletCredits.FormaDePago === 'C' ? 'CARGO A CUENTA' : 'EFECTIVO'}

                              </td>

                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {walletCredits.NombreProducto}
                              </td>


                            </tr>

                          )

                        })
                        }



                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );

}

export default Pagos;
