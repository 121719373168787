import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';


const Approval = (props) => {
    Moment.locale('en');

    return (
        <tr className={props.index % 2 == 0 ? "bg-white" : "bg-gray-50"}>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-500">
            Firma A
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            Comité Ag. Gerente
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            OGONZALEZ
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            Autorizado
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            2020-09-01
            </td>
        </tr>
    )
}

const Approvals = (props) => {
    const grid = props.contract.Observaciones.map( (observacion, index) => { 
        // console.log('data', contracts);
        return (
            <Approval observacion={observacion} contract={props.contract} key={observacion.NombreUsuario+observacion.Fecha.toString()} index={index}/>
        );
    });
    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                Historial de Aprobaciones
                </h3>
            </div>
            <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-col">
                <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                        <thead>
                        <tr>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Firma
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Órgano
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Autoriza
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Estatus
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Fecha
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {grid}    
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>


            </div>
        </div>

    )
}

export default Approvals
