import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

function Select (props) {
  const { label, name, options, prompt, obs, ...rest } = props
  return (
    <div className='mb-10  sm:col-span-1'>
      <label htmlFor={name} className="block text-sm leading-5 font-medium text-gray-700">{label}</label>
      <Field as='select' id={name} name={name} {...rest} className="border-gray-300 rounded-md shadow-sm w-full form-input mt-1 form-select block w-full h-10 pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5">
        {prompt || prompt==undefined ? <option key='0' value="">Seleccione una opción</option> : ''}
        {options.map(option => {
          return (
            <option key={option.key} value={option.key}>
              {option.value}
            </option>
          )
        })}
      </Field>
      <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
        <ErrorMessage name={name} />
      </span>
      {/* <ErrorMessage component={TextError} name={name} /> */}
    </div>
  )
}

export default Select
