import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';



const Documents = (props) => {
    const warning = props.contract.legal_auth.workflow_state == 'signature' ? (
        <div className="px-4 py-5 sm:px-6">
        <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
            <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                </svg>
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm leading-5 text-blue-700">
                Para iniciar el proceso de Autenticación o Registro debe cargar antes Contrato firmado por el Cliente
                </p>
            </div>
            </div>
        </div>
        </div>
    )
    :
    ''

    const files = props.files.map( (file, index) => { 
        // console.log('data', contracts);
        return (
            <div className="sm:col-span-1">
                <a target="_blank" href={file.url}>
                <img className="border-solid border-2 border-gray-200" src="/images/doc.jpg"/>
                <span className="text-xs text-gray-400">{file.name}</span>
                </a>
            </div>
        );
    });

    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                Documentos
                </h3>
            </div>
            {warning}
            <div className="px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-5 gap-y-4 gap-x-8 sm:grid-cols-5">
                {files}    
                </dl>
            </div>        
             <div className="px-4 py-5 sm:px-6">
              <label htmlFor="cover_photo" className="block text-sm leading-5 font-medium text-gray-700">
                Documento
              </label>
              <MyDropzone />
            </div>                        
        </div>
    )
}


export default Documents
