import React from "react";

const handleClickCotizacion = (simulacionID) => {
  window.open('https://www.sumafinanciera.com/landing/print.php?_id='+simulacionID);
 //window.open('http://localhost:8888/print_oferta.php?_id='+simulacionID);
};


export const ButtonsShare = (values) => {

    let buttonsShare;

    buttonsShare = (
        <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">

        
       

          <div className="ml-2 mt-2 inline-flex">
            <button type="button"
              id="sharethree"
              className="bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded"
              onClick={() => updateCotizacion(simulacionID)}
              hidden
            >
              Copiar Enlace para Cliente
            </button>
          </div>

          <div className="ml-2 mt-2 inline-flex">
            <button type="button"
              id="sharefourth"
              className="bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded"
              onClick={handleClickCotizacion}
              hidden
            >
              Ver Cotizacion
            </button>
          </div>

          
          <div className="ml-2 mt-2 inline-flex">
            <button type="button"
              id="shareone"
              className="bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded"
              //onClick={crearCotizacion}
              onClick={() => crearCotizacion(simulacionID)}
              hidden
            >
              Enviar Cotización a Atención al Cliente
            </button>
          </div>
          </div>
      )
    
    return(
      <div>
        {buttonsShare}
      </div>

    )

  }