import { Controller } from "@hotwired/stimulus"
import React from 'react';
import ReactDOM from "react-dom"
import Example from "../components/tailwind"


export default class extends Controller {
  
  connect() {
    console.log('Tailwind controller', this.reactProps)
    ReactDOM.render(
      // React.createElement(Example, this.reactProps),
      <Example />,
      this.element
    )
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.element)
  }
}

/* HTML
  <div controller="react"
    data-react-component="HelloWorld"
    data-react-props="{ \"name\": \"Daniel\" }"
  />
*/
// export default class ReactController extends Controller {
//   connect() {
//     // ReactDOM.render(
//     //   React.createElement(this.reactComponent, this.reactProps),
//     //   this.element
//     // )
//     console.log('ReactController');
//     render(<Example />, this.element);
//   }

//   disconnect() {
//     ReactDOM.unmountComponentAtNode(this.element)
//   }

//   // get reactComponent() {
//   //   const component = window.eval(this.data.react.component)
//   //   if (!component) {
//   //     throw "Component not available on window"
//   //   }
//   //   return component
//   // }

//   // get reactProps() {
//   //   return JSON.parse(this.data.react.props)
//   // }
// }