import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
import DocLogs from './DocLogs'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import Debug from '../Debug';
import JSONPretty from 'react-json-prettify';
import * as Yup from 'yup'
import FormikControl from '../forms/FormikControl'
import FieldValue from './FieldValue';


const docsVehicles = [
    ["Autorizaciones para traspasos vehiculares","6215a925ad14e13b99619a05"],
    ["Hipoteca gestiones vehiculares","624b3ab8ad14e131a3f853ee"]
]
const docsHash = {
    "Alivio Financiero BNP Vehículo (Mayor a 5.000)": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Declaración del Destino de los Fondos","5fc8e1e6ad14e1260bbc329f"],
        ["Pagaré","5fc91818ad14e14466d00383"],
        ["Contrato","5fc92164ad14e14466d00384"]
    ], 
    "Alivio Financiero (Menor a 5.000)": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Declaración del Destino de los Fondos","5fc8e1e6ad14e1260bbc329f"],
        ["Pagaré","5fc91818ad14e14466d00383"],
        ["Contrato","5fc92164ad14e14466d00384"]
    ],    
    // "Alivio Financiero (Menor a 5.000)": [
    //     [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
    //     ["Declaración del Destino de los Fondos","5fc8e1e6ad14e1260bbc329f"],
    //     ["Pagaré","5fc91818ad14e14466d00383"],
    //     ["Contrato","5fc92164ad14e14466d00384"]
    // ],
    "Alivio Financiero Vehículo (Mayor a 5.000)": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Declaración del Destino de los Fondos","5fc8e1e6ad14e1260bbc329f"],
        ["Pagaré","5fc91818ad14e14466d00383"],
        ["Contrato","5fc92164ad14e14466d00384"]
    ],
    "Alivio Financiero BNP Inmobiliaria (Mayor a 5.000)": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Declaración del Destino de los Fondos","5fc8e1e6ad14e1260bbc329f"],
        ["Pagaré","5fc91818ad14e14466d00383"],
        ["Acuerdo de Desembolso con Fondos de BNP","5fc2c857ad14e1362a3483f9"],
        ["Acta Cesión Prendaria", "649ce766ad14e146b9cb2bec"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
        // ["Contrato de línea","6009dde1ad14e1434c7dfedd"]
    ],
    "Prendario (Menor a $5.000)": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré	", "5f9725ffcdebfd1623684334"],
        ["Cesión de Bienes	", "5f97264bcdebfd1623684335"],
        ["Contrato", "61982522ad14e163b4a20ba9"],
        ["Acta Cesión Prendaria", "649ce766ad14e146b9cb2bec"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Prendario + Vehículo (Menor a 5.000)": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré	", "5f9725ffcdebfd1623684334"],
        ["Cesión de Bienes	", "5f97264bcdebfd1623684335"],
        ["Contrato", "61982522ad14e163b4a20ba9"],
        ["Acta Cesión Vehículos", "6494df5dad14e16a57e512c3"],
        ["Acta Cesión Prendaria", "649ce766ad14e146b9cb2bec"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Vehículo (Menor a 5.000)": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré	", "5f9725ffcdebfd1623684334"],
        ["Cesión de Bienes	", "5f97264bcdebfd1623684335"],
        ["Contrato", "61982522ad14e163b4a20ba9"],
        ["Acta Cesión Vehículos", "6494df5dad14e16a57e512c3"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Prendario (Menor a $5.000) Producto 130": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré	", "5f9725ffcdebfd1623684334"],
        ["Cesión de Bienes	", "5f97264bcdebfd1623684335"],
        ["Contrato", "60d35b98ad14e146dc02f0df"],
        ["Acta Cesión Prendaria", "649ce766ad14e146b9cb2bec"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Producto 61 - Suma Express Xtra": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré	", "5f9725ffcdebfd1623684334"],
        ["Cesión de Bienes	", "5f97264bcdebfd1623684335"],
        ["Contrato Suma Express Xtra", "649375f5ad14e15ffeb4e84e"],
        ["Acta Cesión Prendaria", "649ce766ad14e146b9cb2bec"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Prendario + Vehículo (Menor a 5.000) Producto 130": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré	", "5f9725ffcdebfd1623684334"],
        ["Cesión de Bienes	", "5f97264bcdebfd1623684335"],
        ["Contrato", "60d35b98ad14e146dc02f0df"],
        ["Acta Cesión Vehículos", "6494df5dad14e16a57e512c3"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Prendario (Mayor a $5.000) Producto 130": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré	", "5f9725ffcdebfd1623684334"],
        ["Cesión de Bienes	", "5f97264bcdebfd1623684335"],
        ["Acta Cesión Prendaria", "649ce766ad14e146b9cb2bec"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Prendario + Vehículo (Mayor a 5.000) Producto 130": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré	", "5f9725ffcdebfd1623684334"],
        ["Cesión de Bienes	", "5f97264bcdebfd1623684335"],
        ["Acta Cesión Vehículos", "6494df5dad14e16a57e512c3"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Hipoteca Mobiliaria": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré	", "5f9725ffcdebfd1623684334"],
        ["Cesión de Bienes	", "5f97264bcdebfd1623684335"],
        ["Acta Cesión Vehículos", "6494df5dad14e16a57e512c3"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],

        ["Acuerdo de desembolso Línea de Crédito", "61a2764dad14e1505cbe5b2d"],
        ["Acuerdo de desembolso Línea de Crédito Nuevo", "64c02f72ad14e16f0084b2ea"],


        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Hipoteca Mobiliaria (Menor a 5.000)": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré	", "5f9725ffcdebfd1623684334"],
        ["Contrato", "60d35b98ad14e146dc02f0df"],
        ["Cesión de Bienes	", "5f97264bcdebfd1623684335"],
        ["Acta Cesión Vehículos", "6494df5dad14e16a57e512c3"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Hipoteca Inmobiliaria": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré	", "5f9725ffcdebfd1623684334"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Hipoteca Mobiliaria Producto 130": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré	", "5f9725ffcdebfd1623684334"],
        ["Cesión de Bienes	", "5f97264bcdebfd1623684335"],
        ["Acta Cesión Prendaria", "649ce766ad14e146b9cb2bec"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Hipoteca Inmobiliaria Producto 130": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré	", "5f9725ffcdebfd1623684334"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Hipoteca Inmobiliaria Producto 130 + Vehículo": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré	", "5f9725ffcdebfd1623684334"],
        ["Cesión de Bienes	", "5f97264bcdebfd1623684335"],
        ["Acta Cesión Vehículos", "6494df5dad14e16a57e512c3"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Alivio Financiero BNP Prendario (Menor a 5.000)": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Declaración del Destino de los Fondos","5fc8e1e6ad14e1260bbc329f"],
        ["Pagaré","5fc91818ad14e14466d00383"],
        ["Contrato","5fc92164ad14e14466d00384"],
        ["Acta Cesión Prendaria", "649ce766ad14e146b9cb2bec"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Alivio Financiero BNP Prendario y Vehículo (Mayor a 5.000)": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Declaración del Destino de los Fondos","5fc8e1e6ad14e1260bbc329f"],
        ["Pagaré","5fc91818ad14e14466d00383"],
        ["Contrato","5fc92164ad14e14466d00384"],
        ["Acta Cesión Vehículos", "6494df5dad14e16a57e512c3"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    // "Alivio Financiero BNP Inmobiliaria (Mayor a 5.000)": [
    //     ["Plan de Pagos	", "5f303932cdebfdbb701c39f4"],
    //     ["Declaración del Destino de los Fondos","5fc8e1e6ad14e1260bbc329f"],
    //     ["Pagaré","5fc91818ad14e14466d00383"],
    //     ["Acuerdo de Desembolso con Fondos de BNP","5fc2c857ad14e1362a3483f9"]
    // ],
    "Vehículo mayor a 5000": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré	", "5f9725ffcdebfd1623684334"],
        ["Cesión de Bienes	", "5f97264bcdebfd1623684335"],
        ["Acta Cesión Vehículos", "6494df5dad14e16a57e512c3"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Contrato Garantía Inmobiliaria":[
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré","5fc91818ad14e14466d00383"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Contrato Garantía Mobiliaria (Mayor a $5000)": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré	", "5f9725ffcdebfd1623684334"],
        ["Cesión de Bienes	", "5f97264bcdebfd1623684335"],
        ["Acta Cesión Prendaria", "649ce766ad14e146b9cb2bec"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ],
    "Producto 140 - Suma Refuerzo": [
        [["Plan de Pagos (PDF)", "5f303932cdebfdbb701c39f4"],["(XLS)", "5f303932cdebfdbb701c39f4",'xlsx']],
        ["Pagaré	", "5f9725ffcdebfd1623684334"],
        ["Contrato Suma Refuerzo", "619bd25aad14e14ba7a20ba9"],
        ["Acta Cesión Prendaria", "649ce766ad14e146b9cb2bec"],
        // ["Autorización Descuento Jubilados", "6494f8c9ad14e16a57e512c5"],
        ["Autorización Descuento Privados", "649e1f50ad14e17fcc18a6d4"]
    ]
}

const municipiosOptions = [
    { key: "Aguadulce", value: "Aguadulce"},
    { key: "Alanje", value: "Alanje"},
    { key: "Antón", value: "Antón"},
    { key: "Arraiján", value: "Arraiján"},
    { key: "Atalaya", value: "Atalaya"},
    { key: "Balboa", value: "Balboa"},
    { key: "Barú", value: "Barú"},
    { key: "Besikó", value: "Besikó"},
    { key: "Bocas del Toro", value: "Bocas del Toro"},
    { key: "Boquerón", value: "Boquerón"},
    { key: "Boquete", value: "Boquete"},
    { key: "Bugaba", value: "Bugaba"},
    { key: "Calobre", value: "Calobre"},
    { key: "Cañazas", value: "Cañazas"},
    { key: "Capira", value: "Capira"},
    { key: "Cémaco", value: "Cémaco"},
    { key: "Chagres", value: "Chagres"},
    { key: "Chame", value: "Chame"},
    { key: "Changuinola", value: "Changuinola"},
    { key: "Chepigana", value: "Chepigana"},
    { key: "Chepo", value: "Chepo"},
    { key: "Chimán", value: "Chimán"},
    { key: "Chiriquí Grande", value: "Chiriquí Grande"},
    { key: "Chitré", value: "Chitré"},
    { key: "Colón", value: "Colón"},
    { key: "David", value: "David"},
    { key: "Dolega", value: "Dolega"},
    { key: "Donoso", value: "Donoso"},
    { key: "Gualaca", value: "Gualaca"},
    { key: "Guararé", value: "Guararé"},
    { key: "Jirondai", value: "Jirondai"},
    { key: "Kankintú", value: "Kankintú"},
    { key: "Kusapin", value: "Kusapin"},
    { key: "La Chorrera", value: "La Chorrera"},
    { key: "La Mesa", value: "La Mesa"},
    { key: "La Pintada", value: "La Pintada"},
    { key: "Las Minas", value: "Las Minas"},
    { key: "Las Palmas", value: "Las Palmas"},
    { key: "Las Tablas", value: "Las Tablas"},
    { key: "Los Pozos", value: "Los Pozos"},
    { key: "Los Santos", value: "Los Santos"},
    { key: "Macaracas", value: "Macaracas"},
    { key: "Mariato", value: "Mariato"},
    { key: "Mironó", value: "Mironó"},
    { key: "Montijo", value: "Montijo"},
    { key: "Müna", value: "Müna"},
    { key: "Natá", value: "Natá"},
    { key: "Nole Duima", value: "Nole Duima"},
    { key: "Ñurum", value: "Ñurum"},
    { key: "Ocú", value: "Ocú"},
    { key: "Olá", value: "Olá"},
    { key: "Panamá", value: "Panamá"},
    { key: "Parita", value: "Parita"},
    { key: "Pedasí", value: "Pedasí"},
    { key: "Penonomé", value: "Penonomé"},
    { key: "Pesé", value: "Pesé"},
    { key: "Pinogana", value: "Pinogana"},
    { key: "Pocrí", value: "Pocrí"},
    { key: "Portobelo", value: "Portobelo"},
    { key: "Remedios", value: "Remedios"},
    { key: "Renacimiento", value: "Renacimiento"},
    { key: "Río de Jesús", value: "Río de Jesús"},
    { key: "Sambú", value: "Sambú"},
    { key: "San Carlos", value: "San Carlos"},
    { key: "San Félix", value: "San Félix"},
    { key: "San Francisco", value: "San Francisco"},
    { key: "San Lorenzo", value: "San Lorenzo"},
    { key: "San Miguelito", value: "San Miguelito"},
    { key: "Santa Catalina", value: "Santa Catalina"},
    { key: "Santa Fé", value: "Santa Fé"},
    { key: "Santa Isabel", value: "Santa Isabel"},
    { key: "Santa María", value: "Santa María"},
    { key: "Santiago", value: "Santiago"},
    { key: "Soná", value: "Soná"},
    { key: "Taboga", value: "Taboga"},
    { key: "Tolé", value: "Tolé"},
    { key: "Tonosí", value: "Tonosí"},    
]

const Legal = (props) => {
    const docs = () => {
        let docs1 = []
        let docs2 = []
        if (props.contract.workflow_state == "CO")
            docs1 = [docsHash["Alivio Financiero BNP Vehículo (Mayor a 5.000)"][0]]
        else if (props.contract.workflow_state != "CD") {
            if (props.contract.tipo_contrato != null && props.contract.tipo_contrato != undefined)   
                docs1 =  docsHash[props.contract.tipo_contrato]
            docs2 = props.contract.any_vehicle ? docsVehicles : []                        
        }

        console.log('state', props.contract.workflow_state)
        console.log('docs1', docs1)
        console.log('docs2', docs2)
        
        return [...docs1, ...docs2]
    }

    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                {props.contract.workflow_state == "CO" ? "Plan de Pagos" : "Gestión Legal"}
                </h3>
            </div>
            {
                props.contract.workflow_state != "CO" &&
                <div className="px-4 py-5 sm:px-6">
                    {/* <JSONPretty json={props.contract} /> */}
                    <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                    <FieldValue label={"Tipo de Contrato"}>{props.contract.tipo_contrato}</FieldValue>    
                    <FieldValue label={`Responsable  ${props.contract.Monto <= 5000.0 ? "de ATC" : "de Legal"}`}>{props.contract.responsible.name}</FieldValue>    
                    {   false && [
                            "Prendario + Vehículo (Menor a 5.000)", 
                            "Prendario + Vehículo (Menor a 5.000) Producto 130", 
                            "Prendario + Vehículo (Mayor a 5.000) Producto 130", 
                            "Alivio Financiero BNP Prendario y Vehículo (Mayor a 5.000)", 
                            "Vehículo mayor a 5000",
                            "Hipoteca Inmobiliaria Producto 130 + Vehículo",
                            "Hipoteca Mobiliaria Producto 130"
                        ].includes(props.contract.tipo_contrato) &&
                        <FieldValue label={"Se autoriza para gestiones a"}>{props.contract.AutorizaGestionNombreCompleto}</FieldValue>    
                    }
                    {   false && [
                            "Prendario + Vehículo (Menor a 5.000)", 
                            "Prendario + Vehículo (Menor a 5.000) Producto 130", 
                            "Prendario + Vehículo (Mayor a 5.000) Producto 130", 
                            "Alivio Financiero BNP Prendario y Vehículo (Mayor a 5.000)", 
                            "Vehículo mayor a 5000",
                            "Hipoteca Inmobiliaria Producto 130 + Vehículo",
                            "Hipoteca Mobiliaria Producto 130"
                        ].includes(props.contract.tipo_contrato) &&
                        <FieldValue label={"Cédula de autorizado"}>{props.contract.AutorizaGestionCedula}</FieldValue>    
                    }
                    {   false && [
                            "Prendario + Vehículo (Menor a 5.000)", 
                            "Prendario + Vehículo (Menor a 5.000) Producto 130", 
                            "Prendario + Vehículo (Mayor a 5.000) Producto 130", 
                            "Alivio Financiero BNP Prendario y Vehículo (Mayor a 5.000)", 
                            "Vehículo mayor a 5000",
                            "Hipoteca Inmobiliaria Producto 130 + Vehículo",
                            "Hipoteca Mobiliaria Producto 130"
                        ].includes(props.contract.tipo_contrato) &&
                        <FieldValue label={"Gestionar en Municipio"}>{props.contract.AutorizaMunicipio}</FieldValue>    
                    }
                    {   props.contract.tipo_contrato == "Alivio Financiero BNP Inmobiliaria (Mayor a 5.000)" &&
                        <FieldValue label={"Número de Escritura"}>{props.contract.numero_escritura}</FieldValue>    
                    }
                    {   props.contract.tipo_contrato == "Alivio Financiero BNP Inmobiliaria (Mayor a 5.000)" &&
                        <FieldValue label={"Folio Finca"}>{props.contract.folio_finca}</FieldValue>    
                    }
                    {   props.contract.tipo_contrato == "Alivio Financiero BNP Inmobiliaria (Mayor a 5.000)" &&
                        <FieldValue label={"Código Ubicación"}>{props.contract.codigo_ubicacion}</FieldValue>    
                    }
                    {   props.contract.tipo_contrato == "Alivio Financiero BNP Inmobiliaria (Mayor a 5.000)" &&
                        <FieldValue label={"Fecha Notaría"}>
                            {Moment(props.contract.fecha_notaria).format('YYYY-MM-DD')}
                        </FieldValue>
                    }
                    {   props.contract.tipo_contrato == "Alivio Financiero BNP Inmobiliaria (Mayor a 5.000)" &&
                        <FieldValue label={"Fecha Registro"}>
                            {Moment(props.contract.fecha_registro).format('YYYY-MM-DD')}
                        </FieldValue>
                    }
                    {   props.contract.tipo_contrato == "Alivio Financiero BNP Inmobiliaria (Mayor a 5.000)" &&
                        <FieldValue label={"Dueño de Finca"}>
                            {props.contract.dueno_finca = 'undefined' ? '' : props.contract.dueno_finca.NombreCompleto}
                        </FieldValue>
                    }
                    </dl>
                </div>                
            }

            <div className="px-4 py-5 sm:px-6">
                    <div className="text-sm leading-5 font-medium text-gray-500">
                    Imprimir Documentos
                    </div>
                    <DocButtons docs={docs(props)} contract={props.contract} credit={props.credit} currentUser={props.currentUser}/>    
                    <DocLogs contract={props.contract} />

            </div>
        </div>
    )
}

const DocButtons = (props) => {
    const buttons = props.docs == undefined ? '' :
        props.docs.map( (doc, index) => { 
            if(Array.isArray(doc[0])) {
                var format1 = doc[0][2] == undefined ? '' : `.${doc[0][2]}`
                var format2 = doc[1][2] == undefined ? '' : `.${doc[1][2]}`
                return (
                    <div className="mt-2 mr-2 inline-flex" key={index}>
                        <a href={`/gen_docs/${doc[0][1]}/print${format1}?debug=${props.contract.SolicitudCreditoID}`} target="_blank">
                        <button
                        type="button"
                        className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-l-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                        >
                        {doc[0][0]}
                        </button>
                        </a>

                        <a href={`/gen_docs/${doc[1][1]}/print${format2}?debug=${props.contract.SolicitudCreditoID}`} target="_blank">
                        <button
                        type="button"
                        className="-ml-px inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-r-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                        >
                        {doc[1][0]}
                        </button>
                        </a>
                    </div>
                )    
            } else if(doc[0] != "Contrato de línea" || (doc[0] == "Contrato de línea" && props.currentUser.is_admin)) {
                if(doc[1] == "61a2764dad14e1505cbe5b2d" || doc[1] == "64c02f72ad14e16f0084b2ea") { //Optional only for Legal
                    if(props.currentUser.is_legal)
                        return (
                            <div className="mt-2 mr-2 inline-flex" key={index}>
                                <a href={`/gen_docs/${doc[1]}/print?debug=${props.contract.SolicitudCreditoID}`} target="_blank">
                                <button type="button" 
                                className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                >
                                {doc[0]}
                                </button>                
                                </a>
                            </div>
                        )
                } else {
                    var format = doc[2] == undefined ? '' : `.${doc[2]}`
                    return (
                        <div className="mt-2 mr-2 inline-flex" key={index}>
                            <a href={`/gen_docs/${doc[1]}/print${format}?debug=${props.contract.SolicitudCreditoID}`} target="_blank">
                            <button type="button" 
                            className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                            >
                            {doc[0]}
                            </button>                
                            </a>
                        </div>
                    )
                }
            }
            // else
            //     return ();
        });

    return (
        <div>
            {buttons}
            { props.credit.LineaCreditoID != null && props.credit.LineaCreditoID != 0 &&
            <DocLineaCreditoButton contract={props.contract} currentUser={props.currentUser}/>
            }
            { props.contract.workflow_state != "CO" && props.contract.has_obsequial &&
            <DocObsequialButton contract={props.contract} currentUser={props.currentUser}/> 
            }
        </div>

    );
}

const DocObsequialButton = (props) => {
    return (    
        <div className="mt-2 mr-2 inline-flex">
            <a href={`/gen_docs/obsequial_print?debug=${props.contract.SolicitudCreditoID}`} target="_blank">
            <button
            type="button"
            className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-l-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
            >
            Certificado Memorial
            </button>
            </a>
            <span className="mx-4 mb-1 self-center text-red-500">
            Certificado Memorial solo se emite hasta 70 años
            </span>
        </div>
    )
}

const DocLineaCreditoButton = (props) => {
    const docs = [
        ["Acuerdo de desembolso Línea de Crédito", "61a2764dad14e1505cbe5b2d"], 
        ["Acuerdo de desembolso Línea de Crédito Nuevo", "64c02f72ad14e16f0084b2ea"]
    ]
    return props.contract.ProductoCreditoID == 130 ? (    
        <div className="mt-2 mr-2 inline-flex">
            <a href={`/gen_docs/${docs[0][1]}/print?debug=${props.contract.SolicitudCreditoID}`} target="_blank">
            <button
            type="button"
            className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-l-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
            >
            {docs[0][0]}
            </button>
            </a>
        </div>
    ) : (    
        <div className="mt-2 mr-2 inline-flex">
            <a href={`/gen_docs/${docs[1][1]}/print?debug=${props.contract.SolicitudCreditoID}`} target="_blank">
            <button
            type="button"
            className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-l-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
            >
            {docs[1][0]}
            </button>
            </a>
        </div>
    )
}


const Check = (props) => (
    <div className="max-w-lg space-y-4">                                     
    <div className="relative flex items-start">
        <div className="flex items-center h-5">
        <input id={props.name} name={props.name} type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
        </div>
        <div className="ml-3 text-sm">
        <label htmlFor={props.name} className="font-medium text-gray-700">{props.children}</label>
        </div>
    </div>
    </div>
)


const LegalEdit = forwardRef((props, ref) => {
    const options = props.legalUsers.map( (user, index) => { 
        return {value:user.NombreCompleto, key:user.UsuarioID}
    });

    const duenos = () => {
        const avales = props.contract.Avales.map( (aval, index) => { 
            return {value:aval.NombreCompleto, key:`C${aval.AvalID}`}
        })
        return [{value:props.client.NombreCompleto, key:`D${props.client.ClienteID}`}].concat(avales);
    }

    const optionsTipoContrato = () => { 
        var a = (props.contract.ProductoCreditoID == 140) ? [
            ["Producto 140 - Suma Refuerzo"] 
        ] : (
                (props.contract.ProductoCreditoID == 130) ? [
                "Prendario (Menor a $5.000) Producto 130",
                "Prendario + Vehículo (Menor a 5.000) Producto 130",
                "Prendario (Mayor a $5.000) Producto 130",
                "Prendario + Vehículo (Mayor a 5.000) Producto 130",
                "Hipoteca Mobiliaria Producto 130",
                "Hipoteca Inmobiliaria Producto 130",
                "Hipoteca Inmobiliaria Producto 130 + Vehículo",
                "Vehículo mayor a 5000"
            ] : (
                (props.contract.ProductoCreditoID == 61) ? [
                    ["Producto 61 - Suma Express Xtra"] 
                ] : [
                    "Prendario (Menor a $5.000)",
                    "Prendario + Vehículo (Menor a 5.000)",
                    "Hipoteca Mobiliaria",
                    "Hipoteca Mobiliaria (Menor a 5.000)",
                    "Hipoteca Inmobiliaria",
                    "Vehículo mayor a 5000",
                    "Vehículo (Menor a 5.000)"
                ]
            )
             
        )

        // a.push(
        //     "Vehículo mayor a 5000"
        // );

        if (props.contract.ProductoCreditoID == 100 || props.contract.ProductoCreditoID == 110 || props.contract.ProductoCreditoID == 120) {
            a.push(
                "Alivio Financiero BNP Prendario (Menor a 5.000)",
                "Alivio Financiero BNP Prendario y Vehículo (Mayor a 5.000)",
                "Alivio Financiero BNP Inmobiliaria (Mayor a 5.000)"
            );
        }
        a.sort();

        return a.map( (item, index) => {
            return {key:item, value:item}
        });
    };

    const checkboxOptions1 = [
        { key: "Producción", value: "Producción" },
        { key: "Industria", value: "Industria" },
        { key: "Comercialización", value: "Comercialización" },
        { key: "Insumos", value: "Insumos" },
        { key: "Servicio de Transporte", value: "Servicio de Transporte" },
        { key: "Almacenamiento", value: "Almacenamiento" },
        { key: "Procesamiento", value: "Procesamiento" },
        { key: "Conservación y mercados de productos alimenticios", value: "Conservación y mercados de productos alimenticios" },
      ]
    
    const checkboxOptions2 = [
        { key: "Producción y Manufactura", value: "Producción y Manufactura" },
        { key: "Comercio", value: "Comercio" },
        { key: "Construcción", value: "Construcción" },
        { key: "Artesanía", value: "Artesanía" },
        { key: "Fondas / Restaurantes", value: "Fondas / Restaurantes" },
        { key: "Educación", value: "Educación" },
        { key: "Transporte Público", value: "Transporte Público" },
        { key: "Otros Servicios", value: "Otros Servicios" },
      ]
    

    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                Edición de Gestión Legal
                </h3>
            </div>
            <div className="px-4 py-5 sm:px-6">
                {/* <JSONPretty json={values.responsible_id} /> */}

                <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                        <FormikControl
                            control='select'
                            label='Tipo de Contrato'
                            name='tipo_contrato'
                            options={optionsTipoContrato()}
                        />
                        <FormikControl
                            control='select'
                            label={`Responsable ${props.contract.Monto <= 5000.0 ? "de ATC" : "de Legal"}`}
                            name='responsible_id'
                            options={options}
                        />                        
                </div>


                {   props.values.tipo_contrato == "Alivio Financiero BNP Inmobiliaria (Mayor a 5.000)" &&
                        <div>
                        <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                            <FormikControl
                                control='input'
                                label='Número de Escritura'
                                name='numero_escritura'
                            />
                            <FormikControl
                                control='input'
                                label='Folio Finca'
                                name='folio_finca'
                            />
                            <FormikControl
                                control='input'
                                label='Código Ubicación'
                                name='codigo_ubicacion'
                            />
                            <FormikControl
                                control='date'
                                label='Fecha Notaría'
                                name='fecha_notaria'
                            />                            
                            <FormikControl
                                control='date'
                                label='Fecha Registro'
                                name='fecha_registro'
                            />          
                            <FormikControl
                                control='select'
                                label='Dueño de Finca'
                                name='dueno_finca_raw'
                                options={duenos()}
                            />                                
                        </div>                            

                        <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-2">
                            <FormikControl
                                control='checkbox'
                                label='MIPYME Sector Agropecuario y su cadena de valor alineados con la seguridad alimentaria'
                                name='checksblock'
                                options={checkboxOptions1}
                            />                            
                            <FormikControl
                                control='checkbox'
                                label='MIPYME Otros Sectores'
                                name='checksblock'
                                options={checkboxOptions2}
                            />                            
                                
                        </div>
                        

                        </div>
                }

                {   
                    // [
                    //     "Prendario + Vehículo (Menor a 5.000)", 
                    //     "Prendario + Vehículo (Menor a 5.000) Producto 130", 
                    //     "Prendario + Vehículo (Mayor a 5.000) Producto 130", 
                    //     "Alivio Financiero BNP Prendario y Vehículo (Mayor a 5.000)", 
                    //     "Vehículo mayor a 5000",
                    //     "Hipoteca Inmobiliaria Producto 130 + Vehículo",
                    //     "Hipoteca Mobiliaria Producto 130"
                    // ].includes(props.values.tipo_contrato) &&
                    props.values.any_vehicle &&
                    <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                        {props.values.Garantias.map((garantia, index) => {
                            return (
                                <FormikControl
                                    control='select'
                                    label={`Firmante Hipoteca (Garantía: ${garantia.GarantiaID})`}
                                    name={`Garantias[${index}].firmante_hipoteca`}
                                    options={garantia.firmantes.map( (firmante) => {
                                        return {key: firmante, value: firmante} 
                                    })}

                                />      
                            )                         
                        })}

                        { false &&
                            <div>
                                <FormikControl
                                    control='input'
                                    label='Se autoriza para gestiones a'
                                    name='AutorizaGestionNombreCompleto'
                                />
                                <FormikControl
                                    control='input'
                                    label='Cédula de autorizado'
                                    name='AutorizaGestionCedula'
                                />                               
                                <FormikControl
                                    control='select'
                                    label='Gestionar en Municipio'
                                    name='AutorizaMunicipio'
                                    options={municipiosOptions}        
                                />                               
                            </div>
                        }
                    </div>                            
                }

                
            </div>            

        </div>
    )
})


export {Legal, LegalEdit};
