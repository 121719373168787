import moment from 'moment';


export const calculateAgeDetails = (dateOfBirth, termInMonths) => {
    // Ensure the dateOfBirth is a Date object
    const birthDayCopied = new Date(dateOfBirth);
  
    // Calculate the age at the starting of the credit
    let ageStartingCredit = moment().diff(birthDayCopied, 'years');
  
    // Calculate the new date after adding the term in months to the birth date
    const newDate = new Date(birthDayCopied.setMonth(birthDayCopied.getMonth() + Number(termInMonths)));
  
    // Calculate the age at the ending of the credit
    let ageEndingCredit = moment().diff(new Date(newDate), 'years');
    
    // Initialize finalAgeDifference
    let finalAgeDifference = 0;
  
    let finalAge;
  
    // Determine the final age based on the starting and ending ages
    if (Number(ageStartingCredit) > Number(ageEndingCredit)) {
      finalAgeDifference = Number(ageStartingCredit) - Number(ageEndingCredit);
      finalAge = Number(ageStartingCredit) + finalAgeDifference;
    } else {
      finalAge = Number(ageStartingCredit);
    }
  
    return {
      finalAge,
      ageStartingCredit,
      finalAgeDifference,
      ageEndingCredit
    };
  };