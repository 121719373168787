import React, {useEffect,useState,useRef} from "react";
import NumberFormat from 'react-number-format';
import { v4 as uuid_v4 } from "uuid";
import { useReactToPrint } from "react-to-print";


import {Bars} from 'react-loader-spinner';
import axios from 'axios';
import Moment from 'moment';

const UltimosPagos = (props) => {

  console.log(`PROPS COMING FROM ULTIMOS PAGOS`, props);
  const pagosData = props.pagosData.rows || props.pagosData;

  return(
    <div>
    {props.creditsData.map((credit, index) => {
    
            const creditoPago = pagosData.map((pago) => {
    
              return (
                <tr
                  key={index}
                  className="bg-white border-b border-gray-200"
                >
                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {pago.FechaPago}
                  </td>

                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {pago.RefPago}
                  </td>

                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    <NumberFormat value={pago.Capital} displayType={'text'} thousandSeparator={true} prefix={'$'} />
    
                  </td>

                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    <NumberFormat value={pago.Intereses} displayType={'text'} thousandSeparator={true} prefix={'$'} />
    
                  </td>

                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    <NumberFormat value={pago.Moratorios} displayType={'text'} thousandSeparator={true} prefix={'$'} />
    
                  </td>
    
                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    <NumberFormat value={pago.TotalPagado} displayType={'text'} thousandSeparator={true} prefix={'$'} />
    
                  </td>
    
                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {pago.FormaDePago === 'C' ? 'CARGO A CUENTA' : 'EFECTIVO'}
    
                  </td>
    
                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {pago.NombreProducto}
                  </td>
                </tr>
              )
            });

      return(
    <div
          key={uuid_v4()}
          className="px-7 py-5 flex flex-wrap -mx-5 mb-2"
        >
          <div className="flex-grow mx-6 mt-1 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
              <h3 className="text-left text-xl leading-6 font-small text-gray-900">
                Ultimos pagos recibidos SAFI (corte ayer):{" "}
                <span className="text-orange-600">{credit.CreditoID}</span>
              </h3>
            </div>
                      <div className="pl-8 pr-8 pb-5 text-grey-darkest">
                        <div className="flex flex-col">
                          <div className=" py-4 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                            <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="min-w-full">
                                <thead>
                                  <tr>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Fecha Pago
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Referencia Pago
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Capital
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Interes
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Moratorios
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Total Pagado
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Canal de Pago
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Producto
                                    </th>

                                  </tr>
                                </thead>
                                <tbody>{creditoPago}</tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
              
          </div>
        </div>
      
      )
              })}
              </div>
  )


}

export default UltimosPagos;
