import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ["elementToHide", "selectorIcon"]

  toggle(event) {
    this.elementToHideTarget.nextElementSibling.classList.toggle("hidden")
    if (this.elementToHideTarget.nextElementSibling.classList.contains("hidden") )
      this.selectorIconTarget.setAttribute('d', "M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z")
    else
      this.selectorIconTarget.setAttribute('d', "M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z")
  }

  // connect() {
  //   console.log('Conected Toggler');
  // }
}
