import React, {useEffect,useState,useRef} from "react";
import NumberFormat from 'react-number-format';
import { v4 as uuid_v4 } from "uuid";
import { useReactToPrint } from "react-to-print";


import {Bars} from 'react-loader-spinner';
import axios from 'axios';
import Moment from 'moment';



const ResumenCredito = (props) => {

  const creditsTable = (
    <table>
      <thead>
        <tr>
          <th>N Cuota</th>
          <th>Fecha Inicio</th>
          <th>Fecha Exigible</th>
          <th>Días</th>
          <th>Capital</th>
          <th>Interés</th>
          <th>FECI</th>
          <th>Interés Adeudado</th>
          <th>FECI Adeudado</th>
          <th>Interés Prorrateado</th>
          <th>Mora</th>
          <th>Com. Falta Pago</th>
          <th>Abonos</th>
          <th>Retardo</th>
          <th>Balance</th>
          <th>Fecha Pago</th>
          <th>Estatus</th>
        </tr>
      </thead>
      <tbody className="text-center">
      {props.creditsData.map((credit, index) => {
          const days = Math.floor((new Date(credit.FechaExigible) - new Date(credit.FechaInicio)) / (1000 * 60 * 60 * 24));
          return (
          
          <tr key={credit.AmortizacionID}>
            <td>{credit.AmortizacionID}</td>
            <td>{credit.FechaInicio}</td>
            <td>{credit.FechaExigible}</td>
            <td>{days}</td>
            <td>{credit.Capital}</td>
            <td>{credit.Interes}</td>
            <td>{credit.MontoFECI}</td>

            <td>{credit.ClienteID}</td>
            <td>{credit.CuentaID}</td>
            <td>{credit.FechaVencim}</td>
            <td>{credit.FechaLiquida}</td>
            <td>{credit.IVAInteres}</td>
            <td>{credit.SaldoCapVigente}</td>
            <td>{credit.SaldoCapAtrasa}</td>
            <td>{credit.SaldoCapVencido}</td>
            <td>{credit.SaldoCapVenNExi}</td>
            <td>{credit.Estatus}</td>
          {/* <td>{credit.SaldoInteresOrd}</td>
          <td>{credit.SaldoInteresAtr}</td>
          <td>{credit.SaldoInteresVen}</td>
          <td>{credit.SaldoInteresPro}</td>
          <td>{credit.SaldoIntNoConta}</td>
          <td>{credit.SaldoIVAInteres}</td>
          <td>{credit.SaldoMoratorios}</td>
          <td>{credit.SaldoIVAMorato}</td>
          <td>{credit.SaldoComFaltaPa}</td>
          <td>{credit.SaldoIVAComFalP}</td>
          <td>{credit.SaldoOtrasComis}</td>
          <td>{credit.SaldoIVAComisi}</td>
          <td>{credit.ProvisionAcum}</td>
          <td>{credit.SaldoCapital}</td>
          <td>{credit.NumProyInteres}</td>
          <td>{credit.SaldoMoraVencido}</td>
          <td>{credit.SaldoMoraCarVen}</td>
          <td>{credit.MontoSeguroCuota}</td>
          <td>{credit.IVASeguroCuota}</td>
          <td>{credit.SaldoSeguroCuota}</td>
          <td>{credit.SaldoIVASeguroCuota}</td>
          <td>{credit.SaldoComisionAnual}</td>
          <td>{credit.SaldoComisionAnualIVA}</td>
          <td>{credit.SaldoFECI}</td>
          <td>{credit.FECIAcumulado}</td>
          <td>{credit.FECIAdeudado}</td>
          <td>{credit.FECIAdeudadoR}</td>
          <td>{credit.GeneroMora}</td>
          <td>{credit.InteresAdeudado}</td>
          <td>{credit.SaldoInteresAdeudado}</td>
          <td>{credit.EmpresaID}</td>
          <td>{credit.Usuario}</td>
          <td>{credit.FechaActual}</td>
          <td>{credit.DireccionIP}</td>
          <td>{credit.ProgramaID}</td>
          <td>{credit.Sucursal}</td>
          <td>{credit.NumTransaccion}</td> */}
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  return(
    <div>

    {creditsTable}
      
    
    </div>
  )

}

export default ResumenCredito;
