import React, { useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import {Bars} from 'react-loader-spinner'
import axios from 'axios'
import styled from 'styled-components'
import Moment from 'react-moment';
// import Template from '../Template'

import Contract from './Contract'
import Stat from './Stat'
import Table, { NameCell, ProductCell, AmountCell, EstatusCell, OfficeCell, AvatarCell, SelectColumnFilter, StatusPill } from '../Table/Table'  // new


const EstatusTranslate = {"I":"Registradas", "L":"En Comité (Liberadas para Autorización)", "CD": "Autorizadas para Certificación de Datos", "CO": "Certificación de Operaciones", "GC": "Generación de Contrato", "PD": "Por Desembolsar"}


const Contracts = (props) => {
    const [contracts, setContracts] = useState([]);
    const [in_committee, setInCommittee] = useState([]);
    const [in_committee_buckets, setInCommitteeBuckets] = useState([]);
    const [gen_contract_buckets, setGenContractBuckets] = useState([]);
    const [to_disbursement_buckets, setToDisburseBuckets] = useState([]);
    const [pre_committee, setPreCommittee] = useState([]);
    const [inCD, setInCD] = useState([]);
    const [inCO, setInCO] = useState([]);
    const [inGC, setInGC] = useState([]);
    const [inPD, setInPD] = useState([]);
    const [currentUser, setCurrentUser] = useState([]);

    const [state, setState] = useQueryParam('state', StringParam);
    const [substate, setSubState] = useQueryParam('substate', StringParam);
    const [loading, setLoading] = useState(true);

    const columns = useMemo(() => [
    {
        Header: "Préstamo / Cliente",
        accessor: 'SolicitudCreditoID',
        Cell: NameCell,
        path: 'contract'
        // imgAccessor: "imgUrl",
        // emailAccessor: "email",
    },
    {
        Header: "Producto / Tipo",
        accessor: 'ProductoDescripcion',
        Cell: ProductCell,
        Filter: SelectColumnFilter,  // new
    },
    {
        Header: "Tipo / Monto Crédito",
        accessor: 'TipoCredito',
        Cell: AmountCell,
    },
    {
        Header: "Estatus / Resp.",
        accessor: 'Estatus',
        Cell: EstatusCell,
    },
    {
        Header: "Fecha / Agencia",
        accessor: 'NombreSucurs',
        Cell: OfficeCell,
        Filter: SelectColumnFilter,  // new
    },
    ], [])

    
    useEffect(() => {
        setLoading(true);

        const token = document.querySelector('[name=csrf-token]').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token            

        axios.get(`/api/v1/solicitud_creditos?status=${state}&substatus=${substate}`) //&user=29
        .then( resp => {
            console.log('respdata', resp.data);
            setContracts(resp.data.items);
            setPreCommittee(resp.data.pre_committee);
            setInCommittee(resp.data.in_committee);
            setInCommitteeBuckets(resp.data.in_committee_buckets);
            setToDisburseBuckets(resp.data.to_disbursement_buckets);
            setGenContractBuckets(resp.data.gen_contract_buckets);
            setInCD(resp.data.inCD);
            setInCO(resp.data.inCO);
            setInGC(resp.data.inGC);
            setInPD(resp.data.inPD);
            setCurrentUser(resp.data.currentUser);
            setLoading(false);

        })
        .catch( data => console.log('error', data));
    }, []);
  

    const grid = contracts.map( (contract, index) => { 
        // console.log('data', contracts);
        return (
            
            <Contract path={"\contract"} contract={contract} responsible={contract.responsible} key={contract.SolicitudCreditoID}/>
        );
    });

    return (
        <>
            { loading ?
                <div className="flex h-screen">
                <div className="m-auto">
                    <Bars
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={10000} //3 secs
                
                    />
                    </div>
                </div>            
              :  

                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
                <div className="pt-2 pb-6 md:py-6">
                    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-11">
                                <h1 className="text-2xl font-semibold text-gray-900 float-left">Solicitudes de Crédito</h1>
                        </div>
                        <div className="col-span-1">
                        <div className="inline-flex float-right">
                            <span className="ml-2 rounded-md shadow-sm ">
                                <a href={`/downloads/contracts.xlsx?status=${state}${substate ? "&substatus="+substate : ''}`}>
                                <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                                <svg className="-ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                </svg>
                                </button>
                                </a>
                            </span>
                            <span className="ml-2 rounded-md shadow-sm ">
                                <a href={`/pages/refresh?state=${state}`}>
                                <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                                <svg className="-ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                </svg>      
                                </button>
                                </a>
                            </span>                            
                        </div>
                        </div>
                    </div>

                    </div>
                    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                        <Stats 
                            pre_committee={pre_committee} 
                            in_committee={in_committee} 
                            in_committee_buckets={in_committee_buckets} 
                            to_disbursement_buckets={to_disbursement_buckets} 
                            gen_contract_buckets={gen_contract_buckets} 
                            inCD = {inCD}
                            inCO = {inCO}
                            inGC = {inGC}
                            inPD = {inPD}
                            state = {state}
                            substate = {substate}
                        />
                        <br/>
                        <br/>
                        <Table columns={columns} data={contracts} />
                    </div>
                </div>
                </main>
            }
        </>
    );
}

const Stats = (props) => (
    <div>
    <h3 className="text-lg leading-6 font-medium text-gray-900">
        {EstatusTranslate[props.state]}

    </h3>
    <div className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-6">
        <Stat name="Registradas" amount={props.pre_committee ? props.pre_committee.cantidad : 0} number={props.pre_committee ? props.pre_committee.numero : 0} path="/contracts?state=I" selected={props.state=="I"} />
        <Stat name="Comité Liberada" amount={props.in_committee ? props.in_committee.cantidad : 0} number={props.in_committee ? props.in_committee.numero : 0} path="/contracts?state=L" selected={props.state=="L"}/>
        <Stat name="Autorizadas" amount={props.inCD.cantidad} number={props.inCD.numero} path="/contracts?state=CD" selected={props.state=="CD"}/>
        <Stat name="Cer. Operaciones" amount={props.inCO.cantidad} number={props.inCO.numero} path="/contracts?state=CO" selected={props.state=="CO"}/>
        <Stat name="Gen. Contrato" amount={props.inGC.cantidad} number={props.inGC.numero} path="/contracts?state=GC" selected={props.state=="GC"}/>
        <Stat name="Por Desembolsar" amount={props.inPD.cantidad} number={props.inPD.numero} path="/contracts?state=PD" selected={props.state=="PD"}/>
    </div> 
    {
        props.state == "L" &&
        <div className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-6">
            <div className="col-start-2">
                <Stat  name="Comité de Agencia" amount={props.in_committee_buckets.b1 ? props.in_committee_buckets.b1.cantidad : 0} number={props.in_committee_buckets.b1 ? props.in_committee_buckets.b1.numero : 0} path="/contracts?state=L&substate=b1" selected={props.substate=="b1"}/>
            </div>
            <Stat name="Comité Regional" amount={props.in_committee_buckets.b2 ? props.in_committee_buckets.b2.cantidad : 0} number={props.in_committee_buckets.b2 ? props.in_committee_buckets.b2.numero : 0} path="/contracts?state=L&substate=b2" selected={props.substate=="b2"}/>
            <Stat name="Comité Nacional" amount={props.in_committee_buckets.b3 ? props.in_committee_buckets.b3.cantidad : 0} number={props.in_committee_buckets.b3 ? props.in_committee_buckets.b3.numero : 0} path="/contracts?state=L&substate=b3" selected={props.substate=="b3"}/>
            <Stat name="Revisión de Operaciones" amount={props.in_committee_buckets.b4 ? props.in_committee_buckets.b4.cantidad : 0} number={props.in_committee_buckets.b4 ? props.in_committee_buckets.b4.numero : 0} path="/contracts?state=L&substate=b4" selected={props.substate=="b4"}/>
            <Stat name="Autorización de Operaciones" amount={props.in_committee_buckets.b5 ? props.in_committee_buckets.b5.cantidad : 0} number={props.in_committee_buckets.b5 ? props.in_committee_buckets.b5.numero : 0} path="/contracts?state=L&substate=b5" selected={props.substate=="b5"}/>
        </div>
    }
    {
        props.state=="GC" &&
        <div className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-6">
            <div className="col-start-5">
                <Stat  name="En Agencia" amount={props.gen_contract_buckets.b1 ? props.gen_contract_buckets.b1.cantidad : 0} number={props.gen_contract_buckets.b1 ? props.gen_contract_buckets.b1.numero : 0} path="/contracts?state=GC&substate=b1" selected={props.substate=="b1"}/>
            </div>
            <Stat name="En Legal" amount={props.gen_contract_buckets.b2 ? props.gen_contract_buckets.b2.cantidad : 0} number={props.gen_contract_buckets.b2 ? props.gen_contract_buckets.b2.numero : 0} path="/contracts?state=GC&substate=b2" selected={props.substate=="b2"}/>
        </div>
    }
    {
        props.state=="PD" &&
        <div className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-6">
            <div className="col-start-4">
                <Stat  name="Pre-Cierre" amount={props.to_disbursement_buckets.b1 ? props.to_disbursement_buckets.b1.cantidad : 0} number={props.to_disbursement_buckets.b1 ? props.to_disbursement_buckets.b1.numero : 0} path="/contracts?state=PD&substate=b1" selected={props.substate=="b1"}/>
            </div>
            <Stat name="Cierre Legal" amount={props.to_disbursement_buckets.b2 ? props.to_disbursement_buckets.b2.cantidad : 0} number={props.to_disbursement_buckets.b2 ? props.to_disbursement_buckets.b2.numero : 0} path="/contracts?state=PD&substate=b2" selected={props.substate=="b2"}/>
            <Stat name="Mesa de Control" amount={props.to_disbursement_buckets.b3 ? props.to_disbursement_buckets.b3.cantidad : 0} number={props.to_disbursement_buckets.b3 ? props.to_disbursement_buckets.b3.numero : 0} path="/contracts?state=PD&substate=b3" selected={props.substate=="b3"}/>
        </div>
    }
    </div>


);




const TableSF = (props) => {
    return (
        <div className="flex flex-col">
        <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
            <table className="min-w-full">
                <thead>
                <tr>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Prestamo / Cliente
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Producto / Tipo
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Tipo / Monto del Crédito
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Estatus / Resp.
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Fecha / Agencia
                    </th>
                </tr>
                </thead>
                <tbody className="bg-white">
                    {props.grid}                
                </tbody>
            </table>
            </div>
        </div>
        </div>
    
    
    );

}



export default Contracts
