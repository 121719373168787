import React from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom'

const Stat = (props) => (
    <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-2 pt-1 pb-1 sm:p-6">
            <dl>
            <dt className="text-xs leading-5 font-medium text-gray-500 truncate">
                {props.name}
            </dt>
            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="inline-flex items-baseline px-3 py-2 rounded-full text-2xl font-medium leading-5 bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                    {props.number}
                </div>
            </dd>        
            </dl>
        </div>
        <div className={props.selected ? "bg-gray-300 px-2 py-2 sm:px-6" : "bg-gray-100 px-2 py-2 sm:px-6"}>
            <div className="text-sm leading-5">
            <a href={props.path} className="font-medium text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150">
                Ver
            </a>
            </div>
        </div>    
    </div>
)

export default Stat
