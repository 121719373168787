import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';
import FieldValue from './FieldValue'

import * as Yup from 'yup'
import FormikControl from '../forms/FormikControl'


const dropdownSiNoOptions = [
    { key: true, value: 'Sí' },
    { key: false, value: 'No' }
  ]
const dropdownTipoMotivoOptions = [
    { key: 'Campaña', value: 'Campaña' },
    { key: 'Feria', value: 'Feria' },
    { key: 'Promocionado', value: 'Promocionado' },
    { key: 'Propia Cuenta', value: 'Propia Cuenta' },
    { key: 'Publicidad', value: 'Publicidad' },
    { key: 'Referido', value: 'Referido' }
]


const Other = (props) => {
    const [hiddenPanel, setHiddenPanel] = useState(false);

    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            {props.editable && 
            <div className="m-4 inline-flex float-right">
                <span className="inline-flex rounded-md shadow-sm">
                    <button type="submit" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                        Salvar
                    </button>
                </span>
            </div>        
            }
            {!props.editable && 
            <div className="m-4 inline-flex float-right">
                <span className="ml-2 rounded-md shadow-sm ">
                    <a onClick={() => props.setOtherEdit(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>                           
                    </a>
                </span>
            </div>        
            }
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="flex text-lg leading-6 font-medium text-gray-900">   
                <a onClick={() => setHiddenPanel(!hiddenPanel)}>       
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor" data-action="click->toggler#toggle">
                {hiddenPanel
                    ? <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" data-toggler-target="selectorIcon" />
                    : <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" data-toggler-target="selectorIcon" />
                }
                </svg>
                </a>
                Otros Datos
                </h3>
            </div>
            {!hiddenPanel &&
                <div className="px-4 py-5 sm:px-6">
                    {!props.editable &&
                    <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4">
                    <FieldValue label={"Tipo Motivo"}>{props.values.TipoMotivo}</FieldValue>
                    <FieldValue label={"Motivo"}>{props.values.Motivo}</FieldValue>
                    {/* <FieldValue label={"Fecha Mercadeo"}>{Moment(props.values.FechaMercadeo).format('YYYY-MM-DD')}</FieldValue>
                    <FieldValue label={"Contactado"}>{props.values.Contactado ? 'Sí' : 'No'}</FieldValue> */}
                    </dl>
                    }                
                    {props.editable &&
                    <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4">
                    <FormikControl
                        control='select'
                        label='Tipo Motivo'
                        name='TipoMotivo'
                        options={dropdownTipoMotivoOptions}      
                    />
                    <FormikControl
                        control='input'
                        label='Motivo'
                        name='Motivo'
                    />
                    {/* <FormikControl
                        control='date'
                        label='Fecha Mercadeo'
                        name='FechaMercadeo'
                    />
                    <FormikControl
                        control='select'
                        label='Contactado'
                        name='Contactado'
                        options={dropdownSiNoOptions}      
                    /> */}
                    </dl>
                    }
                </div>


            }
        </div>
    )

}

export default Other
