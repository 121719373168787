import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';


const FieldValue = (props) => {
    var missing = 
        props.label!="Ciclo Cliente" && (
            !props.children || props.children === "null" || props.children == "" ||
            (props.label=="Dirección Oficial" && props.children.charAt(0) == "[") ||
            props.children == "$0.00"
        );

        return (
        <div className="mb-4 sm:col-span-1">
            <dt className="text-sm leading-5 font-medium text-gray-500">
            {props.label}
            </dt>
            <dd className={`min-w-full min-h-full max-h-4 mt-1 text-sm leading-5 text-gray-900 ${missing ? 'h-10 rounded-sm border-3 border-red-600 bg-red-50 p-1' : '' }`}>
            {props.children}
            </dd>
            {/* <dd className={`mt-1 text-sm leading-5 text-gray-900`}>
            <div className={ missing ? 'border-3 border-red-600 bg-red-200 p-1' : '' }>{props.children}</div>
            </dd> */}
        </div>
    
    )
}

export default FieldValue
