import React, { useState, useEffect} from 'react'
import axios from 'axios'
import { useFormikContext } from 'formik';
import ChartDiasCobros from './ChartDiasCobros';


const ValueChangeListener = () => {
    const { submitForm, values } = useFormikContext();
  
    useEffect(() => {
      if (values.from_date) {
        submitForm();
      }
    }, [values, submitForm]);
  
    return null;
  };

  


const DiasCobros = (props) => {
    const [loading, setLoading] = useState([]);
    const {currentUser} = props;
    const [refresh, setRefresh] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: 'Var_ExigibleAlDia', direction: 'asc' });
    const [sortConfig2, setSortConfig2] = useState({ key: 'Var_ExigibleAlDia', direction: 'asc' });



    const [indicadores, setIndicadores] = useState([]);

    const getIndicadoresAgencias = (values) => {
        // Create base URL
        let url = 'https://api.sumadata.net/indicadoresagencia';
    
        axios
          .get(url)
          .then((resp) => {
            setIndicadores(resp.data);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
    };

    useEffect(() => {
        getIndicadoresAgencias();
    }, [refresh]);
    
    return (
        <>
                        
              <div className="overflow-y-auto">
                    
                    <>
                    <div className="mx-4 mt-1 bg-white shadow-sm rounded-lg">

                        <ChartDiasCobros />

                    </div>

                <div className="w-full flex-grow bg-gray-100 p-4 shadow-sm bg-white rounded-lg">
    
                  </div>
                    </>   
                    
                    

              </div>
        </>
    );

}

export default DiasCobros
