import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function CheckboxGroupBeauty (props) {
  const { label, name, options, editing, ...rest } = props
  return (
    <div className="sm:col-span-1">
      <div> 
          <div className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700" id="label-email">
          {label}
          </div>
      </div>

      <div className="mt-4 sm:mt-0 sm:col-span-2">

        <Field name={name}>
          {({ field }) => {
            return options.map(option => {
              return (
                <React.Fragment key={option.key}>
                  { option.visible ?

                  <div className="relative flex items-start mt-5">
                  <div className="flex items-center h-5">
                    {editing &&
                      <input
                        type='checkbox'
                        id={option.value}
                        {...field}
                        {...rest}
                        value={option.value}
                        checked={field ? field.value.includes(option.value) : false}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    }  
                      {!editing && field && field.value.includes(option.value) &&
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>                    
                      }
                      {!editing && field && !field.value.includes(option.value) &&
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>                    
                      }
                  </div>
                  <div className="ml-3 text-sm">
                      <label htmlFor={option.value} className="font-medium text-gray-700">{option.key}</label>
                      <p id={`"${option.value}-description"`} className="text-gray-500">
                      {option.description}
                      </p>
                  </div>
                  </div>
                  : ""
                  }
                </React.Fragment>
              )
            })
          }}
        </Field>
        <ErrorMessage component={TextError} name={name} />


      </div>
    </div>
  )
}

export default CheckboxGroupBeauty
