import React, { useEffect, useState } from "react";
import axios from "axios";
import { Bars } from 'react-loader-spinner';
import { orangeClasses, grayClasses } from "../Design/classes";
import { formatDate } from "../Helpers/Helpers";

const WalletConciliados = (props) => {

  const [walletData, setWalletData] = useState([]);
  const [loading, setLoading] = useState([]);
  const { currentUser } = props;
  const [filterText, setFilterText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const includesFilterText = (text) => {
    return text.toLowerCase().includes(filterText.toLowerCase());
};

  useEffect(() => {
    setLoading(true);
    loadConsulta();
  }, [])

  const loadConsulta = async () => {
    await axios.get(`/api/v1/safi/get_all_conciliaciones.json`).then((res) => {
        let sortedData = res.data.conciliaciones.sort((a, b) => new Date(b.conciliaciones.created_at) - new Date(a.conciliaciones.created_at));
        
        setWalletData(sortedData);
        setLoading(false);
    });
  };

  const filteredData = walletData.filter(walletConcilia => {
    let shortenedID = walletConcilia.conciliaciones._id && walletConcilia.conciliaciones._id.$oid ? String(walletConcilia.conciliaciones._id.$oid).slice(-5) : '';
    let usuario = walletConcilia.conciliaciones.usuario?.NombreCompleto ?? "Sin Usuario";
    const idText = 'PagoID: ' + shortenedID + ' / Usuario: ' + usuario;
    const bancoText = walletConcilia.conciliaciones.nombreBanco;
    const montoText = walletConcilia.conciliaciones.montoAConciliar;
    const fechaText = walletConcilia.conciliaciones.created_at;
    const notasText = walletConcilia.conciliaciones.detalleNotas;

    return (
      includesFilterText(idText) ||
      includesFilterText(bancoText) ||
      includesFilterText(montoText) ||
      includesFilterText(fechaText) ||
      includesFilterText(notasText) ||
      walletConcilia.conciliaciones.transactions && walletConcilia.conciliaciones.transactions.some((tran) => {
        if (typeof tran === 'string') {
          const separatedObject = tran.split(',');
          const transactionText =
            'Referencia: ' +
            (separatedObject[7] ?? '') +
            ' / Cliente: ' +
            (separatedObject[6] ?? '') +
            ' / Credito: ' +
            separatedObject[2] +
            ' / Monto: ' +
            separatedObject[0];
          return includesFilterText(transactionText);
        }
        return false;
      })
    );
});

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (direction) => {
    if (direction === 'next' && currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'previous' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      {loading ?
        <div className="flex h-screen">
          <div className="m-auto">
            <Bars
              color="#00BFFF"
              height={100}
              width={100}
              timeout={20000}
            />
          </div>
        </div>
       :
        <div className="overflow-y-auto">
          <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
              <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                Transacciones Conciliadas
              </h3>
            </div>

            <div className="p-5 sm:flex sm:gap-x-2">
              <label className="flex gap-x-2 items-baseline">
                <span className="text-gray-700">Buscar: </span>
                <input
                  type="text"
                  placeholder="Filtrar resultados"
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </label>
            </div>

            <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
              <div className="ml-2 mt-2 w-full mr-2">
                <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                  <table className="min-w-full">
                    <thead>
                      <tr>
                        <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          ID Conciliacion / Usuario
                        </th>
                        <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Banco
                        </th>
                        <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Transacciones
                        </th>
                        <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Monto Conciliado
                        </th>
                        <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Fecha Conciliacion
                        </th>
                        <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Notas
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((walletConcilia, index) => {

                        let shortenedID = walletConcilia.conciliaciones._id && walletConcilia.conciliaciones._id.$oid ? String(walletConcilia.conciliaciones._id.$oid).slice(-5) : '';

                        let usuario = walletConcilia.conciliaciones.usuario?.NombreCompleto ?? "Sin Usuario";


                        const currentGrayClass = grayClasses[index % grayClasses.length];

                        return(
                          <tr
                            key={index}
                            className={`${currentGrayClass} border-b border-gray-200`}
                          >
                            <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {'PagoID: ' + shortenedID + ' / Usuario: ' + usuario}
                            </td>

                            <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {walletConcilia.conciliaciones.nombreBanco}
                            </td>

                            <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                              {
                              walletConcilia.conciliaciones.transactions && walletConcilia.conciliaciones.transactions.map((tran, index) => {
                                if (tran !== 0) {
                                    if (typeof tran === 'string') {
                                      const separatedObject = tran.split(',');

                                      var pagoID = separatedObject[1];
                                      var creditoID = separatedObject[2];
                                      var montoPago = separatedObject[0];
                                      var nombreCliente = separatedObject[6] ?? '';
                                      var numeroReferencia = separatedObject[7] ?? '';

                                      const currentColorClass = orangeClasses[index % orangeClasses.length];

                                      return (
                                        <div key={index}>
                                          <p
                                          className={`font-bold ${currentColorClass} w-76 py-2 px-4 rounded inline-flex items-center`}>
                                            {'Referencia: ' +
                                              numeroReferencia +
                                              ' / ' +
                                              ' Cliente: ' +
                                              nombreCliente +
                                              ' / ' +
                                              ' Credito: ' +
                                              creditoID +
                                              ' / Monto: ' +
                                              montoPago}
                                          </p>
                                        </div>
                                      );
                                    } else {
                                      console.error('tran is not a string:', tran);
                                    }
                                  }
                                })
                              }
                            </td>
                            <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                              }).format(walletConcilia.conciliaciones.montoAConciliar)}
                            </td>

                            <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                              {formatDate(walletConcilia.conciliaciones.created_at)}
                            </td>
                            <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {walletConcilia.conciliaciones.detalleNotas}
                            </td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </table>
                  <div className="pagination">
                    <button 
                      className={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r ${
                        currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      onClick={() => handlePageChange('previous')} 
                      disabled={currentPage === 1}>
                      Anterior
                    </button>
                    <span>Pagina {currentPage} of {Math.ceil(filteredData.length / itemsPerPage)}</span>
                    <button 
                      className={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l ${
                        currentPage === Math.ceil(filteredData.length / itemsPerPage)
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`}                      onClick={() => handlePageChange('next')} 
                      disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}>
                      Siguiente
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default WalletConciliados;
