import React, {useEffect,useState,useMemo, useRef} from "react";
import Template from "../Template";
import axios from "axios";
import {Bars} from 'react-loader-spinner';
import Table, { NameCell, ProductCell, AmountCell, EstatusCell, OfficeCell, AvatarCell, SelectColumnFilter, StatusPill } from '../Table/Table';
import { orangeClasses, grayClasses } from "../Design/classes";
import { formatDate } from "../Helpers/Helpers";



const WalletConciliaciones = (props) => {

  //const [currentUser, setCurrentUser] = useState([]);
  const [walletData,setWalletData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [checkedState, setCheckedState] = useState([0]);
  const [isChecked, setIsChecked] = useState({});
  const [monto, setMonto] = useState([0]);
  const [nombreBanco, setNombreBanco] = useState([]);
  const [numeroReferencia, setNumeroReferencia] = useState([]);
  const [detalleNotas, setDetalleNotas] = useState([]);
  const [numtransaccion, setNumTransaccion] = useState(0);
  const [pagoerror, setPagoError] = useState([]);
  const [paymentSelected, setPaymentSelected] = useState([]);
  const {currentUser} = props;
  const [filterText, setFilterText] = useState('');

  const includesFilterText = (text) => {
    return String(text).toLowerCase().includes(filterText.toLowerCase());
  };

  const sum =  (checkedState.reduce((result,number)=> parseFloat(result)+parseFloat(number))).toFixed(2);

  const handleCheck = (event) => {

    setIsChecked({...isChecked, ...{[event.target.name]: event.currentTarget.checked}});
    var updatedList = [...checkedState];
    if(event.target.checked){
      updatedList = [...checkedState, event.target.value];
    }else{
      updatedList.splice(checkedState.indexOf(event.target.value), 1);
    }
    setCheckedState(updatedList);
    
  };

  const handleInput = (event) => {
    setMonto(event.target.value)    
  }

  const getFilteredData = () => {
    return walletData.pagos.filter(walletConcilia => {
      let shortenedID = String(walletConcilia.pago._id.$oid).slice(-5);
      let usuario = walletConcilia.pago?.usuario?.NombreCompleto ?? "Sin Usuario";
      const idText = 'PagoID: ' + shortenedID + ' / Usuario: ' + usuario;
      const numRef = walletConcilia.pago.referenciaTransferencia;
      const fechaTrans = walletConcilia.pago.fechaTransferencia;
      const tipoPago = walletConcilia.pago.tipoPago;
      const credito = walletConcilia.pago.creditNumber;
      const nombreCliente = walletConcilia.pago.nombreCliente;
      const depositante = walletConcilia.pago.depositante;
      const bancoReceptor = walletConcilia.pago.bancoReceptor;
      const noLibreCobro = walletConcilia.pago.noLibreCobro;
      const montoPagado = walletConcilia.pago.amount;
      return (
        includesFilterText(depositante) ||
        includesFilterText(idText) ||
        includesFilterText(numRef) ||
        includesFilterText(fechaTrans) ||
        includesFilterText(tipoPago) ||
        includesFilterText(credito) ||
        includesFilterText(nombreCliente) ||
        includesFilterText(bancoReceptor) ||
        includesFilterText(noLibreCobro) ||
        includesFilterText(montoPagado)
      );
    });
  };



  useEffect(() => {
    setLoading(true);
    loadConsulta();

    return () => {
      isMounted.current = false;
    };

    
  }, [])

  const loadConsulta = async () => {
    await axios.get(`/api/v1/safi/get_all_pagos.json`).then((res) => {
      if(isMounted.current){
        setWalletData(res.data);
        setLoading(false);
      }
    });
  };

  const isMounted = useRef(true);

  const cancelPago = (cotizaID) => {
    
    const token = document.querySelector('[name=csrf-token]').content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token
    axios.post(`/api/v1/safi/update_pago`, {...{cotizaID}, ...{rechazado: 'true'}})

      .then( resp => {

        loadConsulta()
        setLoading(false);

      })
      .catch( data => console.log('error', data))
  }


  const updateConciliacion = async (cotizaID, event) => {
    setLoading(true);
  
    const myObject = {
      transactions: checkedState,
      montoAConciliar: monto,
      nombreBanco: nombreBanco,
      numeroReferencia: numeroReferencia,
      detalleNotas: detalleNotas,
      usuario: currentUser,
    };
  
    const token = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
  
    const validTransactions = [];
  
    const processTransaction = async (pago) => {
      if (pago !== 0) {
        const separatedObject = pago.split(',');
    
        var cotizaID = separatedObject[1];
        var paymentCreditNumber = separatedObject[2];
        var paymentAmount = separatedObject[0];
        var transactionType = separatedObject[3];
        var paymentCustomerNumber = separatedObject[5];
    
        if (
          transactionType === 'transferencia' ||
          transactionType === 'tdc' ||
          transactionType === 'otros'
        ) {
          const paymentData = {
            customerNumber: paymentCustomerNumber,
            paymentType: 'E',
            creditNumber: paymentCreditNumber,
            savingsAccountNumber: '',
            amount: paymentAmount,
            cashNumber: '27',
            branchOffice: '1',
          };
    
          try {
            const resp = await axios.post(`/api/v1/safi/pago`, paymentData);
    
            if (resp.data.codigoRespuesta === '000000') {
              
              validTransactions.push(pago);
              await axios.post(`/api/v1/safi/update_pago`, {
                cotizaID,
                conciliado: 'true',
                numTransaccion: resp.data.folio,
              });
            } else {
              setPagoError(resp.data.mensajeRespuesta);
              setPaymentSelected('pagoerror');
            }
          } catch (error) {
            console.log('error', error);
          }
        } else {
          validTransactions.push(pago);
          await axios.post(`/api/v1/safi/update_pago`, {
            cotizaID,
            conciliado: 'true',
          });
        }
      }
    };
    
  
    for (const pago of myObject.transactions) {
      await processTransaction(pago);
    }

  
    if (validTransactions.length > 0) {
      try {
        await axios.post(`/api/v1/safi/save_conciliacion`, {
          ...myObject,
          transactions: validTransactions,
        });
        loadConsulta();
      } catch (error) {
        console.log('error', error);
      }
    } else {
      console.log('No valid transactions to save');
    }
  
    setLoading(false);
    setMonto(0);
    setNombreBanco('');
    setDetalleNotas('');
  };
  

    return (
      <>
            { loading ?
                <div className="flex h-screen">
                <div className="m-auto">
                    <Bars
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={30000} //3 secs                
                    />
                    </div>
                </div>            
              :    
              <div className="overflow-y-auto">
                {/*Inner Container Start*/}
                <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">


                  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                      Conciliacion de Pagos
                    </h3>
                  </div>

                  <div className="p-5 sm:flex sm:gap-x-2 sm:justify-between">
                      <label className="flex gap-x-2 items-baseline">
                          <span className="text-gray-700">Buscar: </span>
                          <input
                              type="text"
                              placeholder="Filtrar resultados"
                              value={filterText}
                              onChange={(e) => setFilterText(e.target.value)}
                              className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          />
                      </label>

                      <a href={`/downloads/credito_pagos`}>
                          <button type="button" className="
                              inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                              <svg className="-ml-1 h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                              </svg>
                              Reporte de Pagos
                          </button>
                      </a>
                  </div>


                  <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
                <div className="ml-2 mt-2 w-full mr-2">
                  <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                    <thead className="sm:rounded-lg">
              <tr>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  ID Transacción / Usuario
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Número de Referencia
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Fecha de Transferencia
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Tipo de Pago
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  No. de Crédito
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Nombre de Cliente
                </th>

                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Depositante
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Banco Receptor
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  No. Libre de Cobro
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Monto Pagado
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Comprobante
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Seleccione
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">

                </th>
              </tr>
            </thead>
            <tbody>
            {
              getFilteredData().map((walletConcilia, index) => {

             let shortenedID = String(walletConcilia.pago._id.$oid).slice(-5);
             let usuario = walletConcilia.pago?.usuario?.NombreCompleto ?? "Sin Usuario";

             const currentGrayClass = grayClasses[index % grayClasses.length];
             
             if(walletConcilia.pago.conciliado === 'false' && walletConcilia.pago.rechazado != 'true'){

              return(
                
                    <tr
                    key={index}
                    className={`${currentGrayClass} border-b border-gray-200`}
                    >
                    <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                    {'PagoID: ' + shortenedID + ' / Usuario: ' + usuario}
                    </td>
                     
                    <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                    {walletConcilia.pago.referenciaTransferencia}
                    </td>

                    <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                    {formatDate(walletConcilia.pago.fechaTransferencia)}
                    </td>

                    <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                    {walletConcilia.pago.tipoPago}
                    </td>

                    <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                    {walletConcilia.pago.creditNumber}
                    </td>

                    <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                    {walletConcilia.pago.nombreCliente}
                    </td>

                    <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                    {walletConcilia.pago.nombreDepositante}
                    

                    </td>

                    <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                    {walletConcilia.pago.bancoReceptor}

                    </td>

                    <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                    {walletConcilia.pago.libretaCobro}

                    </td>

                    <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD"
                    }).format(walletConcilia.pago.amount)}




                    </td>
                    
                    <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                    {walletConcilia.pago.attachments.map((attachment, index) => {
                      return(
                        <div key={index}>
                          <a className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150" href={attachment} target="_blank">{`Anexo`}</a>
                        </div>
                      )
                    })}
                    </td>

                    <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                    
                    <input
  key={walletConcilia.pago._id.$oid}
  name={`monto${walletConcilia.pago._id.$oid}`}
  type="checkbox"
  value={
    walletConcilia.pago.amount +
    "," +
    walletConcilia.pago._id.$oid +
    "," +
    walletConcilia.pago.creditNumber +
    "," +
    walletConcilia.pago.tipoPago +
    "," +
    monto +
    "," +
    walletConcilia.pago.customerNumber +
    "," +
    walletConcilia.pago.nombreCliente +
    "," +
    walletConcilia.pago.referenciaTransferencia
  }
  onChange={(event) => handleCheck(event)}
  checked={isChecked[`monto${walletConcilia.pago._id.$oid}`] || false}
/>


                    </td>

                    <td className="max-w-xs px-6 py-3 text-xs leading-5 text-gray-500 text-center">

                      <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => cancelPago(walletConcilia.pago._id.$oid)}>Rechazar</button>
                    </td>

                    

                    


                  </tr>

              )
              }
              

              })
            
              }

              
            </tbody>

            <tfoot>

              <tr>

              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



              </td>
              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



              </td>
              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



              </td>
              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



              </td>
              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



              </td>
              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



              </td>
              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



              </td>
              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



              </td>
              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



              </td>
              <td className={"px-6 py-3 text-sm leading-5  text-center  "}>

              <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                $
                <span> {sum}</span>
              </button>

              

              </td>
              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



              </td>

                
              </tr>

            </tfoot>
                    </table>

              

                  </div>
                </div>
              </div>



                
                </div>
                {/*Inner Container End*/}

              </div>
              
              
            }
            <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="sm:col-span-2 mt-4 mb-4 p-5">
                  <dt className="text-left text-lg leading-5 font-medium text-gray-500">
                      Monto a conciliar
                  </dt>
                  <dd className="flex text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                      <input maxLength="14" className="mr-2 w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline " type="text" placeholder="Monto en USD" name="montoConciliar" onChange={handleInput}/>

                      <select name="nombreBanco" className="mr-2 w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline" onChange={event => setNombreBanco(event.target.value)}>
                          <option value="">Seleccionar</option>
                          <option value="TESTBanco Nacional de Panamá - 10000266755">Banco Nacional de Panamá - 10000266755</option>
                          <option value="Banesco, S.A. (2) - 120000090319">Banesco, S.A. (2) - 120000090319</option>
                          <option value="Banistmo, S.A. (1) - 114339105">Banistmo, S.A. (1) - 114339105</option>
                          <option value="Banistmo, S.A. (1) - 117225764">Banistmo, S.A. (1) - 117225764</option>
                      </select>

                      <input className="mr-2 w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline " type="text" placeholder="Notas" name="detalleNotas" onChange={event => setDetalleNotas(event.target.value)}/>

                      <button onClick={updateConciliacion} className={sum === monto ? 'relative ml-2 bg-yellow-600 h-10 right-0 flex items-center px-8 font-bold  rounded border border-yellow-600 text-white' : 'relative ml-2 h-10 right-0 flex items-center px-8 font-bold bg-cool-gray-600 rounded hover:bg-gray-600 border border-gray-600 text-gray-400'} disabled={sum === monto ? false : true}>Conciliar</button>
                  </dd>
              </div>
          </div>
      </>
    );

}

export default WalletConciliaciones;
