import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
import Template from '../../Template'
import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';

const Analisys = (props) => {
    const [contract, setContract] = useState([]);
    const [client, setClient] = useState([]);
    const [loading, setLoading] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [currentUser, setCurrentUser] = useState([]);


    useEffect(() => {
        setLoading(true);
        axios.get('/api/v1/solicitud_creditos/'+props.match.params.id)
        .then( resp => {
            console.log('respdata', resp.data);
            setContract(resp.data.item);
            setClient(resp.data.client);
            setCurrentUser(resp.data.currentUser);
            setLoading(false)    
        })
        .catch( data => console.log('error', data));
        // await sleep(5000);
    }, [refresh]);

    const Actions = (props) => {

        const action_to_certification = () => {
            setContract({...contract, workflow_state:'CD'});
            console.log(contract.workflow_state);
        };
        
        const action_to_operations = () => {
            setContract({...contract, workflow_state:'CO'});
            console.log(contract.workflow_state);
        };
        
        const action_to_legal = () => {
            setContract({...contract, workflow_state:'GC'});
            console.log(contract.workflow_state);
            // setRefresh(refresh + 1);
        };
        
        const action_to_disbursement = () => {
            setContract({...contract, workflow_state:'PD'});
            console.log(contract.workflow_state);
            // setRefresh(refresh + 1);
        };
        
        const action_disbursed = () => {
            props.contract.workflow_state = 'D'
            setContract({...contract, workflow_state:'D'});
            console.log(contract.workflow_state);
            // setRefresh(refresh + 1);
        };
        
        return (    
        
        <div className="px-2 py-4 sm:px-6">
            <span className="mr-2 inline-flex rounded-md shadow-sm">
                <button type="button" className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                <a href={`/contracts?status=${props.contract.Estatus == "L" ? "L" : props.contract.workflow_state}`} >
                    Salir
                </a>
                </button>
            </span>                
            { contract.Estatus == "A" &&  contract.workflow_state == "CD" &&
                <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                    <button type="button" onClick={action_to_operations} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                        Próximo
                    </button>
                </span>
            }
            { contract.Estatus == "A" &&  contract.workflow_state == "CO" &&
                <div className="inline-flex">
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" onClick={action_to_certification} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                            Regresar
                        </button>
                    </span>
                    <span className="mx-4 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" onClick={action_to_legal} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                            Próximo
                        </button>
                    </span>
                </div>
            }
            { contract.Estatus == "A" &&  contract.workflow_state == "GC" &&
                <div className="inline-flex">
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" onClick={action_to_operations} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                            Regresar
                        </button>
                    </span>
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" onClick={action_to_disbursement} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                            Próximo
                        </button>
                    </span>
                </div>
            }
            { contract.Estatus == "A" &&  contract.workflow_state == "PD" &&
                <div className="inline-flex">
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" onClick={action_to_legal} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                            Regresar
                        </button>
                    </span>
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" onClick={action_disbursed} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                            Próximo
                        </button>
                    </span>
                </div>
            }
            { contract.Estatus == "A" &&  contract.workflow_state == "D" &&
                <div className="inline-flex">
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" onClick={action_to_disbursement} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                            Regresar a Pendiente por Desembolsar
                        </button>
                    </span>
                </div>
            }
        </div>
        )
    }
    
  
    return (
        <>
            { loading ?
                <div className="flex h-screen">
                <div className="m-auto">
                    <Bars
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={3000} //3 secs
                
                    />
                    </div>
                </div>            
              :  
                <div className="overflow-y-auto">                    
                    { true || contract.Estatus == "A" &&  (contract.workflow_state == "CD") &&
                    <Demo contract={contract}/>
                    }
                    { true || contract.Estatus == "A" &&  (contract.workflow_state == "CO") &&
                    <Demo2 contract={contract}/>
                    }
                    { true || contract.Estatus == "A" &&  (contract.workflow_state == "GC") &&
                    <Demo3 contract={contract}/>
                    }
                    { true || contract.Estatus == "A" &&  (contract.workflow_state == "PD") &&
                    <Demo4 contract={contract}/>
                    }
                    <Demo contract={contract}/>
                    <Demo2 contract={contract}/>
                    <Demo3 contract={contract}/>
                    <Demo4 contract={contract}/>                    
                    <Actions contract={contract}/>
                </div>

            }
        </>
    );

}



const Demo2 = (props) => (
    <Formik
    initialValues={props.contract}
    onSubmit={async values => {
        await new Promise(r => setTimeout(r, 500));
        alert(JSON.stringify(values, null, 2));
    }}
    >
    {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
    }) => (          
    <Form>
    
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Información del Negocio
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="maneja-dinero-en-efectivo" className="block text-sm font-medium leading-5 text-gray-700">Maneja dinero en efectivo </label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="ManejaEfectivo" id="maneja-dinero-en-efectivo" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                         <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="mantiene-dinero-en-el-banco" className="block text-sm font-medium leading-5 text-gray-700">Mantiene dinero en el banco</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="DineroEnBanco" id="mantiene-dinero-en-el-banco" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                         <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                      
                              </div>
                          </div>        
                          
                          </div>
                          
                          <FieldArray
                              name="Bancos"
                              render={arrayHelpers => (
                              <div>
                                  {values.Bancos && values.Bancos.length > 0 ? (
                                  values.Bancos.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="banco" className="block text-sm font-medium leading-5 text-gray-700">Banco</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Bancos.${index}.NombreCompleto`} id="banco" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="cuenta" className="block text-sm font-medium leading-5 text-gray-700">Cuenta</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Bancos.${index}.NombreCompleto`} id="cuenta" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="monto" className="block text-sm font-medium leading-5 text-gray-700">Monto</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Bancos.${index}.NombreCompleto`} id="monto" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="adjuntar-documento" className="block text-sm font-medium leading-5 text-gray-700">Adjuntar documento</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Bancos.${index}.NombreCompleto`} id="adjuntar-documento" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Bancos
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="posee-local-propio" className="block text-sm font-medium leading-5 text-gray-700">Posee Local Propio</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="LocalPropio" id="posee-local-propio" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                         <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                      
                              </div>
                          </div>        
                          
                          </div>
                          
                          <FieldArray
                              name="Locales"
                              render={arrayHelpers => (
                              <div>
                                  {values.Locales && values.Locales.length > 0 ? (
                                  values.Locales.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="detalle-local" className="block text-sm font-medium leading-5 text-gray-700">Detalle Local</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Locales.${index}.NombreCompleto`} id="detalle-local" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="valor" className="block text-sm font-medium leading-5 text-gray-700">Valor</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Locales.${index}.NombreCompleto`} id="valor" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="adjuntar-documento" className="block text-sm font-medium leading-5 text-gray-700">Adjuntar documento</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Locales.${index}.NombreCompleto`} id="adjuntar-documento" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Locales
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="posee-vehiculos-propios" className="block text-sm font-medium leading-5 text-gray-700">Posee Vehículos Propios</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="VehiculosPropios" id="posee-vehiculos-propios" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                         <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                      
                              </div>
                          </div>        
                          
                          </div>
                          
                          <FieldArray
                              name="Vehiculos"
                              render={arrayHelpers => (
                              <div>
                                  {values.Vehiculos && values.Vehiculos.length > 0 ? (
                                  values.Vehiculos.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="detalle-vehiculo" className="block text-sm font-medium leading-5 text-gray-700">Detalle Vehículo</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Vehiculos.${index}.NombreCompleto`} id="detalle-vehiculo" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="ano" className="block text-sm font-medium leading-5 text-gray-700">Año</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Vehiculos.${index}.NombreCompleto`} id="ano" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="valor" className="block text-sm font-medium leading-5 text-gray-700">Valor</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Vehiculos.${index}.NombreCompleto`} id="valor" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="adjuntar-documento" className="block text-sm font-medium leading-5 text-gray-700">Adjuntar documento</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Vehiculos.${index}.NombreCompleto`} id="adjuntar-documento" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Vehiculos
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="posee-mobiliario-en-el-negocio" className="block text-sm font-medium leading-5 text-gray-700">Posee Mobiliario en el Negocio</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="MobiliarioNegocio" id="posee-mobiliario-en-el-negocio" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                         <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                      
                              </div>
                          </div>        
                          
                          </div>
                          
                          <FieldArray
                              name="Moviliarios"
                              render={arrayHelpers => (
                              <div>
                                  {values.Moviliarios && values.Moviliarios.length > 0 ? (
                                  values.Moviliarios.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="detalle-mobiliario" className="block text-sm font-medium leading-5 text-gray-700">Detalle Mobiliario</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Moviliarios.${index}.NombreCompleto`} id="detalle-mobiliario" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="cantidad" className="block text-sm font-medium leading-5 text-gray-700">Cantidad</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Moviliarios.${index}.NombreCompleto`} id="cantidad" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="valor" className="block text-sm font-medium leading-5 text-gray-700">Valor</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Moviliarios.${index}.NombreCompleto`} id="valor" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Moviliarios
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="tiene-clientes-que-le-deban-dinero-a-usted" className="block text-sm font-medium leading-5 text-gray-700">Tiene Clientes que le deban dinero a usted</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="ClientesConDeuda" id="tiene-clientes-que-le-deban-dinero-a-usted" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                         <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                      
                              </div>
                          </div>        
                          
                          </div>
                          
                          <FieldArray
                              name="Clientes"
                              render={arrayHelpers => (
                              <div>
                                  {values.Clientes && values.Clientes.length > 0 ? (
                                  values.Clientes.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="nombre-del-cliente" className="block text-sm font-medium leading-5 text-gray-700">Nombre del Cliente</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Clientes.${index}.NombreCompleto`} id="nombre-del-cliente" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="monto-adeudado" className="block text-sm font-medium leading-5 text-gray-700">Monto adeudado</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Clientes.${index}.NombreCompleto`} id="monto-adeudado" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Clientes
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="posee-otros-activos" className="block text-sm font-medium leading-5 text-gray-700">Posee Otros Activos  </label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="OtrosActivos" id="posee-otros-activos" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                         <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                      
                              </div>
                          </div>        
                          
                          </div>
                          
                          <FieldArray
                              name="Activos"
                              render={arrayHelpers => (
                              <div>
                                  {values.Activos && values.Activos.length > 0 ? (
                                  values.Activos.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="detalle-del-activo" className="block text-sm font-medium leading-5 text-gray-700">Detalle del Activo</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Activos.${index}.NombreCompleto`} id="detalle-del-activo" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="cantidad" className="block text-sm font-medium leading-5 text-gray-700">Cantidad</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Activos.${index}.NombreCompleto`} id="cantidad" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="valor" className="block text-sm font-medium leading-5 text-gray-700">Valor</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Activos.${index}.NombreCompleto`} id="valor" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Activos
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                </div>

            </div>
          
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Ingresos del Negocio
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="ingresos-brutos-del-negocio" className="block text-sm font-medium leading-5 text-gray-700">Ingresos Brutos del Negocio</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="ingresos-brutos-del-negocio" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="su-negocio-factura" className="block text-sm font-medium leading-5 text-gray-700">Su negocio Factura</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="su-negocio-factura" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="margen-de-ganancia-del-negocio" className="block text-sm font-medium leading-5 text-gray-700">Margen de Ganancia del Negocio</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="margen-de-ganancia-del-negocio" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="ganancia-luego-de-pagar-los-gastos" className="block text-sm font-medium leading-5 text-gray-700">Ganancia (Luego de pagar los gastos)</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="ganancia-luego-de-pagar-los-gastos" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                    </div>
                    
                </div>

            </div>
          
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Gastos Mensuales del Negocio
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="alquiler" className="block text-sm font-medium leading-5 text-gray-700">Alquiler</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="alquiler" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="luz" className="block text-sm font-medium leading-5 text-gray-700">Luz</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="luz" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="agua" className="block text-sm font-medium leading-5 text-gray-700">Agua</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="agua" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="telefonos" className="block text-sm font-medium leading-5 text-gray-700">Teléfonos</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="telefonos" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="empleados" className="block text-sm font-medium leading-5 text-gray-700">Empleados</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="empleados" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="mantenimiento" className="block text-sm font-medium leading-5 text-gray-700">Mantenimiento</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="mantenimiento" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="transporte" className="block text-sm font-medium leading-5 text-gray-700">Transporte</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="transporte" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="impuestos" className="block text-sm font-medium leading-5 text-gray-700">Impuestos</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="impuestos" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="comidas" className="block text-sm font-medium leading-5 text-gray-700">Comidas</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="comidas" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="combustible" className="block text-sm font-medium leading-5 text-gray-700">Combustible</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="combustible" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="otros" className="block text-sm font-medium leading-5 text-gray-700">Otros</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="otros" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                    </div>
                    
                </div>

            </div>
          
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Información Familiar
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="maneja-dinero-en-efectivo" className="block text-sm font-medium leading-5 text-gray-700">Maneja dinero en efectivo </label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="DineroEfectvoFamilia" id="maneja-dinero-en-efectivo" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                         <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="mantiene-dinero-en-el-banco" className="block text-sm font-medium leading-5 text-gray-700">Mantiene dinero en el banco</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="DineroEnBancoFamilia" id="mantiene-dinero-en-el-banco" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                         <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                      
                              </div>
                          </div>        
                          
                          </div>
                          
                          <FieldArray
                              name="Bancos"
                              render={arrayHelpers => (
                              <div>
                                  {values.Bancos && values.Bancos.length > 0 ? (
                                  values.Bancos.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="banco" className="block text-sm font-medium leading-5 text-gray-700">Banco</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Bancos.${index}.NombreCompleto`} id="banco" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="cuenta" className="block text-sm font-medium leading-5 text-gray-700">Cuenta</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Bancos.${index}.NombreCompleto`} id="cuenta" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="monto" className="block text-sm font-medium leading-5 text-gray-700">Monto</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Bancos.${index}.NombreCompleto`} id="monto" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="adjuntar-documento" className="block text-sm font-medium leading-5 text-gray-700">Adjuntar documento</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Bancos.${index}.NombreCompleto`} id="adjuntar-documento" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Bancos
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="posee-vivienda-propio" className="block text-sm font-medium leading-5 text-gray-700">Posee Vivienda Propio</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="ViviendaPropia" id="posee-vivienda-propio" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                         <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                      
                              </div>
                          </div>        
                          
                          </div>
                          
                          <FieldArray
                              name="Viviendas"
                              render={arrayHelpers => (
                              <div>
                                  {values.Viviendas && values.Viviendas.length > 0 ? (
                                  values.Viviendas.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="detalle-local" className="block text-sm font-medium leading-5 text-gray-700">Detalle Local</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Viviendas.${index}.NombreCompleto`} id="detalle-local" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="valor" className="block text-sm font-medium leading-5 text-gray-700">Valor</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Viviendas.${index}.NombreCompleto`} id="valor" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="adjuntar-documento" className="block text-sm font-medium leading-5 text-gray-700">Adjuntar documento                            </label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Viviendas.${index}.NombreCompleto`} id="adjuntar-documento" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Viviendas
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="posee-vehiculos-personal" className="block text-sm font-medium leading-5 text-gray-700">Posee Vehículos Personal</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="VehiculoPersonal" id="posee-vehiculos-personal" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                         <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                      
                              </div>
                          </div>        
                          
                          </div>
                          
                          <FieldArray
                              name="Vehiculos"
                              render={arrayHelpers => (
                              <div>
                                  {values.Vehiculos && values.Vehiculos.length > 0 ? (
                                  values.Vehiculos.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="detalle-vehiculo" className="block text-sm font-medium leading-5 text-gray-700">Detalle Vehículo</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Vehiculos.${index}.NombreCompleto`} id="detalle-vehiculo" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="ano" className="block text-sm font-medium leading-5 text-gray-700">Año</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Vehiculos.${index}.NombreCompleto`} id="ano" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="valor" className="block text-sm font-medium leading-5 text-gray-700">Valor</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Vehiculos.${index}.NombreCompleto`} id="valor" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="adjuntar-documento" className="block text-sm font-medium leading-5 text-gray-700">Adjuntar documento</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Vehiculos.${index}.NombreCompleto`} id="adjuntar-documento" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Vehiculos
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="posee-mobiliario-en-el-hogar" className="block text-sm font-medium leading-5 text-gray-700">Posee Mobiliario en el Hogar</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="MobiliarioHogar" id="posee-mobiliario-en-el-hogar" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                         <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                      
                              </div>
                          </div>        
                          
                          </div>
                          
                          <FieldArray
                              name="Moviliarios"
                              render={arrayHelpers => (
                              <div>
                                  {values.Moviliarios && values.Moviliarios.length > 0 ? (
                                  values.Moviliarios.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="detalle-mobiliario" className="block text-sm font-medium leading-5 text-gray-700">Detalle Mobiliario</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Moviliarios.${index}.NombreCompleto`} id="detalle-mobiliario" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="cantidad" className="block text-sm font-medium leading-5 text-gray-700">Cantidad</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Moviliarios.${index}.NombreCompleto`} id="cantidad" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="valor" className="block text-sm font-medium leading-5 text-gray-700">Valor</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Moviliarios.${index}.NombreCompleto`} id="valor" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Moviliarios
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="tiene-amigos-que-le-deban-dinero-a-usted" className="block text-sm font-medium leading-5 text-gray-700">Tiene amigos que le deban dinero a usted</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="AmigosEnDeuda" id="tiene-amigos-que-le-deban-dinero-a-usted" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                         <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                      
                              </div>
                          </div>        
                          
                          </div>
                          
                          <FieldArray
                              name="Amigos"
                              render={arrayHelpers => (
                              <div>
                                  {values.Amigos && values.Amigos.length > 0 ? (
                                  values.Amigos.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="nombre-del-amigo" className="block text-sm font-medium leading-5 text-gray-700">Nombre del Amigo</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Amigos.${index}.NombreCompleto`} id="nombre-del-amigo" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="monto-adeudado" className="block text-sm font-medium leading-5 text-gray-700">Monto adeudado</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Amigos.${index}.NombreCompleto`} id="monto-adeudado" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Amigos
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                </div>

            </div>
          
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Ingresos de la Familia
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                          <FieldArray
                              name="Salarios"
                              render={arrayHelpers => (
                              <div>
                                  {values.Salarios && values.Salarios.length > 0 ? (
                                  values.Salarios.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="detalle-salario" className="block text-sm font-medium leading-5 text-gray-700">Detalle Salario</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Salarios.${index}.NombreCompleto`} id="detalle-salario" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="monto" className="block text-sm font-medium leading-5 text-gray-700">Monto</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Salarios.${index}.NombreCompleto`} id="monto" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Salarios Fijos
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="monto-ahorro-mensual" className="block text-sm font-medium leading-5 text-gray-700">Monto Ahorro Mensual</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="monto-ahorro-mensual" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                    </div>
                    
                </div>

            </div>
          
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Gastos Familiares Mensuales
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="alquiler" className="block text-sm font-medium leading-5 text-gray-700">Alquiler</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="alquiler" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="luz" className="block text-sm font-medium leading-5 text-gray-700">Luz</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="luz" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="agua" className="block text-sm font-medium leading-5 text-gray-700">Agua</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="agua" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="aseo" className="block text-sm font-medium leading-5 text-gray-700">Aseo</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="aseo" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="colegio" className="block text-sm font-medium leading-5 text-gray-700">Colegio</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="colegio" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="pension-alimenticia" className="block text-sm font-medium leading-5 text-gray-700">Pensión Alimenticia</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="pension-alimenticia" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="cable-internet" className="block text-sm font-medium leading-5 text-gray-700">Cable / Internet</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="cable-internet" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="mantenimiento" className="block text-sm font-medium leading-5 text-gray-700">Mantenimiento</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="mantenimiento" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="transporte" className="block text-sm font-medium leading-5 text-gray-700">Transporte</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="transporte" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="impuestos" className="block text-sm font-medium leading-5 text-gray-700">Impuestos</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="impuestos" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="alimentacion" className="block text-sm font-medium leading-5 text-gray-700">Alimentación</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="alimentacion" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="combustible" className="block text-sm font-medium leading-5 text-gray-700">Combustible</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="combustible" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="otros" className="block text-sm font-medium leading-5 text-gray-700">Otros</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="NombreCompleto" id="otros" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                      
                              </div>
                          </div>        
                          
                    </div>
                    
                </div>

            </div>
          
    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
    </Form>
    )}
    </Formik> 
)

const Demo = (props) => (
    <Formik
    initialValues={props.contract}
    onSubmit={async values => {
        await new Promise(r => setTimeout(r, 500));
        alert(JSON.stringify(values, null, 2));
    }}
    >
    {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
    }) => (          
    <Form>
    
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Datos del Cliente
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                    <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                    
                          <div className="sm:col-span-1">
                              <label htmlFor="tipo-de-persona" className="block text-sm font-medium leading-5 text-gray-700">Tipo de Persona</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="tipo-de-persona" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="nombre-del-cliente" className="block text-sm font-medium leading-5 text-gray-700">Nombre del Cliente</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="nombre-del-cliente" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="cedula" className="block text-sm font-medium leading-5 text-gray-700">Cédula </label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="cedula" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="genero" className="block text-sm font-medium leading-5 text-gray-700">Genero</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                    <Field name="Sexo" as="select" id="genero" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="">
                                    <option value="F">Femenino</option>
                                    <option value="M">Masculino</option>
                                    </Field>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="fecha-de-nacimiento" className="block text-sm font-medium leading-5 text-gray-700">Fecha de Nacimiento</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="fecha-de-nacimiento" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="edad" className="block text-sm font-medium leading-5 text-gray-700">Edad</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="edad" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="mantiene-creditos-vigentes" className="block text-sm font-medium leading-5 text-gray-700">Mantiene créditos vigentes</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="mantiene-creditos-vigentes" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="cliente-desde" className="block text-sm font-medium leading-5 text-gray-700">Cliente desde:</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="cliente-desde" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="monto-adeudado" className="block text-sm font-medium leading-5 text-gray-700">Monto Adeudado</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="monto-adeudado" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="telefono" className="block text-sm font-medium leading-5 text-gray-700">Teléfono</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="telefono" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="direccion" className="block text-sm font-medium leading-5 text-gray-700">Dirección</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="direccion" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="correo-electronico" className="block text-sm font-medium leading-5 text-gray-700">Correo Electrónico</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="correo-electronico" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                        <div className="sm:col-span-1">
                            <label htmlFor="jubilado" className="block text-sm font-medium leading-5 text-gray-700">Jubilado</label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                
                                        <Field name="Jubilado" id="jubilado" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                        <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                    
                            </div>
                        </div>        
                        
                        <div className="sm:col-span-1">
                            <label htmlFor="pensionado" className="block text-sm font-medium leading-5 text-gray-700">Pensionado</label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                
                                        <Field name="Pensionado" id="pensionado" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                        <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                    
                            </div>
                        </div>        
                        
                        <div className="sm:col-span-1">
                            <label htmlFor="inscrito-en-ampyme" className="block text-sm font-medium leading-5 text-gray-700">Inscrito en Ampyme</label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                
                                        <Field name="EnAmpyme" id="inscrito-en-ampyme" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                        <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                    
                            </div>
                        </div>        
                        
                        <div className="sm:col-span-1">
                            <label htmlFor="estado-civil" className="block text-sm font-medium leading-5 text-gray-700">Estado Civil</label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                
                                        <Field name="NombreCompleto" id="estado-civil" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                        <option value="Soltero">Soltero</option><option value="Casado">Casado</option><option value="Divorciado">Divorciado</option>
                                        </Field>
                                    
                            </div>
                        </div>      
                          
                    </div>
                    
                </div>

            </div>
          
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Datos de la Actividad
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                    <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                    
                          <div className="sm:col-span-1">
                              <label htmlFor="tipo-de-negocio" className="block text-sm font-medium leading-5 text-gray-700">Tipo de Negocio</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="tipo-de-negocio" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="actividad-economica" className="block text-sm font-medium leading-5 text-gray-700">Actividad Económica</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="actividad-economica" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="detalle-actividad-economica" className="block text-sm font-medium leading-5 text-gray-700">Detalle Actividad Económica</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="detalle-actividad-economica" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="desde-cuando-realiza-la-actividad" className="block text-sm font-medium leading-5 text-gray-700">Desde cuando realiza la actividad</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="desde-cuando-realiza-la-actividad" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="direccion-del-negocio" className="block text-sm font-medium leading-5 text-gray-700">Dirección del Negocio</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="direccion-del-negocio" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="telefono-del-negocio" className="block text-sm font-medium leading-5 text-gray-700">Teléfono del Negocio</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="telefono-del-negocio" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                    </div>
                    
                </div>

            </div>
          
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Datos del Crédito
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                    <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                    
                          <div className="sm:col-span-1">
                              <label htmlFor="monto-solicitado" className="block text-sm font-medium leading-5 text-gray-700">Monto Solicitado</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="monto-solicitado" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="plazo" className="block text-sm font-medium leading-5 text-gray-700">Plazo</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="plazo" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="destino-del-credito" className="block text-sm font-medium leading-5 text-gray-700">Destino del Crédito</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="destino-del-credito" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="dia-de-pago" className="block text-sm font-medium leading-5 text-gray-700">Dia de Pago</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="dia-de-pago" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                    </div>
                    
                </div>

            </div>
          
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Co-Deudores del Crédito
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                          <FieldArray
                              name="Avales"
                              render={arrayHelpers => (
                              <div>
                                  {values.Avales && values.Avales.length > 0 ? (
                                  values.Avales.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="nombres-y-apellidos" className="block text-sm font-medium leading-5 text-gray-700">Nombres y Apellidos</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Avales.${index}.NombreCliente`} id="nombres-y-apellidos" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="cedula-identidad" className="block text-sm font-medium leading-5 text-gray-700">Cédula Identidad</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Avales.${index}.NombreCliente`} id="cedula-identidad" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="telefono" className="block text-sm font-medium leading-5 text-gray-700">Teléfono</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Avales.${index}.NombreCliente`} id="telefono" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="correo-electronico" className="block text-sm font-medium leading-5 text-gray-700">Correo Electrónico</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Avales.${index}.NombreCliente`} id="correo-electronico" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="relacion-con-el-deudor" className="block text-sm font-medium leading-5 text-gray-700">Relación con el deudor</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Avales.${index}.NombreCliente`} id="relacion-con-el-deudor" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                          type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                          type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}>
                                      Incluir Co-Deudores del Crédito
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                </div>

            </div>
          
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Garantías
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                    <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                    
                          <div className="sm:col-span-1">
                              <label htmlFor="tipo-de-garantia" className="block text-sm font-medium leading-5 text-gray-700">Tipo de Garantía</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="tipo-de-garantia" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="detalle-de-la-garantia" className="block text-sm font-medium leading-5 text-gray-700">Detalle de la Garantía</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  <Field name="NombreCliente" id="detalle-de-la-garantia" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                              </div>
                          </div>        
                          
                    </div>
                    
                </div>

            </div>
          
    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
    </Form>
    )}
    </Formik>     
)

const Demo3 = (props) => (
    <Formik
    initialValues={props.contract}
    onSubmit={async values => {
        await new Promise(r => setTimeout(r, 500));
        alert(JSON.stringify(values, null, 2));
    }}
    >
    {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
    }) => (          
    <Form>
    
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Servicios
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="posee-materia-prima-en-su-negocio" className="block text-sm font-medium leading-5 text-gray-700">Posee Materia Prima en su negocio</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="MateriaPrima" id="posee-materia-prima-en-su-negocio" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                         <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                      
                              </div>
                          </div>        
                          
                          </div>
                          
                          <FieldArray
                              name="MateriasPrimas"
                              render={arrayHelpers => (
                              <div>
                                  {values.MateriasPrimas && values.MateriasPrimas.length > 0 ? (
                                  values.MateriasPrimas.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="producto" className="block text-sm font-medium leading-5 text-gray-700">Producto</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`MateriasPrimas.${index}.Producto`} id="producto" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="unidad" className="block text-sm font-medium leading-5 text-gray-700">Unidad</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`MateriasPrimas.${index}.Unidad`} id="unidad" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="cantidad" className="block text-sm font-medium leading-5 text-gray-700">Cantidad</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`MateriasPrimas.${index}.Cantidad`} id="cantidad" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="precio-costo" className="block text-sm font-medium leading-5 text-gray-700">Precio / Costo</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`MateriasPrimas.${index}.PrecioCosto`} id="precio-costo" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Materias Primas
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="posee-maquinaria-y-equipos-en-el-negocio" className="block text-sm font-medium leading-5 text-gray-700">Posee maquinaria y Equipos en el negocio</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="EquiposNegocio" id="posee-maquinaria-y-equipos-en-el-negocio" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                         <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                      
                              </div>
                          </div>        
                          
                          </div>
                          
                          <FieldArray
                              name="EquiposMaquinarias"
                              render={arrayHelpers => (
                              <div>
                                  {values.EquiposMaquinarias && values.EquiposMaquinarias.length > 0 ? (
                                  values.EquiposMaquinarias.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="detalle-maquinaria-y-o-equipo" className="block text-sm font-medium leading-5 text-gray-700">Detalle Maquinaria y/o Equipo</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`EquiposMaquinarias.${index}.DetalleEquipo`} id="detalle-maquinaria-y-o-equipo" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="cantidad" className="block text-sm font-medium leading-5 text-gray-700">Cantidad</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`EquiposMaquinarias.${index}.Cantidad`} id="cantidad" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="precio-costo" className="block text-sm font-medium leading-5 text-gray-700">Precio / Costo</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`EquiposMaquinarias.${index}.Precio`} id="precio-costo" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Equipos y Maquinarias
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="posee-inventario-de-mercancia-de-productos-para-la-venta" className="block text-sm font-medium leading-5 text-gray-700">Posee Inventario de Mercancia de Productos para la venta</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="InventarioMercancia" id="posee-inventario-de-mercancia-de-productos-para-la-venta" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                         <option value="No">No</option><option value="Si">Si</option>
                                        </Field>
                                      
                              </div>
                          </div>        
                          
                          </div>
                          
                          <FieldArray
                              name="Inventario"
                              render={arrayHelpers => (
                              <div>
                                  {values.Inventario && values.Inventario.length > 0 ? (
                                  values.Inventario.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="producto" className="block text-sm font-medium leading-5 text-gray-700">Producto</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Inventario.${index}.Producto`} id="producto" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="precio-de-compra" className="block text-sm font-medium leading-5 text-gray-700">Precio de Compra</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Inventario.${index}.PrecioCompra`} id="precio-de-compra" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="precio-de-venta" className="block text-sm font-medium leading-5 text-gray-700">Precio de Venta</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Inventario.${index}.PrecioVenta`} id="precio-de-venta" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="unidad" className="block text-sm font-medium leading-5 text-gray-700">Unidad</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Inventario.${index}.Unidad`} id="unidad" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="stock" className="block text-sm font-medium leading-5 text-gray-700">Stock</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Inventario.${index}.Stock`} id="stock" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Inventario
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                </div>

            </div>
          
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Evaluación del Negocio
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                          
                          <div className="sm:col-span-1">
                              <label htmlFor="periodo-de-facturacion" className="block text-sm font-medium leading-5 text-gray-700">Período de Facturación</label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                  
                                        <Field name="PeriodoFacturacion" id="periodo-de-facturacion" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" as="select">
                                         <option value="Diario">Diario</option><option value="Semanal">Semanal</option><option value="Mensual">Mensual</option>
                                        </Field>
                                      
                              </div>
                          </div>        
                          
                    </div>
                    
                </div>

            </div>
          
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Detalle de Compras
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                          <FieldArray
                              name="DetalleCompras"
                              render={arrayHelpers => (
                              <div>
                                  {values.DetalleCompras && values.DetalleCompras.length > 0 ? (
                                  values.DetalleCompras.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="proveedor" className="block text-sm font-medium leading-5 text-gray-700">Proveedor</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`DetalleCompras.${index}.Proveedor`} id="proveedor" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="frecuencia" className="block text-sm font-medium leading-5 text-gray-700">Frecuencia</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`DetalleCompras.${index}.Frecuencia`} id="frecuencia" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="monto" className="block text-sm font-medium leading-5 text-gray-700">Monto</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`DetalleCompras.${index}.NombrMonto`} id="monto" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Detalle de Compras
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                </div>

            </div>
          
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Detalle de Ventas
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                          <FieldArray
                              name="DetalleVentas"
                              render={arrayHelpers => (
                              <div>
                                  {values.DetalleVentas && values.DetalleVentas.length > 0 ? (
                                  values.DetalleVentas.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="articulo" className="block text-sm font-medium leading-5 text-gray-700">Artículo</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`DetalleVentas.${index}.Articulo`} id="articulo" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="ciclo-venta" className="block text-sm font-medium leading-5 text-gray-700">Ciclo Venta</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`DetalleVentas.${index}.CicloVenta`} id="ciclo-venta" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="cantidad-vendida" className="block text-sm font-medium leading-5 text-gray-700">Cantidad Vendida</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`DetalleVentas.${index}.CantidadVendida`} id="cantidad-vendida" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="unidad-venta" className="block text-sm font-medium leading-5 text-gray-700">Unidad Venta</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`DetalleVentas.${index}.UnidadVenta`} id="unidad-venta" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="precio-de-venta" className="block text-sm font-medium leading-5 text-gray-700">Precio de Venta</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`DetalleVentas.${index}.PrecioVenta`} id="precio-de-venta" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="costo-del-producto" className="block text-sm font-medium leading-5 text-gray-700">Costo del Producto</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`DetalleVentas.${index}.CostoProducto`} id="costo-del-producto" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Detalle de Ventas
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                </div>

            </div>
          
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Otros Ingresos
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                          <FieldArray
                              name="OtrosIngresos"
                              render={arrayHelpers => (
                              <div>
                                  {values.OtrosIngresos && values.OtrosIngresos.length > 0 ? (
                                  values.OtrosIngresos.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="detalle" className="block text-sm font-medium leading-5 text-gray-700">Detalle</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`OtrosIngresos.${index}.Detalle`} id="detalle" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="monto" className="block text-sm font-medium leading-5 text-gray-700">Monto</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`OtrosIngresos.${index}.Monto`} id="monto" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Otros Ingresos
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                </div>

            </div>
          
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Costos de Produción
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                          <FieldArray
                              name="CostosProdución"
                              render={arrayHelpers => (
                              <div>
                                  {values.CostosProdución && values.CostosProdución.length > 0 ? (
                                  values.CostosProdución.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="servicio" className="block text-sm font-medium leading-5 text-gray-700">Servicio</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`CostosProdución.${index}.Servicio`} id="servicio" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="insumos" className="block text-sm font-medium leading-5 text-gray-700">Insumos</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`CostosProdución.${index}.Insumos`} id="insumos" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Costos de Produción
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                </div>

            </div>
          
    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
    </Form>
    )}
    </Formik>      

)

const Demo4 = (props) => (
    <Formik
    initialValues={props.contract}
    onSubmit={async values => {
        await new Promise(r => setTimeout(r, 500));
        alert(JSON.stringify(values, null, 2));
    }}
    >
    {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
    }) => (          
    <Form>
    
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Seguros
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">
                
                          <FieldArray
                              name="Seguros"
                              render={arrayHelpers => (
                              <div>
                                  {values.Seguros && values.Seguros.length > 0 ? (
                                  values.Seguros.map((aval, index) => (
                                      <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                      { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                      
                                          <div className="sm:col-span-1">
                                              <label htmlFor="tipo-de-poliza" className="block text-sm font-medium leading-5 text-gray-700">Tipo de Póliza</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Seguros.${index}.TipoPoliza`} id="tipo-de-poliza" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="numero-de-poliza" className="block text-sm font-medium leading-5 text-gray-700">Número de Póliza</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Seguros.${index}.NumeroPoliza`} id="numero-de-poliza" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="aseguradora" className="block text-sm font-medium leading-5 text-gray-700">Aseguradora</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Seguros.${index}.Aseguradora`} id="aseguradora" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="desde" className="block text-sm font-medium leading-5 text-gray-700">Desde</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Seguros.${index}.Desde`} id="desde" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="hasta" className="block text-sm font-medium leading-5 text-gray-700">Hasta</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Seguros.${index}.Hasta`} id="hasta" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="monto-descontado" className="block text-sm font-medium leading-5 text-gray-700">Monto Descontado</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Seguros.${index}.MontoDescontado`} id="monto-descontado" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="saldo-descontado" className="block text-sm font-medium leading-5 text-gray-700">Saldo Descontado</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Seguros.${index}.SaldoDescontado`} id="saldo-descontado" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="cuota-anual" className="block text-sm font-medium leading-5 text-gray-700">Cuota Anual</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Seguros.${index}.CuotaAnual`} id="cuota-anual" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          
                                          <div className="sm:col-span-1">
                                              <label htmlFor="estatus" className="block text-sm font-medium leading-5 text-gray-700">Estatus</label>
                                              <div className="mt-1 relative rounded-md shadow-sm">
                                                  <Field name={`Seguros.${index}.Estatus`} id="estatus" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                              </div>
                                          </div>        
                                          

                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                          -
                                      </button>
                                      <button
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      type="button"
                                          onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                      >
                                          +
                                      </button>
                                      
                                      </div>
                                  ))
                                  ) : (
                                  <button type="button" onClick={() => arrayHelpers.push('')}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                      Incluir Seguros
                                  </button>
                                  )}
                              </div>
                              )}
                          />                        
                        
                </div>

            </div>
          
    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
    </Form>
    )}
    </Formik>  

)


const Demo5 = (props) => (
    <Formik
    initialValues={props.contract}
    onSubmit={async values => {
        await new Promise(r => setTimeout(r, 500));
        console.log(values);        
        alert(JSON.stringify(values, null, 2));
    }}
    >
    {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
    }) => (
        <Form>
            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">

                
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Datos del Cliente
                    </h3>
                </div>



                <div className="px-4 py-5 sm:px-6">
                    <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                        
                                <div className="sm:col-span-1">
                                    <label htmlFor="tipo-de-persona" className="block text-sm font-medium leading-5 text-gray-700">Tipo de Persona</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <Field id="tipo-de-persona" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="" />
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="nombre-del-cliente" className="block text-sm font-medium leading-5 text-gray-700">Nombre del Cliente</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <Field id="nombre-del-cliente" name="NombreCliente" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>

                                        
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="cedula" className="block text-sm font-medium leading-5 text-gray-700">Cédula </label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="cedula" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="genero" className="block text-sm font-medium leading-5 text-gray-700">Genero</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="genero" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="fecha-de-nacimiento" className="block text-sm font-medium leading-5 text-gray-700">Fecha de Nacimiento</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="fecha-de-nacimiento" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="edad" className="block text-sm font-medium leading-5 text-gray-700">Edad</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="edad" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="mantiene-creditos-vigentes" className="block text-sm font-medium leading-5 text-gray-700">Mantiene créditos vigentes</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="mantiene-creditos-vigentes" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="cliente-desde" className="block text-sm font-medium leading-5 text-gray-700">Cliente desde:</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="cliente-desde" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="monto-adeudado" className="block text-sm font-medium leading-5 text-gray-700">Monto Adeudado</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="monto-adeudado" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="telefono" className="block text-sm font-medium leading-5 text-gray-700">Teléfono</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="telefono" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="direccion" className="block text-sm font-medium leading-5 text-gray-700">Dirección</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="direccion" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="correo-electronico" className="block text-sm font-medium leading-5 text-gray-700">Correo Electrónico</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="correo-electronico" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="jubilado" className="block text-sm font-medium leading-5 text-gray-700">Jubilado</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="jubilado" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="pensionado" className="block text-sm font-medium leading-5 text-gray-700">Pensionado</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="pensionado" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="inscrito-en-ampyme" className="block text-sm font-medium leading-5 text-gray-700">Inscrito en Ampyme</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="inscrito-en-ampyme" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="estado-civil" className="block text-sm font-medium leading-5 text-gray-700">Estado Civil</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="estado-civil" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                    </div>
                </div>

            </div>

            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Datos de la Actividad
                    </h3>
                </div>



                <div className="px-4 py-5 sm:px-6">
                    <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                        
                                <div className="sm:col-span-1">
                                    <label htmlFor="tipo-de-negocio" className="block text-sm font-medium leading-5 text-gray-700">Tipo de Negocio</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="tipo-de-negocio" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="actividad-economica" className="block text-sm font-medium leading-5 text-gray-700">Actividad Económica</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="actividad-economica" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="detalle-actividad-economica" className="block text-sm font-medium leading-5 text-gray-700">Detalle Actividad Económica</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="detalle-actividad-economica" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="desde-cuando-realiza-la-actividad" className="block text-sm font-medium leading-5 text-gray-700">Desde cuando realiza la actividad</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="desde-cuando-realiza-la-actividad" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="direccion-del-negocio" className="block text-sm font-medium leading-5 text-gray-700">Dirección del Negocio</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="direccion-del-negocio" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="telefono-del-negocio" className="block text-sm font-medium leading-5 text-gray-700">Teléfono del Negocio</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="telefono-del-negocio" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                    </div>
                </div>

            </div>

            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Datos del Crédito
                    </h3>
                </div>



                <div className="px-4 py-5 sm:px-6">
                    <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                        
                                <div className="sm:col-span-1">
                                    <label htmlFor="monto-solicitado" className="block text-sm font-medium leading-5 text-gray-700">Monto Solicitado</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="monto-solicitado" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="plazo" className="block text-sm font-medium leading-5 text-gray-700">Plazo</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="plazo" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="destino-del-credito" className="block text-sm font-medium leading-5 text-gray-700">Destino del Crédito</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="destino-del-credito" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="dia-de-pago" className="block text-sm font-medium leading-5 text-gray-700">Dia de Pago</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="dia-de-pago" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                    </div>
                </div>

            </div>


            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Co-Deudores del Crédito
                    </h3>
                </div>


                <div className="px-4 py-5 sm:px-6">


                    <FieldArray
                        name="Avales"
                        render={arrayHelpers => (
                        <div>
                            {values.Avales && values.Avales.length > 0 ? (
                            values.Avales.map((aval, index) => (
                                <div key={index} className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">

                                { index != 0 ? <hr className="sm:col-span-3 sm:col-span-1"/> : null}

                                <div className="sm:col-span-1">
                                    <label htmlFor="nombres-y-apellidos" className="block text-sm font-medium leading-5 text-gray-700">Nombres y Apellidos</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <Field name={`Avales.${index}.ApellidoMaterno`} id="nombres-y-apellidos" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="cedula-identidad" className="block text-sm font-medium leading-5 text-gray-700">Cédula Identidad</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <Field name={`Avales.${index}`} id="cedula-identidad" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="telefono" className="block text-sm font-medium leading-5 text-gray-700">Teléfono</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <Field name={`Avales.${index}`} id="telefono" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="correo-electronico" className="block text-sm font-medium leading-5 text-gray-700">Correo Electrónico</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <Field name={`Avales.${index}`} id="correo-electronico" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="relacion-con-el-deudor" className="block text-sm font-medium leading-5 text-gray-700">Relación con el deudor</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <Field name={`Avales.${index}`} id="relacion-con-el-deudor" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        

                                <button
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                >
                                    -
                                </button>
                                <button
                                    type="button"
                                    onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                >
                                    +
                                </button>
                                
                                </div>
                            ))
                            ) : (
                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                {/* show this when user has removed all friends from the list */}
                                Incluir un Co-Deudor
                            </button>
                            )}
                            <div>
                            <button type="submit">Submit</button>
                            </div>
                        </div>
                        )}
                    />                        

 
                </div>
            </div>


            <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Garantías
                    </h3>
                </div>



                <div className="px-4 py-5 sm:px-6">
                    <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                        
                                <div className="sm:col-span-1">
                                    <label htmlFor="tipo-de-garantia" className="block text-sm font-medium leading-5 text-gray-700">Tipo de Garantía</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="tipo-de-garantia" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                                <div className="sm:col-span-1">
                                    <label htmlFor="detalle-de-la-garantia" className="block text-sm font-medium leading-5 text-gray-700">Detalle de la Garantía</label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input id="detalle-de-la-garantia" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
                                    </div>
                                </div>        
                                
                    </div>
                </div>

            </div>
            <pre>{JSON.stringify(values, null, 2)}</pre>
        </Form>
    )}
    </Formik>

    )

    const Demo6 = (props) => (
        <div className="mx-6 mt-6 bg-white overflow-hidden shadow sm:rounded-lg">
            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Job Postings
                </h3>
            </div>



            <div className="px-4 py-5 sm:px-6">
                <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                    <div className="sm:col-span-1">
                        <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">Email</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <input id="email" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="you@example.com"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-4 py-5">
                <h1>Sign Up</h1>
                <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                }}
                onSubmit={async values => {
                    await new Promise(r => setTimeout(r, 500));
                    alert(JSON.stringify(values, null, 2));
                }}
                >
                <Form>
                    <label htmlFor="firstName">First Name</label>
                    <Field id="firstName" name="firstName" placeholder="Jane" />

                    <label htmlFor="lastName">Last Name</label>
                    <Field id="lastName" name="lastName" placeholder="Doe" />

                    <label htmlFor="email">Email</label>
                    <Field
                    id="email"
                    name="email"
                    placeholder="jane@acme.com"
                    type="email"
                    />
                    <button type="submit">Submit</button>
                </Form>
                </Formik>
            </div>    
        </div>
)


const ContractAmounts = (props) => (
    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
            Montos de Contratos
            </h3>
        </div>
        <div className="px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
            <div className="sm:col-span-1">
                <dt className="text-sm leading-5 font-medium text-gray-500">
                Cliente
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900">
                {props.contract.NombreCliente}
                </dd>
            </div>
            </dl>
        </div>
    </div>
)

const Legal = (props) => (
    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
            Gestión Legal
            </h3>
        </div>
        <div className="px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
            <div className="sm:col-span-1">
                <dt className="text-sm leading-5 font-medium text-gray-500">
                Cliente
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900">
                {props.contract.NombreCliente}
                </dd>
            </div>
            </dl>
        </div>
    </div>
)


const LegalEdit = (props) => (
    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
            Edición de Gestión Legal
            </h3>
        </div>
        <div className="px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-1">
            <div className="sm:col-span-1">
                <form>
                <label htmlFor="about" className="block text-sm font-medium leading-5 text-gray-700">
                    About
                </label>

                <div className="mt-1 rounded-md shadow-sm">
                    <textarea id="about" rows="3" className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"></textarea>
                </div>
                <p className="mt-2 text-sm text-gray-500">Write a few sentences about yourself.</p>
               </form>    
            </div>
            </dl>
        </div>        
 
        
    </div>
)


const Operations = (props) => (
    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
            Gestión de Operaciones
            </h3>
        </div>
        <div className="px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
            <div className="sm:col-span-1">
                <dt className="text-sm leading-5 font-medium text-gray-500">
                Cliente
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900">
                {props.contract.NombreCliente}
                </dd>
            </div>
            </dl>
        </div>
        
    </div>
)

const OperationsEdit = (props) => (
    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
            Edición de Gestión de Operaciones
            </h3>
        </div>
        <div className="px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-1">
            <div className="sm:col-span-1">
                <form>
                <label htmlFor="about" className="block text-sm font-medium leading-5 text-gray-700">
                    About
                </label>

                <div className="mt-1 rounded-md shadow-sm">
                    <textarea id="about" rows="3" className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"></textarea>
                </div>
                <p className="mt-2 text-sm text-gray-500">Write a few sentences about yourself.</p>
               </form>    
            </div>
            </dl>
        </div>        
 
        
    </div>
)

const Client = (props) => (
    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
            Datos del Deudor
            </h3>
        </div>
        <div className="px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
            <div className="sm:col-span-1">
                <dt className="text-sm leading-5 font-medium text-gray-500">
                Cliente
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900">
                {props.contract.NombreCliente}
                </dd>
            </div>
            </dl>
        </div>
    </div>
)


const CoDebtor = (props) => (
    <tr className={props.index % 2 == 0 ? "bg-white" : "bg-gray-50"}>
        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
        {props.aval.NombreCliente}
        
        </td>
        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {props.aval.Cedula}

        </td>
        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        
        </td>
        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {props.aval.Puesto}
        </td>
    </tr>
)

const CoDebtors = (props) => {
    const grid = props.contract.Avales.map( (aval, index) => { 
        // console.log('data', contracts);
        return (
            <CoDebtor aval={aval} key={aval.AvalID} index={index}/>
        );
    });
    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                Codeudores
                </h3>
            </div>
            <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-col">
                <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                        <thead>
                        <tr>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Nombre
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Cédula
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Email
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Role
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {grid}    
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>


            </div>
        </div>

    )
}

const Guarantee = (props) => {
    Moment.locale('en');

    return (
        <tr className={props.index % 2 == 0 ? "bg-white" : "bg-gray-50"}>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
            {props.garantia.TipoGarantia}        
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD"
            }).format(props.garantia.agMontoAsignado)}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            {Moment(props.garantia.agFechaRegistro).format('YYYY-MM-DD')}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            {Number((props.garantia.agMontoAsignado / props.contract.Monto).toFixed(2))}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            {props.garantia.ClasifGarantia} / {props.garantia.Observaciones}
            </td>
        </tr>
    )
}


const Guarantees = (props) => {
    const grid = props.contract.Garantias.map( (garantia, index) => { 
        // console.log('data', contracts);
        return (
            <Guarantee garantia={garantia} contract={props.contract} key={garantia.GarantiaID} index={index}/>
        );
    });
    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                Garantías
                </h3>
            </div>
            <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-col">
                <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                        <thead>
                        <tr>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Tipo Garantía
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Monto Garantía
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Fecha Avaluo
                            </th>
                            <th width="50" className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Ralación Garantía Deuda
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Descripción
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {grid}    
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>


            </div>
        </div>

    )
}

const Accessory = (props) => (
    <tr className={props.index % 2 == 0 ? "bg-white" : "bg-gray-50"}>
        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
        {props.accessory.Descripcion}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD"
        }).format(props.accessory.Monto)}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD"
        }).format(props.accessory.ITBMS)}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD"
        }).format(props.accessory.Monto + props.accessory.ITBMS)}
        </td>
    </tr>
)

const Accessories = (props) => {
    const grid = props.contract.CreditoAccesorios.map( (accessory, index) => { 
        // console.log('data', contracts);
        return (
            <Accessory accessory={accessory} key={accessory.AccesorioID} index={index}/>
        );
    });
    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                Gastos y Comisiones
                </h3>
            </div>
            <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-col">
                <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                        <thead>
                        <tr>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Tipo Gasto
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Monto
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            ITBMS
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            TOTAL
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {grid}    
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>


            </div>
        </div>

    )
}


const Request = (props) => (
    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="text-2xl leading-6 font-medium text-gray-900">
            Detalle de la Solicitud: <span className="text-orange-600">{props.contract.SolicitudCreditoID}</span>
            </h3>
            <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
            Estado de la Solicitud: {props.contract.Estatus}-{props.contract.workflow_state}
            </p>
        </div>
        <div className="px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
            <div className="sm:col-span-1">
                <dt className="text-sm leading-5 font-medium text-gray-500">
                Cliente
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900">
                {props.contract.NombreCliente}
                </dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm leading-5 font-medium text-gray-500">
                Préstamo
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900">
                {props.contract.CreditoID == 0 ? 'No registrado' : props.contract.CreditoID}
                </dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm leading-5 font-medium text-gray-500">
                Monto del Crédito
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900">
                {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(props.contract.Monto)}                    </dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm leading-5 font-medium text-gray-500">
                Agencia
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900">
                    {props.contract.NombreSucurs}
                </dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm leading-5 font-medium text-gray-500">
                Producto / Garantía
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900">
                    {props.contract.ProductoCreditoID} {props.contract.ProductoDescripcion} / {props.contract.TipoGarantiasDescripcion}                        
                </dd>
            </div>
            </dl>
        </div>
    </div>

)

const Temp = (props) => (
    <div className="mx-6 my-6 bg-white shadow overflow-hidden sm:rounded-lg">
    <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
        Detalle de la Solicitud: <span className="text-orange-600">{contract.SolicitudCreditoID}</span>
        </h3>
        <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
        Estado de la Solicitud: {contract.Estatus}
        </p>
    </div>
    <div className="px-4 py-5 sm:p-0">
        <dl>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">
            Cliente
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            {contract.NombreCliente}
            </dd>
        </div>
        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">
            Prestamo
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            {contract.CreditoID}
            </dd>
        </div>
        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">
            Email address
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            margotfoster@example.com
            </dd>
        </div>
        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">
            Salary expectation
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            $120,000
            </dd>
        </div>
        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">
            About
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
            </dd>
        </div>
        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">
            Attachments
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            <ul className="border border-gray-200 rounded-md">
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
                <div className="w-0 flex-1 flex items-center">
                    <svg className="flex-shrink-0 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
                    </svg>
                    <span className="ml-2 flex-1 w-0 truncate">
                    resume_back_end_developer.pdf
                    </span>
                </div>
                <div className="ml-4 flex-shrink-0">
                    <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out">
                    Download
                    </a>
                </div>
                </li>
                <li className="border-t border-gray-200 pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
                <div className="w-0 flex-1 flex items-center">
                    <svg className="flex-shrink-0 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
                    </svg>
                    <span className="ml-2 flex-1 w-0 truncate">
                    coverletter_back_end_developer.pdf
                    </span>
                </div>
                <div className="ml-4 flex-shrink-0">
                    <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out">
                    Download
                    </a>
                </div>
                </li>
            </ul>
            </dd>
        </div>
        </dl>
    </div>
    </div>

)

export default Analisys
