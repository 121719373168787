import React from 'react';
import DatosSimulacion from './DatosSimulacion';

const SimulacionCredito = () => {

    return (
        <>
            {false ?
                <div className="flex h-screen">
                    <div className="m-auto">
                        <Bars
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={20000} //3 secs
                        />
                    </div>
                </div>
                :
                <div className="overflow-y-auto">
                    <DatosSimulacion />
                </div>
            }
        </>
    )
}

export default SimulacionCredito;