import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  BarElement,
  Tooltip,
  LinearScale,
  CategoryScale,
  Legend,
  LineElement,
  PointElement,
  LineController,
  Decimation,
  Filler,
  Title
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import axios from 'axios';
import { formatNumber } from '../Helpers/Helpers'

ChartJS.register(
  BarElement,
  Tooltip,
  LinearScale,
  CategoryScale,
  Legend,
  LineElement,
  PointElement,
  LineController,
  Decimation,
  Filler,
  Title
);



const sucursales = {
    0: 'ALL SUMA',
  };

const colors = [
  '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', 
  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D'
];

const transformData = (data) => {
  const result = {};
  data.forEach(item => {
    const dateObj = new Date(item.InsertDate);
    const dateOnly = dateObj.toLocaleDateString();
    if (!result[dateOnly]) {
      result[dateOnly] = {};
    }
    if (sucursales[item.SucursalID]) {
      result[dateOnly][sucursales[item.SucursalID]] = {
        value: item.Var_NumPrest91a180,
        risk: item.Banda91a180SaldoRiesgo
      };
    }
  });

  return Object.entries(result).map(([date, data]) => ({
    date,
    ...data
  }));
};

const ChartMora91a180 = () => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const res = await axios.get('https://api.sumadata.net/hisindicadores', { cancelToken: source.token });
        const data = transformData(res.data.rows);
        setChartData(data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Cancelled');
        } else {
          throw error;
        }
      }
    };

    fetchData();

    return () => {
      source.cancel();
    };
  }, []);

  const data = {
    labels: chartData.map(item => item.date),
    datasets: Object.values(sucursales).map((sucursal, i) => ({
      label: sucursal,
      data: chartData.map(item => item[sucursal]?.value || 0),
      fill: false,
      borderColor: colors[i % colors.length],
      backgroundColor: colors[i % colors.length],
    }))
  };

  return (
    <div className='shadow-sm rounded-lg' style={{backgroundColor: "white", padding: "20px"}}>
      <Line
        height={100}
        data={data}
        options = {{
          plugins: {
            tooltip: {
              titleFont: {
                size: 16
              },
              bodyFont: {
                size: 16
              },
              callbacks: {
                label: function(context) {
                  const dataset = context.dataset;
                  const index = context.dataIndex;
                  const risk = formatNumber(chartData[index][dataset.label].risk);
                  return `${dataset.label}: ${context.parsed.y} (Riesgo: USD ${risk})`;
                }
              }
            }
          }
        }}
      />
      <p style={{textAlign: "center"}}>Créditos en Mora 91 a 180 días</p>
    </div>
  );
};

export default ChartMora91a180;
