import React from "react";

const PlanPagoRows = ({ amort }) => {
    return(
    <tr key={amort.CreditoID + amort.AmortizacionID} className="bg-white border-b border-gray-200">
        <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  {amort.AmortizacionID}
                </td>
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  {amort.FechaVencim}
                </td>
    
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  {amort.Capital}
                </td>
    
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  {amort.Interes}
                </td>
    
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  {amort.MontoFECI}
                </td>
    
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  {amort.InteresAdeudado}
                </td>
    
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  {amort.FECIAdeudado}
                </td>
    
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  {amort.Estatus}
                </td>
    
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  {(
                    parseFloat(amort.FECIAdeudado) +
                    parseFloat(amort.InteresAdeudado) +
                    parseFloat(amort.MontoFECI) +
                    parseFloat(amort.Interes) +
                    parseFloat(amort.Capital)
                  ).toFixed(2)}
                </td>
    </tr>
    );
};

export default PlanPagoRows;