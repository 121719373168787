import { Controller } from "@hotwired/stimulus"
import React from 'react';
import ReactDOM from "react-dom"
import Prospects from "../components/Prospects/Prospects"


export default class extends Controller {
  
  connect() {
    ReactDOM.render(
      React.createElement(Prospects, this.reactProps),
      this.element
    )
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.element)
  }
}
