import React, { useState, useEffect } from 'react'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import {Bars} from 'react-loader-spinner'
import axios from 'axios'
import styled from 'styled-components'
import Moment from 'react-moment';
// import Template from '../Template'

import Disbursement from './Disbursement'
import Stat from './Stat'

const EstatusTranslate = {"signature":"Adjuntar Contrato", "legal":"Preparar Autenticación", "authentication": "En Autenticación", "auth_scan": "Escanear Documento", "operations": "Por Archivar", "archived": "Archivado"}

const Disbursements = (props) => {
    const [contracts, setContracts] = useState([]);
    const [signature, setSignature] = useState([]);
    const [legal, setLegal] = useState([]);
    const [authentication, setAuthentication] = useState([]);
    const [authScan, setAuthScan] = useState([]);
    const [operations, setOperations] = useState([]);
    const [archived, setArchived] = useState([]);

    const [currentUser, setCurrentUser] = useState([]);

    const [state, setState] = useQueryParam('state', StringParam);
    const [loading, setLoading] = useState([]);


    useEffect(() => {
        setLoading(true);

        const token = document.querySelector('[name=csrf-token]').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token            

        axios.get('/api/v1/solicitud_creditos/disbursements?status='+state)
        .then( resp => {
            setContracts(resp.data.items);
            setSignature(resp.data.signature);
            setLegal(resp.data.legal);
            setAuthentication(resp.data.authentication);
            setAuthScan(resp.data.auth_scan);
            setOperations(resp.data.operations);
            setArchived(resp.data.archived);
            setCurrentUser(resp.data.currentUser);
            setLoading(false)    

        })
        .catch( data => console.log('error', data));
    }, []);
  

    const grid = contracts.map( (contract, index) => { 
        // console.log('data', contracts);
        return (
            <Disbursement path={'\disbursements'} contract={contract} responsible={contract.responsible} key={contract.SolicitudCreditoID}/>
        );
    });

    
    return (
        <>
            { loading ?
                <div className="flex h-screen">
                <div className="m-auto">
                    <Bars
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={20000} //3 secs                
                    />
                    </div>
                </div>            
              :  

                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
                <div className="pt-2 pb-6 md:py-6">
                <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-11">
                            <h1 className="text-2xl font-semibold text-gray-900">Desembolsos</h1>
                        </div>
                        <div className="col-span-1">
                        <div className="inline-flex float-right">
                            <span className="ml-2 rounded-md shadow-sm ">
                                <a href={`/downloads/contracts.xlsx?status=${state}`}>
                                <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                                <svg className="-ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                </svg>
                                </button>
                                </a>
                            </span>
                            <span className="ml-2 rounded-md shadow-sm ">
                                <a href={`/pages/refresh?state=${state}`}>
                                <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                                <svg className="-ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                </svg>      
                                </button>
                                </a>
                            </span>                            
                        </div>
                        </div>
                    </div>

                    </div>                    

                    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                        <Stats 
                            signature={signature}
                            legal={legal}
                            authentication={authentication}
                            authScan={authScan}
                            operations={operations}
                            archived={archived}
                            state = {state}
                        />
                        <br/>
                        <Table grid={grid} />
                    </div>
                </div>
                </main>
            }
        </>
    );
}

const Stats = (props) => (
    <div>
    <h3 className="text-lg leading-6 font-medium text-gray-900">
        {EstatusTranslate[props.state]}
    </h3>
    <div className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-5">
        <Stat name="Desembolsados" amount={props.signature ? props.signature.cantidad : 0} number={props.signature ? props.signature.numero : 0} path="/disbursements"/>
    </div>
    </div>


);

const Table = (props) => (
    <div className="flex flex-col">
    <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
        <table className="min-w-full">
            <thead>
            <tr>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Prestamo / Cliente
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Producto / Garantía
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Monto del Crédito
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Estatus / Resp.
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Fecha / Agencia
                </th>
            </tr>
            </thead>
            <tbody className="bg-white">
                {props.grid}                
            </tbody>
        </table>
        </div>
    </div>
    </div>


);



export default Disbursements
