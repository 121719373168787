import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';
import FieldValue from './FieldValue'
import Accessories from './Accessories';
import ContractAmounts from './ContractAmounts';
import Client from './Client';
import Documents from './Documents';
import Credit from './Credit';
import CoDebtors from './CoDebtors';
import Checks from './Checks';
import Guarantees from './Guarantees';
import Observations from './Observations';
import Exceptions from './Exceptions';
import Approvals from './Approvals';
import Request from './Request';
import {Legal, LegalEdit} from './Legal';
import {ContractAuthentication, ContractAuthenticationEdit} from './ContractAuthentication';
import {Operations, OperationsEdit} from './Operations';


const DisbursementAuthentication = (props) => {
    const [legalUsers, setLegalUsers] = useState([]);
    // const [operationsUsers, setOperationslUsers] = useState([]);
    const [contract, setContract] = useState([]);
    const [client, setClient] = useState([]);
    const [credit, setCredit] = useState([]);
    const [files, setFiles] = useState([]);
    const [currentUser, setCurrentUser] = useState([]);
    const [allowed, setAllowed] = useState([]);
    const [loading, setLoading] = useState([]);
    const [refresh, setRefresh] = useState(0);

    const formRef = useRef(null);

    useEffect(() => {
        setLoading(true);
        axios.get('/api/v1/solicitud_creditos/'+props.match.params.id)
        .then( resp => {
            setContract(resp.data.item);
            setClient(resp.data.client);
            setCredit(resp.data.credit);
            setFiles(resp.data.files);
            setCurrentUser(resp.data.currentUser);
            setAllowed(resp.data.allowed);
            setLoading(false);

            const role = resp.data.item.Monto <= 5000.0 ? 'atc' : 'legal';
            const branchCond = resp.data.item.Monto <= 5000.0 ? `&branch=${resp.data.item.SucursalID}` : '';
            axios.get(`/api/v1/users?role=${role}${branchCond}` )
            .then( resp => setLegalUsers(resp.data.users) ) 
            .catch( data => console.log('error', data));
    
        })
        .catch( data => console.log('error', data));


        // axios.get(`/api/v1/users?role=operaciones` )
        // .then( resp => setOperationslUsers(resp.data.users) )
        // .catch( data => console.log('error', data));

        // await sleep(5000);
    }, [refresh]);

    const Actions = (props) => {
        const action_to_auth_signature = () => putAuthState('signature');
        const action_to_auth_legal = () => putAuthState('legal');
        const action_to_auth_authentication = () => putAuthState('authentication');
        const action_to_auth_auth_scan = () => putAuthState('auth_scan');
        const action_to_auth_operations = () => putAuthState('operations');
        const action_to_auth_archived = () => putAuthState('archived');

        const putAuthState = (state) => {
            axios.put(`/api/v1/solicitud_creditos/${props.contract.SolicitudCreditoID}`, {solicitud_credito: { legal_auth: {workflow_state:state} }} )
            .then( resp => setContract(resp.data.item))
            .catch( data => console.log('error', data))
        };

        const actionsButtons = () => { 
            return (
                <div>

                </div>
            );
        };
    
        // const allowed = false;

        return (    
        <div className="px-2 py-4 sm:px-6">
            <span className="mr-2 inline-flex rounded-md shadow-sm">
                <button type="button" className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                <a href={`/authentications?status=${props.contract.Estatus == "L" ? "L" : props.contract.workflow_state}`} >
                    Salir
                </a>
                </button>
            </span>
            { allowed && contract.Estatus == "D" && contract.legal_auth.workflow_state == "signature" &&
                <div className="inline-flex">
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" onClick={action_to_auth_legal} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
                            Preparar Autenticación en Legal
                        </button>
                    </span>
                </div>
            }
            { allowed && contract.Estatus == "D" && contract.legal_auth.workflow_state == "legal" &&
                <div className="inline-flex">
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" onClick={action_to_auth_signature} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
                            Regresar Solicitud de Autenticación
                        </button>
                    </span>
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" onClick={action_to_auth_authentication} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
                            Enviar a Autenticar
                        </button>
                    </span>
                </div>
            }
            { allowed && contract.Estatus == "D" && contract.legal_auth.workflow_state == "authentication" &&
                <div className="inline-flex">
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" onClick={action_to_auth_auth_scan} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
                            Recibida Autenticación 
                        </button>
                    </span>
                </div>
            }
            { allowed && contract.Estatus == "D" && contract.legal_auth.workflow_state == "auth_scan" &&
                <div className="inline-flex">
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" onClick={action_to_auth_legal} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
                            Enviar Nuevamente a Autenticar
                        </button>
                    </span>
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" onClick={action_to_auth_operations} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
                            Enviar Autenticación a Operaciones
                        </button>
                    </span>
                </div>
            }
            { allowed && contract.Estatus == "D" && contract.legal_auth.workflow_state == "operations" &&
                <div className="inline-flex">
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" onClick={action_to_auth_auth_scan} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
                            Regresar Autenticación para Escanear
                        </button>
                    </span>
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" onClick={action_to_auth_archived } className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
                            Archivar Autenticación
                        </button>
                    </span>
                </div>
            }
            { allowed && contract.Estatus == "D" && contract.legal_auth.workflow_state == "archived" &&
                <div className="inline-flex">
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" onClick={action_to_auth_operations} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
                            Retirar Autenticación de Archivo
                        </button>
                    </span>
                </div>
            }

            
        </div>
        )
    }
    
  
    return (
        <>            
            { loading ?
                <div className="flex h-screen">
                <div className="m-auto">
                    <Bars
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={10000} //3 secs
                
                    />
                    </div>
                </div>            
              :  
              <div className="overflow-y-auto">  
                    <Request contract={contract} client={client}/>
                    <Client contract={contract} client={client}/>
                    <Guarantees contract={contract}/>
                    <CoDebtors contract={contract}/>
                    <Accessories contract={contract}/>
                    { credit ?
                    <Credit contract={contract} credit={credit} /> : ""
                    }
                    { credit ?
                    <Checks contract={contract} /> : ""                  
                    }
                    <Approvals contract={contract}/>
                    <Exceptions contract={contract}/>
                    { false && contract.Estatus == "A" &&  ["GCi","PD","D"].includes(contract.workflow_state) &&
                    <Operations contract={contract}/>
                    }
                    { false && contract.Estatus == "A" &&  ["CO"].includes(contract.workflow_state) &&
                    <OperationsEdit contract={contract}/>
                    }
                    { (true || contract.Estatus == "A" &&  ["GCp","PD","D"].includes(contract.workflow_state)) &&
                    <Legal contract={contract} currentUser={currentUser}/> 
                    }
                    { contract.Estatus == "A" &&  ["GCi"].includes(contract.workflow_state) &&
                    <LegalEdit contract={contract} legalUsers={legalUsers} ref={formRef} />
                    }
                    {/* { contract.Estatus == "A" &&  ["CO","GCi","GCp","PD","D"].includes(contract.workflow_state) &&
                    <Documents contract={contract} files={files} />
                    } */}
                    { contract.Estatus == "A" &&  ["GCp","PD","D"].includes(contract.workflow_state) && contract.legal_auth.workflow_state == "legal" &&
                    <ContractAuthenticationEdit contract={contract}/>
                    }
                    { contract.Estatus == "A" &&  ["GCp","PD","D"].includes(contract.workflow_state) && ["authentication","auth_scan","operations","archived"].includes(contract.legal_auth.workflow_state) &&
                    <ContractAuthentication contract={contract}/>
                    }
                    { contract.Estatus == "A" &&  ["CO","GCi","GCp","PD","D"].includes(contract.workflow_state) &&
                    <Observations contract={contract}/>
                    }
                    <Actions contract={contract}/>
                </div>
            }
        </>
    );

}

export default DisbursementAuthentication
