import React, {useEffect,useState,useMemo, useRef} from "react";
import Template from "../Template";
import axios from "axios";
import {Bars} from 'react-loader-spinner';
import Table, { NameCell, ProductCell, AmountCell, EstatusCell, OfficeCell, AvatarCell, SelectColumnFilter, StatusPill } from '../Table/Table';
import { orangeClasses, grayClasses } from "../Design/classes";
import { formatDate } from "../Helpers/Helpers";
import Modal from 'react-modal';
import ConsultaCredito from '../ConsultaCredito/ConsultaCredito';



const WalletSeguimiento = (props) => {

    
    const customStyles = {
        overlay: {zIndex: 1000}
      };
  //const [currentUser, setCurrentUser] = useState([]);
  const [walletData,setWalletData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [checkedState, setCheckedState] = useState([0]);
  const [modalIsOpen,setIsOpen] = useState(false);

  const [isChecked, setIsChecked] = useState({});
  const [monto, setMonto] = useState([0]);
  const [nombreBanco, setNombreBanco] = useState([]);
  const [numeroReferencia, setNumeroReferencia] = useState([]);
  const [detalleNotas, setDetalleNotas] = useState([]);
  const [numtransaccion, setNumTransaccion] = useState(0);
  const [pagoerror, setPagoError] = useState([]);
  const [paymentSelected, setPaymentSelected] = useState([]);
  const {currentUser} = props;
  const [selectedCreditoId, setSelectedCreditoId] = useState(null);
  const [selectedClienteId, setSelectedClienteId] = useState(null);
  const [filterText, setFilterText] = useState('');

  const includesFilterText = (text) => {
    return String(text).toLowerCase().includes(filterText.toLowerCase());
  };

  function openModal(creditoId, clienteId) {
    setIsOpen(true);
    setSelectedCreditoId(creditoId);
    setSelectedClienteId(clienteId);

  }
  
  function closeModal(){
    setIsOpen(false);
    setSelectedCreditoId(null);
    setSelectedClienteId(null);
  }


  const handleCheck = (event) => {

    setIsChecked({...isChecked, ...{[event.target.name]: event.currentTarget.checked}});
    var updatedList = [...checkedState];
    if(event.target.checked){
      updatedList = [...checkedState, event.target.value];
    }else{
      updatedList.splice(checkedState.indexOf(event.target.value), 1);
    }
    setCheckedState(updatedList);
    
  };

  const handleInput = (event) => {
    setMonto(event.target.value)    
  }

  const getFilteredData = () => {
    const seguimientosArray = walletData.seguimientos.map(item => item.seguimientos);
    console.log('seguimientosArray', seguimientosArray);
    return seguimientosArray.filter(seguimiento => {
      let shortenedID = String(seguimiento._id.$oid).slice(-5);
      const idText = 'SeguimientoID: ' + shortenedID;
      const credito = seguimiento.credito;
      const tipoGestion = seguimiento.tipoGestion;
      const categoriaCobranza = seguimiento.categoriaCobranza;
      return (
        includesFilterText(idText) ||
        includesFilterText(credito) ||
        includesFilterText(tipoGestion) ||
        includesFilterText(categoriaCobranza)
      );
    });
  };

//   const getFilteredData = () => {

//     const seguimientosArray = walletData.seguimientos.map(item => item.seguimientos);


//     console.log('seguimientosArray', seguimientosArray);
//     return walletData.seguimientos.filter(walletConcilia => {
//       let shortenedID = String(walletConcilia.pago._id.$oid).slice(-5);
//       let usuario = walletConcilia.pago?.usuario?.NombreCompleto ?? "Sin Usuario";
//       const idText = 'PagoID: ' + shortenedID + ' / Usuario: ' + usuario;
//       const numRef = walletConcilia.pago.referenciaTransferencia;
//       const fechaTrans = walletConcilia.pago.fechaTransferencia;
//       const tipoPago = walletConcilia.pago.tipoPago;
//       const credito = walletConcilia.pago.creditNumber;
//       const nombreCliente = walletConcilia.pago.nombreCliente;
//       const depositante = walletConcilia.pago.depositante;
//       const bancoReceptor = walletConcilia.pago.bancoReceptor;
//       const noLibreCobro = walletConcilia.pago.noLibreCobro;
//       const montoPagado = walletConcilia.pago.amount;
//       return (
//         includesFilterText(depositante) ||
//         includesFilterText(idText) ||
//         includesFilterText(numRef) ||
//         includesFilterText(fechaTrans) ||
//         includesFilterText(tipoPago) ||
//         includesFilterText(credito) ||
//         includesFilterText(nombreCliente) ||
//         includesFilterText(bancoReceptor) ||
//         includesFilterText(noLibreCobro) ||
//         includesFilterText(montoPagado)
//       );
//     });
//   };



  useEffect(() => {
    setLoading(true);
    loadConsulta();

    return () => {
      isMounted.current = false;
    };

    
  }, [])

  const loadConsulta = async () => {
    await axios.get(`/api/v1/safi/get_all_seguimientos.json`).then((res) => {
      if(isMounted.current){
        setWalletData(res.data);
        setLoading(false);
        const seguimientosArray = res.data.seguimientos.map(item => item.seguimientos);
        console.log('seguimientosArray', seguimientosArray);
      }
    });
  };

  const isMounted = useRef(true);

//   const cancelPago = (cotizaID) => {
    
//     const token = document.querySelector('[name=csrf-token]').content
//     axios.defaults.headers.common['X-CSRF-TOKEN'] = token
//     axios.post(`/api/v1/safi/update_pago`, {...{cotizaID}, ...{rechazado: 'true'}})

//       .then( resp => {

//         loadConsulta()
//         setLoading(false);

//       })
//       .catch( data => console.log('error', data))
//   }


  const updateConciliacion = async (cotizaID, event) => {
    setLoading(true);
  
    const myObject = {
      transactions: checkedState,
      montoAConciliar: monto,
      nombreBanco: nombreBanco,
      numeroReferencia: numeroReferencia,
      detalleNotas: detalleNotas,
      usuario: currentUser,
    };
  
    const token = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
  
    const validTransactions = [];
  
    const processTransaction = async (pago) => {
      if (pago !== 0) {
        const separatedObject = pago.split(',');
    
        var cotizaID = separatedObject[1];
        var paymentCreditNumber = separatedObject[2];
        var paymentAmount = separatedObject[0];
        var transactionType = separatedObject[3];
        var paymentCustomerNumber = separatedObject[5];
    
        if (
          transactionType === 'transferencia' ||
          transactionType === 'tdc' ||
          transactionType === 'otros'
        ) {
          const paymentData = {
            customerNumber: paymentCustomerNumber,
            paymentType: 'E',
            creditNumber: paymentCreditNumber,
            savingsAccountNumber: '',
            amount: paymentAmount,
            cashNumber: '27',
            branchOffice: '1',
          };
    
          try {
            const resp = await axios.post(`/api/v1/safi/pago`, paymentData);

            console.log(`RESPUESTA`, resp.data)
    
            if (resp.data.codigoRespuesta === '000000') {
              
              validTransactions.push(pago);
              await axios.post(`/api/v1/safi/update_pago`, {
                cotizaID,
                conciliado: 'true',
                numTransaccion: resp.data.folio,
              });
            } else {
              setPagoError(resp.data.mensajeRespuesta);
              setPaymentSelected('pagoerror');
            }
          } catch (error) {
            console.log('error', error);
          }
        } else {
          validTransactions.push(pago);
          await axios.post(`/api/v1/safi/update_pago`, {
            cotizaID,
            conciliado: 'true',
          });
        }
      }
    };
    
  
    for (const pago of myObject.transactions) {
      await processTransaction(pago);
    }

  
    if (validTransactions.length > 0) {
      try {
        await axios.post(`/api/v1/safi/save_conciliacion`, {
          ...myObject,
          transactions: validTransactions,
        });
        loadConsulta();
      } catch (error) {
        console.log('error', error);
      }
    } else {
      console.log('No valid transactions to save');
    }
  
    setLoading(false);
    setMonto(0);
    setNombreBanco('');
    setDetalleNotas('');
  };
  

    return (
      <>
        <Modal
        style={customStyles}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Consulta de Crédito"
        >
        
        <ConsultaCredito clienteID={parseInt(selectedClienteId, 10)} creditoID={parseInt(selectedCreditoId, 10)} closeModal={closeModal}/>


      </Modal>
            { loading ?
                <div className="flex h-screen">
                <div className="m-auto">
                    <Bars
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={30000} //3 secs                
                    />
                    </div>
                </div>            
              :    
              <div className="overflow-y-auto">
                {/*Inner Container Start*/}
                <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">


                  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                      Seguimientos de Crédito
                    </h3>
                  </div>

                  <div className="p-5 sm:flex sm:gap-x-2 sm:justify-between">
                      <label className="flex gap-x-2 items-baseline">
                          <span className="text-gray-700">Buscar: </span>
                          <input
                              type="text"
                              placeholder="Filtrar resultados"
                              value={filterText}
                              onChange={(e) => setFilterText(e.target.value)}
                              className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          />
                      </label>

                      <a href={`/downloads/credito_pagos`}>
                          <button type="button" className="
                              inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                              <svg className="-ml-1 h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                              </svg>
                              Reporte de Seguimientos
                          </button>
                      </a>
                  </div>


                  <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
                <div className="ml-2 mt-2 w-full mr-2">
                  <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                    <thead className="sm:rounded-lg">
              <tr>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  ID Seguimiento
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Número de Credito
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Fecha de Gestion
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Tipo de Gestion
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Tiempo de Gestion
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Categoria de Cobranza
                </th>

                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Accion Realizada
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Resultado de Seguimiento
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Proxima Accion
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Comentario
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Fecha Proxima Gestion
                </th>
                
              </tr>
            </thead>
            <tbody>

            {
            getFilteredData().map((seguimiento, index) => {

                let shortenedID = String(seguimiento._id.$oid).slice(-5);
                let idText = 'GestionID: ' + shortenedID;
                let credito = seguimiento.credito;
                let tipoGestion = seguimiento.tipoGestion;
                let categoriaCobranza = seguimiento.categoriaCobranza;
                let accionRealizada = seguimiento.accionRealizada;
                let resultadoSeguimiento = seguimiento.resultadoSeguimiento;
                let proximaAccion = seguimiento.proximaAccion;
                let comentario = seguimiento.comentarioAdicional;
                let fechaProximaGestion = seguimiento.fechaProximaGestion;
                let fechaGestion = seguimiento.fechaGestion;
                let tiempoGestion = seguimiento.tiempoGestion;
                let clienteID = seguimiento.clienteID;

                const currentGrayClass = grayClasses[index % grayClasses.length];

                return(
                <tr
                    key={index}
                    className={`${currentGrayClass} border-b border-gray-200`}
                >
                    <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                    {idText}
                    </td>
                    <td className="px-4 py-2 font-semibold cursor-pointer" onClick={() => openModal(credito, clienteID)}><span className="bg-orange-300 hover:bg-orange-400 text-orange-800 font-bold w-36 py-2 px-4 rounded inline-flex items-center"><svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M19.129,18.164l-4.518-4.52c1.152-1.373,1.852-3.143,1.852-5.077c0-4.361-3.535-7.896-7.896-7.896
                                  c-4.361,0-7.896,3.535-7.896,7.896s3.535,7.896,7.896,7.896c1.934,0,3.705-0.698,5.078-1.853l4.52,4.519
                                  c0.266,0.268,0.699,0.268,0.965,0C19.396,18.863,19.396,18.431,19.129,18.164z M8.567,15.028c-3.568,0-6.461-2.893-6.461-6.461
                                  s2.893-6.461,6.461-6.461c3.568,0,6.46,2.893,6.46,6.461S12.135,15.028,8.567,15.028z"/></svg>{credito}</span></td>
                    {/* <td className="px-6 py-3 text-sm leading-5 text-center">
                    {credito}
                    </td> */}
                    <td className="px-6 py-3 text-sm leading-5 text-center">
                    {fechaGestion}
                    </td>
                    <td className="px-6 py-3 text-sm leading-5 text-center">
                    {tipoGestion}
                    </td>
                    <td className="px-6 py-3 text-sm leading-5 text-center">
                    {tiempoGestion}
                    </td>
                    <td className="px-6 py-3 text-sm leading-5 text-center">
                    {categoriaCobranza}
                    </td>
                    <td className="px-6 py-3 text-sm leading-5 text-center">
                    {accionRealizada}
                    </td>
                    <td className="px-6 py-3 text-sm leading-5 text-center">
                    {resultadoSeguimiento}
                    </td>
                    <td className="px-6 py-3 text-sm leading-5 text-center">
                    {proximaAccion}
                    </td>
                    <td className="px-6 py-3 text-sm leading-5 text-center">
                    {comentario}
                    </td>
                    <td className="px-6 py-3 text-sm leading-5 text-center">
                    {fechaProximaGestion}
                    </td>
                    {/* The rest of your table data cells go here... */}
                </tr>
                )

            })
            }
        
             {/* {
              getFilteredData().map((walletConcilia, index) => {

             let shortenedID = String(walletConcilia.pago._id.$oid).slice(-5);
             let usuario = walletConcilia.pago?.usuario?.NombreCompleto ?? "Sin Usuario";

             const currentGrayClass = grayClasses[index % grayClasses.length];
             
             if(walletConcilia.pago.conciliado === 'false' && walletConcilia.pago.rechazado != 'true'){

              return(
                
                    <tr
                    key={index}
                    className={`${currentGrayClass} border-b border-gray-200`}
                    >
                    <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                    {'PagoID: ' + shortenedID + ' / Usuario: ' + usuario}
                    </td>
                     
                    <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                    {walletConcilia.pago.referenciaTransferencia}
                    </td>

                    <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                    {formatDate(walletConcilia.pago.fechaTransferencia)}
                    </td>

                    <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                    {walletConcilia.pago.tipoPago}
                    </td>

                    <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                    {walletConcilia.pago.creditNumber}
                    </td>

                    <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                    {walletConcilia.pago.nombreCliente}
                    </td>

                    <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                    {walletConcilia.pago.nombreDepositante}
                    

                    </td>

                    <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                    {walletConcilia.pago.bancoReceptor}

                    </td>

                    <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                    {walletConcilia.pago.libretaCobro}

                    </td>

                    <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD"
                    }).format(walletConcilia.pago.amount)}




                    </td>
                    
                    <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                    {walletConcilia.pago.attachments.map((attachment, index) => {
                      return(
                        <div key={index}>
                          <a className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150" href={attachment} target="_blank">{`Anexo`}</a>
                        </div>
                      )
                    })}
                    </td>

                    <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                    
                    </td>

                    <td className="max-w-xs px-6 py-3 text-xs leading-5 text-gray-500 text-center">

                      <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => cancelPago(walletConcilia.pago._id.$oid)}>Rechazar</button>
                    </td>

                    

                    


                  </tr>

              )
              }
              

              })
            
              } */}

              
            </tbody>

            
            </table>

              

                  </div>
                </div>
              </div>



                
                </div>
                {/*Inner Container End*/}

              </div>
              
              
            }
            
        </>
    );

}

export default WalletSeguimiento;
