import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function CheckboxGroup (props) {
  const { label, name, options, ...rest } = props
  return (
    <div className="sm:col-span-1">
      <div> 
          <div className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700" id="label-email">
          {/* <label> */}
          {label}
          {/* </label> */}
          </div>
      </div>

      <div className="mt-4 sm:mt-0 sm:col-span-2">

        <Field name={name}>
          {({ field }) => {
            return options.map(option => {
              return (
                <React.Fragment key={option.key}>
                  <div className="max-w-lg space-y-4">                                     
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                    <input
                      type='checkbox'
                      id={option.value}
                      {...field}
                      {...rest}
                      value={option.value}
                      checked={field ? field.value.includes(option.value) : false}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                    </div>
                    <div className="ml-3 text-sm">
                    <label htmlFor={option.value} className="font-medium text-gray-700">{option.key}</label>
                    </div>
                  </div>
                  </div>
                </React.Fragment>
              )
            })
          }}
        </Field>
        <ErrorMessage component={TextError} name={name} />


      </div>
    </div>
  )
}

export default CheckboxGroup
