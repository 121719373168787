import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  BarElement,
  Tooltip,
  LinearScale,
  CategoryScale,
  Legend,
  LineElement,
  PointElement,
  LineController,
  Decimation,
  Filler,
  Title
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { formatNumber, getStyle } from '../Helpers/Helpers'
import {Bars} from 'react-loader-spinner';
import Modal from 'react-modal';
import ConsultaCredito from '../ConsultaCredito/ConsultaCredito';
import Seguimiento from '../ConsultaCredito/Seguimiento';

ChartJS.register(
  BarElement,
  Tooltip,
  LinearScale,
  CategoryScale,
  Legend,
  LineElement,
  PointElement,
  LineController,
  Decimation,
  Filler,
  Title
);


const ChartDiasCobros = () => {
  const [chartData, setChartData] = useState([]);
  const [selectedBarCreditNumbers, setSelectedBarCreditNumbers] = useState([]);
  const [creditDetails, setCreditDetails] = useState([]);
  const [selectedDay, setSelectedDay] = useState('');
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("");
  const [modalIsOpen,setIsOpen] = useState(false);
  const [selectedCreditoId, setSelectedCreditoId] = useState(null);
  const [selectedClienteId, setSelectedClienteId] = useState(null);



  function openModal(creditoId, clienteId) {
    setIsOpen(true);
    setSelectedCreditoId(creditoId);
    setSelectedClienteId(clienteId);

  }
  
  function closeModal(){
    setIsOpen(false);
    setSelectedCreditoId(null);
    setSelectedClienteId(null);
  }


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };



  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredDetails = creditDetails.filter((detail) => {
    const creditData = detail.rows && detail.rows[0];
    return creditData && (
      creditData.CreditoID.toString().includes(searchQuery) ||
      creditData.NombreCompleto.toLowerCase().includes(searchQuery.toLowerCase()) ||
      creditData.SucursalNombreGrupo.toLowerCase().includes(searchQuery.toLowerCase()) ||
      creditData.NombreGestor.toLowerCase().includes(searchQuery.toLowerCase())
    );
  })
  .sort((a, b) => {

    const creditDataA = a.rows && a.rows[0];
    const creditDataB = b.rows && b.rows[0];
    
    switch(sortBy) {
      case 'MontoPagoPar30':
        return sortOrder === "asc" ?
          creditDataA.MontoPagoPar30 - creditDataB.MontoPagoPar30 :
          creditDataB.MontoPagoPar30 - creditDataA.MontoPagoPar30;
      case 'DiasAtraso':
        const diasAtrasoA = parseInt(creditDataA.DiasAtraso);
        const diasAtrasoB = parseInt(creditDataB.DiasAtraso);
        return sortOrder === "asc" ? 
          (isNaN(diasAtrasoA) ? 0 : diasAtrasoA) - (isNaN(diasAtrasoB) ? 0 : diasAtrasoB) :
          (isNaN(diasAtrasoB) ? 0 : diasAtrasoB) - (isNaN(diasAtrasoA) ? 0 : diasAtrasoA);
      case 'MontoCredito':
        return sortOrder === "asc" ? 
          creditDataA.MontoCredito - creditDataB.MontoCredito :
          creditDataB.MontoCredito - creditDataA.MontoCredito;
      case 'NombreCompleto':
        return sortOrder === "asc" ? 
          creditDataA.NombreCompleto.localeCompare(creditDataB.NombreCompleto) :
          creditDataB.NombreCompleto.localeCompare(creditDataA.NombreCompleto);
      default:
        return 0;
    }

  });

  const toggleSortOrder = (field) => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortBy(field);
  };

  const paginatedDetails = filteredDetails.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );




  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get('https://api.sumadata.net/cobros');
        const data = res.data.rows; // Adjusted this line
        setChartData(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedBarCreditNumbers.length > 0) {
      setLoading(true);
      const fetchCreditDetails = async () => {
        const promises = selectedBarCreditNumbers.map((creditNumber) =>
          axios.get(`https://api.sumadata.net/analitico_today?credito=${creditNumber}`)
        );
        try {
          const results = await Promise.all(promises);
          const details = results.map((res) => res.data);
          console.log(`details`, details);
          setCreditDetails(details);
          setLoading(false);
        } catch (err) {
          console.error(err);
          setLoading(false);
        }
      };

      fetchCreditDetails();
    }
  }, [selectedBarCreditNumbers]);

  const data = {
    labels: chartData.map(item => item.DayOfMonth),
    datasets: [
      {
        label: 'Cantidad de Créditos',
        data: chartData.map(item => item.CreditCount),
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  };

  const customStyles = {
    overlay: {zIndex: 1000}
  };

  const options = {
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    onClick: (event, activeElements) => {
      if (activeElements.length > 0) {
        const index = activeElements[0].index;
        setSelectedDay(chartData[index].DayOfMonth);
        setSelectedBarCreditNumbers(chartData[index].CreditNumbers.split(','));
      }
    },
    plugins: {
      layout: {
        padding: {
          top:10,
          right: 10,
          bottom: 10,
          left: 10
        }
      },
      datalabels: {
        align: 'end',
        anchor: 'end',
        fomatter: (value, context) => {
          return formatNumber(chartData[context.dataIndex].TotalAmount);
        },
        color: '#000',
        labels: {
          title: {
            font: {
              weight: 'bold',
            }
          }
        }
      },
      tooltip: {
        titleFont: {
          size: 16
        },
        bodyFont: {
          size: 16
        },
        callbacks: {
          title: function (tooltipItem) {
            return `Cantidad de Créditos:  ${tooltipItem[0].formattedValue}`;
          },
          label: function (tooltipItem) {
            return `Monto Total: USD ${formatNumber(chartData[tooltipItem.dataIndex].TotalAmount)}`;
          },
        },
      },
    },
  };

  return (
    <div>
      <Modal
        style={customStyles}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Consulta de Crédito"
        >
        
        <ConsultaCredito clienteID={parseInt(selectedClienteId, 10)} creditoID={parseInt(selectedCreditoId, 10)} closeModal={closeModal}/>


      </Modal>
      <Bar data={data} options={options} />
      {selectedBarCreditNumbers.length > 0 && (
        loading ?
          <div className="flex h-screen">
            <div className="m-auto">
              <Bars
                color="#00BFFF"
                height={100}
                width={100}
                timeout={20000} //3 secs
              />
            </div>
          </div>
         :
        <>

        <table className="w-full bg-white rounded-lg shadow-md">
          <thead>
          <tr className="" >
              <th className="px-4 py-2 bg-orange-500 text-white" colSpan="8">Creditos Dia Pago - Dia Seleccionado: {selectedDay}</th>
          </tr>
          <tr>
            <th className="px-5 py-2" colSpan="8">
              <input
                type="text"
                placeholder="Buscar por Credito ID, Sucursal, Nombre de Cliente o Nombre de Gestor"
                className="mt-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                value={searchQuery}
                onChange={handleSearchChange}
                />
    </th>
          </tr>
          <tr className="bg-gray-800 text-white text-center text-xs">
            <th className="px-4 py-2">Credito ID</th>
            <th className="px-4 py-2">Sucursal</th>
            <th className="px-4 py-2">Producto</th>
            <th className="px-4 py-2 cursor-pointer" onClick={() => toggleSortOrder('NombreCompleto')}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
  <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
    <path d="M3 4l3-3 3 3"></path>
    <path d="M3 8l3 3 3-3"></path>
  </g>
</svg>Nombre Cliente</th>
            <th className="px-4 py-2 cursor-pointer" onClick={() => toggleSortOrder('MontoCredito')} ><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
  <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
    <path d="M3 4l3-3 3 3"></path>
    <path d="M3 8l3 3 3-3"></path>
  </g>
</svg>Monto Crédito</th>
            <th className="px-4 py-2 cursor-pointer" onClick={() => toggleSortOrder('DiasAtraso')}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
  <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
    <path d="M3 4l3-3 3 3"></path>
    <path d="M3 8l3 3 3-3"></path>
  </g>
</svg>Días Atraso</th>
            <th className="px-4 py-2" >Nombre Gestor</th>
            <th className="px-4 py-2 cursor-pointer" onClick={() => toggleSortOrder('MontoPagoPar30')}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
  <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
    <path d="M3 4l3-3 3 3"></path>
    <path d="M3 8l3 3 3-3"></path>
  </g>
</svg>Monto Pago Par30</th>

          </tr>
          </thead>
          <tbody>
          {paginatedDetails.map((detail, index) => {
            const creditData = detail.rows && detail.rows[0]; // Access the first item of rows array
            return (
              creditData && (
              <tr className="bg-white text-xs text-center" key={index}>
                <td className="px-4 py-2 font-semibold cursor-pointer" onClick={() => openModal(creditData.CreditoID, creditData.ClienteID)}><span className="bg-orange-300 hover:bg-orange-400 text-orange-800 font-bold w-36 py-2 px-4 rounded inline-flex items-center"><svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M19.129,18.164l-4.518-4.52c1.152-1.373,1.852-3.143,1.852-5.077c0-4.361-3.535-7.896-7.896-7.896
                                  c-4.361,0-7.896,3.535-7.896,7.896s3.535,7.896,7.896,7.896c1.934,0,3.705-0.698,5.078-1.853l4.52,4.519
                                  c0.266,0.268,0.699,0.268,0.965,0C19.396,18.863,19.396,18.431,19.129,18.164z M8.567,15.028c-3.568,0-6.461-2.893-6.461-6.461
                                  s2.893-6.461,6.461-6.461c3.568,0,6.46,2.893,6.46,6.461S12.135,15.028,8.567,15.028z"/></svg>{creditData.CreditoID}</span></td>
                <td className="px-4 py-2">{creditData.SucursalNombreGrupo}</td>
                <td className="px-4 py-2">{creditData.Descripcion}</td>
                <td className="px-4 py-2">{creditData.NombreCompleto}</td>
                <td className="px-4 py-2">{formatNumber(creditData.MontoCredito, 2)}</td>
                <td style={getStyle(creditData.DiasAtraso)} className="px-4 py-2">{creditData.DiasAtraso}</td>

                <td className="px-4 py-2">{creditData.NombreGestor}</td>
                <td className="px-4 py-2">{formatNumber(creditData.MontoPagoPar30, 2)}</td>
              </tr>
              )
            );
          })}
          </tbody>
        </table>
        <div className="flex justify-center mt-4 py-5">
          <button
            className={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l ${
              currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          <span className="mx-4">
            Página {currentPage} de {Math.ceil(filteredDetails.length / itemsPerPage)}
          </span>
          <button
            className={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r ${
              currentPage === Math.ceil(filteredDetails.length / itemsPerPage)
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            onClick={() =>
              handlePageChange(
                Math.min(currentPage + 1, Math.ceil(filteredDetails.length / itemsPerPage))
              )
            }
            disabled={currentPage === Math.ceil(filteredDetails.length / itemsPerPage)}
          >
            Siguiente
          </button>
        </div>
        </>
      )}
    </div>
  );
};

export default ChartDiasCobros;
