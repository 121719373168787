import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function Input(props) {
  const { label, name, symbol, ...rest } = props;

  return (
    <div className="sm:col-span-1">
      <label htmlFor={name} className="block text-sm font-medium leading-5 text-gray-700">
        {label}
      </label>
      <div className="mt-1 relative">
        <Field
          name={name}
          id={name}
          {...rest}
          className={`rounded-md shadow-sm border-gray-300 form-input block w-full sm:text-sm sm:leading-5 ${
            symbol ? 'pr-10' : 'pr-2'
          }`}
          placeholder=""
        />
        {symbol && (
          <div className="absolute inset-y-0 right-0 pr-4 pl-4 mb-1 flex items-center pointer-events-none bg-gray-200 rounded-r-md">
            <span className="text-gray-500 sm:text-sm sm:leading-5">{symbol}</span>
          </div>
        )}
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          <ErrorMessage name={name} />
        </span>
      </div>
    </div>
  );
}

export default Input;



// function Input (props) {
//   const { label, name, ...rest } = props
//   return (
//     <div className="sm:col-span-1">
//         <label htmlFor={name} className="block text-sm font-medium leading-5 text-gray-700">{label}</label>
//         <div className="mt-1"> 
//             <Field name={name} id={name} {...rest} className="rounded-md shadow-sm border-gray-300 form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
//             <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
//             <ErrorMessage name={name} />
//             </span>
//         </div>
//     </div>                                   
//   )
// }

// export default Input
