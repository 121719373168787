import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-select"
export default class extends Controller {
  static targets = ['hideForSelect']
  static values = {initial: String}


  connect() {
    
    this.hideForSelectTargets.forEach((target)=>{
      // target.add
      if (target.id == this.initialValue){
        target.classList.remove("hidden")
        // target.removeAttribute("style")
      }
      else{
        target.classList.add("hidden")
        // target.setAttribute("style","display:none")
      }
    })
  }

  update(event) {
    this.hideForSelectTargets.forEach((target)=>{
      // target.add
      if (target.id == event.target.value){
        // if(event.target.selectedOptions[0].attributes['rubro'])
        //   console.log('rubro',event.target.options[event.target.selectedIndex].attributes.rubro.value);

        target.classList.remove("hidden")
        // target.removeAttribute("style")
      }
      else{
        target.classList.add("hidden")
        // target.setAttribute("style","display:none")
      }
    })
  }
}
