import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form, ErrorMessage } from 'formik';
import JSONPretty from 'react-json-prettify';
import FieldValue from './FieldValue';

import * as Yup from 'yup'
import FormikControl from '../forms/FormikControl'


const dropdownContactTypesOptions = [
    {key: 'Correo Electrónico', value: 'Correo Electrónico'},
    {key: 'Cotizar', value: 'Cotizar'},
    {key: 'Llamada', value: 'Llamada'},
    {key: 'Solicitar Datos', value: 'Solicitar Datos'},
    {key: 'Visitar Cliente', value: 'Visitar Cliente'},
    {key: 'Visita Agencia', value: 'Visita Agencia'},
    {key: 'WhatsApp', value: 'WhatsApp'}
  ]

const dropdownContactNextActionsOptions = [
    {key: 'Contactar', value: 'Contactar'},
    {key: 'Contactar a Futuro', value: 'Contactar a Futuro'},
    {key: 'Gestionar APC', value: 'Gestionar APC'},
    {key: 'Solicitar Datos', value: 'Solicitar Datos'},
    {key: 'Cotizar', value: 'Cotizar'},
    {key: 'Visitar', value: 'Visitar'},
    {key: 'Iniciar Solicitud', value: 'Iniciar Solicitud'},
    {key: 'No Interesado', value: 'No Interesado'},
    {key: 'Rechazado', value: 'Rechazado'}
]
// {key: 'Mala APC', value: 'Mala APC'}


const ContactForm = (props) => {
    const [hiddenPanel, setHiddenPanel] = useState(false);

    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">  
            {props.contactEdit &&
            <div className="m-4 inline-flex float-right">
                <span className="inline-flex rounded-md shadow-sm">
                    <button type="submit" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                        Salvar
                    </button>
                </span>
            </div>        
            }
            {!props.contactEdit &&
                <div className="m-4 inline-flex float-right">
                    <span className="ml-2 rounded-md shadow-sm ">
                        <a onClick={() => props.setContactEdit(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>                           
                        </a>
                    </span>
                </div>        
            }          
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="flex text-lg leading-6 font-medium text-gray-900">   
                <a onClick={() => setHiddenPanel(!hiddenPanel)}>       
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor" data-action="click->toggler#toggle">
                {hiddenPanel
                    ? <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" data-toggler-target="selectorIcon" />
                    : <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" data-toggler-target="selectorIcon" />
                }
                </svg>
                </a>
                Contactos
                </h3>
            </div>
            {!hiddenPanel &&
                <div className="px-4 py-5 sm:px-6">
                    <FieldArray name="contacts">
                        {({ push, remove }) => (
                            <React.Fragment>
                            {props.values.contacts && props.values.contacts.map((contact, index) => (
                                    <div key={index}>
                                    
                                    {contact.created_at ? 
                                        <div>
                                        <dl className="mb-10 grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4"> 
                                        <FieldValue label={"Tipo de Contacto"}>{contact.contact_type}</FieldValue>
                                        <FieldValue label={"Fecha/Hora"}>{Moment(contact.created_at).format('YYYY-MM-DD hh:mm A')}</FieldValue>
                                        <FieldValue label={"Realizado por"}>{contact.user}</FieldValue>                                    
                                        </dl>
                                        <dl className="mb-10 grid grid-cols-1 col-gap-1 gap-x-8 sm:grid-cols-1"> 
                                        <FieldValue label={"Observaciones"}>{contact.observations}</FieldValue>
                                        </dl>
                                        <dl className="mb-10 grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4"> 
                                        <FieldValue label={"Próxima Acción"}>{contact.next_action}</FieldValue>
                                        <FieldValue label={"Fecha de próxima acción"}>{Moment(contact.next_action_date).format('YYYY-MM-DD')}</FieldValue>
                                        </dl>
                                        </div>
                                    :
                                        <div>
                                        {props.contactEdit &&
                                            <div>
                                            <dl className="grid grid-cols-1 col-gap-1 gap-x-8 sm:grid-cols-1"> 
                                            <div className="col-start-4 col-end-5 place-self-end place-self-top">
                                                <button        
                                                    className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm 
                                                    bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    type="button" onClick={() => remove(index)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                                </button>
                                            </div>                                    
                                            </dl>
                                            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4"> 
                                            <FormikControl
                                                control='select'
                                                label='Tipo de Contacto'
                                                name={`contacts[${index}]contact_type`}
                                                options={dropdownContactTypesOptions}      
                                            />
                                            </dl>
                                            <dl className="mb-10 grid grid-cols-1 col-gap-1 gap-x-8 sm:grid-cols-1"> 
                                            <FormikControl
                                                control='textarea'
                                                label='Observaciones'
                                                name={`contacts[${index}]observations`}
                                            />        
                                            </dl>
                                            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4"> 
                                            <FormikControl
                                                control='select'
                                                label='Próxima Acción'
                                                name={`contacts[${index}]next_action`}
                                                options={dropdownContactNextActionsOptions}      
                                            />
                                            <FormikControl
                                                control='date'
                                                label='Fecha de próxima acción'
                                                name={`contacts[${index}]next_action_date`}
                                            />
                                            </dl>
                                            </div>
                                        }

                                        </div>                                
                                    }
                                    <hr className="mb-5" />
                                    </div>

                            ))}
                            {props.contactEdit &&
                            <button
                            type="button"
                            onClick={() => push({ contact_type: null, observations: '', next_action: props.values.next_action,  next_action_date: props.values.next_action_date})}
                            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                            Nuevo Contacto
                            </button>
                            }
                        </React.Fragment>
                        )}
                    </FieldArray>
                </div>
            }
        </div>    
    )

}



export default ContactForm;

