import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input-fields"
export default class extends Controller {
  static targets = ["fieldsContainer"]
  static values = {list: String};

  connect() {
    console.log('connected to input-fields controller')
  }

  addFields() {
    // event.preventDefault()
    
    const field = document.createElement("div")
    if (this.listValue === 'banks') {
      field.innerHTML = `
      <dl class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
      <div>
          <label for="financial_data_business_banks_attributes__Banco">Banco</label>
          <div>
          <input type="text" name="financial_data_business[banks_attributes][0][name]" id="financial_data_business_banks_attributes__name">
          </div>
      </div>
      <div>
          <label for="financial_data_business_banks_attributes__Cuenta">Cuenta</label>
          <div>
          <input type="text" name="financial_data_business[banks_attributes][0][account]" id="financial_data_business_banks_attributes__account">
          </div>
      </div>
      <div>
          <label for="financial_data_business_banks_attributes__Monto">Monto</label>
          <div>
          <input type="text" name="financial_data_business[banks_attributes][0][amount]" id="financial_data_business_banks_attributes__amount">
          </div>
      </div>
      <button name="button" type="button" data-action="click->input-fields#removeFields">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>    
      </button>
      </dl>
      `
    } else if (this.listValue === 'premises') {
      field.innerHTML = `
      <dl class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
      <div class="sm:col-span-2">
          <label for="financial_data_business_premises_Detalle Local">Detalle local</label>
          <div>
          <input type="text" name="financial_data_business[premises][name]" id="financial_data_business_premises_name">
          </div>
      </div>
      <div>
          <label for="financial_data_business_premises_Valor">Valor</label>
          <div>
          <input type="text" name="financial_data_business[premises][amount]" id="financial_data_business_premises_amount">
          </div>
      </div>
      <button name="button" type="button" data-action="click->input-fields#removeFields">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>    
      </button>
      </dl>

      `
    } else if (this.listValue === 'homes') {
      field.innerHTML = `
      <dl class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
      <div class="sm:col-span-2">
          <label for="financial_data_family_homes_Detalle Vivienda">Detalle vivienda</label>
          <div>
          <input type="text" name="financial_data_family[homes][name]" id="financial_data_family_homes_name">
          </div>
      </div>
      <div>
          <label for="financial_data_family_homes_Valor">Valor</label>
          <div>
          <input type="text" name="financial_data_family[homes][amount]" id="financial_data_family_homes_amount">
          </div>
      </div>
      <button name="button" type="button" data-action="click->input-fields#removeFields">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>    
      </button>
      </dl>
      `
    } else if (this.listValue === 'vehicles') {
      field.innerHTML = `
      <dl class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
      <div>
          <label for="financial_data_business_vehicles_Detalle">Detalle</label>
          <div>
          <input type="text" name="financial_data_business[vehicles][name]" id="financial_data_business_vehicles_name">
          </div>
      </div>
      <div>
          <label for="financial_data_business_vehicles_Año">Año</label>
          <div>
          <input type="text" name="financial_data_business[vehicles][year]" id="financial_data_business_vehicles_year">
          </div>
      </div>
      <div>
          <label for="financial_data_business_premises_Valor">Valor</label>
          <div>
          <input type="text" name="financial_data_business[premises][amount]" id="financial_data_business_premises_amount">
          </div>
      </div>
      <button name="button" type="button" data-action="click->input-fields#removeFields">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>    
      </button>
      </dl>

      `
    } else if (this.listValue === 'furnitures') {
      field.innerHTML = `
      <dl class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
      <div>
          <label for="financial_data_business_furnitures_Detalle">Detalle</label>
          <div>
          <input type="text" name="financial_data_business[furnitures][name]" id="financial_data_business_furnitures_name">
          </div>
      </div>
      <div>
          <label for="financial_data_business_furnitures_Cantidad">Cantidad</label>
          <div>
          <input type="text" name="financial_data_business[furnitures][year]" id="financial_data_business_furnitures_year">
          </div>
      </div>
      <div>
          <label for="financial_data_business_furnitures_Valor">Valor</label>
          <div>
          <input type="text" name="financial_data_business[furnitures][amount]" id="financial_data_business_furnitures_amount">
          </div>
      </div>
      <button name="button" type="button" data-action="click->input-fields#removeFields">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>    
      </button>
      </dl>

      `
    } else if (this.listValue === 'debtors') {
      field.innerHTML = `
      <dl class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
      <div>
          <label for="financial_data_business_debtors_Detalle">Detalle</label>
          <div>
          <input type="text" name="financial_data_business[debtors][name]" id="financial_data_business_debtors_name">
          </div>
      </div>
      <div>
          <label for="financial_data_business_debtors_Nombre Cliente">Nombre y Apellidos</label>
          <div>
          <input type="text" name="financial_data_business[debtors][client]" id="financial_data_business_debtors_client">
          </div>
      </div>
      <div>
          <label for="financial_data_business_debtors_Valor">Valor</label>
          <div>
          <input type="text" name="financial_data_business[debtors][amount]" id="financial_data_business_debtors_amount">
          </div>
      </div>
      <button name="button" type="button" data-action="click->input-fields#removeFields">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>    
      </button>
      </dl>
      `
    } else if (this.listValue === 'assets') {
      field.innerHTML = `
      <dl class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
      <div>
          <label for="financial_data_business_assets_Detalle">Detalle</label>
          <div>
          <input type="text" name="financial_data_business[assets][name]" id="financial_data_business_assets_name">
          </div>
      </div>
      <div>
          <label for="financial_data_business_assets_Cantidad">Cantidad</label>
          <div>
          <input type="text" name="financial_data_business[assets][quantity]" id="financial_data_business_assets_quantity">
          </div>
      </div>
      <div>
          <label for="financial_data_business_assets_Valor">Valor</label>
          <div>
          <input type="text" name="financial_data_business[assets][amount]" id="financial_data_business_assets_amount">
          </div>
      </div>
      <button name="button" type="button" data-action="click->input-fields#removeFields">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>    
      </button>
      </dl>
      `
    }
    this.fieldsContainerTarget.appendChild(field)
  }


  removeFields(event) {
    event.preventDefault()

    const field = event.target.closest("div")
    console.log(field)
    field.remove()
  }


}
