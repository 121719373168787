import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';
import FieldValue from './FieldValue'

const TipoSexo = {"M": "Masculino", "F": "Femenino"}


const Client = (props) => (
    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
            Datos del Deudor
            </h3>
        </div>
        <div className="px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
            <FieldValue label={"Cliente"}>{props.client.TipoPersona == "M" ? props.client.RepLegalNombre : props.contract.NombreCliente}</FieldValue>
            <FieldValue label={"Sexo"}>{props.client.TipoPersona == "M" ? TipoSexo[props.client.RepLegalSexo] : TipoSexo[props.client.Sexo]}</FieldValue>
            <FieldValue label={"Cédula"}>{props.client.TipoPersona == "M" ? props.client.RepLegalNumIdentific : (props.client.Cedula == "" ? "Sin Cédula" : props.client.Cedula)}</FieldValue>
            <FieldValue label={"Fecha de Nacimiento"}>
                {Moment(props.client.TipoPersona == "M" ? props.client.RepLegalFechaNac : props.client.FechaNacimiento).format('YYYY-MM-DD')}
            </FieldValue>
            <FieldValue label={"Nacionalidad "}>{props.client.PaisNacimiento}</FieldValue>
            <FieldValue label={"Registro Ampyme"}>{props.client.EstaAMPYME == "S" ? "Sí" : "No" }</FieldValue>
            <FieldValue label={"Es jubilado"}>{props.client.EsJubilado == "S" ? "Sí" : "No" }</FieldValue>
            <FieldValue label={"Es pensionado"}>{props.client.EsPensionado  == "S" ? "Sí" : "No" }</FieldValue>
            <FieldValue label={"Paga FECI"}>{props.client.PagaFECI  == "S" ? "Sí" : "No" }</FieldValue>
            <FieldValue label={"Actividad Principal"}>{props.client.Actividad}</FieldValue>
            <FieldValue label={"Clasificación (de actividad)"}>{props.client.ClasificacionActividad}</FieldValue>
            <FieldValue label={"Ocupación"}>{props.client.Ocupacion}</FieldValue>
            </dl>
        </div>
        <div className="px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-1">
            <FieldValue label={"Dirección Oficial"}>{props.client.DireccionCompleta }</FieldValue>
            </dl>
        </div>
    </div>
)

export default Client
