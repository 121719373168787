import React from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import Moment from 'moment';

const TipoCreditoDictionary = {"N": "Nuevo", "R":"Reestructura", "O":"Renovación", "M":"Crédito Modificado"}

const Disbursement = (props) => (
    <tr className={[100,120].includes(props.contract.ProductoCreditoID) ? "bg-yellow-50" : ""}>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
        <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
                <div className="h-8 w-8 bg-green-400 text-white pl-1 pt-1 font-extrabold rounded-full">
                        {props.contract.initials}
                </div>
            </div>
            <div className="ml-4">
            <Link to={`${props.path}/` + props.contract.SolicitudCreditoID} className="text-indigo-600 hover:text-indigo-900">
            <div className="text-sm leading-5 font-medium">{props.contract.CreditoID==0 ? `Solicitud: ${props.contract.SolicitudCreditoID}` : `${props.contract.CreditoID} (Solicitud: ${props.contract.SolicitudCreditoID})`}</div>
            </Link>
            <div className="text-sm leading-5 text-gray-500">{props.contract.NombreCliente}</div>
            </div>
        </div>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
        <div className="text-sm leading-5 text-gray-900">{props.contract.ProductoCreditoID} {props.contract.ProductoDescripcion}</div>
        <div className="text-sm leading-5 text-gray-500">{props.contract.tipo_contrato}</div>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
        <div className="text-sm leading-5 text-gray-900">{TipoCreditoDictionary[props.contract.TipoCredito]}</div>
        <div className="text-sm leading-5 text-gray-500">
            {new Intl.NumberFormat("en-US", {
                    style: "currency",
                currency: "USD"
            }).format(props.contract.TipoCredito == 'N' ? props.contract.MontoSolici: props.contract.Monto)}
        </div>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
        {props.contract.Estatus}
        </span><br/>
        <div className="text-sm leading-5 text-gray-500">{props.responsible ? props.responsible.Clave : ''}</div>                
        </td>
        <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
        <div className="text-sm leading-5 text-gray-900">{Moment(props.contract.FechaMinistradoCredito).format('YYYY-MM-DD')}</div>
        <div className="text-sm leading-5 text-gray-500">{props.contract.NombreSucurs}</div>
        </td>
    </tr>
);

// const Menu = () => (

// )

// const Content = () => {
//     const handleChanged = () => {
//         console.log('Changed ...')
//       }  
//     return (

//     )  
    
// }

// const BackLink = () => (
// )

export default Disbursement;
