import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
import Template from '../Template'
import Chart from '../Dashboard/Chart';
import GroupButtons from '../Dashboard/GroupButtons';
import Moment from 'moment';
import Debug from '../Debug';
import * as Yup from 'yup'
import { Formik, Field, FieldArray, Form, useFormikContext, setIn } from 'formik';
import FormikControl from '../forms/FormikControl'
import { formatNumber } from '../Helpers/Helpers'


const ValueChangeListener = () => {
    const { submitForm, values } = useFormikContext();
  
    useEffect(() => {
      if (values.from_date) {
        submitForm();
      }
    }, [values, submitForm]);
  
    return null;
  };


const DashboardCobros = (props) => {
    const [loading, setLoading] = useState([]);
    const [dashboards, setDashboards] = useState([]);
    const [regiones, setRegiones] = useState([]);
    const [sucursales, setSucursales] = useState([]);
    const [users, setUsers] = useState([]);
    const [period, setPeriod] = useState('month');
    const [params, setParams] = useState({
        period: 'month', 
        from_date: Moment().startOf('year').format('YYYY-MM-DD'),
        to_date: Moment().format('YYYY-MM-DD')
    });
    //const [currentUser, setCurrentUser] = useState([]);
    const {currentUser} = props;
    const [refresh, setRefresh] = useState(0);

    const validationSchema = Yup.object({         
    })

    const [indicadores, setIndicadores] = useState([]);
    const [indicadoresTotales, setIndicadoresTotales] = useState([]);

    const formRef = useRef(null);

    const onSubmit = values => {
        // setProspect(values);

        // setAction(null);
        // const token = document.querySelector('[name=csrf-token]').content
        // axios.defaults.headers.common['X-CSRF-TOKEN'] = token
        // axios.put(`/api/v1/prospectos/${values.ProspectoID}`, {prospecto: {...values, ...{signature:signature}}} )
        // .then( resp => {
        //     location.replace(`/prospects/${resp.data.ProspectoID}`);
        // })
        // .catch( data => console.log('error', data))
        // location.reload();
    }

    const getSucursales = (regionID) => {
        const token = document.querySelector('[name=csrf-token]').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token
        axios.get(`/api/v1/sucursales?region=${regionID}`)
        .then( resp => {
            setSucursales(resp.data.items);
        })
        .catch( error => {return error});
    };

    const getUsers = (sucursalID) => {
        const token = document.querySelector('[name=csrf-token]').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token
        axios.get(`/api/v1/users?sucursalID=${sucursalID}`)
        .then( resp => {
            setUsers(resp.data.users);
        })
        .catch( error => {return error});
    };

    const optionsUsers = () => {
        return [
                ...[{key: "", value:"Todos los Asesores"}],
                ...users.map(u => ({
                    key: u.UsuarioID, 
                    value: u.NombreCompleto
                }))
        ]        
    }

    const optionsSucursales = () => {
        return [
                ...[{key: "", value:"Todas las Agencias"}],
                ...sucursales.map(s => ({
                    key: s.SucursalID, 
                    value: s.NombreSucurs
                }))
        ]        
    }

    const getIndicadores = (values) => {
        // Create base URL
        let url = 'https://api.sumadata.net/indicadoresagencia';
    
        // If PromotorID is not 0, null, undefined or blank, add it to the URL
        if(currentUser.PromotorID && currentUser.PromotorID !== '0') {
            url += `?promotorID=${currentUser.PromotorID}`;
        }
    
        axios
          .get(url)
          .then((resp) => {
            let filteredIndicadores;
            if(!currentUser.PromotorID || currentUser.PromotorID === '0') {
              // if PromotorID is 0 or null or undefined or blank, filter SucursalID === '0'
              filteredIndicadores = resp.data.rows.filter(
                (indicador) => indicador.SucursalID === '0'
              );
            } else {
              // if PromotorID is something other than 0 or null or undefined or blank, filter PromotorID === currentUser.PromotorID
              filteredIndicadores = resp.data.rows.filter(
                (indicador) => indicador.PromotorID === currentUser.PromotorID
              );
            }
            setIndicadores(resp.data);
            setIndicadoresTotales(filteredIndicadores);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
    };
    

    // const getIndicadores = (values) => {
    //     axios
    //       .get(`https://api.sumadata.net/indicadoresagencia?promotorID=${currentUser.PromotorID}`)
    //       .then((resp) => {
    //         let filteredIndicadores;
    //         if(!currentUser.PromotorID || currentUser.PromotorID === '0') {
    //           // if PromotorID is 0 or null or undefined or blank, filter SucursalID === '0'
    //           filteredIndicadores = resp.data.rows.filter(
    //             (indicador) => indicador.SucursalID === '0'
    //           );
    //         } else {
    //           // if PromotorID is something other than 0 or null or undefined or blank, filter PromotorID === currentUser.PromotorID
    //           filteredIndicadores = resp.data.rows.filter(
    //             (indicador) => indicador.PromotorID === currentUser.PromotorID
    //           );
    //         }
    //         console.log(resp.data);
    //         setIndicadores(resp.data);
    //         setIndicadoresTotales(filteredIndicadores);
    //       })
    //       .catch((error) => {
    //         console.error('Error fetching data:', error);
    //       });
    // };
    


    // const getIndicadores = (values) => {
    //     axios
    //       .get(`https://api.sumadata.net/indicadoresagencia?promotorID=${currentUser.PromotorID}`)
    //       .then((resp) => {
    //         const filteredIndicadores = resp.data.rows.filter(
    //             (indicador) => indicador.SucursalID === '0'
    //           );
    //         setIndicadores(resp.data);
    //         setIndicadoresTotales(filteredIndicadores);
    //       })
    //       .catch((error) => {
    //         console.error('Error fetching data:', error);
    //       });
    // };

    const getData = (values) => {
        setLoading(true);
        axios.get(`/api/v1/dashboards?p=${values['period']}&from_date=${values['from_date']}&to_date=${values['to_date']}$${values['SucursalID']==undefined ? '' : `&sid=${values['SucursalID']}`}${values['UserID']==undefined ? '' : `&uid=${values['UserID']}`}${values['region_id']==undefined ? '' : `&rid=${values['region_id']}`}`)
        .then( resp => {
            //setCurrentUser(resp.data.currentUser);    
            setDashboards(resp.data.dashboards);    
            setParams({...params, ...{from_date: resp.data.from_date, to_date: resp.data.to_date}});    
            setRegiones(
                [
                    ...[{key: "", value:"Todas las Regiones"}],
                    ...resp.data.regiones.map( (region) => {return {key: region.id, value: region.name} })
                ]
            );    
            // setSucursales(resp.data.sucursales);
            setLoading(false);
        })
        .catch( data => console.log('error', data));
    }

    useEffect(() => {
        getData(params);

        getIndicadores();

    }, [refresh]);

    const data = [
        {name: "Sample1", data: {"2021-01-01": 3, "2021-01-02": 4}},
        {name: "Sample2", data: {"2021-01-01": 5, "2021-01-02": 3}}
      ];

    
    const submitMyForm = (values) => {
        getData(values);
    };
    
    return (
        <Template currentUser={currentUser}  location={props.location}>
                        
              <div className="overflow-y-auto">
                    { true &&
                    <>

                <div className="min-w-0 mx-auto py-2 px-4 overflow-y-auto">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                        <div className="bg-white shadow-md rounded p-4">
                            <h3 className="text-gray-600">Cartera Bruta</h3>
                            <h2 className="text-2xl font-bold">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_CarBruta)) : 0}</h2>
                            <div className="text-red-500">{indicadoresTotales.length > 0 ? Number(indicadoresTotales[0].HisPorcentajeCarBruta) : 0}%</div>
                            <p className="text-gray-500">{indicadoresTotales.length > 0 ? Number(indicadoresTotales[0].Var_NumPrestamos) : 0} prestamos <span className="text-red-500">({indicadoresTotales.length > 0 ? Number(indicadoresTotales[0].HisPorcentajeNumPrestamos) : 0})</span></p>
                        </div>
                        <div className="bg-white shadow-md rounded p-4">
                            <h3 className="text-gray-600">Cartera Par {'<='} 30</h3>
                            <h2 className="text-2xl font-bold">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_Par30)) : 0}</h2>
                            <div className="text-red-500">{indicadoresTotales.length > 0 ? Number(indicadoresTotales[0].HisPorcentajePar30) : 0}%</div>
                            <p className="text-gray-500">Desde el mes anterior</p>
                        </div>
                        <div className="bg-white shadow-md rounded p-4">
                            <h3 className="text-gray-600">Cartera Par {'>'} 30</h3>
                            <h2 className="text-2xl font-bold">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_ParMayor30)) : 0}</h2>
                            <div className="text-red-500">{indicadoresTotales.length > 0 ? Number(indicadoresTotales[0].HisPorcentajeParMayor30) : 0}%</div>
                            <p className="text-gray-500">Desde el mes anterior</p>
                        </div>
                        <div className="bg-white shadow-md rounded p-4">
                            <h3 className="text-gray-600">Cartera en Riesgo</h3>
                            <h2 className="text-2xl font-bold">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].CarteraRiesgo)) : 0}</h2>
                            <div className="text-green-500">{indicadoresTotales.length > 0 ? Number(indicadoresTotales[0].PorcentajeCarRiesgo) : 0}%</div>
                            <p className="text-gray-500">Desde el mes anterior</p>
                        </div>
                    </div>
                </div>

                <div className="w-full flex-grow bg-gray-100 p-4 ">
                    <div className="grid grid-cols-3 gap-4 mb-4">
                        <div className="bg-white shadow p-4 rounded-lg text-center">
                            <p className="text-3xl font-bold">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_SolEnCaptura : 0} (${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_MontoEnCaptura)) : 0})</p>
                            <p className="text-gray-600">Solicitudes / En captura</p>
                        </div>
                        <div className="bg-white shadow p-4 rounded-lg text-center">
                            <p className="text-3xl font-bold">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_SolPorAutorizar : 0} (${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_MontoPorAutorizar)) : 0})</p>
                            <p className="text-gray-600">Solicitudes / Por aprobar</p>
                        </div>
                        <div className="bg-white shadow p-4 rounded-lg text-center">
                            <p className="text-3xl font-bold">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_CrePorDesembolsar : 0} (${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_MtoPorDesembolsar)) : 0})</p>
                            <p className="text-gray-600">Solicitudes / Por desembolsar</p>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="w-1/2">
                            <table className="w-full bg-white rounded-lg shadow-md">
                            <thead>
                            <tr className="bg-gray-800 text-white text-center">
                                <th className="px-4 py-2" colSpan="2">Indicadores</th>
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr className="bg-gray-100">
                                <td className="px-4 py-2 font-semibold">Colocado en el Mes</td>
                                <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_NumDesembolso : 0} (${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_MontoDesembolso)) : 0}) </td>
                            </tr>
                            <tr className="bg-gray-200">
                                <td className="px-4 py-2 font-semibold">Exigible al Día</td>
                                <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_ExigibleAlDia)) : 0}</td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="px-4 py-2 font-semibold">Exigible PAR30</td>
                                <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_ExigiblePar30)) : 0}</td>
                            </tr>
                            <tr className="bg-gray-200">
                                <td className="px-4 py-2 font-semibold">Recup.Capital.Mes</td>
                                <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].PagoCapitalMes)) : 0}</td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="px-4 py-2 font-semibold">Total.Recup.Mes</td>
                                <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].PagoTotalMes)) : 0}</td>
                            </tr>
                        </tbody>
                            </table>
                        </div>
                        <div className="w-1/2 pl-4">
                            <table className="w-full bg-white rounded-lg shadow-md">
                            <thead>
                            <tr className="bg-gray-800 text-white text-center">
                                <th className="px-4 py-2" colSpan="2">Indicadores 2</th>
               
                            </tr>
       
                            </thead>
                                <tbody>
                                    <tr className="bg-gray-100">
                                        <td className="px-4 py-2 font-semibold">Cartera en riesgo - Cero días</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].PorcentajeCeroDias : 0}%</td>
                                    </tr>
                                    <tr className="bg-gray-200">
                                        <td className="px-4 py-2 font-semibold">Cartera en riesgo - Par 1-30</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Porcentaje30Dias : 0}%</td>
                                    </tr>
                                    <tr className="bg-gray-200">
                                        <td className="px-4 py-2 font-semibold">Cartera en riesgo Par {">"} 30 </td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].PorcentajeMayor30Dias : 0}%</td>
                                    </tr>
                                    <tr className="bg-gray-200">
                                        <td className="px-4 py-2 font-semibold">Total Préstamos en Mora</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_NumPrestTotMora : 0}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="w-full flex-grow bg-gray-100 p-4 ">


                    <table className="w-full bg-white rounded-lg shadow-md">
                            <thead>
                            <tr className="bg-gray-600 text-white text-center" >
                                <th className="px-4 py-2" colSpan="5">Bandas de Morosidad</th>
           
                            </tr>
                            <tr className="bg-gray-800 text-white text-center">
                                <th className="px-4 py-2">Cartera Vencida</th>
                                <th className="px-4 py-2">Saldo en Riesgo</th>
                                <th className="px-4 py-2">No. Prestamos</th>
                                <th className="px-4 py-2">% Cartera Mora</th>
                                <th className="px-4 py-2">% Cartera Total</th>
                            </tr>
       
                            </thead>
                                <tbody>
                                    <tr className="bg-gray-100 text-center">
                                        <td className="px-4 py-2 font-semibold">1-30 días</td>
                                        <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Banda1a30SaldoRiesgo)) : 0}</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_NumPrest1a30 : 0}</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda1a30Porcentaje : 0}%</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda1a30PorTotal : 0}%</td>
                                    </tr>
                                    <tr className="bg-gray-200 text-center">
                                        <td className="px-4 py-2 font-semibold">31-60 días</td>
                                        <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Banda31a60SaldoRiesgo)) : 0}</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_NumPrest31a60 : 0}</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda31a60Porcentaje : 0}%</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda31a60PorTotal : 0}%</td>
                                    </tr>
                                    <tr className="bg-gray-100 text-center">
                                        <td className="px-4 py-2 font-semibold">61-90 días</td>
                                        <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Banda61a90SaldoRiesgo)) : 0}</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_NumPrest61a90 : 0}</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda61a90Porcentaje : 0}%</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda61a90PorTotal : 0}%</td>
                                    </tr>
                                    <tr className="bg-gray-200 text-center">
                                        <td className="px-4 py-2 font-semibold">91-180 días</td>
                                        <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Banda91a180SaldoRiesgo)) : 0}</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_NumPrest91a180 : 0}</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda91a180Porcentaje : 0}%</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda91a180PorTotal : 0}%</td>
                                    </tr>
                                    <tr className="bg-gray-100 text-center">
                                        <td className="px-4 py-2 font-semibold">181 días</td>
                                        <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Banda180SaldoRiesgo)) : 0}</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_NumPrest180 : 0}</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda180Porcentaje : 0}%</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda180PorTotal : 0}%</td>
                                    </tr>
                                    <tr className="bg-gray-200 text-center">
                                        <td className="px-4 py-2 font-semibold">Total</td>
                                        <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].TotalBandas)) : 0}</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_NumPrestTotMora : 0}</td>
                                        <td className="px-4 py-2">100%</td>
                                        <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].PorcentajeCarRiesgo : 0}%</td>
                                    </tr>
                                </tbody>
                            </table>
                </div>
                    
                </div>

  

                    
                        <div className="mx-6 mt-1 bg-white shadow sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                                <Formik
                                    initialValues={params}
                                    validationSchema={validationSchema}
                                    onSubmit={submitMyForm}
                                    validateOnBlur={false}
                                    validateOnChange={false}                                    
                                >
                                {formik => {
                                    const setDatePeriod = (period) => {
                                        console.log(period)
                                        setPeriod(period)
                                    }
                                    // const getAPC = () => {
                                    //     setLoadingAPC(true);
                                    //     const token = document.querySelector('[name=csrf-token]').content
                                    //     axios.defaults.headers.common['X-CSRF-TOKEN'] = token
                                    //     axios.get(`/api/v1/prospectos/${props.match.params.id}/get_apc`)
                                    //     .then( resp => {
                                    //         setLoadingAPC(false);
                                    //         formik.setFieldValue('apc_scores', resp.data.item.apc_scores);
                                    //     })
                                    //     .catch( error => {return error});
                                    // }

                                return (
                                    <Form>                                  
                                        <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                                        <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:col-span-2 sm:grid-cols-3">
                                        <FormikControl
                                            control='select'
                                            label='Región'
                                            name='region_id'
                                            options={regiones} 
                                            prompt={false}
                                            onChange={(e) => {
                                                formik.setFieldValue('region_id', e.target.value);
                                                getSucursales(e.target.value)
                                            }}
                        
                                        />
                                        <FormikControl
                                            control='select'
                                            label='Agencia'
                                            name='SucursalID'
                                            options={optionsSucursales()} 
                                            prompt={false}
                                            onChange={(e) => {
                                                formik.setFieldValue('SucursalID', e.target.value);
                                                getUsers(e.target.value)
                                            }}
                        
                                        />
                                        <FormikControl
                                            control='select'
                                            label='Asesor'
                                            name='UserID'
                                            options={optionsUsers()}
                                            prompt={false}
                                        />   
                                        </div>
                                    </dl>
                                    {/* <Debug  {...formik}/> */}
                                    <ValueChangeListener/>
                                    </Form>
                                )
                                }}
                                </Formik>                        
                            </div>
                        </div>
                    </>   
                    }
                    {
                        loading ?
                            <div className="flex h-screen">
                            <div className="m-auto">
                                <Bars
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                    timeout={20000} //3 secs
                            
                                />
                                </div>
                            </div>            
                        :  

                        dashboards.map( (dashboard) => {
                            return (
                                // <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg" key={dashboard._id}>
                                //     <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                                //         <h3 className="text-2xl leading-6 font-medium text-gray-900">
                                //         {dashboard.name}
                                //         </h3>
                                //         <div>
                                //             <p className="mt-1 text-sm leading-5 text-gray-500">
                                //             </p> 
                                //         </div>
                            
                                //     </div>
                                //     <div className="px-4 py-5 sm:px-6">
                                //         <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-2">
                                //         { 
                                //             dashboard.views.map( (view) => {
                                //                 return (
                                //                     <Chart type={view.type} title={view.name} data={view.data} key={view._id}  colSpan={view.colSpan} />             
                                //                 )
                                //             }
                                //         )}    
                                //         </dl>
                                //     </div>
                                // </div>    
                                <div></div>
                                )                            
                        })
                    }
                    { false &&
                    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                            <h3 className="text-2xl leading-6 font-medium text-gray-900">
                            Demo <span className="text-orange-600">Indicadores en Construcción</span>
                            </h3>
                            <div>
                                <p className="mt-1 text-sm leading-5 text-gray-500">
                                </p> 
                            </div>
                
                        </div>
                        <div className="px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-2">
                            <Chart type="line" title={"Gráfico 1"} data={data}/> 
                            <Chart type="pie" title={"Gráfico 2"} data={[["Data1", 44], ["Data2", 23]]}/>
                            <Chart type="column" title={"Gráfico 3"} data={[["Sun", 32], ["Mon", 46], ["Tue", 28]]}/> 
                            <Chart type="bar" title={"Gráfico 4"} data={[["Work", 32], ["Play", 1492]]}/>                    
                            <Chart type="area" title={"Gráfico 5"} data={{"2021-01-01": 11, "2021-01-02": 6}}/>
                            <Chart type="scatter" title={"Gráfico 6"} data={[[174.0, 80.0], [176.5, 82.3]]} xtitle="Size" ytitle="Population"/> 
                            </dl>
                        </div>
                    </div>    
                    }

              </div>
        </Template>
    );

}

export default DashboardCobros
