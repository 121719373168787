import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Chart from './Chart';
import GroupButtons from './GroupButtons';
import Moment from 'moment';
import Debug from '../Debug';
import * as Yup from 'yup'
import { Formik, Field, FieldArray, Form, useFormikContext, setIn } from 'formik';
import FormikControl from '../forms/FormikControl'
import { formatNumber } from '../Helpers/Helpers'
import TabComponent from './Tabs'

const ValueChangeListener = () => {
    const { submitForm, values } = useFormikContext();
  
    useEffect(() => {
      if (values.from_date !== undefined) {
        submitForm();
      }
    }, [values, submitForm]);
  
    return null;
};

const Dashboard = (props) => {
    const [loading, setLoading] = useState([]);
    const {currentUser} = props;
    const [refresh, setRefresh] = useState(0);
    const [indicadoresTotales, setIndicadoresTotales] = useState([]);
    const [availableDates, setAvailableDates] = useState([]);

    const getAvailableDates = () => {
        axios.get('https://api.sumadata.net/datesindicadores')
            .then((response) => {
                setAvailableDates(response.data.rows.map(row => row.availableDate));
            })
            .catch((error) => {
                console.error('Error fetching available dates:', error);
            });
    };



    const getIndicadores = (selectedDate) => {
        // Create base URL
        let url = 'https://api.sumadata.net/indicadoresagencia';
    
        // If PromotorID is not 0, null, undefined or blank, add it to the URL
        if(currentUser.PromotorID && currentUser.PromotorID !== '0') {
            url += `?promotorID=${currentUser.PromotorID}`;
        }

        if(selectedDate) {
            url += (url.includes('?') ? '&' : '?') + `date=${selectedDate}`;
        }
    
        axios
          .get(url)
          .then((resp) => {
            let filteredIndicadores;
            if(!currentUser.PromotorID || currentUser.PromotorID === '0') {
              // if PromotorID is 0 or null or undefined or blank, filter SucursalID === '0'
              filteredIndicadores = resp.data.rows.filter(
                (indicador) => indicador.SucursalID === '0'
              );
            } else {
              // if PromotorID is something other than 0 or null or undefined or blank, filter PromotorID === currentUser.PromotorID
              filteredIndicadores = resp.data.rows.filter(
                (indicador) => indicador.PromotorID === currentUser.PromotorID
              );
            }
            setIndicadoresTotales(filteredIndicadores);
            setLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
    };

    useEffect(() => {
        getAvailableDates();
        getIndicadores();
    }, [refresh]);
    
    return (
                        
        <div className="overflow-y-auto">
            {
                loading ?
                    <div className="flex h-screen">
                    <div className="m-auto">
                        <Bars
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={20000} //3 secs
                    
                        />
                        </div>
                    </div>            
                :  

                <>
                    <div className="mx-4 mt-1 bg-white shadow sm:rounded-lg">
                        <div className="flex items-center justify-between px-4 py-5 sm:px-6">
                            <div>
                                <b>Bienvenido(a):</b> {currentUser.NombreCompleto} / <b>Agencia:</b> {currentUser.branch_name}
                            </div>
                            <Formik
                                initialValues={{ from_date: '' }}
                                onSubmit={(values) => getIndicadores(values.from_date)}
                            >
                                <Form>
                                    <Field as="select" name="from_date" className="ml-4">
                                        <option value="" label="Hoy" />
                                        {availableDates.map((date, index) => (
                                            <option key={index} value={date} label={date} />
                                        ))}
                                    </Field>
                                    <ValueChangeListener />
                                </Form>
                            </Formik>
                        </div>
                    </div>

                    <div className="min-w-0 mx-auto py-2 px-4 overflow-y-auto">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                            <div className="bg-white shadow-md rounded p-4">
                                <h3 className="text-gray-600">Cartera Bruta</h3>
                                <h2 className="text-2xl font-bold">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_CarBruta)) : 0}</h2>
                                <div className="text-red-500">{indicadoresTotales.length > 0 ? Number(indicadoresTotales[0].HisPorcentajeCarBruta) : 0}%</div>
                                <p className="text-gray-500">{indicadoresTotales.length > 0 ? Number(indicadoresTotales[0].Var_NumPrestamos) : 0} prestamos <span className="text-red-500">({indicadoresTotales.length > 0 ? Number(indicadoresTotales[0].HisPorcentajeNumPrestamos) : 0})</span></p>
                            </div>
                            <div className="bg-white shadow-md rounded p-4">
                                <h3 className="text-gray-600">Cartera Par {'<='} 30</h3>
                                <h2 className="text-2xl font-bold">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_Par30)) : 0}</h2>
                                <div className="text-red-500">{indicadoresTotales.length > 0 ? Number(indicadoresTotales[0].HisPorcentajePar30) : 0}%</div>
                                <p className="text-gray-500">Desde el mes anterior</p>
                            </div>
                            <div className="bg-white shadow-md rounded p-4">
                                <h3 className="text-gray-600">Cartera Par {'>'} 30</h3>
                                <h2 className="text-2xl font-bold">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_ParMayor30)) : 0}</h2>
                                <div className="text-red-500">{indicadoresTotales.length > 0 ? Number(indicadoresTotales[0].HisPorcentajeParMayor30) : 0}%</div>
                                <p className="text-gray-500">Desde el mes anterior</p>
                            </div>
                            <div className="bg-white shadow-md rounded p-4">
                                <h3 className="text-gray-600">Cartera en Riesgo</h3>
                                <h2 className="text-2xl font-bold">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].CarteraRiesgo)) : 0}</h2>
                                <div className="text-green-500">{indicadoresTotales.length > 0 ? Number(indicadoresTotales[0].PorcentajeCarRiesgo) : 0}%</div>
                                <p className="text-gray-500">Desde el mes anterior</p>
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex-grow bg-gray-100 p-4 ">
                        <div className="grid grid-cols-3 gap-4 mb-4">
                            <div className="bg-white shadow p-4 rounded-lg text-center">
                                <p className="text-3xl font-bold">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_SolEnCaptura : 0} (${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_MontoEnCaptura)) : 0})</p>
                                <p className="text-gray-600">Solicitudes / En captura</p>
                            </div>
                            <div className="bg-white shadow p-4 rounded-lg text-center">
                                <p className="text-3xl font-bold">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_SolPorAutorizar : 0} (${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_MontoPorAutorizar)) : 0})</p>
                                <p className="text-gray-600">Solicitudes / Por aprobar</p>
                            </div>
                            <div className="bg-white shadow p-4 rounded-lg text-center">
                                <p className="text-3xl font-bold">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_CrePorDesembolsar : 0} (${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_MtoPorDesembolsar)) : 0})</p>
                                <p className="text-gray-600">Solicitudes / Por desembolsar</p>
                            </div>
                        </div>

                        <div className="mt-1 bg-white shadow-sm rounded-lg">

                            <TabComponent />

                        </div>

                        <div className="mt-5 bg-white shadow-sm rounded-lg flex">
                            <div className="w-1/2">
                                <table className="w-full bg-white rounded-lg shadow-md">
                                <thead>
                                <tr className="bg-gray-800 text-white text-center">
                                    <th className="px-4 py-2" colSpan="2">Indicadores</th>
                                </tr>
                                
                            </thead>
                            <tbody>
                                <tr className="bg-gray-100">
                                    <td className="px-4 py-2 font-semibold">Colocado en el Mes</td>
                                    <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_NumDesembolso : 0} (${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_MontoDesembolso)) : 0}) </td>
                                </tr>
                                <tr className="bg-gray-200">
                                    <td className="px-4 py-2 font-semibold">Exigible al Día</td>
                                    <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_ExigibleAlDia)) : 0}</td>
                                </tr>
                                <tr className="bg-gray-100">
                                    <td className="px-4 py-2 font-semibold">Exigible PAR30</td>
                                    <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Var_ExigiblePar30)) : 0}</td>
                                </tr>
                                <tr className="bg-gray-200">
                                    <td className="px-4 py-2 font-semibold">Recup.Capital.Mes</td>
                                    <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].PagoCapitalMes)) : 0}</td>
                                </tr>
                                <tr className="bg-gray-100">
                                    <td className="px-4 py-2 font-semibold">Total.Recup.Mes</td>
                                    <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].PagoTotalMes)) : 0}</td>
                                </tr>
                            </tbody>
                                </table>
                            </div>
                            <div className="w-1/2 pl-4">
                                <table className="w-full bg-white rounded-lg shadow-md">
                                <thead>
                                <tr className="bg-gray-800 text-white text-center">
                                    <th className="px-4 py-2" colSpan="2">Indicadores 2</th>
                
                                </tr>
        
                                </thead>
                                    <tbody>
                                        <tr className="bg-gray-100">
                                            <td className="px-4 py-2 font-semibold">Cartera en riesgo - Cero días</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].PorcentajeCeroDias : 0}%</td>
                                        </tr>
                                        <tr className="bg-gray-200">
                                            <td className="px-4 py-2 font-semibold">Cartera en riesgo - Par 1-30</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Porcentaje30Dias : 0}%</td>
                                        </tr>
                                        <tr className="bg-gray-200">
                                            <td className="px-4 py-2 font-semibold">Cartera en riesgo Par {">"} 30 </td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].PorcentajeMayor30Dias : 0}%</td>
                                        </tr>
                                        <tr className="bg-gray-200">
                                            <td className="px-4 py-2 font-semibold">Total Préstamos en Mora</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_NumPrestTotMora : 0}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="w-full flex-grow bg-gray-100 mt-5 ">


                        <table className="w-full bg-white rounded-lg shadow-md">
                                <thead>
                                <tr className="bg-gray-600 text-white text-center" >
                                    <th className="px-4 py-2" colSpan="5">Bandas de Morosidad</th>
            
                                </tr>
                                <tr className="bg-gray-800 text-white text-center">
                                    <th className="px-4 py-2">Cartera Vencida</th>
                                    <th className="px-4 py-2">Saldo en Riesgo</th>
                                    <th className="px-4 py-2">No. Prestamos</th>
                                    <th className="px-4 py-2">% Cartera Mora</th>
                                    <th className="px-4 py-2">% Cartera Total</th>
                                </tr>
        
                                </thead>
                                    <tbody>
                                        <tr className="bg-gray-100 text-center">
                                            <td className="px-4 py-2 font-semibold">1-30 días</td>
                                            <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Banda1a30SaldoRiesgo)) : 0}</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_NumPrest1a30 : 0}</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda1a30Porcentaje : 0}%</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda1a30PorTotal : 0}%</td>
                                        </tr>
                                        <tr className="bg-gray-200 text-center">
                                            <td className="px-4 py-2 font-semibold">31-60 días</td>
                                            <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Banda31a60SaldoRiesgo)) : 0}</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_NumPrest31a60 : 0}</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda31a60Porcentaje : 0}%</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda31a60PorTotal : 0}%</td>
                                        </tr>
                                        <tr className="bg-gray-100 text-center">
                                            <td className="px-4 py-2 font-semibold">61-90 días</td>
                                            <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Banda61a90SaldoRiesgo)) : 0}</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_NumPrest61a90 : 0}</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda61a90Porcentaje : 0}%</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda61a90PorTotal : 0}%</td>
                                        </tr>
                                        <tr className="bg-gray-200 text-center">
                                            <td className="px-4 py-2 font-semibold">91-180 días</td>
                                            <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Banda91a180SaldoRiesgo)) : 0}</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_NumPrest91a180 : 0}</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda91a180Porcentaje : 0}%</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda91a180PorTotal : 0}%</td>
                                        </tr>
                                        <tr className="bg-gray-100 text-center">
                                            <td className="px-4 py-2 font-semibold">181 días</td>
                                            <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].Banda180SaldoRiesgo)) : 0}</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_NumPrest180 : 0}</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda180Porcentaje : 0}%</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Banda180PorTotal : 0}%</td>
                                        </tr>
                                        <tr className="bg-gray-200 text-center">
                                            <td className="px-4 py-2 font-semibold">Total</td>
                                            <td className="px-4 py-2">${indicadoresTotales.length > 0 ? formatNumber(Number(indicadoresTotales[0].TotalBandas)) : 0}</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].Var_NumPrestTotMora : 0}</td>
                                            <td className="px-4 py-2">100%</td>
                                            <td className="px-4 py-2">{indicadoresTotales.length > 0 ? indicadoresTotales[0].PorcentajeCarRiesgo : 0}%</td>
                                        </tr>
                                    </tbody>
                                </table>
                    </div>
                        
                    </div>
                </>                
            }
        </div>
    );

}

export default Dashboard
