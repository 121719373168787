import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';
import FieldValue from './FieldValue';


const EstatusTranslate = {"I-CD":"Registrada", "L-CD":"En Comité (Liberada para Autorización)", "A-CD": "Autorizado en Certificación de Datos", "A-CO": "Certificación de Operaciones", "A-GCi": "Generación de Contrato (Por iniciar)", "A-GCp": "Generación de Contrato (En proceso)", "A-PD": "Por Desembolsar", "A-D": "Desembolsado"}
const EstatusAuthTranslate = {"signature":"Adjuntar Contrato", "legal":"Preparar Autenticación", "authentication": "En Autenticación", "auth_scan": "Escanear Documento", "operations": "Por Archivar", "archived": "Archivado"}
const ClasiDestinCred = {"C":"Comercial", "O":"Consumo", "H":"Vivienda"}
const TipoCreditoDictionary = {"N": "Nuevo", "R":"Reestructura", "O":"Renovación", "M":"Crédito Modificado"}
const SolicitudEstatusDictionary = {"I": "Inactiva", "L": "Liberada", "A": "Autorizada", "C": "Cancelada",  "R": "Rechazada",  "D": "Desembolsada"}
const TipoDispersionDictionary = {"S": "SPEI", "C": "Cheque", "O": "Orden de Pago"}
const FrecuenciaDictionary = {"S": "Semanal", "C": "Catorcenal", "Q": "Quincenal", "M": "Mensual", "P": "Periodo", "B": "Bimestral", "T": "Trimestral", "R": "TetraMestral", "E": "Semestral", "A": "Anual", "L": "Libres"}
const TipoPago = {"C": "Crecientes", "I": "Iguales", "L": "Libres"}


const Steps = (props) => {
    const Step = (props) => {
        if (props.type == "completed")
            return (
                <li className="relative md:flex-1 md:flex">
                <a href="#" className="group flex items-center w-full">
                    <div className="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
                    <div className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-orange-600 rounded-full group-hover:bg-orange-800 transition ease-in-out duration-150">
                        <svg className="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <p className="text-sm leading-5 font-medium text-gray-900">{props.title}</p>
                    </div>
                </a>

                <div className="hidden md:block absolute top-0 right-0 h-full w-5">
                    <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                    <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                    </svg>
                </div>
                </li>
            )
        else if (props.type == "current")
            return (
                <li className="relative md:flex-1 md:flex">
                <div className="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
                    <div className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-orange-600 rounded-full">
                    <p className="text-orange-600">{props.label}</p>
                    </div>
                    <p className="text-sm leading-5 font-medium text-orange-600">
                        {props.title}
                        { props.title == "Por Desembolsar" &&
                            <div><i>{props.contract.to_disbursement_bucket}</i></div>
                        }

                    </p>
                </div>

                <div className="hidden md:block absolute top-0 right-0 h-full w-5">
                    <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                    <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                    </svg>
                </div>
                </li>
            )
        else
            return (
                <li className="relative md:flex-1 md:flex">
                <a href="#" className="group flex items-center">
                    <div className="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
                    <div className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400 transition ease-in-out duration-150">
                        <span className="text-gray-500 group-hover:text-gray-900 transition ease-in-out duration-150">{props.label}</span>
                    </div>
                    <p className="text-sm leading-5 font-medium text-gray-500 group-hover:text-gray-900 transition ease-in-out duration-150">{props.title}</p>
                    </div>

                    {
                        props.type != "upcoming-end" &&
                        <div className="hidden md:block absolute top-0 right-0 h-full w-5">
                            <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                            <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                            </svg>
                        </div>
                    }
                </a>
                </li>
            )                        
    }


    return (
        <div className="px-4 py-5 sm:px-6">
            <nav>
            <ul className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
                <Step label="CD" title="Autorizadas" type={props.contract.tracking.CD} />
                <Step label="CO" title="Certificación Operaciones" type={props.contract.tracking.CO} />
                <Step label="GCi" title="Gestión de Contrato" type={props.contract.tracking.GCi} />
                <Step label="GCp" title="Generar Contratro" type={props.contract.tracking.GCp} />
                <Step label="PD" title="Por Desembolsar" type={props.contract.tracking.PD} contract={props.contract}/>
            </ul>
            </nav>

        </div>    
    )
}

const Request = (props) => {
    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            {   props.contract.Estatus == "A" &&
                <Steps contract={props.contract}/>
            }
            
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <div>
                    <h3 className="text-2xl leading-6 font-medium text-gray-900">
                    Detalle de la Solicitud: <span className="text-orange-600">{props.contract.SolicitudCreditoID}</span>
                    </h3>
                    {props.contract.invoice_disbursed && 
                    <div className="m-0 inline-flex float-right">
                    <span className="ml-2 rounded-md shadow-sm ">
                        <a target="_blank" href={props.contract.invoice_qr}>
                        <button type="button" className={`inline-flex items-center px-4 py-2 border border-transparent text-md leading-5 font-medium rounded-md ${props.contract.invoice_email_sent_at ?  'text-green-600' : 'text-orange-600'} bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150`}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                        </svg> {` Factura Nro. ${props.contract.invoice_doc_number}`}                               
                        </button>
                        </a>
                    </span>
                    </div>
                    }
                    {!props.contract.invoice_disbursed && props.contract.Estatus == 'D' &&
                    <div className="m-0 inline-flex float-right">
                    <span className="ml-2 rounded-md shadow-sm ">
                        <a href={`/disbursements/${props.contract.id}/invoice`}>
                        <button type="button" className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Facturar
                        </button>
                        </a>
                    </span>
                    </div>
                    }

                    <div>
                        <p className="mt-1 text-sm leading-5 text-gray-500">
                        { ["I", "L", "A"].includes(props.contract.Estatus) &&
                            `Estado de la Solicitud: ${EstatusTranslate[`${props.contract.Estatus}-${props.contract.workflow_state}`]}`
                        }    
                        { props.contract.Estatus == "D" &&
                            `Estado de la Solicitud: ${EstatusAuthTranslate[`${props.contract.legal_auth.workflow_state}`]}`
                        }    
                        </p> 
                    </div>
                </div>


    
            </div>
            <div className="px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                <FieldValue label={"Cliente"}>{props.client.TipoPersona == 'M' ? props.client.RazonSocial : props.contract.NombreCliente}</FieldValue>
                <FieldValue label={"Tipo Persona"}>{props.client.TipoPersona == "M" ? "Jurídica" :  "Natural"}</FieldValue>
                <FieldValue label={"Préstamo"}>
                    {props.contract.CreditoID == 0 ? 'No registrado' : props.contract.CreditoID}
                </FieldValue>
                <FieldValue label={"Fecha de Registro"}>
                    {Moment(props.contract.FechaRegistro).format('YYYY-MM-DD')}
                </FieldValue>
                <FieldValue label={"Monto del Crédito"}>
                    {props.contract.TipoCredito == 'N' ? 'N/A' : new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD"
                    }).format(props.contract.Monto)}                                
                </FieldValue>
                <FieldValue label={"Saldo a Liquidar"}>
                    {props.contract.TipoCredito == 'N' ? 'N/A' : new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD"
                    }).format(props.contract.SaldoLiquidar)}                                
                </FieldValue>
                <FieldValue label={"Agencia"}>{props.contract.NombreSucurs}</FieldValue>
                <FieldValue label={"Ciclo Cliente"}>{props.contract.CicloCliente}</FieldValue>
                <FieldValue label={"Producto / Garantía"}>
                    {props.contract.ProductoCreditoID} {props.contract.ProductoDescripcion} / {props.contract.TipoGarantiasDescripcion}                        
                </FieldValue>
                <FieldValue label={"Tipo Crédito"}>{TipoCreditoDictionary[props.contract.TipoCredito]}</FieldValue>
                <FieldValue label={"Asesor"}>{props.contract.Asesor}</FieldValue>
    
                <FieldValue label={"Estatus"}>{SolicitudEstatusDictionary[props.contract.Estatus]}</FieldValue>
                <FieldValue label={"Destino Crédito"}>{props.contract.DestinoCre}</FieldValue>
                <FieldValue label={"Monto Solicitado"}>
                {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(props.contract.MontoBase)}
                </FieldValue>
                <FieldValue label={"Monto Autorizado"}>
                {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(props.contract.MontoAutorizado)}
                </FieldValue>
                <FieldValue label={"Monto Financiado"}>
                {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(props.contract.MontoSolici)}
                </FieldValue>
                <FieldValue label={"Monto por Desembolsar"}>
                {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(props.contract.MontoenMano)}
                </FieldValue>
                <FieldValue label={"Plazo"}>{props.contract.Plazo}</FieldValue>
                <FieldValue label={"Fecha de Inicio"}>
                    {Moment(props.contract.FechaInicio).format('YYYY-MM-DD')}
                </FieldValue>
                <FieldValue label={"Fecha de Vencimiento"}>
                    {Moment(props.contract.FechaVencimiento).format('YYYY-MM-DD')}
                </FieldValue>
                <FieldValue label={"Fecha técnico de Primer Amortización"}>
                    {Moment(props.contract.FechaInicioAmor).format('YYYY-MM-DD')}
                </FieldValue>
                <FieldValue label={"Tipo de Dispersión"}>{TipoDispersionDictionary[props.contract.TipoDispersion]}</FieldValue>
                <FieldValue label={"Tasa fija anualizada"}>
                {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(props.contract.TasaFija)} %
                </FieldValue>
                <FieldValue label={"Tipo de Pago de Capital"}>
                    {TipoPago[props.contract.TipoPagoCapital]}
                </FieldValue>
                <FieldValue label={"Frecuencia de Capital e Interés"}>{FrecuenciaDictionary[props.contract.FrecuenciaCap]}</FieldValue>
                <FieldValue label={"Día del mes (día de pago)"}>{props.contract.DiaMesCapital}</FieldValue>
                <FieldValue label={"Número de cuotas"}>{props.contract.NumAmortizacion}</FieldValue>
                <FieldValue label={"Monto Cuota"}>
                {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(props.contract.MontoCuota)}
                </FieldValue>
                <FieldValue label={"TAE (Tasa Efectiva)"}>
                {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(props.credit && props.credit.ValorCAT || props.contract.TasaFija)} %
                </FieldValue>            
                </dl>
            </div>
            {/* <pre>{ JSON.stringify(props.contract, null, 2) }</pre> */}
        </div>    
    )

}



export default Request;
