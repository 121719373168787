import React, { useState, useEffect } from 'react'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import {Bars} from 'react-loader-spinner'
import axios from 'axios'
import styled from 'styled-components'
import Moment from 'react-moment';
// import Template from '../Template'

import Contract from './Contract'
import Stat from './Stat'

const Contracts = () => {
    const [contracts, setContracts] = useState([]);
    const [in_committee, setInCommittee] = useState([]);
    const [pre_committee, setPreCommittee] = useState([]);
    const [inCD, setInCD] = useState([]);
    const [inCO, setInCO] = useState([]);
    const [inGC, setInGC] = useState([]);
    const [inPD, setInPD] = useState([]);
    const [currentUser, setCurrentUser] = useState([]);

    const [state, setState] = useQueryParam('state', StringParam);
    const [loading, setLoading] = useState([]);


    useEffect(() => {
        setLoading(true);

        const token = document.querySelector('[name=csrf-token]').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token            

        axios.get('/api/v1/solicitud_creditos?status='+state)
        .then( resp => {
            console.log('respdata', resp.data);
            setContracts(resp.data.items);
            setPreCommittee(resp.data.pre_committee);
            setInCommittee(resp.data.in_committee);
            setInCD(resp.data.inCD);
            setInCO(resp.data.inCO);
            setInGC(resp.data.inGC);
            setInPD(resp.data.inPD);
            setCurrentUser(resp.data.currentUser);
            setLoading(false)    

        })
        .catch( data => console.log('error', data));
    }, []);
  

    const grid = contracts.map( (contract, index) => { 
        // console.log('data', contracts);
        return (
            <Contract contract={contract} responsible={contract.responsible} key={contract.SolicitudCreditoID}/>
        );
    });

    
    return (
        <>
            { loading ?
                <div className="flex h-screen">
                <div className="m-auto">
                    <Bars
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={3000} //3 secs
                
                    />
                    </div>
                </div>            
              :  

                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
                <div className="pt-2 pb-6 md:py-6">
                    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                    <h1 className="text-2xl font-semibold text-gray-900">Análisis</h1>
                    </div>
                    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                        <Stats 
                            pre_committee={pre_committee} 
                            in_committee={in_committee} 
                            inCD = {inCD}
                            inCO = {inCO}
                            inGC = {inGC}
                            inPD = {inPD}
                        />
                        <br/>
                        <Table grid={grid} />
                    </div>
                </div>
                </main>
            }
        </>
    );
}

const Stats = (props) => (
    <div>
    <h3 className="text-lg leading-6 font-medium text-gray-900">
        De período actual
    </h3>
    <div className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-6">
        <Stat name="Registradas" amount={props.pre_committee ? props.pre_committee.cantidad : 0} number={props.pre_committee ? props.pre_committee.numero : 0} path="/contracts?state=I"/>
        <Stat name="Comité Liberada" amount={props.in_committee.cantidad} number={props.in_committee.numero} path="/contracts?state=L"/>
        <Stat name="Autorizadas" amount={props.inCD.cantidad} number={props.inCD.numero} path="/contracts?state=CD"/>
        <Stat name="Cer. Operaciones" amount={props.inCO.cantidad} number={props.inCO.numero} path="/contracts?state=CO"/>
        <Stat name="Gen. Contrato" amount={props.inGC.cantidad} number={props.inGC.numero} path="/contracts?state=GC"/>
        <Stat name="Por Desembolsar" amount={props.inPD.cantidad} number={props.inPD.numero} path="/contracts?state=PD"/>
        {/* <Stat name="Total" path="/contracts" amount={
            props.in_committee.cantidad 
            + props.inCD.cantidad
            + props.inCO.cantidad
            + props.inGC.cantidad
            + props.inPD.cantidad
        }
        number={
            props.in_committee.numero
            + props.inCD.numero
            + props.inCO.numero
            + props.inGC.numero
            + props.inPD.numero
        }/> */}
    </div>
    </div>


);

const Table = (props) => (
    <div className="flex flex-col">
    <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
        <table className="min-w-full">
            <thead>
            <tr>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Prestamo / Cliente
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Producto / Garantía
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Monto del Crédito
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Estatus / Resp.
                </th>
                {/*<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Aprobación
                </th>*/}
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
            </tr>
            </thead>
            <tbody className="bg-white">
                {props.grid}                
            </tbody>
        </table>
        </div>
    </div>
    </div>


);



export default Contracts
