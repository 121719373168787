import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navigator"
export default class extends Controller {
  static targets = ["elementToHide"]

  connect() {
    console.log("navigator connected");

    // window.addEventListener("offline", (e) => {
    //   console.log("offline");
    //   this.elementToHideTarget.classList.remove("hidden")
    // });
    
    // window.addEventListener("online", (e) => {
    //   console.log("online");
    //   this.elementToHideTarget.classList.add("hidden")
    // });

    window.onoffline = (event) => {
      console.log("The network connection has been lost.");
      this.elementToHideTarget.classList.remove("hidden")
    };
    window.ononline = (event) => {
      console.log("You are now connected to the network.");
      this.elementToHideTarget.classList.add("hidden")
    };        
        
  }
}
