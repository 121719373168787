import React from 'react'
import Input from './Input'
import AutocompleteInput from './AutocompleteInput'
import Textarea from './Textarea'
import Select from './Select'
import RadioButtons from './RadioButtons'
import CheckboxGroup from './CheckboxGroup'
import CheckboxGroupBeauty from './CheckboxGroupBeauty'
import DatePicker from './DatePicker'
import SelectComplete from './SelectComplete';


function FormikControl (props) {
  const { control, ...rest } = props
  switch (control) {
    case 'input':
      return <Input {...rest} />
    case 'autocomplete':
      return <AutocompleteInput {...rest} />
    case 'textarea':
      return <Textarea {...rest} />
    case 'select':
      return <Select {...rest} />
    case 'radio':
      return <RadioButtons {...rest} />
    case 'checkbox':
      return <CheckboxGroup {...rest} />
    case 'checkboxb':
      return <CheckboxGroupBeauty {...rest} />
    case 'date':
      return <DatePicker {...rest} />
    case 'selectComplete':
      return <SelectComplete {...rest} />;
    default:
      return null
  }
}

export default FormikControl
