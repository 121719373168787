import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';
import FieldValue from './FieldValue'
import Debug from '../Debug';

import * as Yup from 'yup'
import FormikControl from '../forms/FormikControl'

const dropdownSiNoOptions = [
    { key: true, value: 'Sí' },
    { key: false, value: 'No' }
  ]

const dropdownNegativeSiNoOptions = [
    { key: false, value: 'Sí' },
    { key: true, value: 'No' }
  ]

const ConsultAPC = (props) => {
    const queries_grid = (queries) => queries.map( (query, index) => { 
        return (
            <tr className={index % 2 == 0 ? "bg-white" : "bg-gray-50"}>
                <td className="px-6 py-4 whitespace-no-wrap font-medium">
                <div className="text-sm leading-5 text-gray-900">{query["NOM_ASOC"]}</div>
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">            
                {query["FEC1"]}
                </td>
            </tr>
        );
    });
    const grid = (records) => records.map( (record, index) => { 
        return (
            <tr className={index % 2 == 0 ? "bg-white" : "bg-gray-50"}>
                <td className="px-6 py-4 whitespace-no-wrap font-medium">
                <div className="text-sm leading-5 text-gray-900">{record["NOM_ASOC"]}</div>
                <div className="text-sm leading-5 text-gray-500">{record["DESCR_CORTA_RELA"]} {record["NUM_REFER"]}</div>
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(record["MONTO_ORIGINAL"])}
                <br/>
                {record["SALDO_ACTUAL"] ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(record["SALDO_ACTUAL"]) : '-'}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                {record["FEC_INICIO_REL"]}
                <br/>
                {record["FEC_ACTUALIZACION"]}                
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                {record["FEC_FIN_REL"] ? record["FEC_FIN_REL"] : '-'}
                <br/>
                {record["FEC_ULTIMO_PAGO"]}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                {record["IMPORTE_PAGO"] ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(record["IMPORTE_PAGO"]) : ''}                
                <br/>            
                {record["MONTO_ULTIMO_PAGO"] ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(record["MONTO_ULTIMO_PAGO"]) : ''}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                {record["NUM_PAGOS"]=="0" ? '' : record["NUM_PAGOS"] === undefined ? '-' : `${record["NUM_PAGOS"]} `}
                <br/>
                {record["NUM_DIAS_ATRASO"]}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">            
                {record["DESCR_FORMA_PAGO"]}
                <br />
                {record["HISTORIA"]}
                </td>
            </tr>
        );
    });

    const canceled_grid = (records) => records.map( (record, index) => { 
        return (
            <tr className={index % 2 == 0 ? "bg-white" : "bg-gray-50"}>             
                <td className="px-6 py-4 whitespace-no-wrap font-medium">
                <div className="text-sm leading-5 text-gray-900">{record["NOM_ASOC"]}</div>
                <div className="text-sm leading-5 text-gray-500">{record["DESCR_CORTA_RELA"]} {record["NUM_REFER"]}</div>
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                {record["FEC_INICIO_REL"]}
                <br/>
                {record["FEC_FIN_REL"] ? record["FEC_FIN_REL"] : '-'}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                {record["FEC_ULTIMO_PAGO"]}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(record["MONTO_ORIGINAL"])}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                {record["FEC_LIQUIDACION"]}<br/>
                {record["DESCR_OBS_CORTA"]}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">            
                {record["HISTORIA"]}
                </td>
            </tr>
        );
    });

    const apcScoresSections = (apc_scores) => Array.isArray(apc_scores) ? apc_scores.map( (apc_score, index) => { 
        return (
        <div>            
            <h3 className="py-2 text-lg leading-6 font-medium text-gray-900">
                {apc_score.doc_type == "cedula" ? "Cédula" : "Pasaporte"} {apc_score.doc_id}
            </h3>
            {
                apc_score.info &&
                <div>
                <h4 className="py-2 text-sm leading-6 font-medium text-gray-900">
                    {apc_score.info["NOMBRE"]} {apc_score.info["APELLIDO"]}
                </h4>
                <h4 className="pb-4 text-sm leading-6 font-medium text-gray-900">
                    Última consulta: {Moment(apc_score.date).format('YYYY-MM-DD')}
                </h4>
                </div>
            }
            {!apc_score.records && !apc_score.canceled_records &&
                <div>
                <h4 className="pb-4 text-md leading-6 font-medium text-green-500">
                No se ha consultado aún
                </h4>
                </div>
            }
            {Array.isArray(apc_score.records) && apc_score.records.length == 0 && Array.isArray(apc_score.records) && apc_score.records.length == 0 &&
                <div>
                <h4 className="pb-4 text-md leading-6 font-medium text-red-800">
                No se tiene historial para este documento en APC
                </h4>
                </div>
            }
            {(Array.isArray(apc_score.records) && apc_score.records.length || Array.isArray(apc_score.canceled_records) && apc_score.canceled_records.length) &&
            <div className="flex flex-col mb-10">
                <div className="my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div className="px-4 py-3 sm:px-6">
                    <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4">
                    <FieldValue label={"Score"}>{apc_score.score["SCORE"]}</FieldValue>
                    <FieldValue label={"PI"}>{apc_score.score["PI"]}</FieldValue>
                    <FieldValue label={"Exclusión"}>{apc_score.score["EXCLUSION"]}</FieldValue>
                    </dl>
                    </div>
                </div>
                <div className="my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <h4 className="pb-4 text-sm leading-6 font-medium text-gray-500">
                        Referencias Activas
                    </h4>
                    <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                        <thead>
                        <tr>
                        <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Relación
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Monto Original / Saldo
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Inicio / Actualización
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Vencimiento / Fecha Último Pago
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Importe / Último Pago
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Pagos / Días Atraso
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Forma Pago / Historial
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {apc_score.records && grid(apc_score.records)}
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <h4 className="pb-4 text-sm leading-6 font-medium text-gray-500">
                        Referencias Canceladas
                    </h4>
                    <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                        <thead>
                        <tr>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Relación
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Inicio
                            Vencimiento
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Fecha Último Pago
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Monto Original
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Fecha Cancelada
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Historial
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {apc_score.canceled_records && canceled_grid(apc_score.canceled_records)}
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <h4 className="pb-4 text-sm leading-6 font-medium text-gray-500">
                        Consultas Realizadas
                    </h4>
                    <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                        <thead>
                        <tr>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Agente Económico
                            </th>                           
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Fecha de Consulta
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {apc_score.queries && queries_grid(apc_score.queries)}
                        </tbody>
                    </table>
                    </div>
                </div>
                <hr />
            </div>
            }

        </div>

        );
    }) : [];

    const handleClick = event => {
        event.currentTarget.disabled = true;
        console.log("handle Click!!!!");
        props.getAPC();
    };    

    const [hiddenPanel, setHiddenPanel] = useState(false);

    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            {props.editable && 
            <div className="m-4 inline-flex float-right">
                <span className="inline-flex rounded-md shadow-sm">
                    <button type="submit" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                        Salvar
                    </button>
                </span>
            </div>        
            }
            {!props.editable && 
                <div className="m-4 inline-flex float-right">
                <span className="ml-2 rounded-md shadow-sm ">
                    {/* <a target="_blank" href={`/gen_docs/626753ab9d1fa25d495b75b6/print?debug=${props.values.ProspectoID}`}> */}
                    <a target="_blank" href={`/gen_docs/62682ec2ad14e162e003cf16/print?debug=${props.values.ProspectoID}`}>
                    <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                    </svg>                                
                    </button>
                    </a>
                </span>
                <span className="ml-2 rounded-md shadow-sm ">
                    <a onClick={() => props.setConsultApcEdit(true)}>
                    <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>                           
                    </button>
                    </a>
                </span>
                </div>
            }

            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="flex text-lg leading-6 font-medium text-gray-900">   
                <a onClick={() => setHiddenPanel(!hiddenPanel)}>       
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor" data-action="click->toggler#toggle">
                {hiddenPanel
                    ? <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" data-toggler-target="selectorIcon" />
                    : <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" data-toggler-target="selectorIcon" />
                }
                </svg>
                </a>
                Consulta APC
                </h3>
            </div>
            { !hiddenPanel && !props.authSigned  &&
            <div className="px-4 py-5 sm:px-6">
                <div className="mb-4 px-4 py-5 sm:px-6">
                    <span className="m-4 self-center text-red-500">
                    Para consultar APC debe contar con Autorización firmada
                    </span>
                </div>   
            </div>   
            }
            { !hiddenPanel && props.authSigned  &&
                <div className="px-4 py-5 sm:px-6">
                    <div className="mb-10">
                        <button
                            type="button"
                            onClick={handleClick}
                            className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                        >
                        Consultar APC
                        </button>
                    </div>

                    { props.loadingAPC ?
                        <Bars
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={20000} //3 secs
                    
                        />
                    :

                    <div>

                        <div className={`mb-5 sm:px-6`} >
                            {!props.editable &&
                            <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-5">
                                <div className="overflow-hidden rounded-lg bg-gray-100 px-4 py-5 shadow sm:p-6">
                                <dt className="truncate text-sm font-medium text-gray-700">Buena APC</dt>
                                <dd className={`mt-1 text-3xl font-semibold tracking-tight ${props.values.MalaAPC ? "text-red-700" : "text-green-900" }`}>{props.values.MalaAPC ? 'No' : props.values.MalaAPC == false ? 'Sí' : '-'}</dd>
                                </div>
                            </dl>
                            }                
                            {props.editable &&
                            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4">
                            <FormikControl
                                control='select'
                                label='Buena APC'
                                name='MalaAPC'
                                options={dropdownNegativeSiNoOptions}      
                            />
                            </dl>
                            }
                        </div>
                        {apcScoresSections(props.apc_scores)}

                    </div>

                    }
                </div>
            }
            {/* <Debug  {...props}/> */}

        </div>
    )
}

export default ConsultAPC;
