import { Controller } from "@hotwired/stimulus"
import React from 'react';
import ReactDOM from "react-dom"
import Contracts from "../components/Contracts/Contracts"


export default class extends Controller {
  
  connect() {
    ReactDOM.render(
      React.createElement(Contracts, this.reactProps),
      this.element
    )
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.element)
  }
}



// import { RegistrationForm } from "./registration_form"
// import { ChakraProvider } from '@chakra-ui/react'

// document.addEventListener("DOMContentLoaded", () => {
//   const token = document.querySelector('meta[name="csrf-token"]').content;
//   const registrationForm = ReactDOM.createRoot(document.getElementById("registration-form"));
//   registrationForm.render(
//     <ChakraProvider><RegistrationForm token={token} /></ChakraProvider>
//   );
// });