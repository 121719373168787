import React from "react";

export const ButtonsSimulacion = () => {

    let buttonsSimulacion;

       buttonsSimulacion = (
        <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">

                      

          <div className="ml-2 mt-2 inline-flex">
            <button
              className="bg-orange-500 hover:bg-orange-400 text-white font-bold py-2 px-4 border-b-4 border-orange-700 hover:border-orange-500 rounded"
              type="submit"
            >
              Simular Crédito
            </button>
          </div>
          
          

          
        </div>

      )
    

    return(
      <div>
        {buttonsSimulacion}
      </div>

    )

  }