import axios from 'axios';

export const ClienteData = (id, formik, { setNombreCompletoCliente, setFechaNacimientoCliente, setTipoPersonaCliente, setCedulaIdent }) => {
    if (formik.values.clienteType === "activo") {

        console.log(id)

        axios.get(`/api/v1/clientes2/${id}`)
            .then(res => {
                const tipoPersona = res.data.client.TipoPersona === 'F' ? 'natural' : 'juridica';

                console.log('here mf' , res.data)
                
                // Formik fields setting
                formik.setFieldValue('clientName', res.data.client.NombreCompleto);
                formik.setFieldValue('datebirth', res.data.client.FechaNacimiento);
                formik.setFieldValue('tipoPersona', tipoPersona);

                // Set state using passed setter functions
                setNombreCompletoCliente(res.data.client.NombreCompleto);
                setFechaNacimientoCliente(res.data.client.FechaNacimiento);
                setCedulaIdent(res.data.client.Cedula);
                setTipoPersonaCliente(tipoPersona);
            })
            .catch(error => console.error('Error fetching client data:', error));
    }
}
