import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="calc-margin"
export default class extends Controller {
  static targets = ["unitary", "quantity", "total"]

  calc() {
    this.totalTarget.value = parseFloat(this.unitaryTarget.value || 0) * parseFloat(this.quantityTarget.value|| 0)
  }

  ignore(e) {
    e.preventDefault();
  }
}
