import React, { useEffect, useRef, useState } from "react"
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'


function AutocompleteInput (props) {
  const { label, name, obs, onChange, onSelect, ...rest } = props
  return (
    <div className="mb-10 sm:col-span-1">
        <label htmlFor={name} className="block text-sm font-medium leading-5 text-gray-700">{label}</label>
        <div className="mt-1 relative rounded-md shadow-sm">
            {/* <Field name={`Bancos.${index}.NombreCompleto`} id="banco" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/> */}
            <Field name={name}>
              {({ form, field }) => {
                const { setFieldValue } = form
                const { value } = field

                let date = new Date(value); 
                return (
                  <Autocomplete wrapperClassName="min-w-full" className="border-gray-300 rounded-md shadow-sm w-full form-input block min-w-full sm:text-sm sm:leading-5"
                    id={name} 
                    {...field}
                    {...rest}
                    // selected={(value !== '' && value !== null && value !== undefined) ? date : null}
                    onChange={val => {                        
                      setFieldValue(name, val)
                      onChange(val)
                    }}
                    onSelect = {index => onSelect(index)}
                  />
                )
              }}
            </Field>
            <span className="text-gray-600">{obs}</span>
            <ErrorMessage component={TextError} name={name} />              
        </div>
    </div>         
  )
}



function Autocomplete({className, options, value, onChange, onSelect}) {

    const [showOptions, setShowOptions] = useState(false)
    const [cursor, setCursor] = useState(-1)
    const ref = useRef();

    const select = (index, option) => {
        onChange(option)
        onSelect(index)
        setShowOptions(false)
    }

    const handleChange = text => {
        onChange(text);
        setCursor(-1);
        if (text != ''){
            if(!showOptions) {
                setShowOptions(true)
            }
        } 
        else
            setShowOptions(false);
    }

    const filteredOptions = options.filter(option => option.toLowerCase().includes(value.toLowerCase()))

    const moveCursorDown = () => {
        if(cursor < filteredOptions.length - 1) {
            setCursor(c => c + 1)
        }
    }

    const moveCursorUp = () => {
        if(cursor > 0) {
            setCursor(c => c - 1)
        }
    }

    const handleNav = (e) => {
        switch (e.key) {
            case "ArrowUp":
                moveCursorUp();
                break;
            case "ArrowDown":
                moveCursorDown();
                break;
            case "Enter":
                if(cursor >= 0 && cursor < filteredOptions.length) {
                    select(cursor, filteredOptions[cursor]);
                }
                break;
        }
    }

    useEffect(() => {
        const listener = e => {
            if(!ref.current.contains(e.target)) {
                setShowOptions(false)
                setCursor(-1)
            }
        };
        
        document.addEventListener('click', listener)
        document.addEventListener('focusin', listener)
        return () => { 
            document.removeEventListener('click', listener); 
            document.removeEventListener('focusin', listener); 
        }
    },[]);

    return (
        <div ref={ref} className="h-full">

        <input 
            id="search-field"
            type="search"
            name="search"
            placeholder="Search"
            // className="w-full border-2 px-4 py-2 outline-none rounded-lg" 
            className={className}
            value={value}
            onChange={e => handleChange(e.target.value)}
            onFocus={()=> value=='' ? setShowOptions(false) :setShowOptions(true)} 
            onKeyDown={handleNav}
        />

        <ul className={`z-50 absolute w-full bg-white rounded-lg shadow-lg ${!showOptions && 'hidden'} select-none`}>
            {filteredOptions.length > 0 ? filteredOptions.map((option, i, arr) => {
                let className = "px-4 hover:bg-gray-100 "

                if(i === 0)
                    className += "pt-2 pb-1 rounded-t-lg"
                else if(i === arr.length)
                    className += "pt-1 pb-2 rounded-b-lg"
                else if(i ===0 && arr.length === 1)
                    className += "py-2 rounded-lg"
                else 
                    className += "py-1"

                if(cursor === i) {
                    className += " bg-gray-100"
                }

                return <li className={className} 
                    key={option}
                    onClick={() => select(i, option)}
                    >{option}</li>
            }) : <li className="px-4 py-2 text-gray-500">No results</li>}
            
        </ul>
    </div>)
}

export default AutocompleteInput

