import React, {useEffect,useState} from "react";
import { BrowserRouter as Router, Link } from 'react-router-dom'
//import Moment from 'react-moment';
import moment from 'moment';
import DatosCliente from "./DatosCliente";
import ResumenProductos from "./ResumenProductos";
import Template from "../Template";
import axios from "axios";
import {Bars} from 'react-loader-spinner';
import NumberFormat from 'react-number-format';


const WalletCobranza = (props) => {

  const [currentUser, setCurrentUser] = useState([]);
  const [walletData,setWalletData] = useState([]);
  const [loading, setLoading] = useState([]);


  useEffect(() => {
    setLoading(true);
    loadConsulta()
  }, [])

   const loadConsulta = async () => {
    await axios.get(`https://api.sumadata.net/analitico?fechacorte=20211216&promotor=57`).then((res) => {
        setWalletData(res.data);
        console.log(res.data);
        setLoading(false);
    });
  };

    return (
      <>
        {loading ?
          <div className="flex h-screen">
            <div className="m-auto">
              <Bars
                color="#00BFFF"
                height={100}
                width={100}
                timeout={20000} //3 secs
              />
            </div>
          </div>
         :

          <div className="overflow-y-auto">


                <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">

                    <div className="bg-white rounded-lg w-full h-auto py-4 flex flex-row justify-between divide-x divide-solid divide-gray-400">
                        <div className="relative flex-1 flex flex-col gap-2 px-4">
                            <label className="text-gray-800 text-base font-semibold tracking-wider">Total Cartera</label>
                            <label className="text-green-800 text-sm font-bold">$89.000 USD</label>

                        </div>
                        <div className="relative flex-1 flex flex-col gap-2 px-4">
                            <label className="text-gray-800 text-base font-semibold tracking-wider">Total Cartera Mora</label>
                            <label className="text-green-800 text-sm font-bold">$23.000 USD</label>

                        </div>
                        <div className="relative flex-1 flex flex-col gap-2 px-4">
                            <label className="text-gray-800 text-base font-semibold tracking-wider">Mora</label>
                            <label className="text-green-800 text-sm font-bold">Atrasada 12% | Vencida 25%</label>

                        </div>

                    </div>
                </div>

                <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">

                    <div className="bg-white rounded-lg w-full h-auto py-4 flex flex-row justify-between divide-x divide-solid divide-gray-400">

                        <div className="relative flex-1 flex flex-col gap-2 px-4">
                            <label className="text-gray-800 text-base font-semibold tracking-wider">N Creditos</label>
                            <label className="text-green-800 text-sm font-bold">56</label>

                        </div>
                        <div className="relative flex-1 flex flex-col gap-2 px-4">
                            <label className="text-gray-800 text-base font-semibold tracking-wider">Meta recaudo / mes</label>
                            <label className="text-green-800 text-sm font-bold">$7.000 USD</label>

                        </div>
                        <div className="relative flex-1 flex flex-col gap-2 px-4">
                            <label className="text-gray-800 text-base font-semibold tracking-wider">Recaudado en el mes</label>
                            <label className="text-green-800 text-sm font-bold">$1.200 USD</label>

                        </div>
                    </div>
                </div>



            <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                  Cartera Seguimiento Cobranza
                </h3>
              </div>

              <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
                <div className="ml-2 mt-2 w-full mr-2">
                  <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            N. Crédito
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Nombre de Cliente
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Dias de Atraso
                          </th>

                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            PAR30
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Total Adeudo
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Monto Pago Par30
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Ultimo Comentario
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Fecha Ultimo Comentario
                          </th>




                        </tr>
                      </thead>
                      <tbody>
                          {walletData.rows.map(walletCredits => {



                            console.log(moment().subtract(1, 'days').startOf('day').format("YYYYMMDD"))
                            //const date = new Date();




                            return(
                              <tr
                              key={walletCredits.CreditoID}
                              className="bg-white border-b border-gray-200"

                            >
                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                                <Link to={{
                                  pathname: 'consulta/' + walletCredits.ClienteID + '/' + walletCredits.CreditoID,

                                }} className="bg-orange-300 hover:bg-orange-400 text-orange-800 font-bold w-36 py-2 px-4 rounded inline-flex items-center">
                                <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M19.129,18.164l-4.518-4.52c1.152-1.373,1.852-3.143,1.852-5.077c0-4.361-3.535-7.896-7.896-7.896
                                  c-4.361,0-7.896,3.535-7.896,7.896s3.535,7.896,7.896,7.896c1.934,0,3.705-0.698,5.078-1.853l4.52,4.519
                                  c0.266,0.268,0.699,0.268,0.965,0C19.396,18.863,19.396,18.431,19.129,18.164z M8.567,15.028c-3.568,0-6.461-2.893-6.461-6.461
                                  s2.893-6.461,6.461-6.461c3.568,0,6.46,2.893,6.46,6.461S12.135,15.028,8.567,15.028z"/></svg>
                                <span>{walletCredits.CreditoID}</span>
                                </Link>

                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">
                              {walletCredits.NombreCompleto}
                              </td>

                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                              {
                                walletCredits.DiasAtraso > 30 ?
                                  <span className="text-sm font-medium bg-red-100 py-1 px-2 rounded text-red-500 align-middle">{`Mora > 30 días`} ({walletCredits.DiasAtraso} días)</span>
                                :

                                  <span className="text-sm font-medium bg-green-100 py-1 px-2 rounded text-green-500 align-middle">Mora 30 días o menos ({walletCredits.DiasAtraso ?? 0} días)</span>

                              }
                              </td>



                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-left">
                              <NumberFormat value={walletCredits.Par30} displayType={'text'} thousandSeparator={true} prefix={'$'} />

                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-left">
                              <NumberFormat value={walletCredits.TotalAdeudo} displayType={'text'} thousandSeparator={true} prefix={'$'} />

                              </td>
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-left">
                              <NumberFormat value={walletCredits.MontoPagoPar30} displayType={'text'} thousandSeparator={true} prefix={'$'} />

                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-left">
                              -

                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-left">
                              -

                              </td>




                            </tr>
                            )

                          })
                          }

                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
          </div>
}
      </>
    );

}

export default WalletCobranza;
