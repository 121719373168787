import React, {useState} from "react";
// import Template from '../Template'

import axios from 'axios';
import { Formik, Form, useFormikContext, Field } from "formik";
import FormikControl from '../forms/FormikControl';
import moment from 'moment';

const ConsultaAnalitico = (props) => {

    const [currentUser, setCurrentUser] = useState([]);
    const [loading, setLoading] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    
    const triggerDownload = (values) => {
      // const date = new Date(values.date);
      // const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
      const formattedDate = moment(values.date).format('YYYY-MM-DD')
      const downloadUrl = `/downloads/analiticos?date=${formattedDate}`;
      //const downloadUrl = `/downloads/analiticos.xlsx?date=${formattedDate}`;
      window.location.href = downloadUrl;
      // setButtonDisabled(false);
    }

    const onSubmit = (values) => {

      
      // Trigger the download of the CSV
      setButtonDisabled(true);
      triggerDownload(values);    
      setTimeout(() => {
          setButtonDisabled(false);
      }, 40000);

    };
  
    return (
      <Formik 
        initialValues={{ date: moment().format('YYYY-MM-DD') }} 
        enableReinitialize={true}
        onSubmit={onSubmit}
        >
        {(formik) => {

          const updateDate = (e) => {
                console.log(e);
          };

          return (
            <Form>
              <>
              { false ?
                  <div className="flex h-screen">
                  <div className="m-auto">
                      <Bars
                          color="#00BFFF"
                          height={100}
                          width={100}
                          timeout={20000} //3 secs
  
                      />
                      </div>
                  </div>
                :
                  <div className="overflow-y-auto">
  
                      <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                          <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                              <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                              Descargue el Analítico de Cartera
                              </h3>
                          </div>
  
                          <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
  
                              <div className="ml-4 mt-2 inline-flex">
                              <FormikControl
                                  control="date"
                                  label="Fecha de Analitico"
                                  name="date"
                                  dateFormat="yyyy/MM/dd"
                                  onSelect={(value) => {
                                    let date = moment(value).format('YYYY-MM-DD')
                                    console.log(date);
                                    // alert(e.target.value);
                                    formik.setFieldValue('date', date);
                                  }}

  
                              />  
                                  <button
                                    className={`mt-6 relative ml-2 h-9 right-0 flex items-center px-8 font-bold rounded text-gray-600 ${buttonDisabled ? "bg-slate-50" : "bg-slate-300"}`}
                                    type="submit" disabled={buttonDisabled}>
                                    Descargar
                                  </button>
  
                                  {
                                    false &&
                                    <div className="mt-2 mr-2 inline-flex">
                                      <a href={``} target="_blank">
                                      <button
                                      type="button"
                                      className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-l-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      >
                                      CSV
                                      </button>
                                      </a>
  
                                      <a href={`/downloads/analiticos.xlsx?date=${formik.values.date}`} target="_blank">
                                      <button
                                      type="button"
                                      className="-ml-px inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-r-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                      >
                                      XLS
                                      </button>
                                      </a>
                                    </div>                                
                                  }
  
                              </div>
  
                              <div className="rounded-md bg-red-50 p-4 mr-4 ml-4">
  <div className="flex">
  <div className="flex-shrink-0">
  <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
    <path d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"  />
  </svg>
  </div>
  <div className="ml-3">
  <h3 className="text-base font-medium text-yellow-800">Mensaje importante</h3>
  <div className="mt-2 text-base text-yellow-700">
  <p>Estimado usuario, recuerde que el analitico de cartera requiere de un procesamiento importante en la base de datos por lo que solicitamos pacientemente esperar por la descargar del archivo. Al hacer click en Descargar el botón de Descargar se desactiva por 40 segundos antes de poder hacer una nueva solicitud.</p>
  </div>
  </div>
  </div>
  </div>
  
                              
  
                          </div>
                      </div>
                  </div>
              }
              </>
              {/* <div className="form-group">
                <FormikControl
                                  control="date"
                                  label="Fecha de Nacimiento"
                                  name="date"
                              />              </div>
              <button type="submit" className="btn btn-primary">
                Download
              </button> */}
            </Form>
          )
        }
        }
      </Formik>
    );
  };

export default ConsultaAnalitico;
