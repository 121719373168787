import React from "react";

export const TablaSimulacion = (props) => {



    return (
      <div>
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Amortizacion
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Vencimiento
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Capital
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Interes
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Total
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Balance
                </th>
              </tr>
            </thead>
            <tbody>
              {props.amortization.map(amortizacion => {
                return(

                  <tr key={amortizacion.feeNumber} className="bg-white border-b border-gray-200">
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  {amortizacion.feeNumber}
                </td>

                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  {amortizacion.expirationDate}
                </td>

                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  {amortizacion.capital}
                </td>

                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  {amortizacion.interest}
                </td>

                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  {amortizacion.payTotal}
                </td>

                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  {amortizacion.capitalBalance}
                </td>
              </tr>

                )
              })}
              
            </tbody>
          </table>
        </div>
        
          
          
          
        
      </div>
    );

  }