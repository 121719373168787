import React, { 
    useState, 
    useEffect, 
    useRef, 
    forwardRef, 
    useCallback
} from 'react'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Chart from './Chart';

import * as Yup from 'yup'
import { Formik, Field, FieldArray, Form } from 'formik';
import FormikControl from '../forms/FormikControl'
import queryString from 'query-string';


const GroupButtons = (props) => {
    // const params = queryString.parse(props.location.search)

    return (
    //   <span className="relative z-0 inline-flex shadow-sm rounded-md">
        <div className="relative right-2">
            {/* <button
            type="button"
            className={`relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 ${props.period == 'anual' ? 'bg-gray-200' : 'bg-white'}  text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
            onClick={() => props.setPeriod('anual')}
            >
            Anual
            </button> */}
            <button
            type="button"
            className={`relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 ${props.period == 'year' ? 'bg-gray-200' : 'bg-white'}  text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
            onClick={() => props.setPeriod('year')}
            >
            Anual
            </button>
            <button
            type="button"
            className={`-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 ${props.period == 'month' ? 'bg-gray-200' : 'bg-white'} text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
            onClick={() => props.setPeriod('month')}
            >
            Mensual
            </button>
            <button
            type="button"
            className={`-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 ${props.period == 'week' ? 'bg-gray-200' : 'bg-white'} text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
            onClick={() => props.setPeriod('week')}
            >
            Semanal
            </button>
            <button
            type="button"
            className={`-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 ${props.period == 'day' ? 'bg-gray-200' : 'bg-white'} text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
            onClick={() => props.setPeriod('day')}
            >
            Diario
            </button>            
        </div>
    //   </span>
    )
  }
  
export default GroupButtons;
