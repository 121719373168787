import axios from 'axios';

export const getDestinos = async (setDestinos) => {
    const token = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

    try {
        const response = await axios.get(`/api/v1/destinos`);
        setDestinos(response.data);
    } catch (error) {
        console.error('Error fetching destinos', error);
        return error;
    }
};
