import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function Textarea (props) {
  const { label, name, ...rest } = props
  return (
    <div className="sm:col-span-1">
    <label htmlFor={name} className="block text-sm font-medium leading-5 text-gray-700">{label}</label>
    <div className="mt-1 relative rounded-md shadow-sm">
        <Field as='textarea' name={name} id={name} className="border-gray-300 rounded-md shadow-sm w-full form-input block w-full sm:text-sm sm:leading-5" placeholder=""/>
    </div>
    </div>                                   

    // <div className='form-control'>
    //   <label htmlFor={name}>{label}</label>
    //   <Field as='textarea' id={name} name={name} {...rest} />
    //   <ErrorMessage component={TextError} name={name} />
    // </div>
  )
}

export default Textarea
