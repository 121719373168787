import { Controller } from "@hotwired/stimulus"
import {enter, leave} from 'el-transition';

export default class extends Controller {
  static targets = [ "toggleable" ]         

  toggle() {
    // console.log(this.toggleableTarget)
    // this.toggleableTarget.classList.toggle('hidden')
    if(this.toggleableTarget.classList.contains('hidden')) {
      enter(this.toggleableTarget)
    } else {
      leave(this.toggleableTarget)
    }    
  } 
  
}