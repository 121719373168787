// import axios from 'axios';

// export const getAccesorios = async (formik, productoID) => {
//     const token = document.querySelector('[name=csrf-token]').content;
//     axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

//     try {
//         const resp = await axios.get(`/api/v1/accesorios?productoid=${productoID}`);
//         console.log('Accesorios Call:', resp.data);

//         const accesoriosWithLabels = resp.data.accesorios.reduce((acc, item) => {
//             acc[item.AccesorioID] = {
//                 ...resp.data.values[item.AccesorioID], // Ensure you're spreading the existing values here
//                 Etiqueta: item.Etiqueta // Attach the label
//             };
//             return acc;
//         }, {});

//         formik.setFieldValue('accesoriosList', resp.data.accesorios);
//         formik.setFieldValue('accesorios', accesoriosWithLabels);
//     } catch (error) {
//         console.error('Failed to fetch accessories', error);
//     }
// };

import axios from 'axios';

export const getAccesorios = async (formik, productoID) => {
    const token = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

    try {
        const resp = await axios.get(`/api/v1/accesorios?productoid=${productoID}`);
        console.log('Accesorios Call:', resp.data)
        formik.setFieldValue('accesoriosList', resp.data.accesorios);
        formik.setFieldValue('accesorios', resp.data.values);
    } catch (error) {
        console.error('Failed to fetch accesories', error);
    }
};
