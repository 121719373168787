import React, {useEffect,useState,useRef} from "react";
import NumberFormat from 'react-number-format';
import { v4 as uuid_v4 } from "uuid";
import { useReactToPrint } from "react-to-print";


import {Bars} from 'react-loader-spinner';
import axios from 'axios';
import Moment from 'moment';

const ResumenPagoCredito = (props) => {

  console.log(`LOS PROPS`, props);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  
  const [checkedState, setCheckedState] = useState([0]);
  const [isChecked, setIsChecked] = useState({});

  const [paymentSelected, setPaymentSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagoerror, setPagoError] = useState([]);

  const [nombreCliente, setNombreCliente] = useState([]);
  const [tipoPago, setTipoPago] = useState('');

  const [fechaTransferencia, setFechaTransferencia] = useState('');


  const [bancoEmisor, setBancoEmisor] = useState('');
  const [bancoReceptor, setBancoReceptor] = useState('');
  const [referenciaTransferencia, setReferenciaTransferencia] = useState('');
  const [nombreDepositante, setNombreDepositante] = useState('');
  const [libretaCobro, setLibretaCobro] = useState('');
  const [tipoTarjeta, setTipoTarjeta] = useState('');
  const [ultimosDigitos, setUltimosDigitos] = useState('');
  const [fechaVencimiento, setFechaVencimiento] = useState('');
  const [sucursalEmisora, setSucursalEmisora] = useState('');







  



  



  const handleInput = () => {
    console.log(fechaTransferencia);
  }

  const handleChange = (event) => {
    console.log(event.target.value)
    setFechaTransferencia({...fechaTransferencia, fechaDeTransferencia:event.target.value})
    
  }





  const sum =  (checkedState.reduce((result,number)=> parseFloat(result)+parseFloat(number))).toFixed(2);

  const paymentData = {
    customerNumber: props.creditsData[0]?.ClienteID,
    paymentType: 'E',
    creditNumber: props.creditsData[0]?.CreditoID,
    savingsAccountNumber: '',
    amount: sum,
    cashNumber: '27',
    branchOffice: '1',
    nombreCliente: props.creditsData[0]?.NombreCompleto,
    tipoPago: tipoPago,
    fechaTransferencia: fechaTransferencia,
    bancoEmisor: bancoEmisor,
    bancoReceptor: bancoReceptor,
    referenciaTransferencia: referenciaTransferencia,
    nombreDepositante: nombreDepositante,
    libretaCobro: libretaCobro,
    sucursalEmisora: sucursalEmisora

  }

 



      const handleCheck = (event) => {
        setIsChecked({...isChecked, ...{[event.target.name]: event.currentTarget.checked}});
        var updatedList = [...checkedState];
        if(event.target.checked){
          updatedList = [...checkedState, event.target.value];
        }else{
          updatedList.splice(checkedState.indexOf(event.target.value), 1);
        }
        setCheckedState(updatedList);
        props.setMontoPagar(updatedList)

        console.log(updatedList);
        
      };




 




  return(
    <div>
    {props.creditsData.map((credit, index) => {



            

            const paidBills = credit.AmortiCreditos.filter(
              (item) => item.Estatus === "P"
            );
            let countPaidBills = paidBills.length;

            const owedBills = credit.AmortiCreditos.filter(
              (item) => item.Estatus === "V"
            );
            let countOwedBills = owedBills.length;

            const arrearBills = credit.AmortiCreditos.filter(
              (item4) => item4.Estatus === "A" || item4.Estatus === "B"
            );
            let countArrearBills = arrearBills.length;
            let diasAtrasoArrearBills = arrearBills[0]?.DiasAtraso;

            const capitalVigente = credit.AmortiCreditos.find(
              (element) => element.Estatus === "V"
            );

            const cuotasAPagarAtrasadas = credit.AmortiCreditos.filter(
              (element) => element.Estatus === "A" || element.Estatus === "B"
            );

            const cuotasAPagarVigentes = credit.AmortiCreditos.find(
              (element) => element.Estatus === "V"
            );

            //const planPagoPagable = { ...cuotasAPagarAtrasadas, ...cuotasAPagarVigentes}

            const planPagoPagable = [...cuotasAPagarAtrasadas, {...cuotasAPagarVigentes}]

     
            const comFaltaPagoTotal = credit.AmortiCreditos.reduce(
              (a, v) => a + parseFloat(v.SaldoComFaltaPa),
              0
            );
            const ITBMScomFaltaPagoTotal = (comFaltaPagoTotal * 0.07).toFixed(2);

            const FeciAtrasado = arrearBills.map((amortizacion) => {
              return (
                parseFloat(amortizacion.FECIAcumulado) +
                parseFloat(amortizacion.FECIAdeudado)
              );
            });
      
            let FeciAtrasadoS = FeciAtrasado.reduce((a, v) => a + v, 0) ?? 0;
      
      
            const totalAPagarLoop = arrearBills.map((amortizacion) => {
              return (
                parseFloat(amortizacion.SaldoCapVigente) +
                parseFloat(amortizacion.SaldoCapAtrasa) +
                parseFloat(amortizacion.SaldoCapVencido) +
                parseFloat(amortizacion.SaldoCapVenNExi) +
                parseFloat(amortizacion.SaldoInteresOrd) +
                parseFloat(amortizacion.SaldoInteresAtr) +
                parseFloat(amortizacion.SaldoInteresVen) +
                parseFloat(amortizacion.SaldoInteresPro) +
                parseFloat(amortizacion.SaldoIntNoConta) +
                parseFloat(amortizacion.SaldoIVAInteres) +
                parseFloat(amortizacion.SaldoMoratorios) +
                parseFloat(amortizacion.SaldoIVAMorato) +
                parseFloat(amortizacion.SaldoComFaltaPa) +
                parseFloat(amortizacion.SaldoOtrasComis) +
                parseFloat(amortizacion.SaldoIVAComisi) +
                parseFloat(amortizacion.SaldoMoraVencido) +
                parseFloat(amortizacion.SaldoMoraCarVen) +
                parseFloat(amortizacion.MontoSeguroCuota) +
                parseFloat(amortizacion.IVASeguroCuota) +
                parseFloat(amortizacion.SaldoSeguroCuota) +
                parseFloat(amortizacion.SaldoIVASeguroCuota) +
                parseFloat(amortizacion.SaldoComisionAnual) +
                parseFloat(amortizacion.SaldoComisionAnualIVA) +
                parseFloat(amortizacion.FECIAcumulado) +
                parseFloat(amortizacion.FECIAdeudado) +
                parseFloat(amortizacion.SaldoInteresAdeudado)
              );
            });

            const cuotasAtrasadas = arrearBills.length;

            


                  //Capital
            const saldoCapitalAtrasadoCUOTA = credit.SaldoCapAtrasad ?? 0.00
            const saldoCapitalVigenteCUOTA = capitalVigente?.SaldoCapVigente ?? 0.00
            const saldoCapitalVencidoCUOTA = credit.SaldoCapVencido ?? 0.00
            const saldoCapitalVencidoNoExigibleCUOTA = credit.SaldCapVenNoExi ?? 0.00

            //Interes
            const saldoInteresOrdinarioCUOTA = credit.SaldoInterOrdin ?? 0.00
            const saldoInteresAtrasadoCUOTA = credit.SaldoInterAtras ?? 0.00
            const saldoInteresVencidoCUOTA = credit.SaldoInterVenc ?? 0.00

            //Otros
            const saldoComisionFaltaPagoCUOTA = capitalVigente?.SaldoComFaltaPa ?? 0.00
            const saldoInteresNoContabilizadoCUOTA = capitalVigente?.SaldoIntNoConta ?? 0.00
            const saldoOtrasComisionesCUOTA = capitalVigente?.SaldoOtrasComis ?? 0.00

            const FECIAdeudadoCUOTA = capitalVigente?.FECIAdeudado ?? 0.00

            let ultimaCuotaAtrasada = Object.keys(arrearBills).pop();
            const provisionAcumulada = arrearBills[ultimaCuotaAtrasada]?.ProvisionAcum ?? 0.00

            if ((ultimaCuotaAtrasada === undefined) ||
            (ultimaCuotaAtrasada == null) || (ultimaCuotaAtrasada == "undefined")){
            ultimaCuotaAtrasada = 0.00
          }
    
    
    
          //Totales
          const pagoCuotaTotalApagar = (parseFloat(saldoCapitalVigenteCUOTA) + parseFloat(saldoCapitalAtrasadoCUOTA) + parseFloat(saldoCapitalVencidoCUOTA) + parseFloat(saldoCapitalVencidoNoExigibleCUOTA) + parseFloat(saldoInteresOrdinarioCUOTA) + parseFloat(saldoInteresAtrasadoCUOTA) + parseFloat(saldoInteresVencidoCUOTA) + parseFloat(provisionAcumulada) + parseFloat(FeciAtrasadoS)).toFixed(2)


            const totalAPagar =
            parseFloat(totalAPagarLoop.reduce((a, v) => (a = a + v), 0)) +
            parseFloat(provisionAcumulada);
    

            
            



            const planPago = planPagoPagable.map((amort, index) => {

              //const TotalCuota = (parseFloat(amort.FECIAdeudado) + parseFloat(amort.InteresAdeudado) + parseFloat(amort.MontoFECI) + parseFloat(amort.Interes) + parseFloat(amort.Capital)).toFixed(2)
              const TotalCuota = (parseFloat(amort.SaldoMoraVencido) + parseFloat(amort.SaldoMoraCarVen) + parseFloat(amort.SaldoOtrasComis) + parseFloat(amort.SaldoIVAComFalP) + parseFloat(amort.SaldoComFaltaPa) + parseFloat(amort.SaldoIntNoConta) + parseFloat(amort.SaldoInteresPro) + parseFloat(amort.SaldoInteresVen) + parseFloat(amort.SaldoInteresAtr) + parseFloat(amort.SaldoInteresOrd) + parseFloat(amort.SaldoCapVenNExi) + parseFloat(amort.SaldoCapVencido) + parseFloat(amort.SaldoCapAtrasa) + parseFloat(amort.SaldoCapVigente)  + parseFloat(amort.SaldoFECI) + parseFloat(amort.FECIAdeudado)).toFixed(2)

              //console.log(planPagoPagable)

              const CuotaData = {

                TotalCuota: TotalCuota,
                IDAmortizacion: amort.AmortizacionID

              }

              if(amort.AmortizacionID != null){
      
              return (
                <tr
                  key={uuid_v4()}
                  className="bg-white border-b border-gray-200"
                >
                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {amort.AmortizacionID}
                  </td>
      
                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {amort.FechaVencim}
                  </td>
      
                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {amort.Capital}
                  </td>
      
                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {amort.Interes}
                  </td>
      
                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {amort.MontoFECI}
                  </td>
      
                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {amort.InteresAdeudado}
                  </td>
      
                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {amort.FECIAdeudado}
                  </td>
      
                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {amort.Estatus}
                  </td>
      
                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {TotalCuota}
                  </td>
      
      
                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  
                  {/* 300000371 */}
                   <input
                    key={amort.AmortizacionID}
                    name={`monto${index}`}
                    type="checkbox"
                    value={TotalCuota}
                    onChange={!isChecked[`monto${index+1}`] && handleCheck}
                    checked={isChecked[`monto${index}`]}
                    disabled={index == 0 ? false : !isChecked[`monto${index-1}`]}
                   />
                  </td>

                </tr>
                
              );
              }
            });




            

      return(
    <div
          key={uuid_v4()}
          className="px-7 py-5 flex flex-wrap -mx-5 mb-2"
        >


          <div className="flex-grow mx-6 mt-1 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
              <h3 className="text-left text-xl leading-6 font-small text-gray-900">
                Detalle del credito:{" "}
                <span className="text-orange-600">{credit.CreditoID}</span>
              </h3>
            </div>

            <div className="px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4">
                <div className="sm:col-span-1 pl-2">
                  <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                    No. del Credito
                  </dt>
                  <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                    {credit.CreditoID}
                  </dd>
                </div>

                <div className="sm:col-span-1 pl-2">
                  <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                  No. del Cliente
                  </dt>
                  <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                    {credit.ClienteID}
                  </dd>
                </div>

                <div className="sm:col-span-1 pl-2">
                  <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                    Nombre del Cliente
                  </dt>
                  <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                    {credit.NombreCompleto}
                  </dd>
                </div>

                <div className="sm:col-span-1 pl-2">
                  <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                    Cédula
                  </dt>
                  <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                    {credit.Identificacion}
                  </dd>
                </div>

                
              </dl>
            </div>

            <div className="flex-grow mx-6 mt-1 bg-white shadow overflow-hidden sm:rounded-lg">
                      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                        <h3 className="text-left text-xl leading-6 font-small text-gray-900">
                        Cuotas a Pagar (Seleccione las cuotas a pagar):
                        </h3>
                      </div>

                      <div className="pl-8 pr-8 pb-5 text-grey-darkest">
                        <div className="flex flex-col">
                          <div className="py-4 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                            <div className="mr-10  inline-block w-auto  shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="w-150">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 border-b border-green-200 bg-green-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Total Adeudo
                                    </th>
                                    <th className="px-6 py-3 border-b border-green-200 bg-green-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      {(
                                        parseFloat(credit.SaldoCapAtrasad) +
                                        parseFloat(credit.SaldoCapVigent) +
                                        parseFloat(credit.SaldCapVenNoExi) +
                                        parseFloat(credit.SaldoCapVencido) +
                                        parseFloat(credit.SaldoInterOrdin) +
                                        parseFloat(credit.SaldoInterAtras) +
                                        parseFloat(credit.SaldoInterVenc) +
                                        parseFloat(credit.SaldoInterProvi) +
                                        parseFloat(credit.SaldoOtrasComis) +
                                        parseFloat(credit.FECIAdeudado) +
                                        parseFloat(credit.SaldoFECI) +
                                        parseFloat(comFaltaPagoTotal) +
                                        parseFloat(ITBMScomFaltaPagoTotal) +
                                        parseFloat(credit.SaldoMoratorios)
                                      ).toFixed(2)}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                            </div>
                            <div className="mr-10  inline-block w-auto  shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="w-150">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 border-b border-red-200 bg-red-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Total Cuotas por Pagar
                                    </th>
                                    <th className="px-6 py-3 border-b border-red-200 bg-red-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      {countArrearBills + countOwedBills}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="pl-8 pr-8 pb-5 text-grey-darkest">
                        <div className="flex flex-col">
                          <div className=" py-4 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                            <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="min-w-full">
                                <thead>
                                  <tr>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Amortizacion
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Vencimiento
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Capital
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Interes
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      FECI
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Interes Adeudado
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      FECI Adeudado
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Estatus
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Total
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Accion
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>{planPago}</tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
              </div>

          


              



            
          </div>


          







        </div>
      
      )
              })}
              </div>
  )


}

export default ResumenPagoCredito;
