import React, { Component, useState, useEffect, useCallback } from 'react'
import Dropzone, {useDropzone} from 'react-dropzone';
import { Line, Circle } from 'rc-progress';
import axios from 'axios'


function MyDropzoneBasic(props) {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
  
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside>
    </section>
  );
}

const MyDropzone = (props) => {

    const onDrop = useCallback(acceptedFiles => {
      let formData = new FormData();

      for (var i = 0; i < acceptedFiles.length; i++) {
          let file = acceptedFiles[i];
          formData.append('files[]', file);

          return axios.post(`/api/v1/prospectos/${props.values.ProspectoID}/upload`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(res => res.data)    
      }

    }, [])


    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  
    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
            <div className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            <div className="text-center">
              <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48">
                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <p className="mt-1 text-sm text-gray-600">
                <button type="button" className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out">
                  Cargar archivo 
                </button>
                 {" o arrastre aquí "}
              </p>
              <p className="mt-1 text-xs text-gray-500">
                PDF up to 10MB
              </p>
            </div>
          </div>             
        }
        {/* {files.length > 0 && <React.Fragment>
            <div>
                <h4>Files</h4>
                <ul>{files}</ul>
            </div>
            <button onClick={uploadFiles}>Submit</button>
        </React.Fragment>}         */}
      </div>
    )
}

function MyDropzoneAccept(props) {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: 'image/jpeg, image/png'
  });

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
        <em>(Only *.jpeg and *.png images will be accepted)</em>
      </div>
      <aside>
        <h4>Accepted files</h4>
        <ul>{acceptedFileItems}</ul>
        <h4>Rejected files</h4>
        <ul>{fileRejectionItems}</ul>
      </aside>
    </section>
  );
}

function MyDropzonePlugin(props) {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    getFilesFromEvent: event => myCustomFileGetter(event)
  });

  const files = acceptedFiles.map(f => (
    <li key={f.name}>
      {f.name} has <strong>myProps</strong>: {f.myProp === true ? 'YES' : ''}
    </li>
  ));

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside>
    </section>
  );
}

async function myCustomFileGetter(event) {
  const files = [];
  const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;

  for (var i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);
    
    Object.defineProperty(file, 'myProp', {
      value: true
    });

    files.push(file);
  }

  return files;
}


const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


function MyDropzonePreviews(props) {
  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });
  
  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
        />
      </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    </section>
  );
}

class MyDropzoneUpload extends Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
    this.onOpenClick = this.onOpenClick.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.state = {
      files: [],
      percentCompleted: 0
    }
  }

  onFormSubmit(data) {
    const url = '/upload';
    let formData = new FormData();
    formData.append('name', data.name)
    formData.append('description', data.description)
    formData.append('picture', data.picture)
    console.log("formData", formData);
    const config = {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          this.setState({ percentCompleted: percentCompleted });
        }.bind(this)
    }

    post(url, formData, config)
        .then(function(response) {
            console.log(response);
        })
        .catch(function(error) {
            console.log(error);
        });
  }

  onDrop(acceptedFiles) {
    this.setState({
      files: acceptedFiles
    });
    console.log("onDrop", this.state.files);

    acceptedFiles.forEach((file)=> {
      const data = {
        name: "dora1",
        description: "dora1 description",
        picture: file
      }
      console.log("file", JSON.stringify(data));

      this.onFormSubmit(data);
    });

  }

  onOpenClick() {
    this.dropzone.open();
    console.log("onOpenClick", this.state.files);
  }

  renderThumb(file, idx) {
    return (
      <div className="col-md-2" key={ file.name }>
        <img key={ file.name } src={file.preview} className="img-thumbnail" />
      </div>
    )
  }

  renderThumbPreview() {
    if (this.state.files.length > 0) {
      const file = this.state.files[0];
      return (
        <div>
          <img key={ file.name } src={file.preview} className="img-thumbnail" width="200" />
        </div>
      )
    } else {
      return (
        <div>Try dropping some files here, or click to select files to upload.</div>
      )
    }
  }

  render() {
    const progress = this.state.percentCompleted;
    return (
      <div className="col-md-12">
        <div className="row">
          <Dropzone onDrop={this.onDrop}>
              { this.renderThumbPreview() }
          </Dropzone>
          <div className="pull-left">
            <br />
            <button type="button" className="btn" onClick={this.onOpenClick}>
              Open files
            </button>
          </div>
        </div>
        <div className="row">
          <p>{progress} %</p>
          <Line percent={progress} strokeWidth="4" strokeColor="#00ff00" />
          {/* <Circle percent={progress} strokeWidth="4" strokeColor="#D3D3D3" /> */}
        </div>

        {this.state.files.length > 0 ? <div>
        <h2>Uploading {this.state.files.length} files...</h2>
        <div className="row">
          {this.state.files.map((file, idx) => this.renderThumb(file, idx) )}
        </div>
        </div> : null}
      </div>
    )
  }
}


  // export {
  //   MyDropzone,
  //   MyDropzoneBasic,
  //   MyDropzoneAccept,
  //   MyDropzonePlugin,
  //   MyDropzonePreviews,
  //   MyDropzoneUpload
  // };  
  export default MyDropzone;