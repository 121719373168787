import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';


const ContractAuthentication = (props) => (
    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
            Autenticación y Registro de Contrato
            </h3>
        </div>
        <div className="px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
            <div className="sm:col-span-1">
                <dt className="text-sm leading-5 font-medium text-gray-500">
                Tipo de Autenticación
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900">
                Tipo 1
                </dd>
            </div>
            </dl>
        </div>
    </div>
)


const ContractAuthenticationEdit = (props) => (
    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
            Edición de Autenticación y Registro de Contrato
            </h3>
        </div>
        <div className="px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-1">
            <div className="sm:col-span-1">
                <form>
                <div>
                    <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                        <div className="sm:col-span-1">
                        <label htmlFor="location" className="block text-sm leading-5 font-medium text-gray-700">Tipo de Autenticación</label>
                        <select id="location" className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5">
                            <option>Tipo Autenticación 1</option>
                            <option>Tipo Autenticación 2</option>
                            <option>Tipo Autenticación 3</option>
                            <option>Tipo Autenticación 4</option>
                        </select>
                        </div>                    
                    </div>                    
                </div>                    
               </form>    
            </div>
            </dl>
        </div>        
        <div className="px-4 py-5 sm:px-6">
            <h4>Aquí Matriz de Gastos Legales</h4>
        </div>        
 
        
    </div>
)



export {ContractAuthentication, ContractAuthenticationEdit};
