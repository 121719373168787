export const calculateSeguroDesgravamen = (credAmount, montoRefinanciado, gastoTotalAccesoriosSinDesgravamen, termInMonths, factorSeguroDesgravamen, factorComisionSeguroDesgravamen, factorProyeccionCuotas, finalAge) => {

    const baseAmount = Number(credAmount) + Number(montoRefinanciado) + Number(gastoTotalAccesoriosSinDesgravamen);

    const seguroDesgravamenMonto = (baseAmount * factorSeguroDesgravamen / 1000) *  Number(termInMonths) * factorProyeccionCuotas;
    const seguroDesgravamenMontoImpuesto = 0.00;
    const seguroDesgravamenConImpuesto = seguroDesgravamenMonto + seguroDesgravamenMontoImpuesto;
  
    const impuestoDesgravamenMonto = seguroDesgravamenMonto * 0.05;
    const impuestoDesgravamenConImpuesto = impuestoDesgravamenMonto + seguroDesgravamenMontoImpuesto;

    let comisionDesgravamenMonto;
    let comisionDesgravamenMontoImpuesto;
    let comisionDesgravamenConImpuesto;

    if(finalAge >= 18 && finalAge <= 55){
       
      comisionDesgravamenMonto = (baseAmount * factorComisionSeguroDesgravamen / 1000) * ( Number(termInMonths) + 5) * factorProyeccionCuotas;
      comisionDesgravamenMontoImpuesto = comisionDesgravamenMonto * 0.07;
      comisionDesgravamenConImpuesto = comisionDesgravamenMonto + comisionDesgravamenMontoImpuesto;

    }else if(finalAge > 55 && finalAge <= 64){
      
      comisionDesgravamenMonto = (baseAmount * factorComisionSeguroDesgravamen / 1000) * ( Number(termInMonths) + 5) * 0.765;
      comisionDesgravamenMontoImpuesto = comisionDesgravamenMonto * 0.07; 
      comisionDesgravamenConImpuesto = comisionDesgravamenMonto + comisionDesgravamenMontoImpuesto;

    }else if(finalAge > 64 && finalAge <= 70){
      
      comisionDesgravamenMonto = (baseAmount * factorComisionSeguroDesgravamen / 1000) * ( Number(termInMonths) + 5) * 1.20;
      comisionDesgravamenMontoImpuesto = comisionDesgravamenMonto * 0.07; 
      comisionDesgravamenConImpuesto = comisionDesgravamenMonto + comisionDesgravamenMontoImpuesto;

    }else if(finalAge > 70){
      
      comisionDesgravamenMonto = (baseAmount * factorComisionSeguroDesgravamen / 1000) * ( Number(termInMonths) + 5) * 1.60;
      comisionDesgravamenMontoImpuesto = comisionDesgravamenMonto * 0.07; 
      comisionDesgravamenConImpuesto = comisionDesgravamenMonto + comisionDesgravamenMontoImpuesto;

    }

    return {

      seguroDesgravamenMonto,
      seguroDesgravamenMontoImpuesto,
      seguroDesgravamenConImpuesto,
      impuestoDesgravamenMonto,
      impuestoDesgravamenConImpuesto,
      comisionDesgravamenMonto,
      comisionDesgravamenMontoImpuesto,
      comisionDesgravamenConImpuesto

    };

  };