import React, { useState, useEffect } from 'react';
import ChartMora1a30 from './ChartMora1a30';
import ChartMora31a60 from './ChartMora31a60';
import ChartMora61a90 from './ChartMora61a90';
import ChartMora91a180 from './ChartMora91a180';
import ChartMora180plus from './ChartMora180plus';


function TabComponent() {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="mt-1 bg-white shadow-sm rounded-lg">
      <div className="flex">
        <button
          className={`px-4 py-2 ${
            activeTab === 1 ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-600'
          }`}
          onClick={() => handleTabChange(1)}
        >
          Mora 1 a 30 días
        </button>
        <button
          className={`px-4 py-2 ${
            activeTab === 2 ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-600'
          }`}
          onClick={() => handleTabChange(2)}
        >
          Mora 31 a 60 días
        </button>
        <button className={`px-4 py-2 ${
            activeTab === 3 ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-600'
            }`}
            onClick={() => handleTabChange(3)}
        >
            Mora 61 a 90 días
        </button>
        <button className={`px-4 py-2 ${
            activeTab === 4 ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-600'
            }`}
            onClick={() => handleTabChange(4)}
        >
            Mora 91 a 180 días
        </button>
        <button className={`px-4 py-2 ${
            activeTab === 5 ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-600'
            }`}
            onClick={() => handleTabChange(5)}
        >
            Mora 180+ días
        </button>
      </div>
      <div className="p-4">
        {activeTab === 1 && <ChartMora1a30 />}
        {activeTab === 2 && <ChartMora31a60 />}
        {activeTab === 3 && <ChartMora61a90 />}
        {activeTab === 4 && <ChartMora91a180 />}
        {activeTab === 5 && <ChartMora180plus />}
      </div>
    </div>
  );
}

export default TabComponent;
