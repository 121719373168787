import React from "react";

const sucursales = {
    1: 'SEDE PRINCIPAL',
    2: 'AG. LOS ANDES',
    3: 'AG. 24 DICIEMBRE',
    4: 'AG. LA CHORRERA',
    5: 'AG. CORONADO',
    6: 'AG. CHITRÉ',
    7: 'AG. DAVID',
    8: 'AG. SANTIAGO',
    10: 'AG. ARRAIJAN'
  };

  const DatosAvales = ({ avalesData }) => {
  
    if (avalesData && avalesData.length > 0) {

    return (
      <div>
        <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
          <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
            Datos de Codeudores
          </h3>
        </div>
        {avalesData.map((aval, index) => (
          <div key={index}>
            <div className="px-4 py-5 flex flex-wrap -mx-3 mb-2">
              <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Nombre del Codeudor {index + 1}
                </label>

                <input
                  name="avalName"
                  className="placeholder-gray-100 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  readOnly
                  type="text"
                  placeholder="Nombre del Aval"
                  value={aval.NombreCompleto}
                />
              </div>

              <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label className="block text-left  tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Cedula Codeudor {index + 1}
                </label>

                <input
                  name="avalIDN"
                  className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Cedula"
                  value={aval.Cedula}
                  readOnly
                />
              </div>

              <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Fecha de Nacimiento
                </label>

                <input
                  name="avalBirthDate"
                  className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Fecha de Nacimiento / Creación"
                  value={aval.FechaNac}
                  readOnly
                />
              </div>

              <div className="-full py-4 md:w-1/3 px-3 mb-6 md:mb-0">
                <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Teléfonos
                </label>

                <input
                  name="avalPhone"
                  className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Teléfono"
                  value={[aval.Telefono, aval.TelefonoCel, aval.TelefonoTrabajo].filter(Boolean).join(' / ')}
                  readOnly
                />
              </div>

              <div className="-full py-4 md:w-1/3 px-3 mb-6 md:mb-0">
                <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Direccion
                </label>

                <input
                  name="avalAddress"
                  className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Direccion"
                  value={aval.DireccionCompleta}
                  readOnly
                />
              </div>

              <div className="-full py-4 md:w-1/3 px-3 mb-6 md:mb-0">
                <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Tipo de Codeudor
                </label>

                <input
                  name="avalType"
                  className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Tipo de Aval"
                  value={aval.TipoPersona}
                  readOnly
                />
              </div>






            </div>
          </div>
        ))}
      </div>
    );
        } else {
          return (
            <div className=" bg-red-300 px-4 py-5 border-b border-gray-100 sm:px-6">
        <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
          No tiene codeudores
        </h3>
      </div>
        );
        }
  };

  

const DatosCliente = ({
    clientData,
    creditsCount,
    totalOwed,
    totalBillsOwed,
    delayRatio,
    avalesData,
}) => {
    const tipoPersona = clientData?.TipoClienteAPC;
    const tipoPersonaNormalizado = tipoPersona === "1" ? "Natural" : "Juridica";
    const potenciometro = "64.5";

    return (
      <>
      <div>
        <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
          <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
            Datos del Cliente
          </h3>
        </div>
        <div className="px-4 py-5 flex flex-wrap -mx-3 mb-2">
          <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
              Nombre de Cliente
            </label>

            <input
              name="clientName"
              className="placeholder-gray-100 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              readOnly
              type="text"
              placeholder="Nombre de Cliente"
              value={clientData?.NombreCompleto}
            />
          </div>

          <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="block text-left  tracking-wide text-gray-700 text-xs font-bold mb-2">
              Cedula Cliente
            </label>

            <input
              name="clientIDN"
              className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Cedula"
              value={clientData?.Cedula}
              readOnly
            />
          </div>

          <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
              N Cliente
            </label>

            <input
              name="clientNumber"
              className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Numero de Cliente"
              value={clientData?.ClienteID}
              readOnly
            />
          </div>

          <div className="-full py-4 md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
              Tipo de Cliente
            </label>

            <input
              name="clientType"
              className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Tipo de Cliente"
              value={tipoPersonaNormalizado}
              readOnly
            />
          </div>

          <div className="-full py-4 md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
              Cant. Creditos Vigentes
            </label>

            <input
              name="clientACredits"
              className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Cant. Creditos Vigentes"
              value={creditsCount}
              readOnly
            />
          </div>

          <div className="-full py-4 md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
              Total Adeudado
            </label>

            <input
              name="clientTotalAmountDue"
              className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Total Adeudado"
              value={parseFloat(totalOwed).toFixed(2)}
              readOnly
            />
          </div>

          <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
              Agencia
            </label>

            <input
              //value={clientData?.Sucursal}
              value={sucursales[clientData?.Sucursal]}
              name="clientBranch"
              className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Agencia"
              readOnly
            />
          </div>

          <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
              Total Letra Mensual
            </label>

            <input
              name="clientTotalMonthlyBill"
              className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder=" Total Letra Mensual"
              value={parseFloat(totalBillsOwed).toFixed(2)}
              readOnly
            />
          </div>

          <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
              Promedio dias retraso
            </label>

            <input
              name="clientDelayRatio"
              className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Promedio dias retraso"
              value={delayRatio}
              readOnly
            />
          </div>

          <div className="-full py-4  md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
              Cliente desde
            </label>

            <input
              name="clientDateSince"
              className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Cliente desde"
              value={clientData?.FechaAlta}
              readOnly
            />
          </div>

          <div className="-full py-4  md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
              Asesor
            </label>

            <input
              name="clientAdviser"
              className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Asesor"
              value={clientData?.Promotor}
              readOnly
            />
          </div>

          <div className="-full py-4  md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
              Potenciometro
            </label>

            <input
              className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Potenciometro"
              value={potenciometro}
              readOnly
            />
          </div>

          <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
              Actividad Principal
            </label>

            <input
              className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="APC"
              value={clientData?.Actividad}
              readOnly
            />
          </div>

          <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Teléfonos
                </label>

                <input
                  name="clientPhone"
                  className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Teléfono"
                  value={[clientData?.TelTrabajo, clientData?.Telefono, clientData?.TelefonoCelular].filter(Boolean).join(' / ')}
                  readOnly
                />
            </div>
        </div>
      </div>

      <DatosAvales avalesData={avalesData} />
      </>

    );
};

export default DatosCliente;

// import React from "react";

// class DatosCliente extends React.Component {
//   render() {
//     const tipoPersona = this.props?.clientData?.["TipoClienteAPC"];
//     const tipoPersonaNormalizado = tipoPersona === "1" ? "Natural" : "Juridica";
//     //console.log(tipoPersonaNormalizado);
//     const potenciometro = "64.5";

//     return (
//       <div>
//         <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
//           <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
//             Datos del Cliente
//           </h3>
//         </div>
//         <div className="px-4 py-5 flex flex-wrap -mx-3 mb-2">
//           <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
//             <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
//               Nombre de Cliente
//             </label>

//             <input
//               name="clientName"
//               className="placeholder-gray-100 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               readOnly
//               type="text"
//               placeholder="Nombre de Cliente"
//               value={this.props?.clientData?.["NombreCompleto"]}
//             />
//           </div>

//           <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
//             <label className="block text-left  tracking-wide text-gray-700 text-xs font-bold mb-2">
//               Cedula Cliente
//             </label>

//             <input
//               name="clientIDN"
//               className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               type="text"
//               placeholder="Cedula"
//               value={this.props?.clientData?.["Cedula"]}
//             />
//           </div>

//           <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
//             <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
//               N Cliente
//             </label>

//             <input
//               name="clientNumber"
//               className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               type="text"
//               placeholder="Numero de Cliente"
//               value={this.props?.clientData?.["ClienteID"]}
//             />
//           </div>

//           <div className="-full py-4 md:w-1/3 px-3 mb-6 md:mb-0">
//             <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
//               Tipo de Cliente
//             </label>

//             <input
//               name="clientType"
//               className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               type="text"
//               placeholder="Tipo de Cliente"
//               value={tipoPersonaNormalizado}
//             />
//           </div>

//           <div className="-full py-4 md:w-1/3 px-3 mb-6 md:mb-0">
//             <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
//               Cant. Creditos Vigentes
//             </label>

//             <input
//               name="clientACredits"
//               className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               type="text"
//               placeholder="Cant. Creditos Vigentes"
//               value={this.props?.creditsCount}
//               readOnly
//             />
//           </div>

//           <div className="-full py-4 md:w-1/3 px-3 mb-6 md:mb-0">
//             <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
//               Total Adeudado
//             </label>

//             <input
//               name="clientTotalAmountDue"
//               className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               type="text"
//               placeholder="Total Adeudado"
//               value={parseFloat(this.props?.totalOwed).toFixed(2)}
//               readOnly
//             />
//           </div>

//           <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
//             <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
//               Agencia
//             </label>

//             <input
//               value={this.props?.clientData?.["Sucursal"]}
//               name="clientBranch"
//               className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               type="text"
//               placeholder="Agencia"
//               readOnly
//             />
//           </div>

//           <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
//             <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
//               Total Letra Mensual
//             </label>

//             <input
//               name="clientTotalMonthlyBill"
//               className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               type="text"
//               placeholder=" Total Letra Mensual"
//               value={parseFloat(this.props?.totalBillsOwed).toFixed(2)}
//               readOnly
//             />
//           </div>

//           <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
//             <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
//               Promedio dias retraso
//             </label>

//             <input
//               name="clientDelayRatio"
//               className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               type="text"
//               placeholder="Promedio dias retraso"
//               value={this.props?.delayRatio}
//               readOnly
//             />
//           </div>

//           <div className="-full py-4  md:w-1/3 px-3 mb-6 md:mb-0">
//             <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
//               Cliente desde
//             </label>

//             <input
//               name="clientDateSince"
//               className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               type="text"
//               placeholder="Cliente desde"
//               value={this.props?.clientData?.["FechaAlta"]}
//             />
//           </div>

//           <div className="-full py-4  md:w-1/3 px-3 mb-6 md:mb-0">
//             <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
//               Asesor
//             </label>

//             <input
//               name="clientAdviser"
//               className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               type="text"
//               placeholder="Asesor"
//               value={this.props?.clientData?.["Promotor"]}
//               readOnly
//             />
//           </div>

//           <div className="-full py-4  md:w-1/3 px-3 mb-6 md:mb-0">
//             <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
//               Potenciometro
//             </label>

//             <input
//               className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               type="text"
//               placeholder="Potenciometro"
//               value={potenciometro}
//               readOnly
//             />
//           </div>

//           <div className="-full md:w-1/3 px-3 mb-6 md:mb-0">
//             <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
//               Actividad Principal
//             </label>

//             <input
//               className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               type="text"
//               placeholder="APC"
//               value={this.props?.clientData?.["Actividad"]}
//               readOnly
//             />
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default DatosCliente;
