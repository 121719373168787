import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="remote_select"
export default class extends Controller {
  static targets = ["frame"]
  static values = {
    url: String,
    name: String,
    client_id: String,
    id: String
  }

  update(event) {
    const url = new URL(this.urlValue);
    url.searchParams.append(this.nameValue, event.target.value);
    url.searchParams.append('client_id', this.clientIdValue);
    url.searchParams.append('id', this.idValue);
    this.frameTarget.src = url
  }

}
