import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';
import CanvasDraw from "react-canvas-draw";
import { Switch } from '@headlessui/react'

// import html2canvas from "html2canvas";
// import saveAs from "file-saver";


const AuthAPC = (props) => {

    const canvasRef = useRef(null);
    // const [signature, setSignature] = useState(props.values.signature);

    const handleExport = () => {
        const base64 = canvasRef.current.canvasContainer.childNodes[1].toDataURL();        
        props.setSignature(base64);
        const date = new Date().toISOString().slice(0, 10);
        props.setSignatureDate(date);
    }

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ')
    }

    const [hiddenPanel, setHiddenPanel] = useState(false);

    // var check = Moment(props.signatureDate, 'YYYY/MM/DD');
    // var month = check.format('M');
    // var day   = check.format('D');
    // var year  = check.format('YYYY');
  
    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            {props.editable && 
            <div className="m-4 inline-flex float-right">
                <span className="inline-flex rounded-md shadow-sm">
                    <button type="submit" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                        Salvar
                    </button>
                </span>
            </div>        
            }
            {!props.editable && 
            <div className="m-4 inline-flex float-right">
                <span className="ml-2 rounded-md shadow-sm ">
                    <a target="_blank" href={`/gen_docs/62606658ad14e15116d8bef8/print?debug=${props.values.ProspectoID}`}>
                    <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                    </svg>                                
                    </button>
                    </a>
                </span>
                <span className="ml-2 rounded-md shadow-sm ">
                    <a onClick={() => props.setAuthApcEdit(true)}>
                    <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>                           
                    </button>
                    </a>
                </span>
            </div>
            }
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="flex text-lg leading-6 font-medium text-gray-900">   
                <a onClick={() => setHiddenPanel(!hiddenPanel)}>       
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor" >
                {hiddenPanel
                    ? <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd"  />
                    : <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd"  />
                }
                </svg>
                </a>
                Autorización APC
                </h3>
            </div>
            { !hiddenPanel && !props.canAuth  &&
            <div className="px-4 py-5 sm:px-6">
                <div className="mb-4 px-4 py-5 sm:px-6">
                    <span className="m-4 self-center text-red-500">
                    Para Autorizar APC debe completar antes datos necesarios del prospecto (Nombres, Cédula/Pasaporte, Celular/Telefono, Direcciones)
                    </span>
                </div>   
            </div>   
            }
            {!hiddenPanel && props.canAuth &&
                <div>
                <div className="px-4 py-5 sm:px-6">
                {props.editable &&
                    <Switch.Group as="div" className="flex items-center justify-between">
                    <span className="flex-grow flex flex-col">
                        <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                        ¿Tiene autorización impresa?
                        </Switch.Label>
                        <Switch.Description as="span" className="text-sm text-gray-500">
                        Tiene la opcíón de cargar archivo de la Autorizacíón de APC escaneada firmada en papel por el cliente (marque selector a la derecha)
                        </Switch.Description>
                    </span>
                    <Switch
                        checked={props.values.apcEnabled}
                        onChange={(e) => {
                                props.setFieldValue('apcEnabled', !props.values.apcEnabled);
                            }
                        }
                        className={classNames(
                            props.values.apcEnabled ? 'bg-indigo-600' : 'bg-gray-200',
                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        )}
                    >
                        <span
                        aria-hidden="true"
                        className={classNames(
                            props.values.apcEnabled ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                        )}
                        />
                    </Switch>
                    </Switch.Group>
                }
                </div>       
                {!props.values.apcEnabled &&
                    <div className="px-4 py-5 sm:px-6">
                        <b>Señores <br/>
                        SUMA FINANCIERA, S.A. <br/>
                        Ciudad <br/><br/></b>
    
    
                        Estimados señores:<br/><br/>
    
                        <p className="mb-5">El suscrito, <b>{props.values.NombreCompleto}</b>, portador de la cédula de identidad personal o pasaporte número <b>{props.values.Cedula ? props.values.Cedula : props.values.Pasaporte ? props.values.Pasaporte : ''}</b>, actuando en mi propio nombre, <b>{props.values.addresses.length > 0 && props.values.addresses[0].address_text}</b>, con número de teléfono fijo o celular <b>{props.values.TelefonoCelular ? props.values.TelefonoCelular : props.values.Telefono ? props.values.Telefono : ''}</b> y correo electrónico <b>{props.values.Correo ? props.values.Correo : 'NO DISPONIBLE'}</b> por este medio autorizo expresamente a  SUMA  FINANCIERA, S.A., para que, de conformidad con lo expresado en el artículo 24 y demás disposiciones aplicables de la Ley 24 del 22 de mayo de 2002 y sus reformas, y de conformidad con la Ley 81 del 26 de marzo de 2019 sobre Protección de Datos, y con respecto de APC BURÓ, S.A. o cualquier agencia de información de datos, banco o agente económico, capte, obtenga, solicite, recopile, registre, organice, estructure, almacene, conserve, adapte o modifique según lo que permita u ordene la ley, extraiga, consulte, utilice, comunique, transmita, intercambie, comparta, difunda (en aquellos casos en los que la ley lo permita u ordene), limite, suprima o destruya (en aquellos datos en los que la ley lo permita u ordene) y en cualquier otra forma trate dichos datos, con respecto de informaciones relacionadas con obligaciones o transacciones crediticias que mantengo o pudiera mantener con dichos agentes económicos, así como cualquier otro dato relacionado con mi persona y que sirvan a los fines específicos que se indican más adelante. </p>
    
                        <p className="mb-5">Queda entendido que el consentimiento antes otorgado autoriza a SUMA FINANCIERA, S.A, para los actos indicados y autoriza a APC BURÓ, S.A. o cualquier otra agencia de información de datos o que maneje cualquier tipo de datos, para que, a su vez, capte, obtenga, solicite, recopile, registre, organice, estructure, almacene, conserve, adapte o modifique según lo que permita u ordene la ley, extraiga, consulte, utilice, comunique, transmita, intercambie, comparta, difunda (en aquellos casos en los que la ley lo permita u ordene), limite, suprima o destruya (en aquellos datos en los que la ley lo permita u ordene) y en cualquier otra forma trate dichos datos, con respecto de informaciones relacionadas con obligaciones o transacciones crediticias que la empresa que represento mantiene o pudiera mantener con dichos agentes económicos de la localidad, así como cualquier otro dato relacionado con dicha empresa y que sirvan a los fines específicos que se indican más adelante. </p>
    
                        <p className="mb-5">Los fines para los cuales se otorga la autorización establecida en este documento son:   información sobre el historial de crédito, información o predicción sobre comportamiento, información de diversas situaciones jurídicas, información sobre obligaciones con el Estado, aplicación de procedimientos científicos necesarios a las referencias de crédito y/o a los datos descritos anteriormente, a fin de proporcionar cualquier tipo de score, puntuación o índice con relación a las referencias crediticias y/o datos, elaboración de perfiles o segmentaciones, aplicación de métodos científicos y/o estadísticos para la determinación de hábitos de consumo, ya sea para el ofrecimiento de productos o servicios o para la elaboración de datos estadísticos, ofrecimiento de recomendaciones a la empresa firmante de la autorización y/o para la elaboración de estudios de mercado. </p>
    
                        <p className="mb-5">SUMA FINANCIERA, S.A y APC BURÓ, S.A. quedan autorizadas a captar, obtener, solicitar, recopilar, registrar, organizar, estructurar, almacenar, conservar o modifique según lo que permita u ordene la ley, extraiga, consulte, utilice, comunique, transmita, limite, suprima o destruya, datos de la empresa que represento para prevenir el fraude de identidad, incluyendo sin limitar, aquellos de los que trata el numeral 6 del Artículo 30 de la Ley 24 de 2002. </p>
    
                        <p className="mb-5">Así mismo, autorizo a APC BURÓ, S.A. a consultar el Sistema de Verificación de Identidad (SVI) del Tribunal Electoral con el objetivo de validación de mis datos de identificación. </p>
    
                        <p className="mb-5">Declaro que la anterior autorización de otorga de manera libre y voluntaria.  Igualmente, declaro que he recibido información con relación a la finalidad de ofrecimiento de los productos que SUMA FINANCIERA, S.A. y APC BURÓ, S.A. han desarrollado y estarán desarrollando. </p>
    
                        <p className="mb-5">SUMA FINANCIERA, S.A y APC BURÓ, S.A. no utilizarán los datos recopilados de acuerdo con este documento para fines distintos a los establecidos en este documento ni para fines incompatibles con aquellos para los cuales los datos fueron recopilados y pudiendo ser portados a otro agente económico a solicitud de mi persona en lo que respecta a aquellos datos que son regulados por la Ley 81 del 26 de marzo de 2019 sobre Protección de Datos. </p>
    
                        {props.signatureDate &&
                        <p className="mb-5">En la ciudad de Panamá, a los {"DIAFECHAFIRMA"} días del mes de {"MESFECHAFIRMA"} de {"ANOFECHAFIRMA"}.</p>
                        }
        
                        <p className="mb-5">Atentamente,  </p>
    
    
    
                        Nombre y Apellido: {props.values.NombreCompleto}<br/>   
    
                        Cédula: {props.values.Cedula}<br/>   
    
                        Teléfono/Celular: {props.values.TelefonoCelular ? props.values.TelefonoCelular : props.values.Telefono ? props.values.Telefono : ''}<br/><br/>   
    
    
                        FIRMA DE AUTORIZACIÓN <br/><br/>
    
                        {props.signature &&
                            <img className="h-44" src={props.signature}/>
                        }
    
    
                        {props.editable &&
                        <button
                        type="button"
                        onClick={() => {
                            props.setSignature(null);
                            if (canvasRef)
                                canvasRef.current.clear()
                        }}
                        className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                        Borrar Firma
                        </button>
                        }
    
                        {!props.signature &&
                        <button
                            type="button"
                            onClick={handleExport}
                            className="inline-flex items-center ml-5 px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                        Aceptar Firma
                        </button>
                        }
                        {true && !props.signature &&
                        <CanvasDraw 
                            id="signature"
                            className = "mt-10"
                            ref = {canvasRef}
                            lazyRadius = {5}
                            brushRadius = {5}
                            brushColor = "#444"
                            canvasWidth="500"
                            />
                        }    
                        {props.signature && props.editable && 
                        <div className="m-4 inline-flex float-right">
                            <span className="inline-flex rounded-md shadow-sm">
                                <button type="submit" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                    Salvar
                                </button>
                            </span>
                        </div>        
                        }
                    </div>   
                }

                {props.values.apcEnabled &&
                    <div className="px-4 py-5 sm:px-6">
                        <span className="m-4 self-center text-red-500">Debe cargar documento de Autorización de APC escaneado, en el siguiente paso en la sección de Documentos</span>
                    </div>   
                }
                </div>
            }
                     
        </div>
    )
}


export default AuthAPC;
