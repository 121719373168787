import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';
import FieldValue from './FieldValue'

import * as Yup from 'yup'
import FormikControl from '../forms/FormikControl'


const dropdownCalcMethod = [
    { key: 'Intereses sobre saldo', value: 'Intereses sobre saldo' }
  ]

const ExpressQuote = (props) => {
    const [hiddenPanel, setHiddenPanel] = useState(false);

    
    const handleGuaranteeTypeChange = (event) => {
        props.setFieldValue('quote_accepted', null);

        console.log('handleGuaranteeTypeChange', event.target.value);
        if (event.target.value == 'Prendario')
            props.setFieldValue('legal_amount', 50);
        else if (event.target.value == 'Mix de Garantías')
            props.setFieldValue('legal_amount', 125);
        else if (event.target.value == 'Mobiliario')
            props.setFieldValue('legal_amount', 275);
        else if (event.target.value == 'Inmobiliario')
            props.setFieldValue('legal_amount', 650);
        props.setFieldValue('guarantee_type', event.target.value);
    };

    const handleAmountChange = () => {
        props.setFieldValue('quote_accepted', null);

        if (props.values.requested_amount <= 5000){
            props.setFieldValue('comission_rate', 12);
            props.setFieldValue('guarantee_type', '');
            props.setDropdownGuaranteeType( 
                [
                    { key: 'Prendario', value: 'Prendario' },
                    { key: 'Mix de Garantías', value: 'Mix de Garantías' }
                ]
            );
        }
        else if (props.values.requested_amount > 5000) {
            props.setDropdownGuaranteeType( 
                [
                    { key: 'Mobiliario', value: 'Mobiliario' },
                    { key: 'Inmobiliario', value: 'Inmobiliario' }
                ]
            );

            if (props.values.requested_amount > 5000 && props.values.requested_amount <= 15000)
                props.setFieldValue('comission_rate', 10)
            else if (props.values.requested_amount > 15000)
                props.setFieldValue('comission_rate', 8)
        }
    };

    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            {props.editable && 
            <div className="m-4 inline-flex float-right">
                <span className="inline-flex rounded-md shadow-sm">
                    <button type="submit" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                        Salvar
                    </button>
                </span>
            </div>        
            }
            {!props.editable && 
            <div className="m-4 inline-flex float-right">
                <span className="ml-2 rounded-md shadow-sm ">
                    <a onClick={() => props.setOtherEdit(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>                           
                    </a>
                </span>
            </div>        
            }
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="flex text-lg leading-6 font-medium text-gray-900">   
                <a onClick={() => setHiddenPanel(!hiddenPanel)}>       
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor" data-action="click->toggler#toggle">
                {hiddenPanel
                    ? <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" data-toggler-target="selectorIcon" />
                    : <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" data-toggler-target="selectorIcon" />
                }
                </svg>
                </a>
                Cotización Rápida
                </h3>
            </div>
            {!hiddenPanel &&
                <div className="px-4 py-5 sm:px-6">
                    {!props.editable &&
                    <div>
                        <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4">
                        <FieldValue label={"Monto Solicitado $"} >
                            {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                            }).format(props.values.requested_amount)}   
                        </FieldValue>
                        <FieldValue label={"Plazo"}>{props.values.month_periods}</FieldValue>
                        <FieldValue label={"Tasa de interés %"}>{props.values.interest_rate}%</FieldValue>
                        <FieldValue label={"Comisión de cierre %"}>{props.values.comission_rate}%</FieldValue>
                        {/* <FieldValue label={"Seguro mensual %"}>{props.values.secure_rate}%</FieldValue>
                        <FieldValue label={"Gastos de manejo $"}>
                            {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                            }).format(props.values.handling_amount)}   
                        </FieldValue> */}
                        <FieldValue label={"Tipo de Garantía"}>{props.values.guarantee_type}</FieldValue>
                        <FieldValue label={"Gastos Legales $"}>
                            {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                            }).format(props.values.legal_amount)}                               
                        </FieldValue>
                        <FieldValue label={"Método de cálculo"}>{props.values.calc_method}</FieldValue>
                        </dl>
                        { (props.values.requested_amount != 0 && props.values.requested_amount != undefined) &&
                        <div>
                            <div className="mt-10">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Cotización</h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">Resultados calculados en base a las variables suministradas:</p>
                            </div>
                            <div className="mt-5 border-t border-gray-200">
                                {
                                    false &&
                                    <div>
                                    <dl className="divide-y divide-gray-200">
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                        <dt className="text-sm font-medium text-gray-500">Letra</dt>
                                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <span className="flex-grow">
                                            {new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD"
                                            }).format(props.values.monthy_payment)}   
                                        </span>                                 
                                        </dd>
                                    </div>
                                    </dl>
                                    <dl className="divide-y divide-gray-200">
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                        <dt className="text-sm font-medium text-gray-500">Total de Intereses</dt>
                                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <span className="flex-grow">
                                        {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        }).format(props.values.interest_total)}                                    
                                        </span>
                                        </dd>
                                    </div>
                                    </dl>
                                    <dl className="divide-y divide-gray-200">
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                        <dt className="text-sm font-medium text-gray-500">Monto de Seguro</dt>
                                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <span className="flex-grow">
                                            {new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD"
                                            }).format(props.values.secure_amount)}                                    
                                        </span>
                                        </dd>
                                    </div>
                                    </dl>
                                    </div>
                                }
                                <dl className="divide-y divide-gray-200">
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                    <dt className="text-sm font-medium text-gray-500">Letra total</dt>
                                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <span className="flex-grow">
                                        {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        }).format(props.values.monthy_payment + props.values.secure_amount)}                                    
                                    </span>
                                    </dd>
                                </div>
                                </dl>
                                <div className="m-4 inline-flex float-right">
                                    {
                                        props.values.quote_accepted == undefined ?
                                        <span className="inline-flex rounded-md shadow-sm">
                                        <button onClick={() => {
                                                navigator.clipboard.writeText(`https://sumafinanciera.com/landing_quick.php?_id=${props.values.id}&p=1`);
                                                alert('Se ha copiado el enlace a la propuesta');
                                            }} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                            Compartir Cotización
                                        </button>
                                        </span>
                                        : props.values.quote_accepted ?
                                        <span className="text-green-600 text-2xl font-mono">
                                        ACEPTADO
                                        </span>
                                        : <span className="text-red-700 text-2xl font-mono">
                                        NO ACEPTADO
                                        </span>
                                    }

                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    }                
                    {props.editable &&
                    <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4">
                    <FormikControl
                        control='input'
                        label='Monto Solicitado $'
                        name='requested_amount'
                        onKeyUp={handleAmountChange}
                    />
                    <FormikControl
                        control='input'
                        label='Plazo'
                        name='month_periods'
                    />
                    <FormikControl
                        control='input'
                        label='Tasa de interés %'
                        name='interest_rate'
                    />
                    <FormikControl
                        control='input'
                        label='Comisión de cierre %'
                        name='comission_rate'
                    />
                    {/* <FormikControl
                        control='input'
                        label='Seguro mensual %'
                        name='secure_rate'
                    />
                    <FormikControl
                        control='input'
                        label='Gastos de manejo $'
                        name='handling_amount'
                    /> */}
                    <FormikControl
                        control='select'
                        label='Tipo de Garantía'
                        name='guarantee_type'
                        options={props.dropdownGuaranteeType}
                        onChange={handleGuaranteeTypeChange}
                    />                    
                    <FormikControl
                        control='input'
                        label='Gastos Legales $'
                        name='legal_amount'
                    />
                    <FormikControl
                        control='select'
                        label='Método de cálculo'
                        name='calc_method'
                        options={dropdownCalcMethod}      
                    />                    
                    </dl>
                    }
                </div>


            }
        </div>
    )

}

export default ExpressQuote
