import React, {useEffect,useState} from "react";
import { BrowserRouter as Router, Link } from 'react-router-dom'
//import Moment from 'react-moment';
import moment from 'moment';
import DatosCliente from "./DatosCliente";
import ResumenProductos from "./ResumenProductos";
import Template from "../Template";
import axios from "axios";
import {Bars} from 'react-loader-spinner';
import NumberFormat from 'react-number-format';


const WalletTareas = (props) => {

  const [currentUser, setCurrentUser] = useState([]);
  const [walletData,setWalletData] = useState([]);
  const [tareasData,setTareasData] = useState([]);
  const [loading, setLoading] = useState([]);


  useEffect(() => {
    setLoading(true);
    loadConsulta()
  }, [])

   const loadConsulta = async () => {
    await axios.get(`https://api.sumadata.net/analitico?fechacorte=20211219&promotor=14`).then((res) => {
        setWalletData(res.data);
        console.log(res.data);
        setLoading(false);
    });



  };

    return (
      <>
        {loading ?
          <div className="flex h-screen">
            <div className="m-auto">
              <Bars
                color="#00BFFF"
                height={100}
                width={100}
                timeout={20000} //3 secs
              />
            </div>
          </div>
         :

          <div className="overflow-y-auto">


                <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">

                    <div className="bg-white rounded-lg w-full h-auto py-4 flex flex-row justify-between divide-x divide-solid divide-gray-400">
                        <div className="relative flex-1 flex flex-col gap-2 px-4">
                            <label className="text-gray-800 text-2xl font-semibold tracking-wider">Tareas Programadas</label>
                            <label className="text-green-800 text-4xl font-bold">3</label>

                        </div>
                        <div className="relative flex-1 flex flex-col gap-2 px-4">
                            <label className="text-gray-800 text-2xl font-semibold tracking-wider">Tareas Terminadas</label>
                            <label className="text-green-800 text-4xl font-bold">2</label>

                        </div>
                        <div className="relative flex-1 flex flex-col gap-2 px-4">
                            <label className="text-gray-800 text-2xl font-semibold tracking-wider">Clientes sin Programacion</label>
                            <label className="text-green-800 text-4xl font-bold">3</label>

                        </div>

                    </div>
                </div>





            <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                  Seguimiento Tareas
                </h3>
              </div>

              <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
                <div className="ml-2 mt-2 w-full mr-2">
                  <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            N. Tarea
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            N. Credito
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Fecha Programada
                          </th>

                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Estatus
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Tipo Tarea
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Supervisor
                          </th>




                        </tr>
                      </thead>
                      <tbody>

                      <tr
                      key={'000'}
                      className="bg-red-400 border-b border-gray-200">

                      <td className="px-6 py-3 text-sm leading-5 text-white font-bold text-center">
                        SIN PROGRAMACION
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-white font-bold text-center">
                      {'300000266'}
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-white font-bold text-center">
                      LISANDRO GUERRERO VIOLET
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">

                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">

                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">

                      </td>

                      </tr>



                      <tr
                      key={'000'}
                      className="bg-green-400 border-b border-gray-200">

                      <td className="px-6 py-3 text-sm leading-5 text-white font-bold text-center">
                      CON PROGRAMACION
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-white font-bold text-center">
                      {'300000291'}
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-white font-bold text-center">
                      JOYCELIN MELINA CLARKE DIAZ
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">

                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">

                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">

                      </td>

                      </tr>

                      <tr
                      key={'575'}
                      className="bg-green-100 border-b border-gray-200">

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      {'575'}
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      {'300000291'}
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      2021-11-12
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      TERMINADA
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      COBRANZA TELEFONICA (PREVENTIVA)
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      MILEIBY EDITH FRAGOZA MARQUEZ
                      </td>

                      </tr>

                      <tr
                      key={'578'}
                      className="bg-yellow-100 border-b border-gray-200">

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      {'578'}
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      {'300000291'}
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      2021-11-30
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      PROGRAMADA
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      COBRANZA TELEFONICA (PREVENTIVA)
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      MILEIBY EDITH FRAGOZA MARQUEZ
                      </td>

                      </tr>

                      <tr
                      key={'896'}
                      className="bg-yellow-100 border-b border-gray-200">

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      {'896'}
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      {'300000291'}
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      2021-12-31
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      PROGRAMADA
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      COBRANZA TELEFONICA (PREVENTIVA)
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      MILEIBY EDITH FRAGOZA MARQUEZ
                      </td>

                      </tr>


                      <tr
                      key={'000'}
                      className="bg-green-400 border-b border-gray-200">

                      <td className="px-6 py-3 text-sm leading-5 text-white font-bold text-center">
                      CON PROGRAMACION
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-white font-bold text-center">
                      {'300000487'}
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-white font-bold text-center">
                      ELOIS MILCIADES AROSEMENA HERNANDEZ
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">

                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">

                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">

                      </td>

                      </tr>

                      <tr
                      key={'000'}
                      className="bg-green-100 border-b border-gray-200">

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      {'902'}
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      {'300000487'}
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      2021-11-14
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      TERMINADA
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      COBRANZA TELEFONICA (PREVENTIVA)
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      MILEIBY EDITH FRAGOZA MARQUEZ
                      </td>

                      </tr>

                      <tr
                      key={'000'}
                      className="bg-orange-300 border-b border-gray-200">

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 font-bold text-right">
                      {'903'}
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 font-bold text-right">
                      {'300000487'}
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 font-bold text-right">
                      2021-12-07
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 font-bold text-right">
                      ATRASADA
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 font-bold text-right">
                      COBRANZA VENCIDA
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 font-bold text-right">
                      MILEIBY EDITH FRAGOZA MARQUEZ
                      </td>

                      </tr>

                      <tr
                      key={'000'}
                      className="bg-yellow-100 border-b border-gray-200">

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      {'904'}
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      {'300000487'}
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      2021-12-26
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      PROGRAMADA
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      COBRANZA VENCIDA
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-right">
                      MILEIBY EDITH FRAGOZA MARQUEZ
                      </td>

                      </tr>






                      <tr
                      key={'000'}
                      className="bg-red-400 border-b border-gray-200">

                      <td className="px-6 py-3 text-sm leading-5 text-white font-bold text-center">
                        SIN PROGRAMACION
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-white font-bold text-center">
                      {'300000606'}
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-white font-bold text-center">
                      DELADIER EROTEIDES RAMIREZ NAVARRO
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">

                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">

                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">

                      </td>

                      </tr>

                      <tr
                      key={'000'}
                      className="bg-red-400 border-b border-gray-200">

                      <td className="px-6 py-3 text-sm leading-5 text-white font-bold text-center">
                        SIN PROGRAMACION
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-white font-bold text-center">
                      {'100000234'}
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-white font-bold text-center">
                      FREDDY RIVERO
                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">

                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">

                      </td>

                      <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">

                      </td>

                      </tr>











                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
          </div>
}
      </>
    );

}

export default WalletTareas;
