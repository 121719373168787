import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bars } from 'react-loader-spinner';
import { formatDate } from '../Helpers/Helpers';

const WalletPagos = () => {
  const [walletData, setWalletData] = useState({pagos: []});
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [currentTablePage, setCurrentTablePage] = useState(0);
  const itemsPerPage = 20; // Change according to your needs

  useEffect(() => {
    loadConsulta();
  }, []);

  const loadConsulta = async () => {
    const res = await axios.get(`/api/v1/safi/get_all_pagos.json`);

    const sortedData = [...res.data.pagos].sort((a, b) => new Date(b.pago.created_at) - new Date(a.pago.created_at));

    setWalletData({...res.data, pagos: sortedData});
    setLoading(false);
};

const filteredData = walletData.pagos
.filter((data) =>
  (data.pago.nombreCliente && data.pago.nombreCliente.toLowerCase().includes(search.toLowerCase())) ||
  (data.pago.creditNumber && data.pago.creditNumber.toString().toLowerCase().includes(search.toLowerCase())) ||
  (data.pago.nombreDepositante && data.pago.nombreDepositante.toLowerCase().includes(search.toLowerCase()))
);

const totalPages = Math.ceil(filteredData.length / itemsPerPage);


  const getVisiblePageNumbers = () => {
    const start = Math.max(0, currentTablePage - 5);
    const end = Math.min(totalPages, currentTablePage + 5);
    return Array.from({length: end - start}, (_, i) => i + start);
  };

    const visiblePageNumbers = getVisiblePageNumbers();

    const buttons = visiblePageNumbers.map(i =>
    <button
        key={i}
        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mx-2"
        onClick={() => setCurrentTablePage(i)}
        disabled={currentTablePage === i}
    >
        {i+1}
    </button>
    );


  const handleSearch = (event) => {
    setSearch(event.target.value);
  };



  const tableData = filteredData.slice(
    currentTablePage * itemsPerPage,
    (currentTablePage + 1) * itemsPerPage
  );

  const anterior = () => {
    if (currentTablePage > 0) setCurrentTablePage(currentTablePage - 1);
  };

  const siguiente = () => {
    if ((currentTablePage + 1) * itemsPerPage < filteredData.length)
      setCurrentTablePage(currentTablePage + 1);
  };



  return (
    <>      
      {loading ? 
        <div className="flex h-screen">
          <div className="m-auto">
            <Bars
              color="#00BFFF"
              height={100}
              width={100}
              timeout={20000} //3 secs
            />
          </div>
        </div>
       :
        <div className="overflow-y-auto">
          <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
              <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                Historico de Pagos
              </h3>
            </div>

            <div className="p-5 sm:flex sm:gap-x-2 min-w-full mx-6 mt-6">
              <label className="flex gap-x-2 items-baseline">
                <span className="text-gray-700">Buscar: </span>
                <input
                  type="text" 
                  value={search} 
                  onChange={handleSearch} 
                  placeholder="CreditoID / Nombre Cliente / Nombre Depositante" 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                  style={{ width: 'calc(100% + 400px)' }}

                />
              </label>
            </div>

            <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
              <div className="ml-2 mt-2 w-full mr-2">
                <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                  <table className="min-w-full">
                  <thead>
                        <tr>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Número de Referencia
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Fecha de Creación
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Fecha de Pago
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Tipo de Pago
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            No. de Crédito
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Nombre de Cliente
                          </th>

                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Depositante
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Banco Receptor
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            No. Libreta de Cobro
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Monto Pagado
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Comprobante
                          </th>
                          
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          </th>
                        </tr>
                      </thead>
                    <tbody>
                    {tableData.map((walletConcilia, index) => {
                      return(
<tr
                          key={walletConcilia.pago._id.$oid}
                          className="bg-green border-b border-gray-200"
                        >
                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {walletConcilia.pago.referenciaTransferencia}

                              </td>
                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {formatDate(walletConcilia.pago.created_at)}

                              </td>

                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                              {walletConcilia.pago.fechaTransferencia}
                              </td>

                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                              {walletConcilia.pago.tipoPago}
                              </td>

                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {walletConcilia.pago.creditNumber}
                              </td>

                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                              {walletConcilia.pago.nombreCliente}
                              </td>

                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {walletConcilia.pago.nombreDepositante}
                              </td>

                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {walletConcilia.pago.bancoReceptor}

                              </td>

                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {walletConcilia.pago.libretaCobro}

                              </td>

                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                              }).format(walletConcilia.pago.amount)}

                              </td>
                              
                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {walletConcilia.pago.attachments.map((attachment, index) => {
                                return(
                                  <div key={index}>
                                    <a className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" href={attachment} target="_blank">{`Anexo`}</a>
                                  </div>
                                )
                              })}
                              </td>
                        </tr>
                      );
                    })}
                    </tbody>
                  </table>
                </div>

                    <div className="flex justify-center mt-4">
                    <button
                        className={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l ${
                        currentTablePage === 0 ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        onClick={anterior}
                        disabled={currentTablePage === 0}
                    >
                        Anterior
                    </button>
                    {buttons}
                    <button
                        className={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r ${
                        currentTablePage + 1 === totalPages ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        onClick={siguiente}
                        disabled={currentTablePage + 1 === totalPages}
                    >
                        Siguiente
                    </button>
                    </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default WalletPagos;
