
export const calculateFactors = (finalAge, factors) => {
  let factorSeguroDesgravamen;
  let factorProyeccionCuotas;
  let factorComisionSeguroDesgravamen;

  if (finalAge >= 18 && finalAge <= 45) {
    factorSeguroDesgravamen = 0.21;
    factorProyeccionCuotas = 0.541;
    factorComisionSeguroDesgravamen = 1.29;
  } else if (finalAge >= 46 && finalAge <= 55) {
    factorSeguroDesgravamen = 0.37;
    factorProyeccionCuotas = 0.58;
    factorComisionSeguroDesgravamen = 1.13;
  } else if (finalAge >= 56 && finalAge <= 64) {
    factorSeguroDesgravamen = 0.94;
    factorProyeccionCuotas = 0.579;
    factorComisionSeguroDesgravamen = 0.56;
  } else if (finalAge >= 65 && finalAge <= 70) {
    factorSeguroDesgravamen = 1.21;
    factorProyeccionCuotas = 0.568;
    factorComisionSeguroDesgravamen = 0.29;
  } else if (finalAge > 70) {
    factorSeguroDesgravamen = 1.90;
    factorProyeccionCuotas = 0.568;
    factorComisionSeguroDesgravamen = 0.30;
  }

  return {
    factorSeguroDesgravamen,
    factorProyeccionCuotas,
    factorComisionSeguroDesgravamen
  };
};