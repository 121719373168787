import React, {useEffect,useState} from "react";
import { BrowserRouter as Router, Link } from 'react-router-dom'
//import Moment from 'react-moment';
import moment from 'moment';
import DatosCliente from "./DatosCliente";
import ResumenProductos from "./ResumenProductos";
import Template from "../Template";
import axios from "axios";
import {Bars} from 'react-loader-spinner';

const WalletCotizaciones = (props) => {

  const [currentUser, setCurrentUser] = useState([]);
  const [walletData,setWalletData] = useState([]);
  const [loading, setLoading] = useState([]);
  //const [estatus, setEstatus] = useState(props.Estatus);

  useEffect(() => {
    setLoading(true);
    loadConsulta()
  }, [])

  const handleClick = (simulacionID) => {
    //Produccion
    //window.open('https://www.sumafinanciera.com/landing/print.php?_id='+simulacionID);
    //Desarrollo
    window.open('https://www.sumafinanciera.com/landing/print.php?_id='+simulacionID);
  };


   const loadConsulta = async () => {
    await axios.get(`/api/v1/safi/get_all_simulations.json`).then((res) => {
        setWalletData(res.data);
        //console.log(res.data)
        setLoading(false);
    });
  };

  const updateCotizacion = (cotizaID) => {
    const token = document.querySelector('[name=csrf-token]').content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token
    //alert(simulacionID.$oid)compartida
    axios.post(`/api/v1/safi/update_simulation`, {...{cotizaID}, ...{Estatus: 'Cotizacion Compartida'}} )
      .then( resp => {
        //console.log('response',resp);
        navigator.clipboard.writeText('https://www.sumafinanciera.com/landing/index.php?_id='+cotizaID)


        loadConsulta()



        //const initialState = [walletData]
        //let index = walletData.findIndex()

        //setWalletData([{...{initialState}, ...{Estatus: 'Cotizacion Compartida'}}])
        //const _state = {...walletData}

        //console.log(_state)

        //setWalletData([{..._state, Estatus: 'Cotizacion Compartida'}]);


        //setSimulacionID(resp.data.result._id.$oid)

      })
      .catch( data => console.log('error', data))
  }

    return (
      <>
        {loading ?
          <div className="flex h-screen">
            <div className="m-auto">
              <Bars
                color="#00BFFF"
                height={100}
                width={100}
                timeout={20000} //3 secs
              />
            </div>
          </div>
         :

          <div className="overflow-y-auto">





            <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                  Mis cotizaciones
                </h3>
              </div>

              <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
                <div className="ml-2 mt-2 w-full mr-2">
                  <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            N. Cotización
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Fecha
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Monto
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Estatus
                          </th>

                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Cliente
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Enlace
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Cotización
                          </th>


                        </tr>
                      </thead>
                      <tbody>
                      {walletData.simulations.map(walletCotiza => {

                        //console.log(Object.hasOwn(walletCotiza, 'client'))

                       //console.log(typeof(walletCotiza.client.NombreCompleto))

                       var clienteName = ''
                       var simulationID = ''


                       if(Object.hasOwn(walletCotiza, 'client')){

                         clienteName = walletCotiza.client.NombreCompleto

                       }else{

                         clienteName = walletCotiza.simulation.allValues['47']


                       }

                       simulationID = walletCotiza.simulation._id.$oid.substr(walletCotiza.simulation._id.$oid.length - 5)


                       // if (walletCotiza.simulation.Estatus === 'Cotización Creada' || walletCotiza.simulation.Estatus === 'Cotización Compartida' || walletCotiza.simulation.Estatus === 'Cotización Aceptada' || walletCotiza.simulation.Estatus === 'Cotización Rechazada' || walletCotiza.simulation.Estatus === 'Cotizacion Creada' || walletCotiza.simulation.Estatus === 'Cotizacion Compartida' || walletCotiza.simulation.Estatus === 'Cotizacion Aceptada' || walletCotiza.simulation.Estatus === 'Aceptada' || walletCotiza.simulation.Estatus === 'Cotizacion Rechazada' && walletCotiza.simulation.ClienteID != ''){
                        if (walletCotiza.simulation.allValues['16'] === '140'){


                        return(

                          
                              <tr
                              key={walletCotiza.simulation._id.$oid}
                              className="bg-green border-b border-gray-200"

                            >
                              <td className="px-6 py-3  text-xs leading-5 text-gray-500 text-center">
                              {simulationID}

                              </td>

                              <td className={"px-6 py-3 text-xs leading-5  text-center "}>
                              {moment(walletCotiza.simulation.created_at).format('YYYY-MM-DD')}
                              </td>

                              <td className="px-6 py-3  text-xs leading-5 text-gray-500 text-center">
                              USD. {parseFloat(walletCotiza.simulation.Monto).toFixed(2)}
                              </td>

                              <td className="max-w-xs px-6 py-3 text-xs leading-5 text-gray-500 text-center">
                              <span className={walletCotiza.simulation.Estatus === 'Cotizacion Aceptada' ? 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' : walletCotiza.simulation.Estatus === 'Cotizacion Compartida' ? 'bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded' : walletCotiza.simulation.Estatus === 'Cotizacion Rechazada' ? 'bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded' : 'bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded'}>{walletCotiza.simulation.Estatus}</span>
                              </td>

                              <td className="px-6 py-3  text-xs leading-5 text-gray-500 text-center">
                              {clienteName}

                              </td>

                              <td className="max-w-xs px-6 py-3 text-xs leading-5 text-gray-500 text-center">
                              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => updateCotizacion(walletCotiza.simulation._id.$oid)}>Copiar Enlace para Cliente</button>
                              </td>

                              <td className="max-w-xs px-6 py-3 text-xs leading-5 text-gray-500 text-center">


                              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => handleClick(walletCotiza.simulation._id.$oid)}>Ver Cotización</button>
                              </td>


                              

                              
                              


                            </tr>

                        )
                        }

                        })
                      
                        }
                            

                          

                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
          </div>
}
      </>
    );

}

export default WalletCotizaciones;
