import React from "react";
import { v4 as uuid_v4 } from "uuid";

const Facturas = (props) => {
  console.log(`FACTURAS`, props.facturas);

  return (
    <div>
      <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
        <div className="flex-grow mx-6 mt-1 bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="text-left text-xl leading-6 font-small text-gray-900">
              Facturas emitidas
            </h3>
          </div>
          <div className="pl-8 pr-8 pb-5 text-grey-darkest">
            <div className="flex flex-col">
              <div className="py-4 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                  <table className="min-w-full">
                    <thead>
                      <tr>
                        <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Crédito
                        </th>
                        <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Código
                        </th>
                        <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          CUFE
                        </th>
                        <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          QR
                        </th>
                        <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Fecha Creacion
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.facturas.map((factura) => (
                        <tr key={factura.id.$oid}>
                          <td className="px-4 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                            {factura.CreditoID}
                          </td>
                          <td className="px-4 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                            {factura.codigo}
                          </td>
                          <td className="px-4 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                            {factura.cufe}
                          </td>
                          <td className="px-4 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                            <a href={factura.qr} target="_blank" rel="noopener noreferrer">
                              Ver QR
                            </a>
                          </td>
                          <td className="px-4 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                            {new Date(factura.created_at).toLocaleDateString()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facturas;
