import React from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import Moment from 'moment';

const TipoCreditoDictionary = {"N": "Nuevo", "R":"Reestructura", "O":"Renovación", "M":"Crédito Modificado"}

const Contract = (props) => (
    <tr>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="flex items-center">
                <div className="flex-shrink-0 h-10 w-10">
                    <div className="h-8 w-8 bg-green-400 text-white pl-1 pt-1 font-extrabold rounded-full">
                            {props.prospect.initials}
                    </div>
                </div>
                <div className="ml-4">
                <Link to={`prospects/` + props.prospect.ProspectoID} className="text-indigo-600 hover:text-indigo-900">
                <div className="text-sm leading-5 font-medium">{props.prospect.zProspectoID}</div>
                </Link>
                <div className="text-sm leading-5 text-gray-500">{props.prospect.NombreCompleto}</div>
                </div>
            </div>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="text-sm leading-5 text-gray-900">{Moment(props.prospect.created_at).format('YYYY-MM-DD')}</div>
            <div className="text-sm leading-5 text-gray-500"></div>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
            <div className="text-sm leading-5 text-gray-900">{props.prospect.contacts && props.prospect.contacts[props.prospect.contacts.length-1] && Moment(props.prospect.contacts[props.prospect.contacts.length-1].created_at).format('YYYY-MM-DD')}</div>
            <div className="text-sm leading-5 text-gray-500">{props.prospect.contacts && props.prospect.contacts[props.prospect.contacts.length-1] && props.prospect.contacts[props.prospect.contacts.length-1].contact_type}</div>
        </td>
    </tr>
);

// const Menu = () => (

// )

// const Content = () => {
//     const handleChanged = () => {
//         console.log('Changed ...')
//       }  
//     return (

//     )  
    
// }

// const BackLink = () => (
// )

export default Contract;
