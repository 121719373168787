import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
// import { BrowserRouter as Router, Link } from 'react-router-dom'
// import axios from 'axios'
// import {Bars} from 'react-loader-spinner'
// // import Template from '../Template'

// import Moment from 'moment';
// import { Formik, Field, FieldArray, Form } from 'formik';
// import JSONPretty from 'react-json-prettify';
// import * as Yup from 'yup'
// import FormikControl from '../forms/FormikControl'
// import FieldValue from './FieldValue';




const docsHash = {
    "All": [
        ["Simulación Plan de Pagos", "60cfe24ead14e16845890a23"],
        ["Simulación Plan de Pagos (XLS)", "60cfe24ead14e16845890a23",'xlsx']
    ]
}

const Simulation = (props) => {
    const docs = () => {
    }

    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                Simulación de Plan de Pagos
                </h3>
            </div>
            <div className="px-4 py-5 sm:px-6">
                    <div className="text-sm leading-5 font-medium text-gray-500">
                    Imprimir Documentos
                    </div>
                    <DocButtons docs={docsHash["All"]} contract={props.contract} currentUser={props.currentUser}/>
            </div>
        </div>
    )
}

const DocButtons = (props) => {
    const buttons = props.docs == undefined ? '' :
        props.docs.map( (doc, index) => { 
            if(doc[0] != "Contrato de línea" || (doc[0] == "Contrato de línea" && props.currentUser.is_admin))
                // if(doc[0] == "Plan de Pagos@@@")
                //     return (
                //     )
                // else
                var format = doc[2] == undefined ? '' : `.${doc[2]}`
                return (
                    <div className="mt-2 mr-2 inline-flex" key={index}>
                        <a href={`/gen_docs/${doc[1]}/print${format}?debug=${props.contract.SolicitudCreditoID}`} target="_blank">
                        <button type="button" className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                        {doc[0]}
                        </button>                
                        </a>
                    </div>
                )
            // else
            //     return ();
        });

    return (
        <div>
            {buttons}
        </div>

    );
}

export default Simulation;
