import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="calc-margin"
export default class extends Controller {
  static targets = ["from", "to", "profit", "profitRatio"]

  calc() {
    this.profitTarget.value = parseFloat(this.toTarget.value || 0) - parseFloat(this.fromTarget.value|| 0)
    // this.profitRatioTarget.value = Number( (parseFloat(this.toTarget.value || 0) - parseFloat(this.fromTarget.value|| 0)) / parseFloat(this.fromTarget.value|| 0) * 100 ).toFixed(2)
    this.profitRatioTarget.value = (( 1.0 - parseFloat(this.fromTarget.value|| 0) / parseFloat(this.toTarget.value|| 0) ) * 100 ).toFixed(2)
  }

  ignore(e) {
    e.preventDefault();
  }
}
