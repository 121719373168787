import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';


const Guarantee = (props) => {
    Moment.locale('en');

    return (
        <tr className={props.index % 2 == 0 ? "bg-white" : "bg-gray-50"}>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
            {props.garantia.TipoGarantia}        
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD"
            }).format(props.garantia.agMontoAsignado)}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            {Moment(props.garantia.agFechaRegistro).format('YYYY-MM-DD')}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            {Number((props.garantia.agMontoAsignado / props.contract.MontoSolici).toFixed(2))}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            {props.garantia.GarantiaID}: {props.garantia.ClasifGarantia} / {props.garantia.Observaciones}
            </td>
        </tr>
    )
}

const GuaranteeDetail = (props) => {
    Moment.locale('en');

    return (
        <tr className={props.index % 2 == 0 ? "bg-white" : "bg-gray-50"}>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
            {props.garantia[0]}        
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            {props.garantia[1]}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            {props.garantia[2]}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            {props.garantia[3]}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            {props.garantia[4]}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            {props.garantia[5]}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            {props.garantia[6]}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            {props.garantia[7]}
            </td>
        </tr>
    )
}

const Guarantees = (props) => {
    const grid = props.contract.Garantias.map( (garantia, index) => { 
        // console.log('data', contracts);
        return (
            <Guarantee garantia={garantia} contract={props.contract} key={garantia.GarantiaID} index={index}/>
        );
    });
    const grid2 = props.contract.detailGuarantees.map( (garantia, index) => { 
        // console.log('data', contracts);
        return (
            <GuaranteeDetail garantia={garantia} contract={props.contract} key={index} index={index}/>
        );
    });
    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                Garantías
                </h3>
            </div>
            <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-col">
                <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                        <thead>
                        <tr>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Tipo Garantía
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Monto Garantía
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Fecha Avaluo
                            </th>
                            <th width="50" className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Relación Garantía Deuda
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Descripción
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {grid}    
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>


            </div>
            <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-col">
                <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                        <thead>
                        <tr>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            TipoBien
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Marca
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Dimensiones
                            </th>
                            <th width="50" className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Serie
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Modelo
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Color
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Año
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Monto
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {grid2}
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>


            </div>
        </div>

    )
}

export default Guarantees
