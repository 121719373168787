import { Controller } from "@hotwired/stimulus"
import React from 'react';
import ReactDOM from "react-dom"
import App from '../components/App'


// Connects to data-controller="react"
export default class extends Controller {
  static values = {user: Object, component: String};

  connect() {

    ReactDOM.render(
      <App currentUser={this.userValue}/>,
      this.element
    )
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.element)
  }

}
