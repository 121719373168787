import React, {useEffect,useState,useRef,useCallback} from "react";
import { BrowserRouter as Router, Link } from 'react-router-dom'
import Template from "../Template";
import axios from "axios";
import {Bars} from 'react-loader-spinner';
import { formatNumber } from '../Helpers/Helpers'
import Modal from 'react-modal';
import ConsultaCredito from '../ConsultaCredito/ConsultaCredito';



const Wallet = (props) => {

  const [walletData,setWalletData] = useState([]);
  const [loading, setLoading] = useState([]);
  const {currentUser} = props;
  const [totalMontoAdeudado, setTotalMontoAdeudado] = useState(0);
  const [totalMontoCapital, setTotalMontoCapital] = useState(0);
  const [modalIsOpen,setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [creditsAbove30, setCreditsAbove30] = useState(0);
  const [creditsTotal, setCreditsTotal] = useState(0);
  const [creditsBelow30, setCreditsBelow30] = useState(0);
  const [filterType, setFilterType] = useState("all");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isChiefCollector, setIsChiefCollector] = useState(false);
  const [selectedCreditoId, setSelectedCreditoId] = useState(null);
  const [selectedClienteId, setSelectedClienteId] = useState(null);
  const [totalAbove30Capital, setTotalAbove30Capital] = useState(0);
  const [totalBelow30Capital, setTotalBelow30Capital] = useState(0);

  function openModal(creditoId, clienteId) {
    setIsOpen(true);
    setSelectedCreditoId(creditoId);
    setSelectedClienteId(clienteId);

  }
  
  function closeModal(){
    setIsOpen(false);
    setSelectedCreditoId(null);
    setSelectedClienteId(null);
  }

  const handleFilterClick = (newFilterType) => {
    setLoading(true);
    setFilterType(newFilterType);
  
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const customStyles = {
    overlay: {zIndex: 1000}
  };


  useEffect(() => {
    setLoading(true);
    loadConsulta();
  }, [])

  useEffect(() => {

    let above30Amount = 0;
    let below30Amount = 0;
    let totalAbove30Capital = 0;
    let totalBelow30Capital = 0;

    walletData.forEach((credit) => {
      const creditCapital = parseFloat(credit.RealMontoTotalCapVigente);
  
      if (credit.RealDiasAtraso > 30) {
        above30Amount += 1;
        totalAbove30Capital += creditCapital;
      } else {
        below30Amount += 1;
        totalBelow30Capital += creditCapital;
      }
    });

    const above30 = walletData.reduce((acc, curr) => acc + (curr.RealDiasAtraso > 30 ? 1 : 0), 0);
    const below30 = walletData.reduce((acc, curr) => acc + (curr.RealDiasAtraso <= 30 ? 1 : 0), 0);
  
    setCreditsAbove30(above30);
    setCreditsBelow30(below30);
    setCreditsTotal(above30+below30);
    setTotalAbove30Capital(totalAbove30Capital);
    setTotalBelow30Capital(totalBelow30Capital);

    const total = walletData.reduce((acc, curr) => acc + parseFloat(curr.RealMontoTotalAdeudado), 0);
    setTotalMontoAdeudado(total);

    const totalCapital = walletData.reduce((acc, curr) => acc + parseFloat(curr.RealMontoTotalCapVigente), 0);
    setTotalMontoCapital(totalCapital);
  }, [walletData]);



  const loadConsulta = async () => {

    const adminCheck = currentUser.RolID === 1;
    const chiefCollectorCheck = currentUser.RolID === 8;
    
    // Set the respective roles in the state.
    setIsAdmin(adminCheck);
    setIsChiefCollector(chiefCollectorCheck);
    
    // Both admin and chiefCollector use the same API call, but we differentiate them here.
    const apiUrl = `/api/v1/creditos.json${(adminCheck || chiefCollectorCheck) ? "" : `?user=${currentUser.UsuarioID}`}`;
  
    let res;
  
    try {
      res = await axios.get(apiUrl);
    } catch (error) {
      console.error(`Error fetching data from ${apiUrl}:`, error);
    }
    const mainData = res.data;
  
    const promises = mainData.map(async (walletCredits) => {
      return { ...walletCredits};
    });
  
    const combinedData = await Promise.all(promises);
  
    setWalletData(combinedData);
    setLoading(false);
    
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const filteredData = walletData
  .filter((walletCredits) => {
    const filterCondition =
      filterType === "above30"
        ? walletCredits.RealDiasAtraso > 30
        : filterType === "below30"
        ? walletCredits.RealDiasAtraso <= 30
        : true;

    return (
      filterCondition &&
      (walletCredits.CreditoID.toString().includes(searchQuery) ||
        walletCredits.NombreCompleto.toLowerCase().includes(
          searchQuery.toLowerCase()
        ))
    );
  })
  .sort((a, b) => {
    if (sortOrder === "asc") {
      return a.RealMontoTotalAdeudado - b.RealMontoTotalAdeudado;
    } else {
      return b.RealMontoTotalAdeudado - a.RealMontoTotalAdeudado;
    }
  });

  

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

    return (
      <>
      <Modal
        style={customStyles}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Consulta de Crédito"
        >
        
        <ConsultaCredito clienteID={parseInt(selectedClienteId, 10)} creditoID={parseInt(selectedCreditoId, 10)} closeModal={closeModal}/>


      </Modal>
      
        {loading ?
          <div className="flex h-screen">
            <div className="m-auto">
              <Bars
                color="#00BFFF"
                height={100}
                width={100}
                timeout={20000} //3 secs
              />
            </div>
          </div>
         :

          <div className="overflow-y-auto">
                <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="bg-white rounded-lg w-full h-auto py-4 flex flex-row justify-between divide-x divide-solid divide-gray-400">
                        <div className="relative flex-1 flex flex-col gap-2 px-4" onClick={() => handleFilterClick("")}>
                            <label className="text-gray-800 text-base font-semibold tracking-wider">Total Cartera</label>
                            <label className="text-green-800 text-2xl font-bold">
                            ${formatNumber(totalMontoCapital)} ({formatNumber(creditsTotal,0)})
                              </label>
                        </div>
                        <div className="relative flex-1 flex flex-col gap-2 px-4 cursor-pointer" onClick={() => handleFilterClick("above30")}>
                            <label className="text-gray-800 text-base font-semibold tracking-wider " >Creditos {">"} 30 días</label>
                            <label className="text-green-800 text-2xl font-bold">${formatNumber(totalAbove30Capital)} ({formatNumber(creditsAbove30,0)}) </label> 
                        </div>
                        <div className="relative flex-1 flex flex-col gap-2 px-4 cursor-pointer" onClick={() => handleFilterClick("below30")}>
                            <label className="text-gray-800 text-base font-semibold tracking-wider " >Créditos {"<"} 30 días</label>
                            <label className="text-green-800 text-2xl font-bold">${formatNumber(totalBelow30Capital)} ({formatNumber(creditsBelow30,0)})</label>
                        </div>
                    </div>
                </div>

            <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                  Mi cartera
                </h3>
              </div>
              <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
                <div className="ml-2 mt-2 w-full mr-2">
                  <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                  <div className="mx-6 mt-6">
                    <input
                      type="text"
                      placeholder="Buscar por Crédito o Nombre de Cliente"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            N. Crédito
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Dias de Atraso
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Nombre de Cliente
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Cuotas Atrasadas
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Saldo Capital Vigente
                          </th>

                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Saldo Capital Atrasado
                          </th>

                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={toggleSortOrder}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
  <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
    <path d="M3 4l3-3 3 3"></path>
    <path d="M3 8l3 3 3-3"></path>
  </g>
</svg>Monto Total Adeudado
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Fecha/Monto Ultimo Pago
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                          {paginatedData.map((walletCredits, index) => {
                            return(
                              <tr
                              key={index}
                              className="bg-white border-b border-gray-200"
                            >
                              <td className="px-4 py-2 font-semibold cursor-pointer" onClick={() => openModal(walletCredits.CreditoID, walletCredits.ClienteID)}><span className="bg-orange-300 hover:bg-orange-400 text-orange-800 font-bold w-36 py-2 px-4 rounded inline-flex items-center"><svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M19.129,18.164l-4.518-4.52c1.152-1.373,1.852-3.143,1.852-5.077c0-4.361-3.535-7.896-7.896-7.896
                                  c-4.361,0-7.896,3.535-7.896,7.896s3.535,7.896,7.896,7.896c1.934,0,3.705-0.698,5.078-1.853l4.52,4.519
                                  c0.266,0.268,0.699,0.268,0.965,0C19.396,18.863,19.396,18.431,19.129,18.164z M8.567,15.028c-3.568,0-6.461-2.893-6.461-6.461
                                  s2.893-6.461,6.461-6.461c3.568,0,6.46,2.893,6.46,6.461S12.135,15.028,8.567,15.028z"/></svg>{walletCredits.CreditoID}</span></td>
                              

                              <td className={"px-6 py-3 text-sm leading-5  text-center max-w-xs"}>
                              {
                                walletCredits.RealDiasAtraso > 30 ?
                                  <span className="text-sm font-medium bg-red-100 py-1 px-2 rounded text-red-500 align-middle">{`Mora > 30 días`} ({walletCredits.RealDiasAtraso} días)</span>
                                :
                                  <span className="text-sm font-medium bg-green-100 py-1 px-2 rounded text-green-500 align-middle">Mora 30 días o menos ({walletCredits.RealDiasAtraso ?? 0} días)</span>
                              }
                              </td>

                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {walletCredits.NombreCompleto}
                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">
                              {walletCredits.NoCuotasAtraso}
                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">
                              {formatNumber(walletCredits.RealSalCapVigente)}
                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">
                              {formatNumber(walletCredits.RealSalCapAtrasado)}
                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">
                              {formatNumber(walletCredits.RealMontoTotalAdeudado)}
                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">
                                <p>Sin pago</p>
                              </td>

                            </tr>
                            )
                          })
                          }
                      </tbody>
                    </table>
                  </div>
                  <div className="flex justify-center mt-4">
                    <button
                      className={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l ${
                        currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                      disabled={currentPage === 1}
                    >
                      Anterior
                    </button>
                    <span className="mx-4">
                      Página {currentPage} de {Math.ceil(walletData.length / itemsPerPage)}
                    </span>
                    <button
                      className={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r ${
                        currentPage === Math.ceil(walletData.length / itemsPerPage)
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`}
                      onClick={() =>
                        handlePageChange(
                          Math.min(currentPage + 1, Math.ceil(walletData.length / itemsPerPage))
                        )
                      }
                      disabled={currentPage === Math.ceil(walletData.length / itemsPerPage)}
                    >
                      Siguiente
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
}
      </>
    );

}

export default Wallet;