import React, { useEffect, useRef, useState } from 'react';
import { useField, useFormikContext } from 'formik';

function SelectComplete({ name, options, ...props }) {
  const [field, meta, helpers] = useField(name);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [showOptions, setShowOptions] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [lastValidValue, setLastValidValue] = useState('');
  const refContainer = useRef(null);
  const defaultOption = "12 Meses";

  useEffect(() => {
    setFilteredOptions(options);
    if (!field.value) {
      setFieldValue(name, defaultOption);
      setInputValue(defaultOption);
      setLastValidValue(defaultOption);
    } else {
      setInputValue(field.value);
      setLastValidValue(field.value);
    }
  }, [options, field.value, setFieldValue, name, defaultOption]);

  const handleChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
    const newFilteredOptions = options.filter(option =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(newFilteredOptions);
    setShowOptions(true);
  };

  const handleBlur = () => {
    setShowOptions(false);
    if (!options.includes(inputValue)) {
      setFieldValue(name, lastValidValue);
      setInputValue(lastValidValue);
    }
    setFieldTouched(name, true);
  };

  const handleFocus = () => {
    setShowOptions(true);
  };

  const handleSelect = (selectedOption) => {
    setFieldValue(name, selectedOption);
    setInputValue(selectedOption);
    setLastValidValue(selectedOption);
    setShowOptions(false);
  };

  return (
    <div className="mb-10 sm:col-span-1" ref={refContainer}>
      <label htmlFor={name} className="block text-sm font-medium leading-5 text-gray-700">
        {props.label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          id={name}
          type="text"
          {...props}
          className="border-gray-300 rounded-md shadow-sm w-full form-input block sm:text-sm sm:leading-5"
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
        {showOptions && (
          <ul className="z-50 absolute w-full bg-white rounded-lg shadow-lg select-none">
            {filteredOptions.map((option, index) => (
              <li
                key={option}
                className={`px-4 py-2 hover:bg-gray-100 ${
                  index === 0 ? 'rounded-t-lg' : index === filteredOptions.length - 1 ? 'rounded-b-lg' : ''
                }`}
                onMouseDown={() => handleSelect(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
        {meta.touched && meta.error ? (
          <div className="text-red-500 text-sm">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
}

export default SelectComplete;
