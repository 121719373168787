import React, {useEffect,useState,useRef} from "react";
import NumberFormat from 'react-number-format';
import { v4 as uuid_v4 } from "uuid";
import { useReactToPrint } from "react-to-print";


import {Bars} from 'react-loader-spinner';
import axios from 'axios';
import Moment from 'moment';

const UltimosPagosSFCore = (props) => {


  return(
    <div>
    {props.creditsData.map((credit, index) => {
      
            const creditoPago = props.pagosSFCore.pagos?.map(
              
              (pago) => {

              
              if(Number(credit.CreditoID) === Number(pago.pago.creditNumber)){

                //console.log(pago)

              return (
                
                <tr
                  key={uuid_v4()}
                  className="bg-white border-b border-gray-200"
                >
                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {pago.pago.creditNumber}
                  </td>

                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {pago.pago.amount}
                  </td>

                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    
                    {pago.pago.nombreCliente}
                  </td>

                  <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {pago.pago.fechaTransferencia}
                  </td>

                  {/* <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500"> */}
                  <td className='px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500'>

                  <button className={
                    pago.pago.rechazado === 'true'
                      ? 'bg-red-500 hover:bg-red-700 text-black font-bold py-2 px-4 rounded'
                      : pago.pago.conciliado === 'true'
                      ? 'bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded'
                      : 'bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded'
                  }>
                    {pago.pago.rechazado === 'true'
                      ? 'Pago Rechazado'
                      : pago.pago.conciliado === 'true'
                      ? 'Pago conciliado'
                      : 'Pago no conciliado'}
                  </button>
                  </td>
                </tr>
              )

            }
            });

      return(
    <div
          key={uuid_v4()}
          className="px-7 py-5 flex flex-wrap -mx-5 mb-2"
        >

          <div className="flex-grow mx-6 mt-1 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
              <h3 className="text-left text-xl leading-6 font-small text-gray-900">
                Ultimos pagos recibidos EN SFCore (al momento):{" "}
                <span className="text-orange-600">{credit.CreditoID}</span>
              </h3>
            </div>
                      <div className="pl-8 pr-8 pb-5 text-grey-darkest">
                        <div className="flex flex-col">
                          <div className=" py-4 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                            <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="min-w-full">
                                <thead>
                                  <tr>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Crédito
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Monto Pago
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Nombre Cliente
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Fecha Transferencia
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Conciliado
                                    </th>
                                    
                                  </tr>
                                </thead>
                                <tbody>{creditoPago}</tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
              
          </div>

        </div>
      
      )
              })}
  </div>
  )


}

export default UltimosPagosSFCore;