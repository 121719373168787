import React from "react";
import NumberFormat from "react-number-format";

const PagosRows = ({ pago }) => {
    
    return(
    <tr
        key={pago.NumTransaccion + pago.Fecha + pago.ReferenciaMov}
        className="bg-white border-b border-gray-200"
    >
        <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {pago.Fecha}
        </td>

        <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
        <NumberFormat value={pago.CantidadMov} displayType={'text'} thousandSeparator={true} prefix={'$'} />

        </td>

        <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {pago.DescripcionMov}

        </td>

        <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {pago.ReferenciaMov}
        </td>
    </tr>
          
    )

};

export default PagosRows;