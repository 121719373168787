import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';


const DocRecord = (props) => {
    Moment.locale('en');

    return (
        <tr className={props.index % 2 == 0 ? "bg-white" : "bg-gray-50"}>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
            {props.doc_log.document_name}        
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            {props.doc_log.user_name}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
            {Moment(props.doc_log.created_at).format('YYYY-MM-DD')}
            </td>
        </tr>
    )
}


const DocLogs = (props) => {
    console.log(props.contract);
    const grid = props.contract.doc_logs.map( (doc_log, index) => { 
        // console.log('data', contracts);
        return (
            <DocRecord doc_log={doc_log} contract={props.contract} key={doc_log._id} index={index}/>
        );
    });
    return (
        <>
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                Historial de Impresión
                </h3>
            </div>
            <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-col">
                <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                        <thead>
                        <tr>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Documento
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Usuario
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Fecha
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {grid}    
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>


            </div>
        </>

    )
}

export default DocLogs
