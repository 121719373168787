
import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';
import FieldValue from './FieldValue'

const Address = (props) => {
    const [hiddenPanel, setHiddenPanel] = useState(false);

    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="m-4 inline-flex float-right">
                <span className="ml-2 rounded-md shadow-sm ">
                    <a onClick={() => props.setAddressEdit(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>                           
                    </a>
                </span>
            </div>        
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="flex text-lg leading-6 font-medium text-gray-900">   
                <a onClick={() => setHiddenPanel(!hiddenPanel)}>       
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor" data-action="click->toggler#toggle">
                {hiddenPanel
                    ? <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" data-toggler-target="selectorIcon" />
                    : <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" data-toggler-target="selectorIcon" />
                }
                </svg>
                </a>
                Direcciones
                </h3>
            </div>
            <div className="px-4 py-5 sm:px-6">
                {!hiddenPanel && props.prospect.addresses && props.prospect.addresses.map( (address, index) => (
                    <div>
                    <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4">
                    <FieldValue label={"Provincia"}>{address.Estado}</FieldValue>
                    <FieldValue label={"Distrito"}>{address.Municipio}</FieldValue>
                    <FieldValue label={"Corregimiento"}>{address.Localidad}</FieldValue>
                    <FieldValue label={"Barriada"}>{address.Colonia}</FieldValue>
                    <FieldValue label={"Calle / No"}>{address.Calle}</FieldValue>
                    <FieldValue label={"Edificio / Casa / Número"}>{address.NumeroCasa}</FieldValue>
                    <FieldValue label={"Rural / Urbano"}>{address.TipoZona}</FieldValue>
                    <FieldValue label={"Waze"}>{address.URLlocation}</FieldValue>
                    <FieldValue label={"Coordenada Latitud"}>{address.Latitud}</FieldValue>
                    <FieldValue label={"Coordenada Longitud"}>{address.Longitud}</FieldValue>
                    </dl>
                    <hr className="my-5"/>
                    </div>
                ))}
                { (!hiddenPanel && !props.prospect.addresses || props.prospect.addresses.length == 0) &&
                    <div className="mb-4 px-4 py-5 sm:px-6">
                        <span className="m-4 self-center text-red-500">
                        Debe registrar al menos una dirección para el prospecto
                        </span>
                    </div>   
                }

            </div>
        </div>
    )
}

export default Address
