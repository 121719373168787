import React from 'react'
import { 
  LineChart, 
  PieChart,
  ColumnChart,
  BarChart,
  AreaChart,
  ScatterChart,
  // GeoChart,
  // Timeline,
} from 'react-chartkick'
import 'chartkick/chart.js'

const Chart = (props) => {

    return (
      <div className={`mx-5 my-10 ${props.colSpan ? `col-span-${props.colSpan}` : ''}`}>
        <h1 className="mb-1 text-lg leading-6 font-medium text-gray-900">{props.title}</h1>
        < ChartType {...props} />
      </div>
    )
};

const Stat = (props) => (
  <div className="bg-gray-100 overflow-hidden shadow rounded-lg">
      <div className="px-2 pt-1 pb-1 sm:p-6">
          <dl>
          <dt className="text-xs leading-5 font-medium text-gray-500 truncate">
              {props.name}
          </dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div className="inline-flex items-baseline px-3 py-2 rounded-full text-2xl font-medium leading-5 bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                  {props.number}
              </div>
          </dd>        
          </dl>
      </div>
      {/* <div className={props.selected ? "bg-gray-300 px-2 py-2 sm:px-6" : "bg-gray-200 px-2 py-2 sm:px-6"}>
          <div className="text-sm leading-5">
          <a href={props.path} className="font-medium text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150">
              Ver
          </a>
          </div>
      </div>     */}
  </div>
)

const ProspectStatus = (props) => {

    return (
      <div className={`mx-5 my-10 ${props.colSpan ? `col-span-${props.colSpan}` : ''}`}>
        <h1 className="mb-1 text-lg leading-6 font-medium text-gray-900">{props.title}</h1>
        <div className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-6">
        <Stat name="Registrados" number={props.status['in_registration'] || 0} path="/prospects?state=in_registration" selected={props.state=="in_registration"} />
        <Stat name="Gestión de APC" number={props.status['in_apc'] || 0} path="/prospects?state=in_apc" selected={props.state=="in_apc"} />
        <Stat name="Buena APC" number={props.status['in_apc_ok'] || 0} path="/prospects?state=in_apc_ok" selected={props.state=="in_apc_ok"} />
        <Stat name="Gestión de Documentos" number={props.status['in_doc'] || 0} path="/prospects?state=in_doc" selected={props.state=="in_doc"} />
        <Stat name="Cotización" number={props.status['in_quote'] || 0} path="/prospects?status=in_quote" selected={props.state=="in_quote"} />
        <Stat name="Listo para Solicitud" number={props.status['in_ready'] || 0} path="/prospects?state=in_ready" selected={props.state=="in_ready"} />
        </div> 
      </div> 
    )
};

const ChartType = ({type, data, xtitle, ytitle}) => {

  if(type=="line")
    return (<LineChart data={data} />)
  else if(type=="pie")
    return (<PieChart data={data} />)
  else if(type=="column")
    return (<ColumnChart data={data} />)
  else if(type=="bar")
    return (<BarChart data={data} />)
  else if(type=="area")
    return (<AreaChart data={data} />)
  else if(type=="scatter")
    return (<ScatterChart data={data} xtitle={xtitle} ytitle={ytitle} />)
  else if(type=="prospectStatus")
    return (<ProspectStatus status={data} />)
};

export default Chart
