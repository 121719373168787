import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';


const LegalChecks = (props) => (
    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
            Gestión Legal
            </h3>
        </div>
        <div className="px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
            <div className="sm:col-span-1">
                <dt className="text-sm leading-5 font-medium text-gray-500">
                Tipo de Contrato
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900">
                {props.contract.tipo_contrato}
                </dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm leading-5 font-medium text-gray-500">
                Responsable  {props.contract.Monto <= 5000.0 ? "de ATC" : "de Legal"}
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900">
                {props.contract.responsible.name}
                </dd>
            </div>
            </dl>
        </div>
        <div className="px-4 py-5 sm:px-6">
                <div className="text-sm leading-5 font-medium text-gray-500">
                Imprimir Documentos
                </div>
                {   props.contract.tipo_contrato != "Alivio Financiero Prendario y Vehículo" &&
                    <div>
                        <div className="mt-2 mr-2 inline-flex">
                            <a href={"/gen_docs/61982522ad14e163b4a20ba9/print?debug="+props.contract.SolicitudCreditoID} target="_blank">
                            <button type="button" className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                            Contrato
                            </button>                
                            </a>
                        </div>                
                        <div className="mt-2 mr-2 inline-flex">
                            <a href={"/gen_docs/5f303932cdebfdbb701c39f4/print?debug="+props.contract.SolicitudCreditoID} target="_blank">
                            <button type="button" className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                            Plan de Pagos
                            </button>                
                            </a>
                        </div>                
                        <div className="mt-2 mr-2 inline-flex">
                            <a href={"/gen_docs/5f9725ffcdebfd1623684334/print?debug="+props.contract.SolicitudCreditoID} target="_blank">
                            <button type="button" className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                            Pagaré
                            </button>                
                            </a>
                        </div>                
                        <div className="mt-2 mr-2 inline-flex">
                            <a href={"/gen_docs/5f97264bcdebfd1623684335/print?debug="+props.contract.SolicitudCreditoID} target="_blank">
                            <button type="button" className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                            Cesión de Bienes
                            </button>                
                            </a>
                        </div> 
                    </div>

                }
                {   props.contract.tipo_contrato == "Alivio Financiero Prendario y Vehículo" &&
                    <div>
                        <div className="mt-2 mr-2 inline-flex">
                            <a href={"/gen_docs/5fc92164ad14e14466d00384/print?debug="+props.contract.SolicitudCreditoID} target="_blank">
                            <button type="button" className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                            Contrato
                            </button>                
                            </a>
                        </div>                
                        <div className="mt-2 mr-2 inline-flex">
                            <a href={"/gen_docs/5f303932cdebfdbb701c39f4/print?debug="+props.contract.SolicitudCreditoID} target="_blank">
                            <button type="button" className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                            Plan de Pagos
                            </button>                
                            </a>
                        </div>                
                        <div className="mt-2 mr-2 inline-flex">
                            <a href={"/gen_docs/5fc91818ad14e14466d00383/print?debug="+props.contract.SolicitudCreditoID} target="_blank">
                            <button type="button" className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                            Pagaré
                            </button>                
                            </a>
                        </div>                
                        <div className="mt-2 mr-2 inline-flex">
                            <a href={"/gen_docs/5fc8e1e6ad14e1260bbc329f/print?debug="+props.contract.SolicitudCreditoID} target="_blank">
                            <button type="button" className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                            Declaración del Destino de los Fondos
                            </button>                
                            </a>
                        </div> 
                    </div>

                }
        </div>
    </div>
)


const LegalChecksEdit = forwardRef((props, ref) => {
    const options = props.legalUsers.map( (user, index) => { 
        return (
            <option key={user.UsuarioID} value={user.UsuarioID}>{user.NombreCompleto}</option>
        );
    });
    const optionsTipoContrato = () => { 
        var a = [
            "Prendario (Menor a $5.000)",
            "Prendario + Vehículo (Menor a 5.000)",
            "Hipoteca Mobiliaria",
            "Hipoteca Inmobiliaria",
        ];
        if (props.contract.ProductoCreditoID == 100 || props.contract.ProductoCreditoID == 110) {
            a.push(["Alivio Financiero Prendario y Vehículo"]);
            a.sort();
        }

        return a.map( (item, index) => {
            return (
                <option key={index}>{item}</option>
            )
        });
    };

    return (
        <Formik
        initialValues={props.contract}
        onSubmit={(values, setContract)  => {
            axios.put(`/api/v1/solicitud_creditos/${values.SolicitudCreditoID}`, {solicitud_credito: {responsible_id: values.responsible_id, tipo_contrato: values.tipo_contrato}} )
            .then( resp => setContract(values))
            .catch( data => console.log('error', data))
        }}
        innerRef={ref}
        >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
        }) => (          
        <Form>        
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                Edición de Gestión Legal
                </h3>
            </div>
            <div className="px-4 py-5 sm:px-6">
                {/* <JSONPretty json={values} /> */}
                <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">

                        <div> 
                            <div className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700" id="label-email">
                            MIPYME Sector Agropecuario y su cadena de valor<br/> alineados con la seguridad alimentaria
                            </div>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg space-y-4">
                            
                            <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                <input id="candidates" name="candidates" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                                </div>
                                <div className="ml-3 text-sm">
                                <label for="candidates" className="font-medium text-gray-700">Producción</label>
                                </div>
                            </div>
                            </div>

                            <div>
                            <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                                </div>
                                <div className="ml-3 text-sm">
                                <label for="offers" className="font-medium text-gray-700">Industria</label>
                                </div>
                            </div>
                            </div>
                            
                            <div>
                            <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                                </div>
                                <div className="ml-3 text-sm">
                                <label for="offers" className="font-medium text-gray-700">Comercialización</label>
                                </div>
                            </div>
                            </div>
                            
                            <div>
                            <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                                </div>
                                <div className="ml-3 text-sm">
                                <label for="offers" className="font-medium text-gray-700">Insumos</label>
                                </div>
                            </div>
                            </div>
                            
                            <div>
                            <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                                </div>
                                <div className="ml-3 text-sm">
                                <label for="offers" className="font-medium text-gray-700">Servicio de Transporte</label>
                                </div>
                            </div>
                            </div>
                            
                            <div>
                            <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                                </div>
                                <div className="ml-3 text-sm">
                                <label for="offers" className="font-medium text-gray-700">Almacenamiento</label>
                                </div>
                            </div>
                            </div>
                            
                            <div>
                            <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                                </div>
                                <div className="ml-3 text-sm">
                                <label for="offers" className="font-medium text-gray-700">Procesamiento</label>
                                </div>
                            </div>
                            </div>
                            
                            <div>
                            <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                                </div>
                                <div className="ml-3 text-sm">
                                <label for="offers" className="font-medium text-gray-700">  Conservación y mercados de productos alimenticios</label>
                                </div>
                            </div>
                            </div>
                        </div>

                        </div>                            
                        <div className="sm:col-span-1">

                        <div> 
                            <div className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700" id="label-email">
                            MIPYME Otros Sectores<br/><br/>
                            </div>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg space-y-4">     
                            
                            <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                <Field type="checkbox" name="production" value="Producción y Manufactura" />    
                                </div>
                                <div className="ml-3 text-sm">
                                <label for="production" className="font-medium text-gray-700">Producción y Manufactura</label>
                                </div>
                            </div>
                            </div>

                            <div>
                            <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                                </div>
                                <div className="ml-3 text-sm">
                                <label for="offers" className="font-medium text-gray-700">Comercio</label>
                                </div>
                            </div>
                            </div>
                            
                            <div>
                            <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                                </div>
                                <div className="ml-3 text-sm">
                                <label for="offers" className="font-medium text-gray-700">Construcción</label>
                                </div>
                            </div>
                            </div>
                            
                            <div>
                            <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                                </div>
                                <div className="ml-3 text-sm">
                                <label for="offers" className="font-medium text-gray-700">Artesanía</label>
                                </div>
                            </div>
                            </div>
                            
                            <div>
                            <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                                </div>
                                <div className="ml-3 text-sm">
                                <label for="offers" className="font-medium text-gray-700">Fondas / Restaurantes</label>
                                </div>
                            </div>
                            </div>
                            
                            <div>
                            <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                                </div>
                                <div className="ml-3 text-sm">
                                <label for="offers" className="font-medium text-gray-700">Educación</label>
                                </div>
                            </div>
                            </div>
                            
                            <div>
                            <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                                </div>
                                <div className="ml-3 text-sm">
                                <label for="offers" className="font-medium text-gray-700">Transporte Público</label>
                                </div>
                            </div>
                            </div>
                            
                            <div>
                            <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                                </div>
                                <div className="ml-3 text-sm">
                                <label for="offers" className="font-medium text-gray-700">Otros Servicios</label>
                                </div>
                            </div>
                            </div>
                        </div>

                        </div>        
                        
                </div>
                
            </div>            
        </div>
        </Form>
        )}
        </Formik>                 
    )
})


export {LegalChecks, LegalChecksEdit};
