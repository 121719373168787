import axios from 'axios';

export const getPlazos = async (productoID, setFrecuencias, setPlazos) => {
    const token = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

    try {
        const plazosResponse = await axios.get(`/api/v1/plazos.json?productoid=${productoID}`);
        const plazosid = plazosResponse.data[0].PlazoID;
        setFrecuencias(plazosResponse.data[0].Frecuencias.split(','));

        const frecuenciasResponse = await axios.get(`/api/v1/plazosyfrecuencias.json?plazosid=${plazosid}`);
        const sortedArray = frecuenciasResponse.data.sort((item1, item2) => {
            const item1DescValue = parseInt(item1.Descripcion.split(" ")[0], 10);
            const item2DescValue = parseInt(item2.Descripcion.split(" ")[0], 10);
            return item1DescValue - item2DescValue;
        });

        setPlazos(sortedArray);
    } catch (error) {
        console.error('Error fetching plazos or frecuencias', error);
        throw error; // Throwing the error to be handled by the caller if needed
    }
};
