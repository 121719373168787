import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'

const Steps = (props) => {
    const Step = (props) => {
        if (props.type == "completed")
            return (
                <li className="relative md:flex-1 md:flex">
                <a href="#" className="group flex items-center w-full">
                    <div className="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
                    <div className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-orange-600 rounded-full group-hover:bg-orange-800 transition ease-in-out duration-150">
                        <svg className="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <p className="text-sm leading-5 font-medium text-gray-900">{props.title}</p>
                    </div>
                </a>

                <div className="hidden md:block absolute top-0 right-0 h-full w-5">
                    <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                    <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                    </svg>
                </div>
                </li>
            )
        else if (props.type == "current")
            return (
                <li className="relative md:flex-1 md:flex">
                <div className="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
                    <div className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-orange-600 rounded-full">
                    <p className="text-orange-600">{props.label}</p>
                    </div>
                    <p className="text-sm leading-5 font-medium text-orange-600">
                        {props.title}
                    </p>
                </div>

                <div className="hidden md:block absolute top-0 right-0 h-full w-5">
                    <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                    <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                    </svg>
                </div>
                </li>
            )
        else
            return (
                <li className="relative md:flex-1 md:flex">
                <a href="#" className="group flex items-center">
                    <div className="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
                    <div className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400 transition ease-in-out duration-150">
                        <span className="text-gray-500 group-hover:text-gray-900 transition ease-in-out duration-150">{props.label}</span>
                    </div>
                    <p className="text-sm leading-5 font-medium text-gray-500 group-hover:text-gray-900 transition ease-in-out duration-150">{props.title}</p>
                    </div>

                    {
                        props.type != "upcoming-end" &&
                        <div className="hidden md:block absolute top-0 right-0 h-full w-5">
                            <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                            <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                            </svg>
                        </div>
                    }
                </a>
                </li>
            )                        
    }

    // # contact  Primer Contacto
    // # interest  Quiere el Crédito
    // # estimate  Letra estimada
    // # apc  Consulta APC
    // # acceptance  Aceptación Cliente (Completar Información /Cotizacion Formal) Buena APC
    // # request  Formalizar Solicitud
    // # out  No Interesado


    return (
        <div className="px-4 py-5 sm:px-6">
            <nav>
            <ul className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
                <Step label="PC" title="Primer Contacto" type={props.prospect.tracking.contact} />
                <Step label="QC" title="Quiere el Crédito" type={props.prospect.tracking.interest} />
                <Step label="AP" title="Consulta APC" type={props.prospect.tracking.apc} />
                <Step label="LE" title="Letra estimada" type={props.prospect.tracking.estimate} />
                <Step label="AC" title="Aceptación Cliente" type={props.prospect.tracking.acceptance} />
                <Step label="FS" title="Formalizar Solicitud" type={props.prospect.tracking.request} />
            </ul>
            </nav>

        </div>    
    )
}


export default Steps;
