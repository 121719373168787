import React from "react";
import NumberFormat from "react-number-format";


const Seguimientos = (props) => {


    const seguimientosArray = props.seguimientosData.data?.seguimientos.map(item => item.seguimientos);


    return(
    <>
    {seguimientosArray?.map((seguimiento, index) => {
        if(Number(props.creditoID) === Number(seguimiento.credito)){
        return (
            <tr
                key={index}
                className="bg-white border-b border-gray-200"
            >
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {seguimiento.fechaGestion}
                </td>
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {seguimiento.tipoGestion}
                </td>
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {seguimiento.tiempoGestion}
                </td>
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {seguimiento.categoriaCobranza}
                </td>
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {seguimiento.accionRealizada}
                </td>
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {seguimiento.verificacionGarantias}
                </td>
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {seguimiento.proximaAccion}
                </td>
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {seguimiento.comentarioAdicional}
                </td>
                <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {seguimiento.fechaProximaGestion}
                </td>
            </tr>
        )
        }
    })}

    </>
          
    )
};

const SeguimientosRows = (props) => {

    //console.log(`FROM SEGUIMIENTOSROWS`, props)

    
    return(
        <>
        <div className="bg-gray-800 text-white px-4 py-5 border-b border-gray-100 sm:px-6 rounded-lg">
          <h3 className="text-left text-lg leading-6 font-medium text-white">
        Seguimientos efectuados para este credito:
        <span className="text-orange-600"></span>
      </h3>
    </div>
              <div className="pb-5 text-grey-darkest">
                <div className="flex flex-col">
                  <div className=" py-4 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                      <table className="min-w-full">
                        <thead>
                          <tr>
                            <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                              Fecha Gestion
                            </th>
                            <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                              Tipo de Gestion
                            </th>
                            <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                              Tiempo de Gestion
                            </th>
                            <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                              Categoria
                            </th>
                            <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                              Accion Realizada
                            </th>
                            <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                              Se verificaron Garantias?
                            </th>
                            <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                              Proxima Gestion
                            </th>
                            <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                              Comentario Adicional
                            </th>
                            <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                              Fecha Proxima Gestion
                            </th>
                            {/* <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                              Monto Pago
                            </th>
                            <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                              Nombre Cliente
                            </th>
                            <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                              Fecha Transferencia
                            </th>
                            <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                              Conciliado
                            </th> */}
                            
                          </tr>
                        </thead>
                        <tbody><Seguimientos creditoID={props.creditsData[0]?.CreditoID} seguimientosData={props.seguimientosData} /></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
      
  </>
    )
}

export default SeguimientosRows;