export const calculateTimbresFiscales = (credAmount, montoRefinanciado, ageStartingCredit, accesorios) => {
    let accesorio1 = accesorios['1'] ? Number(accesorios['1']) : 0;
    let accesorio6 = accesorios['6'] ? Number(accesorios['6']) : 0;
    let timbresFiscales = 0.00;
  
    if (credAmount < 5000) {
      if(ageStartingCredit >= 70){
        timbresFiscales = (credAmount + montoRefinanciado + accesorio1) * 0.001;
      } else {
        timbresFiscales = (credAmount + montoRefinanciado + accesorio1 + accesorio6) * 0.001;
      }
    }
  
    return timbresFiscales;
  };