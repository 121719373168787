import React, {useEffect,useState,useRef} from "react";
import {Bars} from 'react-loader-spinner';
import axios from 'axios';
import Moment from 'moment';
import { useReactToPrint } from "react-to-print";
//import Documents from './Documents';
import {MyDropzoneForm} from '../Prospect/MyDropzone';
import FormikControl from "../forms/FormikControl";
import {Formik, Field, FieldArray, Form} from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
//import {isFormValid} from '../Helpers/Helpers';

const validationSchema = Yup.object({
  montoAPagar: Yup.string()
      .required('Requerido'),

})

const Seguimiento = (props) => {

    const [paymentSelected, setPaymentSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fechaGestion, setFechaGestion] = useState('');
    const [fechaProximaGestion, setFechaProximaGestion] = useState('');
    const [pagoerror, setPagoError] = useState([]);
    const [tipoGestion, setTipoGestion] = useState('');
    const [tiempoGestion, setTiempoGestion] = useState('');
    const [categoriaCobranza, setCategoriaCobranza] = useState('');
    const [accionRealizada, setAccionRealizada] = useState('');
    const [verificacionGarantias, setVerificacionGarantias] = useState('');
    const [atendidoPor, setAtendidoPor] = useState('');
    const [nombreAtiende, setNombreAtiende] = useState('');
    const [motivoNoPago, setMotivoNoPago] = useState('');
    const [resultadoSeguimiento, setResultadoSeguimiento] = useState('');
    const [proximaAccion, setProximaAccion] = useState('');
    const [comentarioAdicional, setComentarioAdicional] = useState('');
    const [tipoGarantia, setTipoGarantia] = useState('');
    const [estadoGarantia, setEstadoGarantia] = useState('');
    const [custodia, setCustodia] = useState('');
    const [gestionGuardada, setGestionGuardada] = useState('');

    
    const [file, setFile] = useState({});
    const [files, setFiles] = useState([]);



    const credito = props.creditsData[0]?.CreditoID;    
    const cliente = props.clientData.NombreCompleto;
    const clienteID = props.clientData.ClienteID;
    const componentRef = useRef();

    const initialState = {
        tipoGestion: "", // Add other state variables here
        tiempoGestion: "",
        categoriaCobranza: "",
        accionRealizada: "",
        verificacionGarantias: "",
        tipoGarantia: "",
        estadoGarantia: "",
        custodia: "",
        atendidoPor: "",
        nombreAtiende: "",
        motivoNoPago: "",
        resultadoSeguimiento: "",
        proximaAccion: "",
        comentarioAdicional: "",
        fechaGestion: "",
        fechaProximaGestion: "" 
      };

      const resetForm = () => {
        setTipoGestion("");
        setTiempoGestion("");
        setCategoriaCobranza("");
        setAccionRealizada("");
        setVerificacionGarantias("");
        setTipoGarantia("");
        setEstadoGarantia("");
        setCustodia("");
        setAtendidoPor("");
        setNombreAtiende("");
        setMotivoNoPago("");
        setResultadoSeguimiento("");
        setProximaAccion("");
        setComentarioAdicional("");
        setFechaGestion("");
        setFechaProximaGestion("");
      };

    const seguimientoData = {
        credito: credito,
        cliente: cliente,
        clienteID: clienteID,
        tipoGestion: tipoGestion,
        tiempoGestion: tiempoGestion,
        categoriaCobranza: categoriaCobranza,
        accionRealizada: accionRealizada,
        verificacionGarantias: verificacionGarantias,
        tipoGarantia: tipoGarantia,
        estadoGarantia: estadoGarantia,
        custodia: custodia,
        atendidoPor: atendidoPor,
        nombreAtiende: nombreAtiende,
        motivoNoPago: motivoNoPago,
        resultadoSeguimiento: resultadoSeguimiento,
        proximaAccion: proximaAccion,
        comentarioAdicional: comentarioAdicional,
        fechaGestion: fechaGestion,
        fechaProximaGestion: fechaProximaGestion    
      }

    //FUNCTIONS
    const postFiles = (pagoID, acceptedFiles) => {

      let formData = new FormData();

      for (var i = 0; i < acceptedFiles.length; i++) {
          let file = acceptedFiles[i];
          formData.append('files[]', file);

          return axios.post(`/api/v1/safi/${pagoID}/upload_pago`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
           .then(res => 
             setPaymentSelected('pagoexitoso')
           )    
      }
    }

    const ingresarGestion = (event) => {
      //if (isFormValid(tipoGestion , tiempoGestion, categoriaCobranza, accionRealizada, fechaGestion, fechaProximaGestion /*, other inputs */)) {
        setLoading(true);
    
        const token = document.querySelector('[name=csrf-token]').content;
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
    
          axios
            //.post(`/api/v1/safi/save_seguimiento`, { ...seguimientoData, ...{ file: file } })
            .post(`/api/v1/safi/save_seguimiento`, { ...seguimientoData })
            .then((resp) => {
                console.log(resp.data.result._id.$oid)
              //postFiles(resp.data.result._id.$oid, files);
              setGestionGuardada('si');
              alert('Gestion Guardada');
              resetForm();
            })
            .catch((data) => console.log('error', data));
    
          setLoading(false);
        
      //}
    };


      const onSubmit = (values) => {
        console.log(values)
      }

      const [paymentParams, setPaymentParams] = useState(
        {
          "creditoID": 0
        }
      )

    return(

        <>

              

            <div className="bg-gray-800 text-white px-4 py-5 border-b border-gray-100 sm:px-6 rounded-lg">
          <h3 className="text-left text-lg leading-6 font-medium text-white">
                Gestion de Cobranza: 

              </h3>

              </div>
              

                {
                  loading ?
                  <div className="flex h-screen">
                  <div className="m-auto">
                      <Bars
                          color="#00BFFF"
                          height={100}
                          width={100}
                          timeout={30000} //3 secs                
                      />
                      </div>
                  </div> 
                  :   
                  
                  
                  
                  <div className="px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4">

                        <div className="sm:col-span-1 pl-2">
                            <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                            Tipo de Gestion
                            </dt>
                            <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                            <select className="form-input mt-1 form-select block w-full max-h-full min-h-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5" onChange={e => setTipoGestion(e.target.value)} value={tipoGestion}>
                                <option disabled value="">-- Seleccione opcion --</option>
                                <option value="Seguimiento">Seguimiento</option>
                                <option value="General">General</option>
                                <option value="Promesa de Pago">Promesa de Pago</option>
                                <option value="Contacto">Contacto</option>
                                <option value="Cobranza">Cobranza</option>
                                <option value="Condonaciones">Condonaciones</option>
                                <option value="Extension de Moratoria">Extension Moratoria</option>
                                <option value="Ilocalizable">Ilocalizable</option>
                                <option value="No contactado">No contactado</option>
                                <option value="Reclamos">Reclamos</option>
                                <option value="Sin Gestion">Sin Gestion</option>
                                <option value="Covid Positivo">Covid Positivo</option>
                            </select>
                            
                            </dd>
                        </div>

                        <div className="sm:col-span-1 pl-2">
                            <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                            Tiempo de Gestion
                            </dt>
                            <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                            <select className="form-input mt-1 form-select block w-full max-h-full min-h-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5" onChange={e => setTiempoGestion(e.target.value)} value={tiempoGestion}>
                                <option disabled value="">-- Seleccione opcion --</option>

                                <option selected value="Gestion 1 a 30">1 a 30 dias</option>
                                <option value="Gestion 31 a 60">31 a 60 dias</option>
                                <option value="Gestion 61 a 90">61 a 90 dias</option>
                                <option value="Gestion Mayor a 90">Mayor a 90 dias</option>
                                <option value="Gestion Preventiva">Preventiva</option>
                                <option value="Gestion Legal">Gestion Legal</option>
                    
                            </select>
                            
                            </dd>
                        </div>

                        <div className="sm:col-span-1 pl-2">
                            <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                            Categoria de Cobranza
                            </dt>
                            <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                            <select className="form-input mt-1 form-select block w-full max-h-full min-h-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5" onChange={e => setCategoriaCobranza(e.target.value)} value={categoriaCobranza}>
                                <option disabled value="">-- Seleccione opcion --</option>
                                <option selected value="Cobranza Preventiva">Cobranza Preventiva</option>
                                <option value="Cobranza Administrativa">Cobranza Administrativa</option>
                                <option value="Cobranza Atrasada">Cobranza Atrasada</option>
                                <option value="Cobranza Vencida">Cobranza Vencida</option>
                                <option value="Cobranza Judicial">Cobranza Judicial</option>
                                <option value="Cobranza Telefonica">Cobranza Telefonica (Preventiva)</option>
                                <option value="Sin gestion">Sin gestion</option>
                            </select>
                            
                            </dd>
                        </div>

                        <div className="sm:col-span-1 pl-2">
                            <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                            Accion Realizada
                            </dt>
                            <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                            <select className="form-input mt-1 form-select block w-full max-h-full min-h-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5" onChange={e => setAccionRealizada(e.target.value)} value={accionRealizada}>
                                <option disabled value="">-- Seleccione opcion --</option>

                                <option selected value="Llamada Telefonica">Llamada Telefonica</option>
                                <option value="Visita al Cliente">Visita al Cliente</option>
                                <option value="Compromiso de Pago">Compromiso de Pago</option>
                                <option value="Citacion en Agencia">Citacion en Agencia</option>
                                <option value="Mensaje de Texto">Mensaje de Texto</option>
                                <option value="Traslado de Expediente">Traslado de Expediente</option>
                                <option value="Levantamiento de Garantias">Levantamiento de Garantias</option>
                                <option value="Venta y Aplicacion de Bienes">Venta y Aplicacion de Bienes</option>
                                <option value="Sin gestion">Sin gestion</option>
                            </select>
                            
                            </dd>
                        </div>

                        <div className="sm:col-span-1 pl-2">
                            <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                            Se verificaron Garantias?
                            </dt>
                            <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                            <select className="form-input mt-1 form-select block w-full max-h-full min-h-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5" onChange={e => setVerificacionGarantias(e.target.value)} value={verificacionGarantias}>
                                <option disabled value="">-- Seleccione opcion --</option>

                                <option selected value="No">No</option>
                                <option value="Si">Si</option>
      
                            </select>
                            
                            </dd>
                        </div>
                        {verificacionGarantias === 'Si' && (

                        <>

                        <div className="sm:col-span-1 pl-2">
                            <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                            Tipo de Garantia
                            </dt>
                            <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                            <select className="form-input mt-1 form-select block w-full max-h-full min-h-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5" onChange={e => setTipoGarantia(e.target.value)} value={tipoGarantia}>
                                <option disabled value="">-- Seleccione opcion --</option>

                                <option selected value="Prendaria">Prendaria</option>
                                <option value="Vehiculo">Vehiculo</option>
                                <option value="Mix de Garantias">Mix de Garantias</option>
                                <option value="Semoviente">Semoviente</option>
                            </select>
                            </dd>
                        </div>
                        

                        

                        <div className="sm:col-span-1 pl-2">
                            <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                            Estado de Garantia
                            </dt>
                            <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                            <select className="form-input mt-1 form-select block w-full max-h-full min-h-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5" onChange={e => setEstadoGarantia(e.target.value)} value={estadoGarantia}>
                                <option disabled value="">-- Seleccione opcion --</option>

                                <option selected value="En custodia">En custodia</option>
                                <option value="Espera de Captura">Espera de captura</option>
                                <option value="Vendida">Vendida</option>
                                <option value="No Ubicada">No ubicada</option>
                            </select>
                            </dd>
                        </div>

                        <div className="sm:col-span-1 pl-2">
                            <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                            Custodia
                            </dt>
                            <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                            <select className="form-input mt-1 form-select block w-full max-h-full min-h-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5" onChange={e => setCustodia(e.target.value)} value={custodia}>
                                <option disabled value="">-- Seleccione opcion --</option>

                                <option selected value="Entrega voluntaria">Entrega voluntaria</option>
                                <option value="Capturada">Capturada</option>
                            </select>
                            </dd>
                        </div>
                        </>
                        )}
                        


                        <div className="sm:col-span-1 pl-2">
                            <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                            Fecha de Gestion
                            </dt>
                            <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                            <DatePicker

                            className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline "
                            dateFormat="MM/dd/yyyy"
                            onChange={(date) => setFechaGestion(date)}
                            selected={fechaGestion}
                            inputProps={{
                                readOnly: true,
                            }}
                            />
                            </dd>
                        </div>

                        <div className="sm:col-span-1 pl-2">
                            <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                            Atendido por:
                            </dt>
                            <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                            <select className="form-input mt-1 form-select block w-full max-h-full min-h-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5" onChange={e => setAtendidoPor(e.target.value)} value={atendidoPor}>
                                <option disabled value="">-- Seleccione opcion --</option>

                                <option selected value="Cliente">Cliente</option>
                                <option value="Codeudor">Codeudor</option>
                                <option value="Conyuge">Conyuge</option>
                                <option value="Hijo">Hijo</option>
                                <option value="Padre">Padre</option>
                                <option value="Administrador">Administrador</option>
                                <option value="Contador">Contador</option>
                                <option value="Integrante">Integrante</option>
                                <option value="Otro">Otro</option>
                            </select>
                            
                            </dd>
                        </div>

                        <div className="sm:col-span-1 pl-2">
                        <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                          Nombre de quien atiende la gestion:
                        </dt>
                        <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                          <input className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline " type="text" placeholder="Nombre y Apellido" name="nombreCompleto" onChange={e => setNombreAtiende(e.target.value)} value={nombreAtiende}/>
                        </dd>
                      </div>

                        <div className="sm:col-span-1 pl-2">
                            <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                            Motivo no pago
                            </dt>
                            <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                            <select className="form-input mt-1 form-select block w-full max-h-full min-h-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5" onChange={e => setMotivoNoPago(e.target.value)} value={motivoNoPago}>
                                <option disabled value="">-- Seleccione opcion --</option>

                                <option value="Baja produccion">Baja producción</option>
                                <option value="Precios bajos">Precios bajos</option>
                                <option value="Desfase de produccion">Desfase de producción</option>
                                <option value="Desfase de comercializacion">Desfase de comercialización</option>
                                <option value="Baja comercializacion">Baja comercialización</option>
                                <option value="Enfermedad del cliente">Enfermedad cliente</option>
                                <option value="Enfermedad de familiar">Enfermedad familiar</option>
                                <option value="Ausencia del cliente">Ausencia del cliente</option>
                                <option value="Muerte del cliente">Muerte cliente</option>
                                <option value="Manejo tecnico inadecuado">Manejo técnico/comercial inadecuado</option>
                                <option value="Manejo financiero inadecuado">Manejo financiero inadecuado</option>
                                <option value="Mano de obra no calificada">Mano de obra no calificada</option>
                                <option value="Credito tardio">Crédito tardío</option>
                                <option value="Mal funcionamiento de maquinaria">Mal funcionamiento de maq/eq</option>
                                <option value="Siniestro en instalaciones">Siniestro instalaciones</option>
                                <option value="Falta de capacitacion">Falta de capacitación</option>
                                <option value="Desvio de recursos">Desvío de recursos del crédito</option>
                                <option value="Gastos excesivos">Gastos excesivos</option>
                                <option value="Fraude comercializador">Fraude comercializador</option>
                            </select>
                            
                            </dd>
                        </div>

                        <div className="sm:col-span-1 pl-2">
                            <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                            Resultado Seguimiento
                            </dt>
                            <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                            <select className="form-input mt-1 form-select block w-full max-h-full min-h-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5" onChange={e => setResultadoSeguimiento(e.target.value)} value={resultadoSeguimiento}>
                                <option disabled value="">-- Seleccione opcion --</option>

                                <option value="Promesa de pago">Promesa de pago</option>
                                <option value="Pago realizado">Pago realizado</option>
                                <option value="Abono realizado">Abono realizado</option>
                                <option value="No responde">No atendio / No responde</option>
                                <option value="Negativa de pago">Cliente se rehusa a pagar</option>
                            </select>
                            
                            </dd>
                        </div>

                        <div className="sm:col-span-1 pl-2">
                            <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                            Proxima Accion
                            </dt>
                            <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                            <select className="form-input mt-1 form-select block w-full max-h-full min-h-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5" onChange={e => setProximaAccion(e.target.value)} value={proximaAccion}>
                                <option disabled value="">-- Seleccione opcion --</option>

                                <option value="Visita">Visita</option>
                                <option value="Llamada">Llamada</option>
                                <option value="Verificar promesa">Verificar promesa de pago</option>
                                <option value="Pase de gestion">Pase de Gestion</option>
                                <option value="Cliente se rehusa a pagar">Cliente se rehusa a pagar</option>
                            </select>
                            
                            </dd>
                        </div>

                        <div className="sm:col-span-1 pl-2">
                            <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                            Fecha proxima Gestion
                            </dt>
                            <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                            <DatePicker

                            className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline "
                            dateFormat="MM/dd/yyyy"
                            onChange={(date) => setFechaProximaGestion(date)}
                            selected={fechaProximaGestion}
                            inputProps={{
                                readOnly: true,
                            }}
                            />
                            </dd>
                        </div>

                        <div className="sm:col-span-4 pl-2">
                        <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                          Comentarios adicionales
                        </dt>
                        <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                          <input className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline " type="text" placeholder="Comentarios adicionales sobre la gestion" name="comentarioAdicional" onChange={e => setComentarioAdicional(e.target.value)} value={comentarioAdicional}/>
                        </dd>
                      </div>

                                           

                      <div className="sm:col-span-4 pl-2">    
                      <MyDropzoneForm setFiles={setFiles} />
                      </div>

                      <div className="sm:col-span-1 pl-2">                      
                        <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">                        
                        <button
                        onClick={ingresarGestion}
   
                        className={`relative ml-2 h-10 right-0 flex items-center px-8 font-bold rounded hover:text-white hover:bg-yellow-600 border-yellow-600 text-yellow-600 border`}
                        >
                            Ingresar Gestion
                        </button>
                        </dd>
                      </div>
                      
                    </dl>
                  </div>

                }
                
                


        </>





        

    )


}

export default Seguimiento;
