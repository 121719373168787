import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import {MyDropzone} from './MyDropzone'
import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';

import * as Yup from 'yup'
import FormikControl from '../forms/FormikControl'


const Documents = (props) => {

    const requiredDocumentsOptions = [
        { key: 'Documento de Identidad', value: 'Documento de Identidad', description: 'Cédula de Identidad o Pasaporte', visible: true },
        { key: 'Recibo de Servicio', value: 'Recibo de Servicio', description: 'Recibo de Servicio de Utilidad (electricidad, agua, etc)', visible: true },
        { key: 'Autorización APC', value: 'Autorización APC', description: 'Autorización para consultar a APC', visible: props.values.apcEnabled }
    ]
    
    const files = props.values.files.map( (file, index) => { 
        return (
            <div className="sm:col-span-1">
                <a target="_blank" href={file.url}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
                <span className="text-xs text-gray-400">{file.name}</span>
                </a>
            </div>
        );
    });

    const [hiddenPanel, setHiddenPanel] = useState(false);


    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            {props.editable && 
            <div className="m-4 inline-flex float-right">
                <span className="inline-flex rounded-md shadow-sm">
                    <button type="submit" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                        Salvar
                    </button>
                </span>
            </div>        
            }
            {!props.editable &&
                <div className="m-4 inline-flex float-right">
                    <span className="ml-2 rounded-md shadow-sm ">
                        <a onClick={() => props.setDocumentEdit(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>                           
                        </a>
                    </span>
                </div>
            }
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="flex text-lg leading-6 font-medium text-gray-900">   
                <a onClick={() => setHiddenPanel(!hiddenPanel)}>       
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor" data-action="click->toggler#toggle">
                {hiddenPanel
                    ? <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" data-toggler-target="selectorIcon" />
                    : <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" data-toggler-target="selectorIcon" />
                }
                </svg>
                </a>
                Documentos
                </h3>
            </div>
            {!hiddenPanel &&
                <div>
                <div className="px-4 py-5 sm:px-6">
                    <FormikControl
                    control='checkboxb'
                    label='Documentos Requeridos'
                    name='requiredDocuments'
                    options={requiredDocumentsOptions}
                    editing={props.editable}
                    />
                    
                </div>
                <div className="px-4 py-5 sm:px-6">
                {props.editable &&
                <MyDropzone {...props}   />
                }
                </div>                        
                <div className="px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-5 gap-y-4 gap-x-8 sm:grid-cols-5">
                    {files}    
                    </dl>
                </div> 
                </div>
            }
        </div>
    )
}


export default Documents
