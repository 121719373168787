export const dropdownFrecuenciaOptions = [
    { key: 'M', value: 'Mensual' },
    { key: 'B', value: 'Bimestral' },
    { key: 'T', value: 'Trimestral' },
    { key: 'S', value: 'Semestral' }
]

export const dropdownGarantias = [
    { key: 'prendaria', value: 'Prendaria' },
    { key: 'mobiliaria', value: 'Mobiliaria' },
    { key: 'semoviente', value: 'Semoviente' },
    { key: 'inmobiliaria', value: 'Inmobiliaria' },
    { key: 'otras', value: 'Otras' },
    { key: 'singarantias', value: 'Sin Garantias' }
]

export const dropdownGastosLegales = [
    { key: 'contPrendario', value: 'Contrato Prendario (Incluye Semovientes) hasta $5000' },
    { key: 'contPrendarioHipotecaVehiculo', value: 'Contrato Prendario hasta $5000 + Hipoteca Vehiculo' },
    { key: 'contPrendarioEscrituraPublica', value: 'Contrato Prendario mas de $5000 (Escritura Publica)' },
    { key: 'contInmobiliarioEscrituraPublica', value: 'Contrato Inmobiliario mas de $5000 (Escritura Publica)' },
    { key: 'contEscrituraBienMuebleBPN', value: 'Contrato Escritura Bien Mueble Alivio BNP' }
]


export const dropdownDiaMesPagoOptions = [
    { key: '1',value: '1' },
    { key: '2',value: '2' },
    { key: '3',value: '3' },
    { key: '4',value: '4' },
    { key: '5',value: '5' },
    { key: '6',value: '6' },
    { key: '7',value: '7' },
    { key: '8',value: '8' },
    { key: '9',value: '9' },
    { key: '10',value: '10' },
    { key: '11',value: '11' },
    { key: '12',value: '12' },
    { key: '13',value: '13' },
    { key: '14',value: '14' },
    { key: '15',value: '15' },
    { key: '16',value: '16' },
    { key: '17',value: '17' },
    { key: '18',value: '18' },
    { key: '19',value: '19' },
    { key: '20',value: '20' },
    { key: '21',value: '21' }
]

export const dropDownProductOptions = [
    { key: '10', value: 'SUMA CONFIANZA' },
    { key: '20', value: 'SUMA SOBRE RUEDAS' },
    { key: '21', value: 'SUMA SOBRE RUEDAS' },
    { key: '30', value: 'SUMA MAQUINARIA (EXISTENTE)' },
    { key: '31', value: 'SUMA MAQUINARIA (COMPRA)' },
    { key: '40', value: 'SUMA EMPRESARIOS' },
    { key: '50', value: 'SUMA CONSUMO FINANC. POLIZAS SEGURO' },
    { key: '60', value: 'SUMA XPRESS' },
    { key: '61', value: 'SUMA XPRESS XTRA' },
    { key: '70', value: 'SUMA PERSONAS' },
    { key: '100', value: 'SUMA ACTIVATE' },
    { key: '120', value: 'SUMA ACTIVATE AGRO' },
    { key: '130', value: 'SUMA ACTIVATE PLUS' },
    { key: '140', value: 'SUMA REFUERZO' }
]

 

// export const dropdownTipoPersona = [
//     { key: 'natural', value: 'Natural' },
//     { key: 'juridica', value: 'Juridica' }
// ]

export const dropdownClienteType = [
    { key: 'nuevo', value: 'Nuevo' },
    { key: 'activo', value: 'Activo' }
];

export const dropDownComCierre = [
    { key: '0.08', value: '8%' },
    { key: '0.1', value: '10%' },
    { key: '0.12', value: '12%' }
]

export const dropdownVehiculos = [
    { key: '1', value: '1' },
    { key: '2', value: '2 ' },
    { key: '3', value: '4' },
    { key: '4', value: '4' },
    { key: '5', value: '5' },
    { key: '6', value: '6' }
]

export const dropdownHipotecas = [
    { key: 'si', value: 'Si' },
    { key: 'no', value: 'No' }
]

export const dropdownCheque = [
    { key: 'si', value: 'Si' },
    { key: 'no', value: 'No' }
]

export const dropdownCod = [
    { key: 'si', value: 'Si' },
    { key: 'no', value: 'No' }
]

export const dropdownJub = [
    { key: 'si', value: 'Si' },
    { key: 'no', value: 'No' }
]




// export const dropdownInterestRateOptions = [
//     { key: '5',value: '5' },
//     { key: '6',value: '6' },
//     { key: '7',value: '7' },
//     { key: '8',value: '8' },
//     { key: '9',value: '9' },
//     { key: '10',value: '10' },
//     { key: '11',value: '11' },
//     { key: '12',value: '12' },
//     { key: '13',value: '13' },
//     { key: '14',value: '14' },
//     { key: '15',value: '15' },
//     { key: '16',value: '16' },
//     { key: '17',value: '17' },
//     { key: '18',value: '18' },
//     { key: '19',value: '19' },
//     { key: '20',value: '20' },
//     { key: '21',value: '21' },
//     { key: '22',value: '22' },
//     { key: '23',value: '23' },
//     { key: '24',value: '24' },
//     { key: '25',value: '25' },
//     { key: '26',value: '26' },
//     { key: '27',value: '27' },
//     { key: '28',value: '28' },
//     { key: '29',value: '29' },
//     { key: '30',value: '30' },
//     { key: '31',value: '31' },
//     { key: '32',value: '32' },
//     { key: '33',value: '33' },
//     { key: '34',value: '34' },
//     { key: '35',value: '35' },
//     { key: '36',value: '36' },
//     { key: '37',value: '37' },
//     { key: '38',value: '38' },
//     { key: '39',value: '39' },
//     { key: '40',value: '40' },
//     { key: '41',value: '41' },
//     { key: '42',value: '42' },
//     { key: '43',value: '43' },
//     { key: '44',value: '44' },
//     { key: '45',value: '45' },
//     { key: '46',value: '46' },
//     { key: '47',value: '47' },
//     { key: '48',value: '48' },
//     { key: '49',value: '49' },
//     { key: '50',value: '50' }
// ]