import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import MyDropzone from './MyDropzone'


const Documents = (props) => {
    //console.log('props', props);

    const requiredDocumentsOptions = [
        { key: 'Documento de Identidad', value: 'Documento de Identidad', description: 'Cédula de Identidad o Pasaporte', visible: true },
        { key: 'Recibo de Servicio', value: 'Recibo de Servicio', description: 'Recibo de Servicio de Utilidad (electricidad, agua, etc)', visible: true },
       // { key: 'Autorización APC', value: 'Autorización APC', description: 'Autorización para consultar a APC', visible: props.values.apcEnabled }
    ]
    
    // const files = props.values.files.map( (file, index) => { 
    //     return (
    //         <div className="sm:col-span-1">
    //             <a target="_blank" href={file.url}>
    //             <img className="border-solid border-2 border-gray-200" src="/images/doc.jpg"/>
    //             <span className="text-xs text-gray-400">{file.name}</span>
    //             </a>
    //         </div>
    //     );
    // });

    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                Documentos
                </h3>
            </div>
             <div className="px-4 py-5 sm:px-6">
              
                 
            </div>
             <div className="px-4 py-5 sm:px-6">
              {
              <MyDropzone values={props.values} />
              }
            </div>                        
            <div className="px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-5 gap-y-4 gap-x-8 sm:grid-cols-5">
                </dl>
            </div> 
        </div>
    )
}


export default Documents
