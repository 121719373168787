import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="calc-margin"
export default class extends Controller {
  static targets = ["from", "to", "profit", "profitRatio", "quantity", "total", "fromTotal", "cycle", "monthlyTotal"]

  calc() {
    this.profitTarget.value = parseFloat(this.toTarget.value || 0) - parseFloat(this.fromTarget.value|| 0)
    this.profitRatioTarget.value = (( 1.0 - parseFloat(this.fromTarget.value|| 0) / parseFloat(this.toTarget.value|| 0) ) * 100 ).toFixed(2)    
    this.totalTarget.value = parseFloat(this.toTarget.value || 0) * parseFloat(this.quantityTarget.value|| 0)

    // console.log(this.cycleTarget.value)
    // if(this.cycleTarget)
    let factor = 1.0
    if(this.cycleTarget.value == 'Diario')
      factor = 28.0
    else if(this.cycleTarget.value == 'Semanal')
      factor = 4.0
    else if(this.cycleTarget.value == 'Quincenal')
      factor = 2.0
    else if(this.cycleTarget.value == 'Mensual')
      factor = 1.0
      
    this.monthlyTotalTarget.value = parseFloat(this.totalTarget.value || 0) * factor
    this.fromTotalTarget.value = parseFloat(this.fromTarget.value || 0) * parseFloat(this.quantityTarget.value|| 0) * factor
  }


  ignore(e) {
    e.preventDefault();
  }
}
