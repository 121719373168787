import React, { Component, Fragment } from 'react'
import { 
  BrowserRouter as Router, 
  Route, 
  Switch
} from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params';
// import Example from './Contracts/Example'
import Discards from './Discards/Discards'
import Prospects from './Prospects/Prospects'
import ProspectCreate from './Prospect/ProspectCreate'
import Prospect from './Prospect/Prospect'
import Contracts from './Contracts/Contracts'
import Disbursements from './Contracts/Disbursements'
import Autenticacion from './Contracts/Autenticacion'
import Analisis from './Contracts/Analisis'
import Analisys from './Contract/Analisys/Analisys'
import Contract from './Contract/Contract'
import Disbursement from './Contract/Disbursement'
import DisbursementAuthentication from './Contract/DisbursementAuthentication'
import Wallet from './Consulta/Wallet'
import WalletCobranza from './Consulta/WalletCobranza'
import WalletTareas from './Consulta/WalletTareas'

import Pagos from './Consulta/Pagos'
import Ventanilla from './Consulta/Ventanilla'
import WalletSeguimiento from './Seguimiento/WalletSeguimiento';

import ConsultaCredito from './ConsultaCredito/ConsultaCredito';
//import ConsultaCredito from './Consulta/ConsultaCredito'
import ConsultaCreditoPago from './PagoCredito/ConsultaCreditoPago'
import Dashboard from './Dashboard/Dashboard'
import DashboardCobros from './Cobros/DashboardCobros';


import './App.css'
import SimulacionCredito from './Simulacion/Simulacion';
import SimulacionCredito2 from './SimulacionV2/Simulacion';

import WalletCotizaciones from './Consulta/WalletCotizaciones';
import WalletConciliaciones from './Conciliacion/WalletConciliaciones';
import WalletPagosRechazados from './Conciliacion/WalletPagosRechazados';
import WalletPagos from './Conciliacion/WalletPagos';


import WalletSimulaciones from './Consulta/WalletSimulaciones';
import WalletConciliados from './Conciliacion/WalletConciliados';

import ConsultaEstadoCuenta from './EstadoCuenta/Consulta'
import ConsultaAnalitico from './Reportes/ConsultaAnalitico';

import DiasCobros from './Cobros/DiasCobros';

class App extends Component {  
  
  render(){

    const { currentUser } = this.props;

    return(
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Switch>
          <Route exact path="/dashboard_prospects" render={(routeProps) => <DashboardProspects {...routeProps} currentUser={currentUser} />} />
          <Route exact path="/dashboard" render={(routeProps) => <Dashboard {...routeProps} currentUser={currentUser} />} />
          <Route exact path="/contracts" component={Contracts} />
          <Route exact path="/prospects" component={Prospects} />
          <Route exact path="/discards" component={Discards} />
          <Route exact path="/prospects/new" component={ProspectCreate} />
          <Route exact path="/prospects/:id/:action?" component={Prospect} />
          <Route exact path="/disbursements_old" component={Disbursements} />
          <Route exact path="/authentications" component={Autenticacion} />
          <Route exact path="/analisis" component={Analisis} />
          <Route exact path="/contract/analisys/:id" component={Analisys} />
          <Route exact path="/consulta/:clienteID/:creditoID" render={(routeProps) => <ConsultaCredito {...routeProps} currentUser={currentUser} /> } />
          <Route exact path="/contract/:id" component={Contract} />
          <Route exact path="/disbursements/:id" component={Disbursement} />
          <Route exact path="/authentication/:id" component={DisbursementAuthentication} />
          <Route exact path="/cartera" render={(routeProps) => <Wallet {...routeProps} currentUser={currentUser} />} />
          <Route exact path="/dashboardcobros" render={(routeProps) => <DashboardCobros {...routeProps} currentUser={currentUser} />} />
          <Route exact path="/diascobros" render={(routeProps) => <DiasCobros {...routeProps} currentUser={currentUser} />} />


          <Route exact path="/cobranza" component={WalletCobranza} />
          <Route exact path="/seguimientos" render={(routeProps) => <WalletSeguimiento {...routeProps} currentUser={currentUser} />}  />
          <Route exact path="/edocuenta" component={ConsultaEstadoCuenta} />
          <Route exact path="/analiticos" component={ConsultaAnalitico} />

          <Route exact path="/ventanilla" component={Ventanilla} />
          <Route exact path="/pagos" component={Pagos} />
          <Route exact path="/pagocredito" render={(routeProps) => <ConsultaCreditoPago {...routeProps} currentUser={currentUser} />} />
          <Route exact path="/conciliacion" render={(routeProps) => <WalletConciliaciones {...routeProps} currentUser={currentUser} />} />
          <Route exact path="/seguimiento" render={(routeProps) => <WalletSeguimiento {...routeProps} currentUser={currentUser} />} />
          <Route exact path="/pagosrechazados" component={WalletPagosRechazados} />
          <Route exact path="/historicopagos" component={WalletPagos} />

          <Route exact path="/conciliados" component={WalletConciliados} />
          <Route exact path="/cotizaciones" component={WalletCotizaciones} />
          <Route exact path="/simulaciones" component={WalletSimulaciones} />

          <Route exact path="/simularold" component={SimulacionCredito} />
          <Route exact path="/simular" component={SimulacionCredito2} />

        </Switch>
      </QueryParamProvider>
    </Router>

    )
  }
}

export default App
