import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';


const Operations = (props) => (
    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
            Gestión de Operaciones
            </h3>
        </div>
        <div className="px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
            <div className="sm:col-span-1">
                <dt className="text-sm leading-5 font-medium text-gray-500">
                Cliente
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900">
                {props.contract.NombreCliente}
                </dd>
            </div>
            </dl>
        </div>
        
    </div>
)

const OperationsEdit = (props) => (
    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
            Edición de Gestión de Operaciones
            </h3>
        </div>
        <div className="px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-1">
            <div className="sm:col-span-1">
                <form>
                <label htmlFor="about" className="block text-sm font-medium leading-5 text-gray-700">
                    About
                </label>

                <div className="mt-1 rounded-md shadow-sm">
                    <textarea id="about" rows="3" className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"></textarea>
                </div>
                <p className="mt-2 text-sm text-gray-500">Write a few sentences about yourself.</p>
               </form>    
            </div>
            </dl>
        </div>        
 
        
    </div>
)

export {Operations, OperationsEdit};
