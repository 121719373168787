import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="calc-margin"
export default class extends Controller {
  static targets = ["vals", "min", "max", "avg"]

  
  calc() {
    const arrSum = arr => arr.reduce((a,b) => a + b, 0)

    const vals = this.valsTargets.map(elm => parseFloat(elm.value || 0));
    const filteredVals = vals.filter(x => x > 0)
    this.minTarget.value = Math.min(...filteredVals)
    this.maxTarget.value = Math.max(...filteredVals)
    this.avgTarget.value = (arrSum(filteredVals) / filteredVals.length).toFixed(2);
  }

  ignore(e) {
    e.preventDefault();
  }
}
