import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';
import FieldValue from './FieldValue'


const EstatusCredito = {"I": "Inactivo", "A":"Autorizado", "V":"Vigente", "P": "Pagado", "C": "Cancelado", "B":"Vencido", "K":"Castigado", "S":"Suspendido"}
const TipoDispersionCredito = {"S":"SPEI", "C":"Cheque", "O":"Orden de Pago", "E":"Efectivo"}
const TipoPagoCapitalCredito = {"C":"Crecientes", "I":"Iguales", "L":"Libres"}


const Credit = (props) => (
    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            {props.credit.Estatus === "P" &&
                <div className="m-4 mt-0 inline-flex float-right">
                    <span className="ml-2 rounded-md shadow-sm ">
                        <a target="_blank" href={`/gen_docs/63b5ea3bad14e17025b44c6b/print?debug=${props.credit.CreditoID}`}>
                        <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                        </svg> Carta Cancelación                                
                        </button>                    
                        </a>
                    </span>
                </div>
            }
            <h3 className="text-lg leading-6 font-medium text-gray-900">
            Datos del Crédito
            </h3>

        </div>
        <div className="px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
            <FieldValue label={"Número (Número de Crédito)"}>{props.credit.CreditoID}</FieldValue>
            <FieldValue label={"Relacionado (Reacreditación)"}>{props.credit.Relacionado}</FieldValue>
            <FieldValue label={"Línea de Crédito"}>{props.credit.LineaCreditoID}</FieldValue>
            <FieldValue label={"Saldo de Línea"}>
                {props.credit.SaldoDisponible ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(props.credit.SaldoDisponible) : ''}                                
            </FieldValue>
            <FieldValue label={"Estatus (línea)"}>{EstatusCredito[props.credit.Estatus]}</FieldValue>
            <FieldValue label={"Saldo a liquidar"}>
                {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(props.credit.SaldoCapVigent)}                                
            </FieldValue>
            <FieldValue label={"Fecha de Inicio"}>
                {Moment(props.credit.FechaInicio).format('YYYY-MM-DD')}
            </FieldValue>
            <FieldValue label={"Fecha de Inicio de Primer Amortización"}>
                {Moment(props.credit.FechaInicioAmor).format('YYYY-MM-DD')}
            </FieldValue>
            <FieldValue label={"Fecha de Vencimiento"}>
                {Moment(props.credit.FechaVencimien).format('YYYY-MM-DD')}
            </FieldValue>
            <FieldValue label={"Tipo de Dispersión"}>{TipoDispersionCredito[props.credit.TipoDispersion]}</FieldValue>
            <FieldValue label={"Fecha Prog. Desembolso"}>
                {Moment(props.credit.FechaMinistrado).format('YYYY-MM-DD')}
            </FieldValue>
            <FieldValue label={"Monto Financiado"}>
                {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(props.credit.MontoCredito)}
            </FieldValue>
            <FieldValue label={"Monto Por Desembolsar"}>
                {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(props.credit.MontoPorDesemb)}
            </FieldValue>
            <FieldValue label={"Monto Desembolsado"}>
                {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(props.credit.MontoDesemb)}
            </FieldValue>
            <FieldValue label={"Plazo"}>{props.credit.Plazo}</FieldValue>
            <FieldValue label={"Tasa fija anualizada TasaFija"}>
            {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(props.credit.TasaFija)} %
            </FieldValue>
            <FieldValue label={"Tipo de Pago de Capital"}>{TipoPagoCapitalCredito[props.credit.TipoPagoCapital]}</FieldValue>
            <FieldValue label={"Frecuencia de Capital e Interés"}>{props.credit.FrecuenciaCap}</FieldValue>
            <FieldValue label={"Día del mes (día de pago)"}>{props.credit.DiaMesCapital}</FieldValue>
            <FieldValue label={"Número de cuotas"}>{props.credit.NumAmortizacion}</FieldValue>
            <FieldValue label={"Monto Cuota"}>
                {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(props.credit.MontoCuota)}
            </FieldValue>
            <FieldValue label={"TAE (Tasa Efectiva)"}>
            {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(props.credit.ValorCAT)} %
            </FieldValue>                
            </dl>
        </div>
    </div>
)



export default Credit
