export const calculateExequialExpenses = (ageStartingCredit, termInMonths) => {
    let termInMonthsExequial = ageStartingCredit < 70 ? Number(termInMonths) + 1 : Number(termInMonths);
  
    let totalExequiasMonto = ageStartingCredit >= 65 ? termInMonthsExequial * 3 : termInMonthsExequial * 2;
    let totalExequiasMontoImpuesto = 0.00;
    let totalExequiasConImpuesto = totalExequiasMonto + totalExequiasMontoImpuesto;
  
    let memorialExequialMonto = ageStartingCredit >= 65 ?  Number(termInMonths) * 1.75 : Number(termInMonths) * 0.54;
    let memorialExequialMontoImpuesto = 0.00;
    let memorialExequialConImpuesto = memorialExequialMonto + memorialExequialMontoImpuesto;
  
    let comisionMemorialMonto = totalExequiasConImpuesto - memorialExequialConImpuesto;
    let comisionMemorialMontoImpuesto = comisionMemorialMonto * 0.07;
    let comisionMemorialConImpuesto = comisionMemorialMonto + comisionMemorialMontoImpuesto;
  
    return {
      totalExequiasMonto,
      totalExequiasMontoImpuesto,
      totalExequiasConImpuesto,
      memorialExequialMonto,
      memorialExequialMontoImpuesto,
      memorialExequialConImpuesto,
      comisionMemorialMonto,
      comisionMemorialMontoImpuesto,
      comisionMemorialConImpuesto
    };
  };