import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';
import FieldValue from './FieldValue';

import * as Yup from 'yup'
import FormikControl from '../forms/FormikControl'


const dropdownSiNoOptions = [
    { key: 'S', value: 'Sí' },
    { key: 'N', value: 'No' }
  ]



const WorkForm = (props) => {

    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">            
            <div className="m-4 inline-flex float-right">
                <span className="inline-flex rounded-md shadow-sm">
                    <button type="submit" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                        Salvar
                    </button>
                </span>
            </div>        
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                Datos Laborables
                </h3>
            </div>
            <div className="px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4">    
                <FormikControl
                    control='input'
                    label='Nombre de Empresa / Negocio'
                    name='business_name'
                />
                <FormikControl
                    control='input'
                    label='Antiguedad'
                    name='FechaIniTrabajo'
                />
                <FormikControl
                    control='input'
                    label='Telefono'
                    name='Telefono'
                />
                <FormikControl
                    control='input'
                    label='Correo'
                    name='CorreoAlterPM'
                />
                <FormikControl
                    control='select'
                    label='Es Jubilado'
                    name='EsJubilado'
                    options={dropdownSiNoOptions}      
                />
                <FormikControl
                    control='select'
                    label='Es Pensionado'
                    name='EsPensionado'
                    options={dropdownSiNoOptions}      
                />
                { false &&
                    <div>
                    <FormikControl
                        control='select'
                        label='Registro Ampyme'
                        name='EstaAMPYME'
                        options={dropdownSiNoOptions}      
                    />
                    <FormikControl
                        control='select'
                        label='Paga FECI'
                        name='PagaFECI'
                        options={dropdownSiNoOptions}      
                    />
                    <FormikControl
                        control='input'
                        label='Tipo de Cliente'
                        name='client_type'
                    />
                    <FormikControl
                        control='input'
                        label='Sub Clasificación Cliente'
                        name='subclass_client'
                    />
                    <FormikControl
                        control='input'
                        label='Nro de Empleados'
                        name='employees_count'
                    />
                    <FormikControl
                        control='input'
                        label='Cargo'
                        name='Puesto'
                    />
                    <FormikControl
                        control='input'
                        label='Extensión'
                        name='ExtTelefonoTrab'
                    />
                    <FormikControl
                        control='input'
                        label='Website'
                        name='website'
                    />
                    <FormikControl
                        control='input'
                        label='Tipo de Negocio'
                        name='business_type'
                    />
                    <FormikControl
                        control='input'
                        label='Clasificación'
                        name='Clasificacion'
                    />
                    </div>
                }


                </dl>
            </div>
            {/* <pre>{ JSON.stringify(props.values, null, 2) }</pre> */}
        </div>    
    )

}



export default WorkForm;
