import React from 'react'
import DateView from 'react-datepicker'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment';


function DatePicker (props) {
  const { label, name, obs, ...rest } = props
  return (
    <div className="mb-10 sm:col-span-1">
        <label htmlFor={name} className="block text-sm font-medium leading-5 text-gray-700">{label}</label>
        <div className="mt-1">
            {/* <Field name={`Bancos.${index}.NombreCompleto`} id="banco" className="form-input block w-full sm:text-sm sm:leading-5" placeholder=""/> */}
            <Field name={name}>
              {({ form, field }) => {
                const { setFieldValue } = form
                const { value } = field

                let date = new Date(value); 
                return (
                  <DateView wrapperClassName="min-w-full" className="border-gray-300 rounded-md shadow-sm w-full form-input block min-w-full sm:text-sm sm:leading-5"
                    id={name} 
                    {...field}
                    {...rest}
                    time-zone="false" 
                    selected={(value !== '' && value !== null && value !== undefined) ? date : null}
                    dateFormat="yyyy-MM-dd"
                    onChange={val => setFieldValue(name, moment(val, "yyyy-MM-dd").toDate())}
                  />
                )
              }}
            </Field>
            <span className="text-gray-600">{obs}</span>
            <ErrorMessage component={TextError} name={name} />              
        </div>
    </div>         
  )
}

export default DatePicker
// onChange={val => setFieldValue(name, moment(val).format('YYYY-MM-DD'))}
// "dd.mm.yy"

