import React, {useEffect,useState} from "react";
import Template from "../Template";
import axios from "axios";
import {Bars} from 'react-loader-spinner';

const WalletConciliaciones = (props) => {

  const [currentUser, setCurrentUser] = useState([]);
  const [walletData,setWalletData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [checkedState, setCheckedState] = useState([0]);
  const [isChecked, setIsChecked] = useState({});
  const [monto, setMonto] = useState([0]);
  const [nombreBanco, setNombreBanco] = useState([]);
  const [numeroReferencia, setNumeroReferencia] = useState([]);
  const [detalleNotas, setDetalleNotas] = useState([]);

  const sum =  (checkedState.reduce((result,number)=> parseFloat(result)+parseFloat(number))).toFixed(2);


  const handleCheck = (event) => {
    //console.log(event.target.value.conPack)
    setIsChecked({...isChecked, ...{[event.target.name]: event.currentTarget.checked}});
    var updatedList = [...checkedState];
    if(event.target.checked){
      updatedList = [...checkedState, event.target.value];
    }else{
      updatedList.splice(checkedState.indexOf(event.target.value), 1);
    }
    //console.log(updatedList);
    setCheckedState(updatedList);

  };

  const handleInput = (event) => {
    //console.log(event.target.value);
    setMonto(event.target.value)

    
  }

  useEffect(() => {
    setLoading(true);
    loadConsulta();

    
  }, [])


  const loadConsulta = async () => {
    await axios.get(`/api/v1/safi/get_all_pagos.json`).then((res) => {
        setWalletData(res.data);
        //console.log(`ALLPAGOS`, res.data)
        setLoading(false);
    });
  };

  const recuperarPago = (cotizaID) => {
    
    const token = document.querySelector('[name=csrf-token]').content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token
    axios.post(`/api/v1/safi/update_pago`, {...{cotizaID}, ...{rechazado: 'false'}})

      .then( resp => {

        //navigator.clipboard.writeText('https://www.sumafinanciera.com/landing/index.php?_id='+cotizaID)

        loadConsulta()
        setLoading(false);

      })
      .catch( data => console.log('error', data))
  }




    return (
      <>
        {loading ?
          <div className="flex h-screen">
            <div className="m-auto">
              <Bars
                color="#00BFFF"
                height={100}
                width={100}
                timeout={20000} //3 secs
              />
            </div>
          </div>
         :

          <div className="overflow-y-auto">





            <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                  Pagos Rechazados
                </h3>
              </div>

              <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
                <div className="ml-2 mt-2 w-full mr-2">
                  <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Número de Referencia
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Tipo de Pago
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            No. de Crédito
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Nombre de Cliente
                          </th>

                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Depositante
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Banco Receptor
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            No. Libreta de Cobro
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Monto Pagado
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Comprobante
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Seleccione
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {walletData.pagos.map((walletConcilia, index) => {
                        
                       let conPack = {
                         cotizaID : walletConcilia.pago._id.$oid,
                         tipoPago: walletConcilia.pago.tipoPago,
                         credito: walletConcilia.pago.creditNumber,
                         monto: walletConcilia.pago.amount
                       }

                       if(walletConcilia.pago.conciliado === 'false' && walletConcilia.pago.rechazado === 'true'){

                        return(
                          
                              <tr
                              key={walletConcilia.pago._id.$oid}
                              className="bg-green border-b border-gray-200"

                            >
                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {walletConcilia.pago.referenciaTransferencia}

                              </td>

                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                              {walletConcilia.pago.tipoPago}
                              </td>

                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {walletConcilia.pago.creditNumber}
                              </td>

                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                              {walletConcilia.pago.nombreCliente}
                              </td>

                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {walletConcilia.pago.nombreDepositante}
                              </td>

                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {walletConcilia.pago.bancoReceptor}

                              </td>

                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {walletConcilia.pago.libretaCobro}

                              </td>

                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                              }).format(walletConcilia.pago.amount)}

                              </td>
                              
                              <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                              {walletConcilia.pago.attachments.map((attachment, index) => {
                                return(
                                  <div key={index}>
                                    <a className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" href={attachment} target="_blank">{`Anexo`}</a>
                                  </div>
                                )
                              })}
                              </td>

                              <td className="max-w-xs px-6 py-3 text-xs leading-5 text-gray-500 text-center">
                                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => recuperarPago(walletConcilia.pago._id.$oid)}>Recuperar</button>
                              </td>

                            </tr>

                        )
                        }
                        

                        })
                      
                        }
                            

                          

                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
          </div>
}
      </>
    );

}

export default WalletConciliaciones;
